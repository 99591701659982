import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Spacer from "../../../components/utils/spacer.component";
import TableHeader from "../../../components/tables/table-header.component";

function StatementRow({ record }) {
  const [collapse, setCollapse] = useState(false);
  const theme = useTheme();

  const renderStatus = () => {
    if (record.status === "dispute") {
      return (
        <Typography
          variant="body2"
          sx={{ textTransform: "capitalize", color: theme.palette.colors.text.error }}
        >
          {record.status}
        </Typography>
      );
    }
    if (record.status === "open") {
      return (
        <Typography
          variant="body2"
          sx={{ textTransform: "capitalize", color: theme.palette.colors.text.success }}
        >
          {record.status}
        </Typography>
      );
    }
    if (record.status === "pending") {
      return (
        <Typography
          variant="body2"
          sx={{ textTransform: "capitalize", color: theme.palette.colors.text.link }}
        >
          {record.status}
        </Typography>
      );
    }
    return (
      <Typography
        variant="body2"
        sx={{ textTransform: "capitalize", color: theme.palette.colors.text.purple }}
      >
        {record.status}
      </Typography>
    );
  };

  return (
    <>
      <TableRow
        key={record.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "0px",
          },
        }}
      >
        <TableCell width="150px">
          <Typography variant="body2">{record.id}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
            {record.merchant.businessName}
          </Typography>
        </TableCell>
        <TableCell>{renderStatus()}</TableCell>
        <TableCell>
          <Typography variant="body2">RM {record.amount}</Typography>
        </TableCell>
        <TableCell>
          <Link
            to={`/statements/detail?statementId=${record.id}`}
            style={{ textDecorationColor: theme.palette.colors.brand.primary }}
          >
            <Typography
              variant="body2"
              noWrap={true}
              sx={{
                color: theme.palette.colors.brand.primary,
              }}
            >
              View Detail
            </Typography>
          </Link>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
              {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Spacer size="m" />
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography>Statement Details</Typography>
            </Box>
            <Spacer />
            <Table size="small">
              <TableHeader
                headerCells={[
                  "Transaction ID",
                  "Description / Commission",
                  "Date Created",
                  "Amount (RM)",
                ]}
                nonSortableColumns={[
                  "Transaction ID",
                  "Description / Commission",
                  "Date Created",
                  "Amount (RM)",
                ]}
              />
              <TableBody>
                {record.transactions.map((item, index) => {
                  if (index > 3) {
                    return (
                      <TableRow key={item.id}>
                        <TableCell colSpan={4}>
                          <Link
                            to={`/statements/detail?statementId=${record.id}`}
                            style={{ textDecorationColor: theme.palette.colors.brand.primary }}
                          >
                            <Typography
                              variant="body2"
                              noWrap={true}
                              sx={{
                                textAlign: "center",
                                color: theme.palette.colors.brand.primary,
                              }}
                            >
                              View Detail for more transactions
                            </Typography>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        <Typography variant="body2" noWrap={true}>
                          {item.id}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap={true} sx={{ width: "350px" }}>
                          {item.category === "merchant_sale"
                            ? `${item.itemTitle}`
                            : `${item.description}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap={true}>
                          {item.createdAt}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          noWrap={true}
                          sx={{
                            color: item.amount.includes("-")
                              ? theme.palette.colors.text.error
                              : theme.palette.colors.text.success,
                          }}
                        >
                          {item.amount.includes("-") ? "-" : "+"}
                          {item.amount.replace("-", "")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Spacer size="m" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

StatementRow.defaultProps = {
  record: {
    id: null,
    businessName: PropTypes.string,
    status: PropTypes.string,
    amount: PropTypes.string,
    transactions: PropTypes.arrayOf(
      PropTypes.shape({
        id: null,
        amount: PropTypes.string,
        category: PropTypes.string,
        description: PropTypes.string,
        createdAt: PropTypes.string,
      }),
    ),
  },
};

StatementRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    merchant: PropTypes.shape({
      name: PropTypes.string,
      businessName: PropTypes.string,
      bankAccountName: PropTypes.string,
      bankAccountNo: PropTypes.string,
      bankName: PropTypes.string,
    }),
    status: PropTypes.string,
    amount: PropTypes.string,
    transactions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        category: PropTypes.string,
        description: PropTypes.string,
        itemTitle: PropTypes.string,
        amount: PropTypes.string,
        createdAt: PropTypes.string,
      }),
    ),
  }),
};

export default StatementRow;
