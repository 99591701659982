import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import EditImageCropper from "./edit-image-cropper.component";
import Spacer from "../../../../components/utils/spacer.component";
import { merchantBusinessSelector } from "../../../../services/merchant/business/merchant-business-slice.service";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const UploadButtonContainer = styled(Button)(({ theme }) => ({
  minHeight: "140px",
  backgroundColor: "transparent",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  border: `2px dashed ${theme.palette.colors.ui.border}`,
  color: theme.palette.colors.ui.border,
  height: "100%",
  "&:hover": {
    background: "transparent",
  },
  marginTop: "-3px",
}));

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  stroke: theme.palette.colors.ui.white,
  strokeWidth: 2,
  fontSize: "16px",
}));

const StyledButtonIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  padding: "0px",
  top: "10px",
  right: "10px",
  backgroundColor: theme.palette.colors.brand.primary,
  width: "25px",
  height: "25px",
  ":hover": { backgroundColor: theme.palette.colors.brand.primary },
}));

function MerchantImageEdit({ name, onRemoveImage }) {
  const theme = useTheme();
  const { setFieldError, errors, touched, setFieldTouched } = useFormikContext();
  const [image, setImage] = useState("");
  const [showCropper, setShowCropper] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isShowTwoItems = useMediaQuery(theme.breakpoints.down("1150"));
  const showError = touched[name] && typeof errors[name] === "string";
  const { getMerchantBusinessDetailObj } = useSelector(merchantBusinessSelector);

  const getItemToShow = () => {
    if (isMobile) {
      return 12;
    }
    if (isShowTwoItems) {
      return 6;
    }
    return 3;
  };

  const inputButton = () => (
    <input
      type="file"
      name={name}
      hidden
      accept="image/*"
      onClick={(event) => {
        event.target.value = null;
      }}
      onBlur={() => setFieldTouched(name)}
      onChange={(e) => {
        const { files } = e.target;
        if (files) {
          const selectedFIles = [];
          const targetFilesObject = [...files];
          targetFilesObject.map((file) => selectedFIles.push(file));
          const withinFileSizeLimit = selectedFIles.some((item) => item.size <= 5000000); // 1mb = 1^6 bytes
          const allowedFileType = selectedFIles.some((item) =>
            SUPPORTED_FORMATS.includes(item.type),
          );
          if (withinFileSizeLimit && allowedFileType) {
            setImage(URL.createObjectURL(selectedFIles[0]));
            setShowCropper(true);
          } else if (!withinFileSizeLimit) {
            setFieldError(name, "Image file cannot be more than 5mb");
          } else if (!allowedFileType) {
            setFieldError(name, `Only ${SUPPORTED_FORMATS} are allowed`);
          }
        }
      }}
    />
  );

  const renderUploadButton = () => (
    <UploadButtonContainer onClick={() => setFieldError(name, "")} component="label" fullWidth>
      <AddCircleOutlineRoundedIcon />
      {inputButton()}
    </UploadButtonContainer>
  );

  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={2}
          sx={{
            alignSelf: "flex-end",
          }}
        >
          <LabelContainer>
            <Typography>Images</Typography>
          </LabelContainer>
        </Grid>
        <Grid item xs={2}>
          {allowEdit ? (
            <>
              <IconButton size="small" onClick={() => setAllowEdit(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                onClick={() => {
                  setAllowEdit(true);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>

      <Typography sx={{ marginTop: "-10px" }}>(Maximum 5 photos)</Typography>
      {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      <Spacer size="s" position="top" />
      <Grid container spacing={1}>
        <EditImageCropper
          name={name}
          setIsShowModal={setShowCropper}
          isShowModal={showCropper}
          imageFile={image}
        />

        {getMerchantBusinessDetailObj.data.images.map((item) => (
          <Grid item xs={getItemToShow()} key={item.id}>
            <Box sx={{ position: "relative" }}>
              {allowEdit && (
                <StyledButtonIcon onClick={() => onRemoveImage(item.id)}>
                  <StyledCloseIcon />
                </StyledButtonIcon>
              )}
              <img
                src={item.imagePath}
                alt="business-img"
                style={{
                  borderTopLeftRadius: theme.shape.borderRadius[0],
                  borderTopRightRadius: theme.shape.borderRadius[0],
                  borderBottomLeftRadius: theme.shape.borderRadius[0],
                  borderBottomRightRadius: theme.shape.borderRadius[0],
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        ))}
        {getMerchantBusinessDetailObj.data.images.length < 5 && (
          <Grid item xs={getItemToShow()}>
            {allowEdit && renderUploadButton()}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

MerchantImageEdit.propTypes = {
  onRemoveImage: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default MerchantImageEdit;
