import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Breadcrumbs, Stack, Link, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import PaddedView from "../../../components/utils/padded-view.component";
import {
  getMerchantDetail,
  merchantSelector,
} from "../../../services/merchant/merchant-slice.service";
import MerchantDetails from "../components/merchant-edit/merchant-details.component";
import MerchantEditLoader from "../loader/merchant-edit-loader.component";

function MerchantEditScreen() {
  const dispatch = useDispatch();
  const location = useLocation();
  const merchantId = new URLSearchParams(location.search).get("merchantId");
  const { getMerchantDetailObj } = useSelector(merchantSelector);

  useEffect(() => {
    dispatch(getMerchantDetail(merchantId));
  }, []);

  return (
    <>
      <Box>
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Merchant Edit</Typography>
            </Grid>
            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link underline="hover" key="2" color="inherit" href="/merchants/list">
                    <Typography variant="body1">Merchant List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    Merchant Edit
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
        </PaddedView>
      </Box>

      {getMerchantDetailObj.status === "succeeded" ? (
        <>
          <MerchantDetails record={getMerchantDetailObj.data} />
        </>
      ) : (
        <MerchantEditLoader />
      )}
    </>
  );
}

export default MerchantEditScreen;
