import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../../api.service";

export const getMerchantSubscriptionSubscribers = createAsyncThunk(
  "merchants/subscription/subscriber",
  async (payload) => {
    const { merchantsId, q, plansId, statuses } = payload;
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/subscriptions?page=${
        payload.page + 1
      }${q ? "&q=".concat(q) : ""}${merchantsId ? "&merchantsId=".concat(merchantsId) : ""}${
        plansId ? "&plansId=".concat(plansId) : ""
      }${statuses ? "&statuses=".concat(statuses) : ""}`,
    );
    return response;
  },
);

const merchantSubscriptionSlice = createSlice({
  name: "merchantSubscription",
  initialState: {
    getMerchantSubscriptionSubscribersObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getMerchantSubscriptionSubscribers.pending]: (state) => {
      state.getMerchantSubscriptionSubscribersObj.status = "pending";
    },
    [getMerchantSubscriptionSubscribers.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantSubscriptionSubscribersObj.status = "succeeded";
      state.getMerchantSubscriptionSubscribersObj.data = data;
      state.getMerchantSubscriptionSubscribersObj.successMessage = message;
    },
    [getMerchantSubscriptionSubscribers.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantSubscriptionSubscribersObj.status = "failed";
      state.getMerchantSubscriptionSubscribersObj.errorMessage = message;
    },
  },
});

export default merchantSubscriptionSlice.reducer;

// state
export const merchantSubscriptionSelector = (state) => state.merchantSubscription;
