import React from "react";
import { styled, Box } from "@mui/material";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const StyledFowardButton = styled(ArrowForwardIosIcon)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  backgroundColor: "rgba(0,0,0,0.5)",
  borderRadius: theme.shape.borderRadius[4],
  height: "40px",
  width: "40px",
  padding: "10px",
  right: 34,
  position: "absolute",
  "&:hover": {
    color: theme.palette.colors.text.white,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  "&:active": {
    color: theme.palette.colors.text.white,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
}));

const StyledArrowBackIosNewIcon = styled(ArrowBackIosNewIcon)(({ theme }) => ({
  color: theme.palette.colors.text.white,
  backgroundColor: "rgba(0,0,0,0.5)",
  borderRadius: theme.shape.borderRadius[4],
  height: "40px",
  width: "40px",
  padding: "10px",
  left: 35,
  position: "absolute",
  "&:hover": {
    color: theme.palette.colors.text.white,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  "&:active": {
    color: theme.palette.colors.text.white,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
}));

function SlickArrow(arrowProps) {
  const { children, currentSlide, slideCount, ...props } = arrowProps;
  return (
    <StyledButtonBox {...props} type="button">
      {children}
    </StyledButtonBox>
  );
}

const StyledButtonBox = styled(Box)({
  zIndex: 1,
  marginTop: "-25px",
});

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: (
    <SlickArrow>
      <StyledFowardButton />
    </SlickArrow>
  ),
  prevArrow: (
    <SlickArrow>
      <StyledArrowBackIosNewIcon />
    </SlickArrow>
  ),
};

function ImageBanner({ attachment }) {
  return (
    <Slider {...settings}>
      {attachment.map((item) => (
        <Box key={item.id}>
          <img
            src={item.path}
            alt="image_banner"
            width="100%"
            style={{ maxHeight: "450px", objectFit: "contain" }}
          />
        </Box>
      ))}
    </Slider>
  );
}

ImageBanner.propTypes = {
  attachment: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ImageBanner;
