import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../../api.service";

export const createClass = createAsyncThunk(
  "employees/merchants/fitness_classes/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/fitness_classes`,
      payload,
    );
    return response;
  },
);

export const getClassAutoComplete = createAsyncThunk(
  "employees/merchants/fitness_classes/auto_complete",
  async (payload) => {
    const { q, page, merchantsId, businessesId } = payload;
    const response = await getReq(
      `${
        process.env.REACT_APP_API_PRIVATE_V1
      }/employees/merchants/fitness_classes/auto_complete?page=${page}${q ? "&q=".concat(q) : ""}${
        merchantsId ? "&merchantsId=".concat(merchantsId) : ""
      }${businessesId ? "&businessesId=".concat(businessesId) : ""}`,
    );
    return response;
  },
);

export const updateClass = createAsyncThunk(
  "employees/merchants/fitness_classes/update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/fitness_classes/${payload.classId}`,
      payload,
    );
    return response;
  },
);

export const getClasses = createAsyncThunk(
  "employees/merchants/fitness_classes/index",
  async (payload) => {
    const { q, categoriesId, merchantsId, businessesId, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/fitness_classes/?page=${
        page + 1
      }${q ? "&q=".concat(q) : ""}${categoriesId ? "&categoriesId=".concat(categoriesId) : ""}${
        merchantsId ? "&merchantsId=".concat(merchantsId) : ""
      }${businessesId ? "&businessesId=".concat(businessesId) : ""}`,
    );
    return response;
  },
);

export const getClassDetail = createAsyncThunk(
  "employees/merchants/fitness_classes/detail",
  async (payload) => {
    const { classId } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/fitness_classes/${classId}`,
    );
    return response;
  },
);

export const deleteClass = createAsyncThunk(
  "employees/merchants/fitness_classes/delete",
  async (payload) => {
    const { classId } = payload;
    const response = delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/fitness_classes/${classId}`,
    );
    return response;
  },
);

const fitnessClassSlice = createSlice({
  name: "fitnessClass",
  initialState: {
    createClassObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getClassesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateClassObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getClassAutoCompleteObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getClassDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteClassObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [createClass.pending]: (state) => {
      state.createClassObj.status = "pending";
    },
    [createClass.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createClassObj.status = "succeeded";
      state.createClassObj.data = data;
      state.createClassObj.successMessage = message;
    },
    [createClass.rejected]: (state, action) => {
      const { message } = action.error;

      state.createClassObj.status = "failed";
      state.createClassObj.errorMessage = message;
    },
    [getClasses.pending]: (state) => {
      state.getClassesObj.status = "pending";
    },
    [getClasses.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getClassesObj.status = "succeeded";
      state.getClassesObj.data = data;
      state.getClassesObj.successMessage = message;
    },
    [getClasses.rejected]: (state, action) => {
      const { message } = action.error;

      state.getClassesObj.status = "failed";
      state.getClassesObj.errorMessage = message;
    },
    [updateClass.pending]: (state) => {
      state.updateClassObj.status = "pending";
    },
    [updateClass.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateClassObj.status = "succeeded";
      state.updateClassObj.data = data;
      state.updateClassObj.successMessage = message;
    },
    [updateClass.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateClassObj.status = "failed";
      state.updateClassObj.errorMessage = message;
    },
    [getClassAutoComplete.pending]: (state) => {
      state.getClassAutoCompleteObj.status = "pending";
    },
    [getClassAutoComplete.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getClassAutoCompleteObj.status = "succeeded";
      state.getClassAutoCompleteObj.data = data;
      state.getClassAutoCompleteObj.successMessage = message;
    },
    [getClassAutoComplete.rejected]: (state, action) => {
      const { message } = action.error;

      state.getClassAutoCompleteObj.status = "failed";
      state.getClassAutoCompleteObj.errorMessage = message;
    },
    [getClassDetail.pending]: (state) => {
      state.getClassDetailObj.status = "pending";
    },
    [getClassDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getClassDetailObj.status = "succeeded";
      state.getClassDetailObj.data = data;
      state.getClassDetailObj.successMessage = message;
    },
    [getClassDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getClassDetailObj.status = "failed";
      state.getClassDetailObj.errorMessage = message;
    },
    [deleteClass.pending]: (state) => {
      state.deleteClassObj.status = "pending";
    },
    [deleteClass.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteClassObj.status = "succeeded";
      state.deleteClassObj.data = data;
      state.deleteClassObj.successMessage = message;
    },
    [deleteClass.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteClassObj.status = "failed";
      state.deleteClassObj.errorMessage = message;
    },
  },
});

export default fitnessClassSlice.reducer;

// state
export const fitnessClassSelector = (state) => state.merchantFitnessClass;
