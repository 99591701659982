import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, styled, TableCell, TableRow, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterIcon from "@mui/icons-material/Filter";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import { Link } from "react-router-dom";

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

function PopUpRow({ record, onClickDelete, onPreviewClicked }) {
  return (
    <TableRow key={record.id}>
      <TableCell width="150px">
        <Typography variant="body2" noWrap={true}>
          {record.id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.slug}
        </Typography>
      </TableCell>
      <TableCell width="150px">
        <Typography variant="body2" noWrap={true}>
          {record.type === "images" ? (
            <IconButton
              sx={{ padding: "0px" }}
              onClick={() => onPreviewClicked("images", record.attachments)}
            >
              <FilterIcon />
            </IconButton>
          ) : (
            <IconButton
              sx={{ padding: "0px" }}
              onClick={() => onPreviewClicked("video", record.attachments)}
            >
              <SmartDisplayIcon />
            </IconButton>
          )}
        </Typography>
      </TableCell>

      <TableCell width="150px">
        <RowBox>
          <Link to={`/pop_up/details/${record.id}/edit`} state={{ id: record.id }}>
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>

          <IconButton onClick={() => onClickDelete(record.id)}>
            <DeleteIcon />
          </IconButton>
        </RowBox>
      </TableCell>
    </TableRow>
  );
}

PopUpRow.propTypes = {
  onPreviewClicked: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    slug: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default PopUpRow;
