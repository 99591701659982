import { TableCell, TableRow, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function CreditTransactionsRow({ record }) {
  return (
    <TableRow
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "unset",
        },
      }}
    >
      <TableCell>
        <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
          {record.user.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
          {record.type.replace("_", " ")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.amount}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.description}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.createdAt}</Typography>
      </TableCell>
    </TableRow>
  );
}

CreditTransactionsRow.defaultProps = {
  record: {
    id: null,
    type: "",
    amount: "",
    description: "",
    createdAt: "",
    user: {
      id: null,
      name: "",
    },
  },
};

CreditTransactionsRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    amount: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }),
};

export default CreditTransactionsRow;
