import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Breadcrumbs,
  Stack,
  Link,
  Grid,
  styled,
  Avatar,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import PaddedView from "../../../components/utils/padded-view.component";
import { getUser, userSelector, deleteUserImage } from "../../../services/user/user-slice.service";
import AlertDialog from "../../../components/notification/dialog-boxes.component";
import UserDetails from "../components/user-edit/user-details.component";

const AvatarBox = styled(Box)((props) => ({
  position: "relative",
  "& .MuiIconButton-root": {
    ":hover": { backgroundColor: props.theme.palette.colors.brand.primary },
  },
}));

const StyledIconButton = styled(IconButton)((props) => ({
  position: "absolute",
  bottom: 0,
  background: props.theme.palette.colors.brand.primary,
  right: 0,
  height: 35,
  width: 35,
}));

const WhiteDeleteIcon = styled(DeleteIcon)((props) => ({
  color: props.theme.palette.colors.brand.white,
}));

function UserEditScreen() {
  const dispatch = useDispatch();
  const params = useParams();
  const { getUserObj, deleteUserImageObj } = useSelector(userSelector);
  const [isShowAlert, setIsShowAlert] = useState(false);

  useEffect(() => {
    dispatch(getUser(params.id));
  }, []);

  const confirmDeleteImage = () => {
    dispatch(deleteUserImage(params.id)).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsShowAlert(false);
        dispatch(getUser(params.id));
      }
    });
  };

  return (
    <>
      <AlertDialog
        isShow={isShowAlert}
        setIsShowAlert={setIsShowAlert}
        isConfirmHandle={confirmDeleteImage}
        title="Confirmation"
        message="Are you sure to delete this user's image?"
        buttonText="Confirm"
        disabled={deleteUserImageObj.status === "pending"}
      />
      <Box>
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">User Edit</Typography>
            </Grid>
            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link underline="hover" key="2" color="inherit" href="/users">
                    <Typography variant="body1">User List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    User Edit
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
        </PaddedView>
      </Box>

      {getUserObj.status === "succeeded" ? (
        <>
          <Grid item xs={12} sm={12}>
            <Grid container justifyContent="center">
              <AvatarBox>
                {getUserObj.data && getUserObj.data.user.imagePath ? (
                  <Avatar sx={{ width: 100, height: 100 }} src={getUserObj.data.user.imagePath} />
                ) : (
                  <Avatar sx={{ width: 100, height: 100 }}>
                    {getUserObj.data.user.firstName?.charAt().toUpperCase()}
                    {getUserObj.data.user.lastName?.charAt().toUpperCase()}
                  </Avatar>
                )}
                {getUserObj.data && getUserObj.data.user.imagePath && (
                  <StyledIconButton onClick={() => setIsShowAlert(true)}>
                    <WhiteDeleteIcon />
                  </StyledIconButton>
                )}
              </AvatarBox>
            </Grid>
          </Grid>
          <UserDetails record={getUserObj.data} />
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default UserEditScreen;
