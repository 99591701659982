import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, useMediaQuery, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import VerificationBlocks from "./code-verification-blocks.component";
import CreateNewPassword from "./create-new-password-form.component";

function EnterVerificationForm({
  userEmail,
  resendVerificationCode,
  handleSubmit,
  backButtonHandle,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showPasswordAlert, setShowPasswordAlert] = useState(false);
  const [showCodeAlert, setShowCodeAlert] = useState(false);
  let testing = [];

  const getVerificationCodes = (values) => {
    testing = values;
  };

  const submitHandle = (values) => {
    if (values.password !== values.confirmPassword) {
      setShowPasswordAlert(true);
      return;
    }
    if (values.password === values.confirmPassword) {
      setShowPasswordAlert(false);
    }
    if (testing.length !== 6) {
      setShowCodeAlert(true);
      return;
    }
    values.verificationCode = testing.join("").toString();

    handleSubmit(values);
  };

  return (
    <PaddedView multiples={isMobile ? 3 : 4}>
      <Typography variant="h5">Enter OTP code</Typography>
      <Spacer size="xs" />
      <Typography variant="body2">An email with OTP code has been to </Typography>
      <Typography variant="body2">{userEmail}</Typography>
      <Spacer size="l" />

      <VerificationBlocks
        verificationCodeSubmitHandle={getVerificationCodes}
        showAlert={showCodeAlert}
        setShowAlert={setShowCodeAlert}
      />

      <CreateNewPassword handleSubmit={submitHandle} showAlert={showPasswordAlert} />

      <Spacer size="l" />
      <Grid>
        <Grid container justifyContent="space-between">
          <Grid>
            <Button onClick={() => backButtonHandle()}>
              <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
                {"< Back"}
              </Typography>
            </Button>
          </Grid>
          <Grid>
            <Button onClick={() => resendVerificationCode({ email: userEmail })}>
              <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
                Resend Code
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </PaddedView>
  );
}

EnterVerificationForm.propTypes = {
  userEmail: PropTypes.string.isRequired,
  resendVerificationCode: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  backButtonHandle: PropTypes.func.isRequired,
};

export default EnterVerificationForm;
