import { TableCell, TableRow, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function PromoCodeRedemptionRow({ record }) {
  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {record.id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {record.userId}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {record.username}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {record.status.toUpperCase().replace("_", " ")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {record.dateRedeemed}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          RM {record.discountAmount}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {record.purchaseItem}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

PromoCodeRedemptionRow.defaultProps = {
  record: {
    id: "",
    status: "",
    dateRedeemed: "",
    userId: "",
    username: "",
  },
};

PromoCodeRedemptionRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    dateRedeemed: PropTypes.string,
    userId: PropTypes.number,
    username: PropTypes.string,
    discountAmount: PropTypes.string,
    purchaseItem: PropTypes.string,
  }),
};

export default PromoCodeRedemptionRow;
