import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { FormLabel, Grid, IconButton } from "@mui/material";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { SnackbarContext } from "../../../../../../../components/notification/snackbar.context";
import PaddedView from "../../../../../../../components/utils/padded-view.component";
import Spacer from "../../../../../../../components/utils/spacer.component";
import { updatePackagePlanItem } from "../../../../../../../services/merchant/package/plan/item/plan-item-slice.service";
import SubscriptionPlanSpecificListAutoComplete from "../../../../components/subscription-plan-specific-list-autocomplete.component";

const validationSchema = Yup.object().shape({
  subscriptionPlansId: Yup.array().label("Subscription Plans"),
});

function PlanItem({ record }) {
  const [allowEdit, setAllowEdit] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);

  const onHandleSubmit = (values) => {
    setAllowEdit(false);
    const payloadValues = {
      ...values,
      subscriptionPlansId: values.subscriptionPlansId.map((item) => item.id),
    };
    dispatch(updatePackagePlanItem(payloadValues)).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({ type: "success", message: payload.message });
      }
    });
  };

  return (
    <>
      <PaddedView>
        <Formik
          validationSchema={validationSchema}
          onSubmit={onHandleSubmit}
          initialValues={{
            planId: record.id,
            subscriptionPlansId: record.items.map((item) => ({
              id: item.id,
              option: item.name,
            })),
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Grid item container sx={{ width: 200 }}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      <FormLabel>Items</FormLabel>
                    </Grid>
                    <Grid item container xs={6} sx={{ justifyContent: "flex-end" }}>
                      {allowEdit ? (
                        <>
                          <IconButton size="small" onClick={handleSubmit}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setAllowEdit(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAllowEdit(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2}>
                    <Grid item xs={12}>
                      <Spacer size="m" />
                      <SubscriptionPlanSpecificListAutoComplete
                        disabled={!allowEdit}
                        name="subscriptionPlansId"
                        placeholder="Subscription Plans"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </PaddedView>
    </>
  );
}

PlanItem.propTypes = {
  record: PropTypes.shape({
    deletable: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    imagePath: PropTypes.string.isRequired,
    numberOfPurchasers: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    priceCents: PropTypes.number.isRequired,
    numberOfSessions: PropTypes.number.isRequired,
    status: PropTypes.string,
    title: PropTypes.string.isRequired,
    merchant: PropTypes.shape({
      id: PropTypes.number.isRequired,
      businessName: PropTypes.string.isRequired,
    }),
    planDetails: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
      }),
    ).isRequired,
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.string,
      }),
    ).isRequired,
    category: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
          imagePath: PropTypes.string,
        }),
      ),
    }).isRequired,
  }).isRequired,
};

export default PlanItem;
