import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";
import { darkColors } from "./dark-colors";
import { borderSizes } from "./borders";

const theme = createTheme({
  palette: {
    colors,
    darkColors,
  },
  shape: {
    borderRadius: borderSizes,
  },
  mixins: {
    toolbar: {
      minHeight: 76,
      maxHeight: 80,
    },
  },
});

export default theme;
