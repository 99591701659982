import React, { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import {
  Box,
  Breadcrumbs,
  FormLabel,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import SimpleBackdrop from "../../../components/utils/backdrop-loading.component";
import PaddedView from "../../../components/utils/padded-view.component";
import {
  getMerchantDetail,
  merchantSelector,
  updateMerchant,
} from "../../../services/merchant/merchant-slice.service";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import OutlinedSelectTextField from "../../../components/forms/outlined-select-text-field.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormFieldNumber from "../../../components/forms/form-field-number.component";

const BANKLIST = [
  { type: "Affin Bank", value: "Affin Bank" },
  { type: "Agro Bank", value: "Agro Bank" },
  { type: "Al-Rajhi Malaysia", value: "Al-Rajhi Malaysia" },
  { type: "Alliance Bank Malaysia Berhad", value: "Alliance Bank Malaysia Berhad" },
  { type: "AmBank", value: "AmBank" },
  { type: "Bank Islam Malaysia", value: "Bank Islam Malaysia" },
  { type: "Bank Muamalat Malaysia Berhad", value: "Bank Muamalat Malaysia Berhad" },
  { type: "Bank Rakyat", value: "Bank Rakyat" },
  { type: "Bank Simpanan Nasional (BSN)", value: "Bank Simpanan Nasional (BSN)" },
  { type: "CIMB Bank", value: "CIMB Bank" },
  { type: "Citibank", value: "Citibank" },
  { type: "Co-op Bank Pertama", value: "Co-op Bank Pertama" },
  { type: "Hong Leong Bank", value: "Hong Leong Bank" },
  { type: "HSBC Bank Malaysia", value: "HSBC Bank Malaysia" },
  { type: "Maybank", value: "Maybank" },
  { type: "MBSB Bank", value: "MBSB Bank" },
  { type: "OCBC Bank", value: "OCBC Bank" },
  { type: "Public Bank", value: "Public Bank" },
  { type: "RHB Bank", value: "RHB Bank" },
  { type: "Standard Chartered Bank", value: "Standard Chartered Bank" },
  { type: "UOB Malaysia", value: "UOB Malaysia" },
];

const validationSchema = Yup.object().shape({
  accountNo: Yup.number().nullable().required().label("Account Number"),
  bankName: Yup.string().nullable().required().label("Bank Name"),
  accountHolderName: Yup.string().nullable().required().label("Account Holder Name"),
});

function MerchantBankEditScreen() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const { merchantId } = useParams();
  const { getMerchantDetailObj } = useSelector(merchantSelector);
  const createSnackBar = useContext(SnackbarContext);

  useEffect(() => {
    dispatch(getMerchantDetail(merchantId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  }, []);

  const onUpdateMerchantBankDetails = (values) => {
    setIsLoading(true);
    dispatch(updateMerchant(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setAllowEdit(false);
        createSnackBar({ type: "success", message: payload.message });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  };

  return (
    <Box>
      <SimpleBackdrop isLoading={isLoading} />
      <>
        <PaddedView>
          {getMerchantDetailObj.status === "succeeded" ? (
            <>
              <Formik
                onSubmit={onUpdateMerchantBankDetails}
                validationSchema={validationSchema}
                initialValues={{
                  merchantId,
                  commissionPercentage: getMerchantDetailObj.data.commissionPercentage,
                  businessName: getMerchantDetailObj.data.name,
                  firstName: getMerchantDetailObj.data.firstName,
                  lastName: getMerchantDetailObj.data.lastName,
                  bankName: getMerchantDetailObj.data.bank.name,
                  accountNo: getMerchantDetailObj.data.bank.account,
                  accountHolderName: getMerchantDetailObj.data.bank.holderName,
                  phone: getMerchantDetailObj.data.phoneNo,
                  email: getMerchantDetailObj.data.email,
                  status: getMerchantDetailObj.data.status,
                  rejectReason:
                    getMerchantDetailObj.data.rejections?.length > 0
                      ? getMerchantDetailObj.data.rejections[
                          getMerchantDetailObj.data.rejections.length - 1
                        ].reason
                      : "",
                  password: "",
                }}
              >
                {({ handleSubmit, dirty }) => (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Typography variant="h6">Merchant Bank Edit</Typography>
                          </Grid>
                          <Grid item sx={{ alignSelf: "center" }}>
                            <Stack spacing={2} sx={{ paddingRight: 1 }}>
                              <Breadcrumbs separator=">" aria-label="breadcrumb">
                                <Link underline="hover" key="1" color="inherit" href="/">
                                  <Typography variant="body1">Dashboard</Typography>
                                </Link>
                                <Link
                                  underline="hover"
                                  key="2"
                                  color="inherit"
                                  href="/merchants/list"
                                >
                                  <Typography variant="body1">Merchant List</Typography>
                                </Link>
                                <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                                  Merchant Bank Edit
                                </Typography>
                              </Breadcrumbs>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid
                            item
                            xs={2}
                            sx={{
                              alignSelf: "flex-end",
                            }}
                          >
                            <FormLabel>Details</FormLabel>
                          </Grid>
                          <Grid item xs={2}>
                            {allowEdit ? (
                              <>
                                <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                  <CheckIcon fontSize="small" />
                                </IconButton>
                                <IconButton size="small" onClick={() => setAllowEdit(false)}>
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setAllowEdit(true);
                                  }}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={10}>
                            <OutlinedSelectTextField
                              name="bankName"
                              label="Bank Name"
                              selections={BANKLIST}
                              disabled={!allowEdit}
                            />
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <FormFieldNumber
                              label="Bank Account Number"
                              placeholder="Bank Account Number"
                              name="accountNo"
                              disabled={!allowEdit}
                            />
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <FormFieldText
                              label="Account Holder Name"
                              placeholder="Account Holder Name"
                              name="accountHolderName"
                              disabled={!allowEdit}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Formik>
            </>
          ) : (
            <></>
          )}
        </PaddedView>
      </>
    </Box>
  );
}

export default MerchantBankEditScreen;
