import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, styled, TableCell, TableRow, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

function AnnouncementRow({ record, onClickDelete }) {
  return (
    <TableRow key={record.id}>
      <TableCell width="150px">
        <Typography variant="body2" noWrap={true}>
          {record.id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.header}
        </Typography>
      </TableCell>
      <TableCell width="200px">
        <Typography variant="body2" noWrap={true}>
          {record.createdAt}
        </Typography>
      </TableCell>

      <TableCell width="200px">
        <RowBox>
          <Link to={`/announcement/details/${record.id}`} state={{ id: record.id }}>
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>

          <IconButton onClick={() => onClickDelete(record.id)}>
            <DeleteIcon />
          </IconButton>
        </RowBox>
      </TableCell>
    </TableRow>
  );
}

AnnouncementRow.propTypes = {
  onClickDelete: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    header: PropTypes.string,
    subheader: PropTypes.string,
    content: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
};

export default AnnouncementRow;
