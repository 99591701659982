import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Breadcrumbs, Stack, Link, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import PaddedView from "../../../components/utils/padded-view.component";
import MerchantBusinessDetails from "../components/merchant-business-edit/merchant-business-details.component";
import Form from "../../../components/forms/form.component";
import ConfirmationModal from "../../../components/notification/confirmation-modal.component";
import MerchantImageEdit from "../components/merchant-business-edit/merchant-image-edit.component";
import {
  getMerchantBusinessDetail,
  merchantBusinessSelector,
} from "../../../services/merchant/business/merchant-business-slice.service";
import {
  createMerchantBusinessImage,
  merchantBusinessImageSelector,
  removeMerchantBusinessImage,
} from "../../../services/merchant/business/image/merchant-business-image-slice.service";
import {
  getMerchantBusinessAmenities,
  merchantBusinessAmenitySelector,
} from "../../../services/merchant/business/amenity/merchant-business-amenity-slice.service";
import MerchantBusinessEditLoader from "../loader/merchant-business-edit-loader.component";

const imageValidationSchema = Yup.object().shape({
  image: Yup.string().required().label("Image Path"),
});

function MerchantEditScreen() {
  const dispatch = useDispatch();
  const location = useLocation();
  const merchantId = new URLSearchParams(location.search).get("merchantId");
  const businessId = new URLSearchParams(location.search).get("businessId");
  const { removeMerchantBusinessImageObj, createMerchantBusinessImageObj } = useSelector(
    merchantBusinessImageSelector,
  );
  const { getMerchantBusinessDetailObj } = useSelector(merchantBusinessSelector);
  const { getMerchantBusinessAmenitiesObj } = useSelector(merchantBusinessAmenitySelector);
  const [imgToDelete, setImgToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(getMerchantBusinessDetail({ merchantId, businessId }));
    dispatch(getMerchantBusinessAmenities());
  }, [removeMerchantBusinessImageObj, createMerchantBusinessImageObj]);

  const onUploadImage = (values) => {
    dispatch(createMerchantBusinessImage(values));
  };

  const onRemoveImage = (imageId) => {
    setImgToDelete(imageId);
    setShowDeleteModal(true);
  };

  const onConfirmDeleteImage = () => {
    dispatch(removeMerchantBusinessImage({ businessId, merchantId, imageId: imgToDelete })).then(
      ({ meta }) => {
        if (meta.requestStatus === "fulfilled") {
          setShowDeleteModal(false);
        }
      },
    );
  };

  return (
    <>
      <ConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title="Remove image"
        message="Are you sure to remove this image?"
        onSubmit={onConfirmDeleteImage}
        isDisabled={removeMerchantBusinessImageObj.status === "pending"}
      />
      <Box>
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Business Edit</Typography>
            </Grid>
            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link underline="hover" key="2" color="inherit" href="/merchants/business/list">
                    <Typography variant="body1">Business List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    Business Edit
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
        </PaddedView>
      </Box>

      {getMerchantBusinessDetailObj.status === "succeeded" &&
      getMerchantBusinessAmenitiesObj.status === "succeeded" ? (
        <>
          <Form
            validationSchema={imageValidationSchema}
            initialValues={{ image: "", businessId, merchantId }}
            onSubmit={onUploadImage}
          >
            <MerchantImageEdit name="image" onRemoveImage={onRemoveImage} />
          </Form>

          <MerchantBusinessDetails
            record={getMerchantBusinessDetailObj.data}
            amenity={getMerchantBusinessAmenitiesObj.data}
            merchantId={merchantId}
            businessId={businessId}
          />
        </>
      ) : (
        <MerchantBusinessEditLoader />
      )}
    </>
  );
}

export default MerchantEditScreen;
