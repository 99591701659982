import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow, Typography } from "@mui/material";

function UserBookingDetailRow({ record }) {
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const getTrainers = (trainerArr) => {
    const trainers = [];
    trainerArr.map((item) => trainers.push(`${item.firstName} ${item.lastName}`));
    return trainers.join(", ");
  };

  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "unset",
        },
      }}
    >
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.type === "merchant_class_session" && "Class Session"}
          {record.type === "merchant_event_attendee" && "Merchant Event"}
          {(record.type === "gym_session" || record.type === "merchant_hourly_booking") &&
            "Gym Session"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.location.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.date}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.time}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.type.toUpperCase() === "MERCHANT_CLASS_SESSION"
            ? record.session.class.category.label
            : "-"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.type.toUpperCase() === "MERCHANT_CLASS_SESSION" && record.session?.class.name}
          {record.type.toUpperCase() === "MERCHANT_EVENT_ATTENDEE" && record.session?.event.name}
          {record.type.toUpperCase() !== "MERCHANT_CLASS_SESSION" &&
            record.type.toUpperCase() !== "MERCHANT_EVENT_ATTENDEE" &&
            "-"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.type.includes("merchant_class_session") && record.session?.trainers?.length > 0
            ? getTrainers(record.session.trainers)
            : "-"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.promoCode !== null ? record.promoCode : "-"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.status !== null ? capitalize(record.status.toString()) : "-"}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

UserBookingDetailRow.defaultProps = {
  record: {
    id: null,
    type: "",
    date: "",
    status: "",
    time: "",
    location: {
      name: "",
      address: "",
      lat: "",
      long: "",
      imagePath: "",
    },
    session: {
      trainers: [
        {
          id: null,
          firstName: "",
          lastName: "",
          imagePath: "",
        },
      ],
      class: {
        name: "",
        category: {
          label: "",
        },
      },
    },
    promoCode: "",
  },
};

UserBookingDetailRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    date: PropTypes.string,
    status: PropTypes.string,
    time: PropTypes.string,
    location: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
      lat: PropTypes.string,
      long: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    session: PropTypes.shape({
      trainers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          imagePath: PropTypes.string,
        }),
      ),
      event: PropTypes.shape({
        name: PropTypes.string,
      }),
      class: PropTypes.shape({
        name: PropTypes.string,
        category: PropTypes.shape({
          label: PropTypes.string,
        }),
      }),
    }),
    promoCode: PropTypes.string,
  }),
};

export default UserBookingDetailRow;
