export const darkColors = {
  brand: {
    primary: "#E60013",
    secondary: "#FFD279",
    white: "#FFFFFF",
  },
  ui: {
    primary: "#AAAAAA",
    secondary: "#6B6B6B",
    disabled: "#AAAAAA",
    error: "#D0421B",
    success: "#138000",
    white: "#FFFFFF",
    border: "#494747",
    indicator: "#FFFFFF",
  },
  bg: {
    primary: "#262626",
    secondary: "#494747",
    tertiary: "#EEEEEE",
    white: "#FFFFFF",
  },
  timeSlot: {
    selected: "#E60013",
    unselected: "#AAAAAA",
  },
  tab: {
    selected: "#FFFFFF",
    unselected: "#AAAAAA",
    bg: "#1D1D1D",
  },
  loading: {
    backgroundColor: "#262626",
    foregroundColor: "#AAAAAA",
    imageBg: "#AAAAAA",
    dot1: "#262626",
    dot2: "#6B6B6B",
    dot3: "#FFFFFF",
  },
  text: {
    primary: "#FFFFFF",
    secondary: "#AAAAAA",
    placeholder: "#AAAAAA",
    white: "#FFFFFF",
    main: "#262626",
    disabled: "#AAAAAA",
    error: "#D0421B",
    success: "#138000",
  },
};
