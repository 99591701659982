import { TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function NotificationHistoryTableRowLoader() {
  return (
    <TableBody>
      <TableRow>
        <TableCell width="300px">
          <Skeleton width="60%" />
        </TableCell>

        <TableCell>
          <Skeleton width="60%" />
        </TableCell>

        <TableCell width="200px">
          <Skeleton width="60%" />
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
export default NotificationHistoryTableRowLoader;
