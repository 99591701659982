import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import TableWrapper from "../../../../../../components/tables/table-wrapper.component";
import TableHeader from "./table-header.component";
import Row from "./row.component";

function TableIndex({
  records,
  setSortCate,
  sortCate,
  page,
  handlePageChange,
  handleOnPressDelete,
}) {
  return (
    <TableWrapper>
      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerTitle="Plans List"
          setSortCate={setSortCate}
          sortCate={sortCate}
          headerCells={[
            "Image",
            "Title",
            "Merchant Business Name",
            "Sessions",
            "Purchasers",
            "Price",
            "Status",
            "Action",
            "",
          ]}
        />

        <TableBody>
          {records.items.map((record) => (
            <Row record={record} key={uuidv4()} handleOnPressDelete={handleOnPressDelete} />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={records.pagination.perPage}
              count={records.pagination.totalItems}
              rowsPerPageOptions={[]}
              showFirstButton={true}
              showLastButton={true}
              onPageChange={handlePageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableWrapper>
  );
}

TableIndex.propTypes = {
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handleOnPressDelete: PropTypes.func.isRequired,
  sortCate: PropTypes.shape({
    title: PropTypes.oneOf(["idle", "asc", "dsc"]),
    sessions: PropTypes.oneOf(["idle", "asc", "dsc"]),
    purchasers: PropTypes.oneOf(["idle", "asc", "dsc"]),
    price: PropTypes.oneOf(["idle", "asc", "dsc"]),
    status: PropTypes.oneOf(["idle", "asc", "dsc"]),
  }).isRequired,
  setSortCate: PropTypes.func.isRequired,
  records: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        deletable: PropTypes.bool.isRequired,
        id: PropTypes.number.isRequired,
        imagePath: PropTypes.string.isRequired,
        numberOfPurchasers: PropTypes.number.isRequired,
        price: PropTypes.string.isRequired,
        priceCents: PropTypes.number.isRequired,
        numberOfSessions: PropTypes.number.isRequired,
        status: PropTypes.string,
        title: PropTypes.string.isRequired,
        businesses: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
          }),
        ).isRequired,
      }),
    ),
    pagination: PropTypes.shape({
      perPage: PropTypes.number.isRequired,
      totalItems: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default TableIndex;
