import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material";
import { useFormikContext } from "formik";

function NotificationTypeSelect({ name, onChangeNotificationType }) {
  const theme = useTheme();
  const { setFieldValue, values } = useFormikContext();

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
    setFieldValue("announcementsId", []);
    setFieldValue("popUpsId", []);
    onChangeNotificationType(event.target.value);
  };

  return (
    <FormControl sx={{ minWidth: "300px" }} size="small">
      <Select
        placeholder="Select Type"
        value={values[name]}
        onChange={handleChange}
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
        }}
      >
        <MenuItem value="announcement">General Announcement</MenuItem>
        <MenuItem value="pop-up">Pop Up Announcement</MenuItem>
      </Select>
    </FormControl>
  );
}

NotificationTypeSelect.propTypes = {
  onChangeNotificationType: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default NotificationTypeSelect;
