import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import FormFieldNumber from "../../../../../components/forms/form-field-number.component";
import FormMultipleToggleButton from "../../../../../components/forms/form-multiple-toggle-button.component";
import FormPrice from "../../../../../components/forms/form-price.component";
import FormTimePicker from "../../../../../components/forms/form-time-picker.component";
import OutlinedSelectTextField from "../../../../../components/forms/outlined-select-text-field.component";
import Spacer from "../../../../../components/utils/spacer.component";
import ClassListAutoComplete from "./class-list-autocomplete.component";
import FormDatePicker from "./form-date-picker.component";
import StaffListAutoComplete from "./staff-list-autocomplete.component";

function RecurrentCreateSessionForm() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10}>
          <ClassListAutoComplete name="selectedClass" placeholder="Class" multiple={false} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormTimePicker name="startTime" label="*Start Time" />
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormTimePicker name="endTime" label="*End Time" />
        </Grid>
        <Grid item xs={12} sm={5}>
          <OutlinedSelectTextField
            name="occurenceType"
            label="Repeats"
            selections={[
              { type: "Daily", value: "daily" },
              { type: "Weekly", value: "weekly" },
            ]}
          />
        </Grid>
      </Grid>
      <Spacer />
      {values.occurenceType === "weekly" && (
        <Grid item xs={6}>
          <Spacer />
          <Typography sx={{ fontWeight: "bold" }}>Repeat on</Typography>
          <Spacer />
          <FormMultipleToggleButton
            name="weeklyRepeat"
            size={isMobile ? "small" : "large"}
            itemList={[
              { id: 0, value: 7, label: "Sun" },
              { id: 1, value: 1, label: "Mon" },
              { id: 2, value: 2, label: "Tue" },
              { id: 3, value: 3, label: "Wed" },
              { id: 4, value: 4, label: "Thu" },
              { id: 5, value: 5, label: "Fri" },
              { id: 6, value: 6, label: "Sat" },
            ]}
          />
        </Grid>
      )}
      <Spacer size="l" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <FormDatePicker name="startDate" label="*Start Date" disablePast={true} />
        </Grid>
        <Grid item xs={12}>
          <Spacer />
          <Typography sx={{ fontWeight: "bold" }}>Ends</Typography>
          <Spacer />
          <Grid container>
            <RadioGroup
              value={values.endType}
              onChange={(e) => {
                setFieldValue("endType", e.target.value);
              }}
            >
              <FormControlLabel
                value="occurenceNumber"
                control={<Radio />}
                label={
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ alignItems: "center", width: isMobile ? "100%" : 350 }}
                  >
                    <Grid item xs={12} sm={5}>
                      <Typography type={values.endType === "occurenceNumber" ? "" : "GreyColor"}>
                        After
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      {isMobile && <Spacer />}
                      <FormFieldNumber
                        disabled={values.endType === "endDate"}
                        name="occurenceNumber"
                        label="Occurence"
                        placeholder="0"
                      />
                    </Grid>
                  </Grid>
                }
              />
              <Spacer />
              <FormControlLabel
                value="endDate"
                control={<Radio />}
                label={
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ alignItems: "center", width: isMobile ? "100%" : 350 }}
                  >
                    <Grid item xs={12} sm={5}>
                      <Typography type={values.endType === "endDate" ? "" : "GreyColor"}>
                        Specified Date
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      {isMobile && <Spacer />}
                      <FormDatePicker
                        label="*End Date"
                        disabled={values.endType === "occurenceNumber"}
                        name="endDate"
                        disablePast={true}
                        width="100%"
                        placeholder="End Date"
                      />
                    </Grid>
                  </Grid>
                }
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormFieldNumber
            name="maxCapacity"
            placeholder="Enter maximum capacity"
            label="Capacity"
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormPrice name="price" placeholder="Enter price" label="Price" />
        </Grid>
        <Grid item xs={12}>
          <StaffListAutoComplete
            disabled={false}
            name="staffsId"
            placeholder="Staffs"
            forEdit={true}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default RecurrentCreateSessionForm;
