import { Box, Chip, Grid, Modal, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import BorderCtaButton from "../../../../../components/button/border-cta-button.component";
import CtaButton from "../../../../../components/button/cta-button.component";
import Spacer from "../../../../../components/utils/spacer.component";
import { fitnessClassSessionSelector } from "../../../../../services/merchant/fitness-class/sessions/session.slice.service";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: `${theme.shape.borderRadius[0]}px`,
  boxShadow: 24,
  outline: "none",
  maxHeight: "90%",
}));

export default function SessionPublishConfirmationModal({
  item,
  showModal,
  setShowModal,
  handleUpdateSessionStatus,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { updateSessionObj } = useSelector(fitnessClassSessionSelector);

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          padding: isMobile ? "30px" : "50px",
          width: isMobile ? "350px" : "80%",
          maxWidth: "700px",
        }}
      >
        <Grid container columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Session Confirmation</Typography>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: 8 }}>
            <Typography>The session cannot be edited once confirmed.</Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={3}>
              <Typography>Merchant:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography type="GreyColor">{item.merchant.businessName}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={3}>
              <Typography>Business:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography type="GreyColor">{item.business.name}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={3}>
              <Typography>Class:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography type="GreyColor">{item.class.name}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={3}>
              <Typography>Start at:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography type="GreyColor">{item.startAt}</Typography>
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={3}>
              <Typography>End at:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography type="GreyColor">{item.endAt}</Typography>
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={3}>
              <Typography>Capacity: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography type="GreyColor">
                {item.maxCapacity === 0 ? "Unlimited" : item.maxCapacity}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={3}>
              <Typography>Price:</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography type="GreyColor">{`RM ${item.price}`}</Typography>
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={3}>
              <Typography>Staffs:</Typography>
            </Grid>
            <Grid item xs={9}>
              {item.staffs.map((staff) => (
                <Grid item key={staff.id}>
                  <Chip
                    label={<Typography type="BrandColor">{staff.name}</Typography>}
                    sx={{
                      backgroundColor: theme.palette.colors.brand.secondary,
                      margin: "3px",
                      borderRadius: `${theme.shape.borderRadius[0]}px`,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Spacer size="m" position="top" />
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <BorderCtaButton onClickButton={handleCancel} width="100px" isLoading={false}>
              <Typography sx={{ color: theme.palette.colors.brand.primary }}>Cancel</Typography>
            </BorderCtaButton>
            <Spacer size="m" position="left" />
            <Grid item xs={2}>
              <CtaButton
                onClickButton={() => handleUpdateSessionStatus(item.id, "publish")}
                disabled={updateSessionObj.status === "pending"}
              >
                <Typography>Confirm</Typography>
              </CtaButton>
            </Grid>
          </Box>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

SessionPublishConfirmationModal.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    merchant: PropTypes.shape({
      id: PropTypes.number.isRequired,
      businessName: PropTypes.string.isRequired,
    }),
    business: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    class: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    category: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }),
    startAt: PropTypes.string.isRequired,
    endAt: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    currentCapacity: PropTypes.number.isRequired,
    maxCapacity: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    allowPublish: PropTypes.bool.isRequired,
    staffs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        gender: PropTypes.string.isRequired,
        dob: PropTypes.string.isRequired,
        image_path: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleUpdateSessionStatus: PropTypes.func.isRequired,
};
