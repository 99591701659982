import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../../api.service";

export const searchMerchantEvent = createAsyncThunk("merchants/event/index", async (payload) => {
  const { q, page } = payload;
  const response = getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/events?page=${page}${
      q ? "&q=".concat(q) : ""
    }`,
  );
  return response;
});

const merchantEventSlice = createSlice({
  name: "merchantEvent",
  initialState: {
    searchMerchantEventObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [searchMerchantEvent.pending]: (state) => {
      state.searchMerchantEventObj.status = "pending";
    },
    [searchMerchantEvent.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchMerchantEventObj.status = "succeeded";
      state.searchMerchantEventObj.data = data;
      state.searchMerchantEventObj.successMessage = message;
    },
    [searchMerchantEvent.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchMerchantEventObj.status = "failed";
      state.searchMerchantEventObj.errorMessage = message;
    },
  },
});

export default merchantEventSlice.reducer;

// state
export const merchantEventSelector = (state) => state.merchantEvent;
