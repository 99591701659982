import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Collapse,
  IconButton,
  Link,
  List,
  ListItem,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Spacer from "../../../../../../components/utils/spacer.component";
import PlanDeletionModalConfirmation from "./plan-deletion-modal-confirmation.component";

function Row({ record, handleOnPressDelete }) {
  const theme = useTheme();
  const [collapse, setCollapse] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  return (
    <>
      <PlanDeletionModalConfirmation
        showDeletionModal={showDeletionModal}
        setShowDeletionModal={setShowDeletionModal}
        record={record}
        handleOnPressDelete={handleOnPressDelete}
      />
      <TableRow>
        <TableCell sx={{ width: 150 }}>
          <img
            src={record.imagePath}
            alt="plan"
            style={{ width: 150, borderRadius: theme.shape.borderRadius[1] }}
          />
        </TableCell>
        <TableCell sx={{ width: 150 }}>
          <Typography variant="body2" noWrap={true} sx={{ width: 150 }}>
            {record.title}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: 150 }}>
          <Link color="inherit" href={`/merchants/edit?merchantId=${record.merchant.id}`}>
            <Typography variant="body2" noWrap={true}>
              {record.merchant.businessName}
            </Typography>
          </Link>
        </TableCell>
        <TableCell sx={{ width: 150 }}>
          <Typography variant="body2" noWrap={true} sx={{ width: "90px", marginLeft: 1 }}>
            {record.numberOfDays}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: 150 }}>
          <Typography variant="body2" noWrap={true} sx={{ width: 150 }}>
            {record.numberOfSubscribers}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: 150 }}>
          <Typography variant="body2" noWrap={true} sx={{ width: 150 }}>
            {`RM${record.price}`}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: 150 }}>
          <Typography
            variant="body2"
            noWrap={true}
            sx={{ width: "100px", textTransform: "capitalize" }}
          >
            {record.status}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: 150 }}>
          <RouterLink
            to={{
              pathname: `/merchants/subscriptions/plans/${record.id}/edit`,
            }}
            style={{ textDecoration: "none" }}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </RouterLink>
          <IconButton
            disabled={!record.deletable}
            onClick={() => {
              setShowDeletionModal(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
        <TableCell sx={{ width: 150 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
            {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Spacer />
            <Typography sx={{ fontWeight: "bold" }}>Businesses</Typography>
            {record.businesses.length > 0 &&
              record.businesses.map((business) => (
                <List sx={{ listStyleType: "disc", pl: 4 }} key={business.id}>
                  <ListItem sx={{ display: "list-item" }} disablePadding>
                    {business.name}
                  </ListItem>
                </List>
              ))}
            <Spacer />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  handleOnPressDelete: PropTypes.func.isRequired,
  record: PropTypes.shape({
    deletable: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    imagePath: PropTypes.string.isRequired,
    numberOfSubscribers: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    numberOfDays: PropTypes.number.isRequired,
    status: PropTypes.string,
    title: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    merchant: PropTypes.shape({
      id: PropTypes.number.isRequired,
      businessName: PropTypes.string.isRequired,
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default Row;
