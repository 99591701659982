import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api.service";

export const publishNotification = createAsyncThunk("notification/publish", async (payload) => {
  const response = postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/notifications`,
    payload,
  );
  return response;
});

export const searchConsumer = createAsyncThunk("notification/search_consumer", async (payload) => {
  const response = postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/notifications/search_consumer`,
    payload,
  );
  return response;
});

export const getNotificationHistory = createAsyncThunk("notification/history", async (payload) => {
  const response = getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/notifications?page=${
      payload.page + 1
    }&consumer=${payload.consumer}&type=${payload.type}&q=${payload.q}`,
    payload,
  );
  return response;
});

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    publishNotificationObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchConsumerObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getNotificationHistoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [publishNotification.pending]: (state) => {
      state.publishNotificationObj.status = "pending";
    },
    [publishNotification.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.publishNotificationObj.status = "succeeded";
      state.publishNotificationObj.data = data;
      state.publishNotificationObj.successMessage = message;
    },
    [publishNotification.rejected]: (state, action) => {
      const { message } = action.error;

      state.publishNotificationObj.status = "failed";
      state.publishNotificationObj.errorMessage = message;
    },
    [searchConsumer.pending]: (state) => {
      state.searchConsumerObj.status = "pending";
    },
    [searchConsumer.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchConsumerObj.status = "succeeded";
      state.searchConsumerObj.data = data;
      state.searchConsumerObj.successMessage = message;
    },
    [searchConsumer.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchConsumerObj.status = "failed";
      state.searchConsumerObj.errorMessage = message;
    },
    [getNotificationHistory.pending]: (state) => {
      state.getNotificationHistoryObj.status = "pending";
    },
    [getNotificationHistory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getNotificationHistoryObj.status = "succeeded";
      state.getNotificationHistoryObj.data = data;
      state.getNotificationHistoryObj.successMessage = message;
    },
    [getNotificationHistory.rejected]: (state, action) => {
      const { message } = action.error;

      state.getNotificationHistoryObj.status = "failed";
      state.getNotificationHistoryObj.errorMessage = message;
    },
  },
});

export default notificationSlice.reducer;

// state
export const notificationSelector = (state) => state.notification;
