import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, Button, FormHelperText, IconButton, styled } from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import { useFormikContext } from "formik";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const StyledButtonIcon = styled(IconButton)((props) => ({
  position: "absolute",
  padding: 0,
  top: 10,
  right: 10,
  backgroundColor: props.theme.palette.colors.brand.primary,
  width: 25,
  height: 25,
}));

const StyledCloseIcon = styled(CloseIcon)((props) => ({
  width: 17,
  height: 17,
  stroke: props.theme.palette.colors.brand.white,
  strokeWidth: 3,
}));

const StyledAddCircleOutlineRoundedIcon = styled(AddCircleOutlineRoundedIcon)(({ theme }) => ({
  color: theme.palette.colors.ui.disabled,
}));

function ImageUrlConverter({ name, filePreview, setFilePreview }) {
  const theme = useTheme();
  const { setFieldValue, setFieldError, errors } = useFormikContext();
  const showError = typeof errors[name] === "string";

  const handleRemoveImage = () => {
    setFilePreview("");
    setFieldValue(name, "");
  };

  const renderUploadButton = () => (
    <Button
      component="label"
      fullWidth
      sx={{
        borderTopLeftRadius: theme.shape.borderRadius[1],
        borderTopRightRadius: theme.shape.borderRadius[1],
        borderBottomLeftRadius: theme.shape.borderRadius[1],
        borderBottomRightRadius: theme.shape.borderRadius[1],
        border: `2px dashed ${theme.palette.colors.ui.disabled}`,
        justifyContent: "center",
        alignItems: "center",
        minHeight: "180px",
        "&:hover": {
          background: "transparent",
        },
      }}
    >
      <StyledAddCircleOutlineRoundedIcon />
      <input
        type="file"
        hidden
        accept="image/*"
        onChange={(e) => {
          const { files } = e.target;

          if (files) {
            const myFile = files[0];
            const withinFileSizeLimit = myFile && myFile.size <= 5000000;
            const allowedFileType = myFile && SUPPORTED_FORMATS.includes(myFile.type);
            if (myFile && withinFileSizeLimit && allowedFileType) {
              const fileReader = new FileReader();
              fileReader.onload = () => {
                if (fileReader.readyState === 2) {
                  setFieldValue(name, fileReader.result);
                  setFilePreview(fileReader.result);
                }
              };
              fileReader.readAsDataURL(myFile);
            } else if (!withinFileSizeLimit) {
              setFieldError(name, "Image file cannot be more than 5mb");
            } else if (!allowedFileType) {
              setFieldError(name, `Only jpg, jpeg and png are allowed`);
            }
          }
        }}
      />
    </Button>
  );

  const renderContent = () => (
    <Box
      sx={{
        position: "relative",
        "& .MuiIconButton-root": {
          "&:hover": {
            backgroundColor: theme.palette.colors.brand.primary,
          },
        },
      }}
    >
      <img
        src={filePreview}
        alt="attachment"
        style={{
          width: "100%",
          maxHeight: "180px",
          borderRadius: theme.shape.borderRadius[1],
          objectFit: "contain",
        }}
      />
      <StyledButtonIcon onClick={() => handleRemoveImage(filePreview)}>
        <StyledCloseIcon />
      </StyledButtonIcon>
    </Box>
  );

  return (
    <Box sx={{ position: "relative" }}>
      {filePreview ? renderContent() : renderUploadButton()}
      {showError && <FormHelperText error={showError}>{errors[name]}</FormHelperText>}
    </Box>
  );
}

ImageUrlConverter.propTypes = {
  name: PropTypes.string.isRequired,
  filePreview: PropTypes.string.isRequired,
  setFilePreview: PropTypes.func.isRequired,
};

export default ImageUrlConverter;
