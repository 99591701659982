import "react-image-crop/dist/ReactCrop.css";
import React, { useEffect, useState } from "react";
import { Box, Modal, styled, Typography, useTheme } from "@mui/material";
import ReactCrop from "react-image-crop";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import CtaButton from "../../../../components/button/cta-button.component";
import BorderCtaButton from "../../../../components/button/border-cta-button.component";
import Spacer from "../../../../components/utils/spacer.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.primary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "20px",
  paddingRight: "20px",
  maxWidth: "600px",
  outline: "none",
  display: "flex",
  maxHeight: "80%",
  flexDirection: "column",
}));

const ImageCropperContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "80%",
  flex: 1,
  overflow: "scroll",
});

const BottomButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  height: "20%",
  alignItems: "center",
});

function EditImageCropper({ isShowModal, setIsShowModal, name, imageFile }) {
  const [image, setImage] = useState(null);
  const cropSetting = { aspect: 16 / 9, unit: "px", width: 300, height: 168.75, x: 0, y: 0 };
  const [crop, setCrop] = useState(cropSetting);
  const { handleSubmit, setFieldValue } = useFormikContext();
  const theme = useTheme();

  useEffect(() => {
    setCrop(cropSetting);
  }, [imageFile]);

  const handleCancel = () => {
    setCrop(cropSetting);
    setIsShowModal(false);
  };

  const getCroppedImage = () => {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );
    const base64ImageURL = canvas.toDataURL("image/jpeg");
    setFieldValue(name, base64ImageURL);
    setTimeout(() => {
      handleSubmit();
    }, 50);

    setIsShowModal(false);
  };

  return (
    <Modal open={isShowModal} onClose={handleCancel}>
      <ModalBox>
        <ImageCropperContainer>
          <ReactCrop
            crop={crop}
            onChange={setCrop}
            aspect={16 / 9}
            keepSelection={true}
            style={{ height: "100%", overflow: "scroll" }}
          >
            <img
              src={imageFile}
              alt="test"
              onLoad={(o) => {
                setImage(o.target);
              }}
            />
          </ReactCrop>
        </ImageCropperContainer>

        <Spacer size="l" position="top" />
        <BottomButtonWrapper>
          <Box sx={{ width: "45%" }}>
            <CtaButton onClickButton={getCroppedImage}>
              <Typography type="WhiteColor">Upload</Typography>
            </CtaButton>
          </Box>
          <Box sx={{ width: "45%" }}>
            <BorderCtaButton onClickButton={handleCancel}>
              <Typography sx={{ color: theme.palette.colors.brand.primary }}>Cancel</Typography>
            </BorderCtaButton>
          </Box>
        </BottomButtonWrapper>
      </ModalBox>
    </Modal>
  );
}

EditImageCropper.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModal: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  imageFile: PropTypes.string.isRequired,
};

export default EditImageCropper;
