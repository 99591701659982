import { Box, Breadcrumbs, Grid, Link, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PaddedView from "../../../components/utils/padded-view.component";
import { getRoleOptions } from "../../../services/role/role-slice.service";
import { getStaff, staffSelector } from "../../../services/staff/staff-slice.service";
import ImageEdit from "../components/staff-edit/image-edit.component";
import Permission from "../components/staff-edit/permission.component";
import StaffDetails from "../components/staff-edit/staff-details.component";
import { getUserRole } from "../../../infrastructure/utils";

const permissionOptions = [
  {
    id: 1,
    name: "finance-read",
  },
  {
    id: 2,
    name: "finance-write",
  },
  {
    id: 3,
    name: "general-read",
  },
  {
    id: 4,
    name: "general-write",
  },
  {
    id: 5,
    name: "marketing-read",
  },
  {
    id: 6,
    name: "marketing-write",
  },
  {
    id: 7,
    name: "merchant-read",
  },
  {
    id: 8,
    name: "merchant-write",
  },
  {
    id: 9,
    name: "sale-read",
  },
  {
    id: 10,
    name: "sale-write",
  },
  {
    id: 11,
    name: "staff-read",
  },
  {
    id: 12,
    name: "staff-write",
  },
  {
    id: 13,
    name: "user-read",
  },
  {
    id: 14,
    name: "user-write",
  },
];

function StaffEditScreen() {
  const dispatch = useDispatch();
  const params = useParams();
  const { getStaffObj } = useSelector(staffSelector);

  useEffect(() => {
    dispatch(getStaff({ staffId: params.staff_id }));
  }, []);

  useEffect(() => {
    dispatch(getRoleOptions());
  }, []);

  return (
    <>
      <Box>
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Staff Edit</Typography>
            </Grid>
            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link underline="hover" key="2" color="inherit" href="/staffs/list">
                    <Typography variant="body1">Staff List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    Staff Edit
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
        </PaddedView>
      </Box>

      {getStaffObj.status === "succeeded" ? (
        <>
          <ImageEdit record={getStaffObj.data} />
          <StaffDetails record={getStaffObj.data} />
          {getUserRole().some((role) => role === "super-admin") && (
            <Permission
              options={permissionOptions}
              staffId={Number(params.staff_id)}
              record={getStaffObj.data.permissions}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default StaffEditScreen;
