import { Box, Grid, Modal, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import BorderCtaButton from "../../../../components/button/border-cta-button.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Form from "../../../../components/forms/form.component";
import OutlinedSelectTextField from "../../../../components/forms/outlined-select-text-field.component";
import Spacer from "../../../../components/utils/spacer.component";
import { fitnessClassSelector } from "../../../../services/merchant/fitness-class/class-slice.service";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
}));

const updateValidationSchema = Yup.object().shape({
  classId: Yup.number().required().label("Class Id"),
  name: Yup.string()
    .required()
    .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allow")
    .label("Name"),
  categoryId: Yup.number().required().label("Category"),
});

export default function ClassEditModal({
  showModal,
  setShowModal,
  categoryList,
  updateItem,
  updateClassDetail,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { updateClassObj } = useSelector(fitnessClassSelector);

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          padding: "30px",
          width: isMobile ? "350px" : "600px",
        }}
      >
        {updateItem.id && (
          <Form
            initialValues={{
              classId: updateItem.id,
              name: updateItem.name,
              categoryId: updateItem.category.id,
            }}
            validationSchema={updateValidationSchema}
            onSubmit={updateClassDetail}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">Edit Class</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Merchant: {updateItem.merchant.businessName}</Typography>
                <Typography>Business: {updateItem.business.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormFieldText name="name" placeholder="Enter class name" label="Class Name" />
              </Grid>
              <Grid item xs={12}>
                <OutlinedSelectTextField
                  name="categoryId"
                  label="Category"
                  selections={categoryList}
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  <BorderCtaButton
                    onClickButton={() => setShowModal(false)}
                    width="100px"
                    isLoading={updateClassObj.status === "pending"}
                  >
                    <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                      Cancel
                    </Typography>
                  </BorderCtaButton>
                  <Spacer size="m" position="left" />
                  <FormSubmitButton width="100px" disabled={updateClassObj.status === "pending"}>
                    <Typography>Save</Typography>
                  </FormSubmitButton>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </ModalBox>
    </Modal>
  );
}

ClassEditModal.propTypes = {
  updateItem: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    merchant: PropTypes.shape({
      id: PropTypes.number,
      businessName: PropTypes.string,
    }),
    business: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    category: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
  }).isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  updateClassDetail: PropTypes.func.isRequired,
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
};
