import React from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material";
import Skeleton from "react-loading-skeleton";

function BannerLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={2} paddingTop={2}>
      <Grid item xs={isMobile ? 12 : 4}>
        <Skeleton height={250} />
      </Grid>
      <Grid item xs={isMobile ? 12 : 4}>
        <Skeleton height={250} />
      </Grid>
      <Grid item xs={isMobile ? 12 : 4}>
        <Skeleton height={250} />
      </Grid>
    </Grid>
  );
}

export default BannerLoader;
