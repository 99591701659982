import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormHelperText } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

function PhoneNumberTextField({ name, disabled }) {
  const theme = useTheme();
  const { setFieldValue, errors, setFieldTouched, touched, values } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";
  const [isPhoneFieldTouched, setIsPhoneFieldTouched] = useState(false);

  return (
    <>
      <PhoneInput
        placeholder="Phone Number"
        value={values[name]}
        country="my"
        specialLabel="Phone"
        onBlur={() => {
          setFieldTouched(name);
          setIsPhoneFieldTouched(false);
        }}
        onFocus={() => setIsPhoneFieldTouched(true)}
        onChange={(e) => setFieldValue(name, `+${e}`)}
        disabled={disabled}
        inputStyle={{
          width: "100%",
          borderRadius: theme.shape.borderRadius[1],
          borderColor: disabled
            ? theme.palette.colors.text.disabled
            : showError && theme.palette.darkColors.ui.error,
          color: disabled ? theme.palette.colors.text.disabled : theme.palette.colors.text.primary,
        }}
        containerStyle={{
          // eslint-disable-next-line no-nested-ternary
          color: disabled
            ? theme.palette.colors.text.disabled
            : isPhoneFieldTouched
            ? theme.palette.colors.text.link
            : theme.palette.colors.text.secondary,
        }}
      />
      {showError && (
        <FormHelperText
          sx={{
            color: theme.palette.darkColors.text.error,
            margin: "3px 14px 0px 14px",
          }}
        >
          {errors[name]}
        </FormHelperText>
      )}
    </>
  );
}

PhoneNumberTextField.defaultProps = {
  disabled: false,
};

PhoneNumberTextField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default PhoneNumberTextField;
