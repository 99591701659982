import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postReq } from "../api.service";

export const dashboardSalesReport = createAsyncThunk("dashboard/sales_report", async (payload) => {
  const response = postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/dashboard/sales_report`,
    payload,
  );
  return response;
});

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    totalSalesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    dashboardSalesReportObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [dashboardSalesReport.pending]: (state) => {
      state.dashboardSalesReportObj.status = "pending";
    },
    [dashboardSalesReport.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.dashboardSalesReportObj.status = "succeeded";
      state.dashboardSalesReportObj.data = data;
      state.dashboardSalesReportObj.successMessage = message;
    },
    [dashboardSalesReport.rejected]: (state, action) => {
      const { message } = action.error;

      state.dashboardSalesReportObj.status = "failed";
      state.dashboardSalesReportObj.errorMessage = message;
    },
  },
});

export default dashboardSlice.reducer;

// state
export const dashboardSelector = (state) => state.dashboard;

// action
// export const {} = gymSlice.actions;
