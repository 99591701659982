import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api.service";

export const createCategory = createAsyncThunk(
  "wellness/category/create_category",
  async (payload) => {
    const response = postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/wellness_categories`,
      payload,
    );
    return response;
  },
);

export const getCategoryList = createAsyncThunk("wellness/category/category_list", async () => {
  const response = getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/employees/wellness_categories`);
  return response;
});

export const deleteCategory = createAsyncThunk(
  "wellness/category/delete_category",
  async (payload) => {
    const response = delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/wellness_categories/${payload}`,
    );
    return response;
  },
);

export const updateCategory = createAsyncThunk(
  "wellness/category/update_category",
  async (payload) => {
    const response = putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/wellness_categories/${payload.categoryId}`,
      payload,
    );
    return response;
  },
);

export const updateCategoryImage = createAsyncThunk(
  "wellness/category/update_category_image",
  async (payload) => {
    const response = putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/wellness_categories/${payload.categoryId}/image`,
      payload,
    );
    return response;
  },
);

const wellnessCategorySlice = createSlice({
  name: "wellnessCategory",
  initialState: {
    createCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getCategoryListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateCategoryImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [createCategory.pending]: (state) => {
      state.createCategoryObj.status = "pending";
    },
    [createCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createCategoryObj.status = "succeeded";
      state.createCategoryObj.data = data;
      state.createCategoryObj.successMessage = message;
    },
    [createCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.createCategoryObj.status = "failed";
      state.createCategoryObj.errorMessage = message;
    },
    [getCategoryList.pending]: (state) => {
      state.getCategoryListObj.status = "pending";
    },
    [getCategoryList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCategoryListObj.status = "succeeded";
      state.getCategoryListObj.data = data;
      state.getCategoryListObj.successMessage = message;
    },
    [getCategoryList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCategoryListObj.status = "failed";
      state.getCategoryListObj.errorMessage = message;
    },
    [deleteCategory.pending]: (state) => {
      state.deleteCategoryObj.status = "pending";
    },
    [deleteCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteCategoryObj.status = "succeeded";
      state.deleteCategoryObj.data = data;
      state.deleteCategoryObj.successMessage = message;
    },
    [deleteCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteCategoryObj.status = "failed";
      state.deleteCategoryObj.errorMessage = message;
    },
    [updateCategory.pending]: (state) => {
      state.updateCategoryObj.status = "pending";
    },
    [updateCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateCategoryObj.status = "succeeded";
      state.updateCategoryObj.data = data;
      state.updateCategoryObj.successMessage = message;
    },
    [updateCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateCategoryObj.status = "failed";
      state.updateCategoryObj.errorMessage = message;
    },
    [updateCategoryImage.pending]: (state) => {
      state.updateCategoryImageObj.status = "pending";
    },
    [updateCategoryImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateCategoryImageObj.status = "succeeded";
      state.updateCategoryImageObj.data = data;
      state.updateCategoryImageObj.successMessage = message;
    },
    [updateCategoryImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateCategoryImageObj.status = "failed";
      state.updateCategoryImageObj.errorMessage = message;
    },
  },
});

export default wellnessCategorySlice.reducer;

// state
export const wellnessCategorySelector = (state) => state.wellnessCategory;
