import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import NotificationHistoryConsumerFilter from "../components/notification-history-consumer-filter.component";
import CustomizedSnackbars from "../../../components/notification/snackbar.component";
import { getNotificationHistory } from "../../../services/notification/notification-slice.service";
import NotificationHistoryTableIndex from "../components/notification-history-table-index.component";

const FlexStartRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
});

const LabelContainer = styled(Box)({
  display: "flex",
  width: "220px",
  height: "56px",
  alignItems: "center",
});

function NotificationHistoryScreen() {
  const theme = useTheme();
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [type, setType] = useState("announcement");
  const [consumer, setConsumer] = useState([{ label: "All", value: "all" }]);
  const [q, setQ] = useState("");
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();

  const showErrorSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("error");
    setIsShowSnackBar(true);
  };

  const getConsumerString = (consumerList) => {
    const consumerStr = [];
    consumerList.map((item) => consumerStr.push(item.value));
    return consumerStr.toString();
  };

  useEffect(() => {
    dispatch(getNotificationHistory({ page, consumer: getConsumerString(consumer), type, q })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          showErrorSnackBar(error.message);
        }
      },
    );
  }, []);

  const handleChangeType = (event) => {
    setType(event.target.value);
    setPage(0);
    dispatch(
      getNotificationHistory({
        page: 0,
        consumer: getConsumerString(consumer),
        type: event.target.value,
        q,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onChangeConsumerType = (values) => {
    setConsumer(values);
    setPage(0);
    dispatch(
      getNotificationHistory({ page: 0, consumer: getConsumerString(values), type, q }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onChangeSearchKeyword = (keyword) => {
    setQ(keyword);
    setPage(0);
    dispatch(
      getNotificationHistory({ page: 0, consumer: getConsumerString(consumer), type, q: keyword }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(
      getNotificationHistory({ page: newPage, consumer: getConsumerString(consumer), type, q }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const renderTypeSelect = () => (
    <FlexStartRowBox>
      <LabelContainer sx={{ height: "40px" }}>
        <Typography>Type</Typography>
      </LabelContainer>
      <FormControl sx={{ minWidth: "300px" }} size="small">
        <Select
          placeholder="Select Type"
          value={type}
          onChange={handleChangeType}
          sx={{
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
          }}
        >
          <MenuItem value="announcement">General Announcement</MenuItem>
          <MenuItem value="pop-up">Pop Up Announcement</MenuItem>
        </Select>
      </FormControl>
    </FlexStartRowBox>
  );

  return (
    <PaddedView>
      <CustomizedSnackbars
        isShow={isShowSnackBar}
        setShowSnackbar={setIsShowSnackBar}
        message={snackBarMsg}
        type={snackBarType}
      />
      <Typography variant="h6">Notification History</Typography>
      <Spacer size="m" position="top" />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {renderTypeSelect()}
        </Grid>
        <Grid item xs={12}>
          <FlexStartRowBox>
            <LabelContainer sx={{ height: "40px" }}>
              <Typography>Targeted Consumer</Typography>
            </LabelContainer>
            <NotificationHistoryConsumerFilter
              consumer={consumer}
              onChangeConsumerType={onChangeConsumerType}
            />
          </FlexStartRowBox>
        </Grid>
        <Grid item xs={12}>
          <NotificationHistoryTableIndex
            handlePageChange={handlePageChange}
            type={type}
            searchKeyword={q}
            onChangeSearchKeyword={onChangeSearchKeyword}
            page={page}
          />
        </Grid>
      </Grid>
    </PaddedView>
  );
}
export default NotificationHistoryScreen;
