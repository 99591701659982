import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormLabel, Grid, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import PaddedView from "../../../../components/utils/padded-view.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import OutlinedSelectTextField from "../../../../components/forms/outlined-select-text-field.component";
import PhoneNumberTextField from "../../../../components/forms/phone-number-text-field.component";
import { updateUserDetails } from "../../../../services/user/user-slice.service";
import FormPickerDate from "../../../../components/forms/form-picker-date.component";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().min(2).label("First Name"),
  lastName: Yup.string().required().min(2).label("Last Name"),
  email: Yup.string().required().email().label("Email"),
  gender: Yup.string()
    .nullable()
    .notRequired()
    .when({
      is: (value) => value?.length,
      then: (rule) => rule.oneOf(["others", "male", "female"]),
    })
    .label("Gender"),
  dateOfBirth: Yup.date().notRequired().nullable().typeError("Invalid date"),
  phoneNumber: Yup.string()
    .nullable()
    .notRequired()
    .when({
      is: (value) => value?.length,
      then: (rule) => rule.min(10),
    })
    .label("Phone Number"),
});

function UserDetails({ record }) {
  const [allowEdit, setAllowEdit] = useState(false);
  const dispatch = useDispatch();

  const onHandleSubmit = (values) => {
    setAllowEdit(false);
    dispatch(updateUserDetails({ userId: record.user.id, params: values }));
  };

  return (
    <>
      <PaddedView>
        <Formik
          validationSchema={validationSchema}
          onSubmit={onHandleSubmit}
          initialValues={{
            firstName: record.user.firstName,
            lastName: record.user.lastName,
            email: record.user.email,
            dateOfBirth: record.user.dateOfBirth,
            gender: record.user.gender || "",
            phoneNumber: record.user.phoneNumber,
          }}
        >
          {({ handleSubmit, dirty }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        alignSelf: "flex-end",
                      }}
                    >
                      <FormLabel>Details</FormLabel>
                    </Grid>
                    <Grid item xs={2}>
                      {allowEdit ? (
                        <>
                          <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setAllowEdit(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAllowEdit(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        name="firstName"
                        placeholder="Enter First Name"
                        label="First Name"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        name="lastName"
                        placeholder="Enter Last Name"
                        label="Last Name"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        name="email"
                        placeholder="Enter email"
                        label="Email"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormPickerDate
                        name="dateOfBirth"
                        label="Date of Birth"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <OutlinedSelectTextField
                        name="gender"
                        label="Gender"
                        disabled={!allowEdit}
                        selected={record.user.gender ? record.user.gender : ""}
                        selections={[
                          { type: "Male", value: "male" },
                          { type: "Female", value: "female" },
                          { type: "Others", value: "others" },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <PhoneNumberTextField name="phoneNumber" disabled={!allowEdit} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </PaddedView>
    </>
  );
}

UserDetails.propTypes = {
  record: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      imagePath: PropTypes.string,
      gender: PropTypes.string,
      dateOfBirth: PropTypes.string,
      phoneNumber: PropTypes.string,
      registeredAt: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default UserDetails;
