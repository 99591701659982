import { Box, Breadcrumbs, Grid, Link, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Form from "../../../../components/forms/form.component";
import OutlinedSelectTextField from "../../../../components/forms/outlined-select-text-field.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import SimpleBackdrop from "../../../../components/utils/backdrop-loading.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import {
  classCategorySelector,
  getCategoryList,
} from "../../../../services/category/class-category-slice.service";
import {
  createClass,
  fitnessClassSelector,
} from "../../../../services/merchant/fitness-class/class-slice.service";
import BusinessListAutoComplete from "../components/business-list-autocomplete.component";
import MerchantListAutoComplete from "../components/merchant-list-autocomplete.component";

const validationSchema = Yup.object().shape({
  merchantId: Yup.number().required().label("Merchant"),
  businessId: Yup.number().required().label("Business"),
  name: Yup.string()
    .required()
    .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allow")
    .label("Name"),
  categoryId: Yup.number().required().label("Category"),
});

export default function ClassNewScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createSnackBar = useContext(SnackbarContext);
  const { createClassObj } = useSelector(fitnessClassSelector);
  const { getCategoryListObj } = useSelector(classCategorySelector);

  useEffect(() => {
    dispatch(getCategoryList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const getClassCategoriesSelection = () => {
    const itemList = [];
    getCategoryListObj.data.map((item) =>
      itemList.push({ type: item.label, label: item.label, value: item.id }),
    );
    return itemList;
  };

  const onSubmit = (values) => {
    dispatch(createClass(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        navigate("/merchants/fitness_class/list");
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
    });
  };

  return (
    <>
      <SimpleBackdrop isLoading={createClassObj.status === "pending"} />
      <Box>
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">New Class</Typography>
            </Grid>

            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link
                    underline="hover"
                    key="2"
                    color="inherit"
                    href="/merchants/fitness_class/list"
                  >
                    <Typography variant="body1">Class List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    New Class
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
          <Spacer size="l" />
          <Form
            initialValues={{
              merchantId: "",
              selectedMerchant: null,
              businessId: "",
              selectedBusiness: null,
              name: "",
              categoryId: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MerchantListAutoComplete
                  name="selectedMerchant"
                  placeholder="Select a merchant"
                  disabled={false}
                  multiple={false}
                />
              </Grid>
              <Grid item xs={12}>
                <BusinessListAutoComplete
                  disabled={false}
                  name="selectedBusiness"
                  placeholder="Merchant Business"
                  forSearch={false}
                  multiple={false}
                />
              </Grid>
              <Grid item xs={12}>
                <FormFieldText name="name" placeholder="Enter class name" label="Class Name" />
              </Grid>
              {getCategoryListObj.status === "succeeded" ? (
                <Grid item xs={12}>
                  <OutlinedSelectTextField
                    name="categoryId"
                    label="Category"
                    selections={getClassCategoriesSelection()}
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Skeleton width="100%" height={50} />
                </Grid>
              )}

              <Grid item xs={12}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  <Spacer size="m" position="left" />
                  <FormSubmitButton width="100px" disabled={createClassObj.status === "pending"}>
                    <Typography>Create</Typography>
                  </FormSubmitButton>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </PaddedView>
      </Box>
    </>
  );
}
