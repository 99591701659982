import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

function Form({ initialValues, onSubmit, validationSchema, children, ...props }) {
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        {...props}
      >
        {() => <>{children}</>}
      </Formik>
    </>
  );
}

Form.defaultProps = {
  onSubmit: () => {},
  validationSchema: {},
};

Form.propTypes = {
  initialValues: PropTypes.instanceOf(Object).isRequired,
  validationSchema: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Form;
