import { TableCell, TableRow, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function EarningRow({ record }) {
  return (
    <TableRow
      key={record.statementId}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell width="150px">
        <Typography variant="body2">{record.statementId}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.merchantBusinessName}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.revenue}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">({record.merchantSum})</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.platformCommission}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">({record.merchantPromoCode})</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">({record.platformPromoCode})</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.grossProfit}</Typography>
      </TableCell>
    </TableRow>
  );
}

EarningRow.defaultProps = {
  record: {
    statementId: null,
    merchantBusinessName: null,
    revenue: 0,
    merchantSum: 0,
    platformCommission: 0,
    merchantPromoCode: 0,
    platformPromoCode: 0,
    grossProfit: 0,
  },
};

EarningRow.propTypes = {
  record: PropTypes.shape({
    statementId: PropTypes.number,
    merchantBusinessName: PropTypes.string,
    revenue: PropTypes.string,
    merchantSum: PropTypes.string,
    platformCommission: PropTypes.string,
    merchantPromoCode: PropTypes.string,
    platformPromoCode: PropTypes.string,
    grossProfit: PropTypes.string,
  }),
};

export default EarningRow;
