import { setAxiosToken } from "../../services/api.service";

export const isLogin = () => {
  const storedAuthToken = localStorage.getItem("authToken");
  if (storedAuthToken) {
    setAxiosToken(storedAuthToken);
    return true;
  }
  return false;
};

export const getUserRole = () => {
  const userDetail = localStorage.getItem("userDetail");

  if (userDetail) {
    return JSON.parse(userDetail).role;
  }
  return [];
};

export const getUserPermission = () => {
  const userDetail = localStorage.getItem("userDetail");

  if (userDetail) {
    return JSON.parse(userDetail).permissions;
  }
  return [];
};
