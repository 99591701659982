import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableHead, TableRow, IconButton, Typography, Box } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { v4 as uuidv4 } from "uuid";
import Spacer from "../../../../../../components/utils/spacer.component";

function TableHeader({ headerTitle, headerCells, sortCate, setSortCate }) {
  const renderTableCell = (headCell) => {
    switch (headCell) {
      case "Title":
        return (
          <>
            <Box sx={{ display: "flex", paddingTop: 1 }}>
              <Typography>{headCell}</Typography>
              <Spacer position="right" />
              <IconButton
                size="small"
                sx={{ marginTop: "-5px", marginLeft: "-10px" }}
                onClick={() =>
                  setSortCate({
                    title: sortCate.title === "asc" ? "dsc" : "asc",
                    merchantBusinessName: "idle",
                    validity: "idle",
                    subscribers: "idle",
                    price: "idle",
                    status: "idle",
                  })
                }
              >
                {sortCate.title === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Box>
          </>
        );
      case "Merchant Business Name":
        return (
          <>
            <Box sx={{ display: "flex", paddingTop: 1 }}>
              <Typography>{headCell}</Typography>
              <Spacer position="right" />
              <IconButton
                size="small"
                sx={{ marginTop: "-5px", marginLeft: "-10px" }}
                onClick={() =>
                  setSortCate({
                    title: "idle",
                    merchantBusinessName: sortCate.merchantBusinessName === "asc" ? "dsc" : "asc",
                    validity: "idle",
                    subscribers: "idle",
                    price: "idle",
                    status: "idle",
                  })
                }
              >
                {sortCate.merchantBusinessName === "asc" ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </IconButton>
            </Box>
          </>
        );
      case "Days":
        return (
          <>
            <Box sx={{ display: "flex", paddingTop: 1 }}>
              <Typography>{headCell}</Typography>
              <Spacer position="right" />
              <IconButton
                size="small"
                sx={{ marginTop: "-5px", marginLeft: "-10px" }}
                onClick={() =>
                  setSortCate({
                    title: "idle",
                    merchantBusinessName: "idle",
                    validity: sortCate.validity === "asc" ? "dsc" : "asc",
                    subscribers: "idle",
                    price: "idle",
                    status: "idle",
                  })
                }
              >
                {sortCate.validity === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Box>
          </>
        );
      case "Subscribers":
        return (
          <>
            <Box sx={{ display: "flex", paddingTop: 1 }}>
              <Typography>{headCell}</Typography>
              <Spacer position="right" />
              <IconButton
                size="small"
                sx={{ marginTop: "-5px", marginLeft: "-10px" }}
                onClick={() =>
                  setSortCate({
                    title: "idle",
                    merchantBusinessName: "idle",
                    validity: "idle",
                    subscribers: sortCate.subscribers === "asc" ? "dsc" : "asc",
                    price: "idle",
                    status: "idle",
                  })
                }
              >
                {sortCate.subscribers === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Box>
          </>
        );
      case "Price":
        return (
          <>
            <Box sx={{ display: "flex", paddingTop: 1 }}>
              <Typography>{headCell}</Typography>
              <Spacer position="right" />
              <IconButton
                size="small"
                sx={{ marginTop: "-5px", marginLeft: "-10px" }}
                onClick={() =>
                  setSortCate({
                    title: "idle",
                    merchantBusinessName: "idle",
                    validity: "idle",
                    subscribers: "idle",
                    price: sortCate.price === "asc" ? "dsc" : "asc",
                    status: "idle",
                  })
                }
              >
                {sortCate.price === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Box>
          </>
        );
      case "Status":
        return (
          <>
            <Box sx={{ display: "flex", paddingTop: 1 }}>
              <Typography>{headCell}</Typography>
              <Spacer position="right" />
              <IconButton
                size="small"
                sx={{ marginTop: "-5px", marginLeft: "-10px" }}
                onClick={() =>
                  setSortCate({
                    title: "idle",
                    merchantBusinessName: "idle",
                    validity: "idle",
                    subscribers: "idle",
                    price: "idle",
                    status: sortCate.status === "asc" ? "dsc" : "asc",
                  })
                }
              >
                {sortCate.status === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Box>
          </>
        );
      default:
        return <Typography>{headCell}</Typography>;
    }
  };
  return (
    <TableHead>
      {!!headerCells.length && (
        <>
          <TableRow>
            {headerCells.map((headCell, index) => (
              <TableCell key={uuidv4()}>
                <>{index === 0 && <Typography variant="h6">{headerTitle}</Typography>}</>
              </TableCell>
            ))}
          </TableRow>
          <TableRow
            sx={{
              "& .MuiTableCell-root": {
                borderTop: "1px solid #e0e0e0",
              },
            }}
          >
            {headerCells.map((headCell) => (
              <TableCell key={headCell}>{renderTableCell(headCell)}</TableCell>
            ))}
          </TableRow>
        </>
      )}
    </TableHead>
  );
}

TableHeader.defaultProps = {
  setSortCate: () => {},
  sortCate: {},
};

TableHeader.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  headerCells: PropTypes.arrayOf(
    PropTypes.oneOf([
      "Image",
      "Title",
      "Merchant Business Name",
      "Days",
      "Subscribers",
      "Price",
      "Status",
      "Action",
      "",
    ]),
  ).isRequired,
  sortCate: PropTypes.shape({
    title: PropTypes.oneOf(["idle", "asc", "dsc"]),
    merchantBusinessName: PropTypes.oneOf(["idle", "asc", "dsc"]),
    validity: PropTypes.oneOf(["idle", "asc", "dsc"]),
    subscribers: PropTypes.oneOf(["idle", "asc", "dsc"]),
    price: PropTypes.oneOf(["idle", "asc", "dsc"]),
    status: PropTypes.oneOf(["idle", "asc", "dsc"]),
  }),
  setSortCate: PropTypes.func,
};

export default TableHeader;
