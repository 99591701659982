import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postReq, delReq } from "../../../api.service";

export const createMerchantBusinessImage = createAsyncThunk(
  "merchant/business/images/upload",
  async (payload) => {
    const response = postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${payload.merchantId}/businesses/${payload.businessId}/images`,
      payload,
    );
    return response;
  },
);

export const removeMerchantBusinessImage = createAsyncThunk(
  "merchant/business/images/remove",
  async (payload) => {
    const response = delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${payload.merchantId}/businesses/${payload.businessId}/images/${payload.imageId}`,
    );
    return response;
  },
);

const merchantBusinessImageSlice = createSlice({
  name: "merchantBusinessImage",
  initialState: {
    createMerchantBusinessImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    removeMerchantBusinessImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [createMerchantBusinessImage.pending]: (state) => {
      state.createMerchantBusinessImageObj.status = "pending";
    },
    [createMerchantBusinessImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createMerchantBusinessImageObj.status = "succeeded";
      state.createMerchantBusinessImageObj.data = data;
      state.createMerchantBusinessImageObj.successMessage = message;
    },
    [createMerchantBusinessImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.createMerchantBusinessImageObj.status = "failed";
      state.createMerchantBusinessImageObj.errorMessage = message;
    },
    [removeMerchantBusinessImage.pending]: (state) => {
      state.removeMerchantBusinessImageObj.status = "pending";
    },
    [removeMerchantBusinessImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.removeMerchantBusinessImageObj.status = "succeeded";
      state.removeMerchantBusinessImageObj.data = data;
      state.removeMerchantBusinessImageObj.successMessage = message;
    },
    [removeMerchantBusinessImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.removeMerchantBusinessImageObj.status = "failed";
      state.removeMerchantBusinessImageObj.errorMessage = message;
    },
  },
});

export default merchantBusinessImageSlice.reducer;

// state
export const merchantBusinessImageSelector = (state) => state.merchantBusinessImage;
