import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableRow,
  TableFooter,
  TablePagination,
  styled,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  useTheme,
  TableBody,
} from "@mui/material";
import { DebounceInput } from "react-debounce-input";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from "react-redux";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import TableHeader from "../../../components/tables/table-header.component";
import Spacer from "../../../components/utils/spacer.component";
import PopUpRow from "./pop-up-row.component";
import { popUpSelector } from "../../../services/pop-up/pop-up-slice.service";
import PopUpTableLoader from "../loader/pop-up-table-loader.component";
import TableSort from "../../../components/tables/table-sort.component";

const TableTopContainer = styled(Box)(({ theme }) => ({
  padding: "16px 16px 16px 16px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.colors.ui.tableBorder}`,
  alignItems: "center",
  "& .MuiButton-root": {
    padding: "8px 24px 8px 24px",
    borderTopLeftRadius: theme.shape.borderRadius[3],
    borderTopRightRadius: theme.shape.borderRadius[3],
    borderBottomLeftRadius: theme.shape.borderRadius[3],
    borderBottomRightRadius: theme.shape.borderRadius[3],
    backgroundColor: theme.palette.colors.brand.primary,
    textTransform: "none",
    ":hover": {
      backgroundColor: theme.palette.colors.brand.primary,
    },
  },
}));

function PopUpTableIndex({
  handlePageChange,
  page,
  onClickDelete,
  onPreviewClicked,
  searchKeyword,
  onChangeSearchKeyword,
}) {
  const theme = useTheme();
  const { getPopUpListObj } = useSelector(popUpSelector);
  const columnMapping = {
    ID: "id",
    Slug: "slug",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getPopUpListObj.data && getPopUpListObj.status === "succeeded") {
      records = { ...getPopUpListObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableContent = () => {
    if (getPopUpListObj.status === "succeeded") {
      const records = getProcessedRecord();

      return (
        <>
          <TableBody>
            {getPopUpListObj.data.items &&
              records?.items.map((record) => (
                <PopUpRow
                  record={record}
                  key={record.id}
                  onClickDelete={onClickDelete}
                  onPreviewClicked={onPreviewClicked}
                />
              ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                page={page}
                rowsPerPage={getPopUpListObj.data.pagination.perPage}
                count={getPopUpListObj.data.pagination.totalItems}
                rowsPerPageOptions={[]}
                showFirstButton={true}
                showLastButton={true}
                onPageChange={handlePageChange}
              />
            </TableRow>
          </TableFooter>
        </>
      );
    }
    return <PopUpTableLoader />;
  };

  return (
    <TableWrapper>
      <TableTopContainer>
        <Typography variant="h6">Pop Up List</Typography>

        <DebounceInput
          sx={{
            borderTopLeftRadius: theme.shape.borderRadius[1],
            borderTopRightRadius: theme.shape.borderRadius[1],
            borderBottomLeftRadius: theme.shape.borderRadius[1],
            borderBottomRightRadius: theme.shape.borderRadius[1],
            width: "400px",
            "& .MuiOutlinedInput-root": {
              height: "40px",
              borderTopLeftRadius: theme.shape.borderRadius[1],
              borderTopRightRadius: theme.shape.borderRadius[1],
              borderBottomLeftRadius: theme.shape.borderRadius[1],
              borderBottomRightRadius: theme.shape.borderRadius[1],
              backgroundColor: theme.palette.colors.bg.primary,
            },
          }}
          debounceTimeout={300}
          onChange={(e) => onChangeSearchKeyword(e.target.value)}
          element={TextField}
          value={searchKeyword}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
                <Spacer position="right" />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {searchKeyword && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onChangeSearchKeyword("")}>
                      <HighlightOffIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
          placeholder="Search list by slug"
        />
      </TableTopContainer>

      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerTitle=""
          headerCells={["ID", "Slug", "File", "Action"]}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
          columnMapping={columnMapping}
          nonSortableColumns={["File"]}
        />
        {renderTableContent()}
      </Table>
    </TableWrapper>
  );
}

PopUpTableIndex.propTypes = {
  searchKeyword: PropTypes.string.isRequired,
  onChangeSearchKeyword: PropTypes.func.isRequired,
  onPreviewClicked: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

export default PopUpTableIndex;
