import { Box, Grid, Table, TableBody, Typography, styled, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import TableHeader from "../../../components/tables/table-header.component";
import DisputeRow from "./dispute-row.component";
import { statementSelector } from "../../../services/statement/statement-slice.service";
import BorderCtaButton from "../../../components/button/border-cta-button.component";

const DetailCardContainer = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  backgroundColor: theme.palette.colors.bg.white,
  boxShadow: `1px 2px 5px ${theme.palette.colors.loading.foregroundColor}`,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "15px",
  paddingRight: "15px",
}));

export default function DisputeLog({ setShowModal, setRejectReason, setShowRejectStatementModal }) {
  const { reduxStoreStatementDetails } = useSelector(statementSelector);
  const theme = useTheme();

  return (
    <DetailCardContainer>
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="h5">Dispute Log</Typography>
        </Grid>
        {reduxStoreStatementDetails.status === "dispute" && (
          <Grid item xs={3}>
            <BorderCtaButton width="80%" onClickButton={() => setShowRejectStatementModal(true)}>
              <Typography sx={{ color: theme.palette.colors.text.error }}>
                Add Dispute Log
              </Typography>
            </BorderCtaButton>
          </Grid>
        )}
      </Grid>
      <Table>
        <TableHeader
          headerCells={["Dispute Id", "Employee", "Date Created", "Action"]}
          nonSortableColumns={["Dispute Id", "Employee", "Date Created"]}
        />
        <TableBody>
          {reduxStoreStatementDetails?.disputeLog?.map((item) => (
            <DisputeRow
              record={item}
              key={item.id}
              setShowModal={setShowModal}
              setRejectReason={setRejectReason}
            />
          ))}
        </TableBody>
      </Table>
    </DetailCardContainer>
  );
}

DisputeLog.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  setRejectReason: PropTypes.func.isRequired,
  setShowRejectStatementModal: PropTypes.func.isRequired,
};
