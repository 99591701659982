import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api.service";

export const getCreditTransactions = createAsyncThunk(
  "credit/transactions/index",
  async (payload) => {
    const { page, userIds, startAt, endAt, types } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/credits?${
        page ? "page=".concat(page + 1) : "page=1"
      }${userIds ? "&userIds=".concat(userIds) : ""}${startAt ? "&startAt=".concat(startAt) : ""}${
        endAt ? "&endAt=".concat(endAt) : ""
      }${types ? "&types=".concat(types) : ""}`,
    );
    return response;
  },
);

export const addCreditTransactions = createAsyncThunk(
  "credit/transactions/add",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/credits/add`,
      payload,
    );
    return response;
  },
);

export const deductCreditTransactions = createAsyncThunk(
  "credit/transactions/deduct",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/credits/deduct`,
      payload,
    );
    return response;
  },
);

const creditSlice = createSlice({
  name: "credit",
  initialState: {
    getCreditTransactionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    addCreditTransactionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deductCreditTransactionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getCreditTransactions.pending]: (state) => {
      state.getCreditTransactionsObj.status = "pending";
    },
    [getCreditTransactions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCreditTransactionsObj.status = "succeeded";
      state.getCreditTransactionsObj.data = data;
      state.getCreditTransactionsObj.successMessage = message;
    },
    [getCreditTransactions.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCreditTransactionsObj.status = "failed";
      state.getCreditTransactionsObj.errorMessage = message;
    },
    [addCreditTransactions.pending]: (state) => {
      state.addCreditTransactionsObj.status = "pending";
    },
    [addCreditTransactions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.addCreditTransactionsObj.status = "succeeded";
      state.addCreditTransactionsObj.data = data;
      state.addCreditTransactionsObj.successMessage = message;
    },
    [addCreditTransactions.rejected]: (state, action) => {
      const { message } = action.error;

      state.addCreditTransactionsObj.status = "failed";
      state.addCreditTransactionsObj.errorMessage = message;
    },
    [deductCreditTransactions.pending]: (state) => {
      state.deductCreditTransactionsObj.status = "pending";
    },
    [deductCreditTransactions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deductCreditTransactionsObj.status = "succeeded";
      state.deductCreditTransactionsObj.data = data;
      state.deductCreditTransactionsObj.successMessage = message;
    },
    [deductCreditTransactions.rejected]: (state, action) => {
      const { message } = action.error;

      state.deductCreditTransactionsObj.status = "failed";
      state.deductCreditTransactionsObj.errorMessage = message;
    },
  },
});

export default creditSlice.reducer;

// state
export const creditSelector = (state) => state.credit;
