import { Box, Grid, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { statementSelector } from "../../../services/statement/statement-slice.service";

const DetailCardContainer = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  backgroundColor: theme.palette.colors.bg.white,
  boxShadow: `1px 2px 5px ${theme.palette.colors.loading.foregroundColor}`,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "15px",
  paddingRight: "15px",
}));

export default function StatementDetails() {
  const theme = useTheme();
  const { getStatementDetailObj } = useSelector(statementSelector);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <DetailCardContainer>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 3}>
              <Box>
                <Typography>Business Name: </Typography>
                <Typography
                  sx={{ color: theme.palette.colors.text.secondary, textTransform: "capitalize" }}
                >
                  {getStatementDetailObj.data.merchant.businessName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <Box>
                <Typography>Bank Account Name: </Typography>
                <Typography sx={{ color: theme.palette.colors.text.secondary }}>
                  {getStatementDetailObj.data.merchant.bankAccountName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <Box>
                <Typography>Bank Name: </Typography>
                <Typography sx={{ color: theme.palette.colors.text.secondary }}>
                  {getStatementDetailObj.data.merchant.bankName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <Box>
                <Typography>Bank Account No: </Typography>
                <Typography sx={{ color: theme.palette.colors.text.secondary }}>
                  {getStatementDetailObj.data.merchant.bankAccountNo}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box>
                <Typography variant="h6">Amount to be paid: </Typography>
                <Typography variant="h6" sx={{ color: theme.palette.colors.text.secondary }}>
                  RM {getStatementDetailObj.data.amount}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <Box>
                <Typography variant="h6">Paid On: </Typography>
                <Typography variant="h6" sx={{ color: theme.palette.colors.text.secondary }}>
                  {getStatementDetailObj.data.completedAt
                    ? getStatementDetailObj.data.completedAt
                    : "-"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DetailCardContainer>
  );
}
