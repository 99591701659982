import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  Link,
  List,
  ListItem,
  styled,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Spacer from "../../../../components/utils/spacer.component";

const TableTopContainer = styled(Box)(({ theme }) => ({
  padding: "6px 16px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.colors.ui.tableBorder}`,
  alignItems: "flex-end",
}));

const TABLE_CELL_WIDTH = 150;
function Row({ record }) {
  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Box sx={{ display: "flex" }}>
            <Avatar
              alt={record.user.name}
              src={record.user.imagePath}
              sx={{ width: 100, height: 100 }}
            />
            <Box sx={{ width: 50, height: 50 }} />
          </Box>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.user.name}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.plan.title}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            RM{record.price}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.plan.numberOfDays}
          </Typography>
        </TableCell>
        {record.start_at ? (
          <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
            <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
              {moment(record.start_at).format("D MMM YYYY")} -{" "}
            </Typography>
            <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
              {moment(record.end_at).format("D MMM YYYY")}
            </Typography>
          </TableCell>
        ) : (
          <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
            <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
              -
            </Typography>
          </TableCell>
        )}

        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography
            variant="body2"
            noWrap={true}
            sx={{ width: TABLE_CELL_WIDTH, textTransform: "capitalize" }}
          >
            {record.status.replace("_", " ")}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
            {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <TableTopContainer>
              <Typography sx={{ fontWeight: "bold" }}>Merchant Business Name</Typography>
            </TableTopContainer>
            <List sx={{ listStyleType: "disc", pl: 4 }}>
              <Link color="inherit" href={`/merchants/edit?merchantId=${record.merchant.id}`}>
                <Typography sx={{ textTransform: "capitalize", fontWeight: "bold" }}>
                  {record.merchant.businessName}
                </Typography>
              </Link>
            </List>
            <Spacer size="m" />
            <TableTopContainer>
              <Typography sx={{ fontWeight: "bold" }}>Businesses Access</Typography>
            </TableTopContainer>
            {record.businesses.length > 0 &&
              record.businesses.map((business) => (
                <List sx={{ listStyleType: "disc", pl: 4 }} key={business.id}>
                  <ListItem sx={{ display: "list-item" }} disablePadding>
                    {business.name}
                  </ListItem>
                  <ListItem disablePadding>{`- ${business.location}`}</ListItem>
                </List>
              ))}
            <Spacer size="xl" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    status: PropTypes.oneOf(["active", "expired", "pending_activation"]),
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      phone: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    merchant: PropTypes.shape({
      id: PropTypes.number.isRequired,
      businessName: PropTypes.string.isRequired,
    }),
    plan: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      shortDescription: PropTypes.string.isRequired,
      numberOfDays: PropTypes.number.isRequired,
    }).isRequired,
    price: PropTypes.string.isRequired,
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default Row;
