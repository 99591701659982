import React from "react";
import * as Yup from "yup";
import { Typography, Breadcrumbs, Stack, Link, Grid, FormLabel } from "@mui/material";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaddedView from "../../../components/utils/padded-view.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { createStaff, staffSelector } from "../../../services/staff/staff-slice.service";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  email: Yup.string().email().required().label("Email"),
});

function StaffNewScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createStaffObj } = useSelector(staffSelector);

  const onHandleSubmit = (values) => {
    dispatch(createStaff(values)).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        navigate(`/staffs/${payload.data.id}/edit`);
      }
    });
  };

  return (
    <>
      <PaddedView>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item xs={4}>
                <Typography variant="h6">New Staff</Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid container justifyContent="flex-end">
                  <Stack spacing={2} sx={{ paddingRight: 1 }}>
                    <Breadcrumbs separator=">" aria-label="breadcrumb">
                      <Link underline="hover" key="1" color="inherit" href="/">
                        <Typography variant="body1">Dashboard</Typography>
                      </Link>
                      <Link underline="hover" key="2" color="inherit" href="/staffs/list">
                        <Typography variant="body1">Staff List</Typography>
                      </Link>
                      <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                        New Staff
                      </Typography>
                    </Breadcrumbs>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Formik
              validationSchema={validationSchema}
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
              }}
              onSubmit={onHandleSubmit}
            >
              {({ isValid, touched }) => (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormLabel>Details</FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <FormFieldText
                            name="firstName"
                            label="First Name"
                            placeholder="First Name"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormFieldText
                            name="lastName"
                            label="Last Name"
                            placeholder="Last Name"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormFieldText name="email" label="Email" placeholder="Email" />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container justifyContent="flex-end">
                        <Grid item xs={12} sm={2}>
                          <FormSubmitButton
                            type="submit"
                            disabled={
                              !isValid ||
                              (Object.keys(touched).filter((objKey) => objKey).length === 0 &&
                                touched.constructor === Object) ||
                              createStaffObj.status === "pending"
                            }
                          >
                            <Typography>Submit</Typography>
                          </FormSubmitButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
        </Grid>
      </PaddedView>
    </>
  );
}

export default StaffNewScreen;
