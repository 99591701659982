import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import moment from "moment";
import Form from "../../../components/forms/form.component";
import TableLoader from "../../../components/tables/table-loader.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { staffSelector, getStaffs } from "../../../services/staff/staff-slice.service";
import { setStaffHasImage } from "../../../services/staff/image/staff-image-slice.service";
import TableIndex from "../components/staff-list/table-index.component";
import FilterOptions from "../components/staff-list/filter-options.component";

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable().label("Search"),
});

function StaffListScreen() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const { getStaffsObj } = useSelector(staffSelector);
  const [payloadParams, setPayloadParams] = useState(null);
  const [sortCate, setSortCate] = useState({
    name: "idle",
    email: "idle",
    createdAt: "idle",
  });

  useEffect(() => {
    dispatch(setStaffHasImage(false));
    dispatch(getStaffs({ page }));
  }, []);

  const onHandleSubmit = (values) => {
    const payload = {
      q: values.q,
      page,
    };
    setPayloadParams(payload);
    dispatch(getStaffs(payload));
  };

  const processRecords = () => {
    const sortedData = { ...getStaffsObj.data };

    if (sortCate.name === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.firstName < b.firstName) {
          return 1;
        }
        if (a.firstName > b.firstName) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.name === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.firstName > b.firstName) {
          return 1;
        }
        if (a.firstName < b.firstName) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.email === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.email < b.email) {
          return 1;
        }
        if (a.email > b.email) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.email === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.email > b.email) {
          return 1;
        }
        if (a.email < b.email) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.createdAt === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (moment(a.createdAt) < moment(b.createdAt)) {
          return 1;
        }
        if (moment(a.createdAt) > moment(b.createdAt)) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.createdAt === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (moment(a.createdAt) > moment(b.createdAt)) {
          return 1;
        }
        if (moment(a.createdAt) < moment(b.createdAt)) {
          return -1;
        }
        return 0;
      });
    }

    return sortedData;
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getStaffs({ ...payloadParams, page: newPage }));
  };

  const renderDataRecords = () => (
    <>
      {getStaffsObj.data && getStaffsObj.data.items && getStaffsObj.data.items.length > 0 ? (
        <>
          <PaddedView>
            <TableIndex
              page={page}
              handlePageChange={handlePageChange}
              records={processRecords()}
              sortCate={sortCate}
              setSortCate={setSortCate}
            />
          </PaddedView>
        </>
      ) : (
        <Typography>No staffs.</Typography>
      )}
    </>
  );

  return (
    <>
      <Form initialValues={{ q: "" }} onSubmit={onHandleSubmit} validationSchema={validationSchema}>
        <FilterOptions />
      </Form>

      <Spacer size="l" />
      {getStaffsObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </>
  );
}

export default StaffListScreen;
