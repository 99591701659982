import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, TextField, Paper, LinearProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";

const searchOptions = {
  componentRestrictions: { country: "MY" },
};

function MerchantBusinessAddressAutoComplete({ name, disabled }) {
  const { setFieldValue, values, setFieldTouched, touched, errors } = useFormikContext();
  const [address, setAddress] = useState(values[name]);
  const theme = useTheme();

  const showError = !!(
    touched[name] &&
    (errors[name] ||
      errors.locationLat ||
      errors.locationLong ||
      errors.city ||
      errors.state ||
      errors.postcode)
  );

  const handleSelect = async (value, placeID) => {
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);
    const [place] = await geocodeByPlaceId(placeID);
    const { long_name: postcode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) || {};
    const { long_name: city = "" } =
      place.address_components.find((c) => c.types.includes("locality")) || {};
    const { long_name: state = "" } =
      place.address_components.find((c) => c.types.includes("administrative_area_level_1")) || {};

    setAddress(value);
    setFieldValue(name, value);
    setFieldValue("locationLat", latLng.lat);
    setFieldValue("locationLong", latLng.lng);
    setFieldValue("city", city);
    setFieldValue("state", state);
    setFieldValue("postcode", postcode);
  };

  return (
    <>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        searchOptions={searchOptions}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Box
            sx={{
              "& .MuiTextField-root": {
                borderTopLeftRadius: theme.shape.borderRadius[1],
                borderTopRightRadius: theme.shape.borderRadius[1],
                borderBottomLeftRadius: theme.shape.borderRadius[1],
                borderBottomRightRadius: theme.shape.borderRadius[1],
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderTopLeftRadius: theme.shape.borderRadius[1],
                  borderTopRightRadius: theme.shape.borderRadius[1],
                  borderBottomLeftRadius: theme.shape.borderRadius[1],
                  borderBottomRightRadius: theme.shape.borderRadius[1],
                  backgroundColor: theme.palette.colors.bg.primary,
                },
              },
              position: "relative",
            }}
          >
            <TextField
              {...getInputProps()}
              onBlur={() => setFieldTouched(name)}
              label="Address"
              placeholder="Enter address"
              value={address}
              error={showError}
              disabled={disabled}
              multiline
              rows={3}
              helperText={
                showError
                  ? errors[name] ||
                    errors.locationLat ||
                    errors.locationLong ||
                    errors.city ||
                    errors.state ||
                    errors.postcode
                  : null
              }
            />

            {loading && (
              <>
                <Spacer />
                <LinearProgress />
              </>
            )}

            <Box
              sx={{
                position: "absolute",
                top: 56,
                left: 0,
                background: "#fff",
                zIndex: 2,
                marginTop: 1,
              }}
            >
              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#ffffff",
                  cursor: "pointer",
                };
                return (
                  <Paper
                    {...getSuggestionItemProps(suggestion, { style })}
                    key={suggestion.description}
                  >
                    <PaddedView>
                      <Typography>{suggestion.description}</Typography>
                    </PaddedView>
                  </Paper>
                );
              })}
            </Box>
          </Box>
        )}
      </PlacesAutocomplete>
    </>
  );
}

MerchantBusinessAddressAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};
export default MerchantBusinessAddressAutoComplete;
