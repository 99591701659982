import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { putReq } from "../../api.service";

export const updateStaffGymAccess = createAsyncThunk(
  "staffs/{staff_id}/gym_access",
  async (payload) => {
    const { staffId, gymsId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/staffs/${staffId}/gym_accesses`,
      { gymsId },
    );
    return response;
  },
);

const staffSlice = createSlice({
  name: "staffGymAccess",
  initialState: {
    updateStaffGymAccessObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [updateStaffGymAccess.pending]: (state) => {
      state.updateStaffGymAccessObj.status = "pending";
    },
    [updateStaffGymAccess.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.updateStaffGymAccessObj.status = "succeeded";
      state.updateStaffGymAccessObj.data = data;
      state.updateStaffGymAccessObj.successMessage = message;
    },
    [updateStaffGymAccess.rejected]: (state, action) => {
      const { message } = action.error;
      state.updateStaffGymAccessObj.status = "failed";
      state.updateStaffGymAccessObj.errorMessage = message;
    },
  },
});

export default staffSlice.reducer;

// state
export const staffGymAccessSelector = (state) => state.staffGymAccess;

// action
export const { setStaffHasImage } = staffSlice.actions;
