import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";

import Row from "./row.component";
import TableWrapper from "../../../../components/tables/table-wrapper.component";
import TableHeader from "../../../../components/tables/table-header.component";
import TableSort from "../../../../components/tables/table-sort.component";

function TableIndex({ records, handlePageChange, page, shadowLoginToUser }) {
  const columnMapping = {
    Email: "user.email",
    "Full Name": "user.firstName",
    "Registered On": "user.registeredAt",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);
  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let sortedRecords = null;
    sortedRecords = { ...records };

    const sortedData = [...sortedRecords.items].sort((a, b) => {
      if (sortColumn) {
        const columnA = parseValue(getColumnValue(a, sortColumn));
        const columnB = parseValue(getColumnValue(b, sortColumn));

        // Handle boolean values
        if (typeof columnA === "boolean" && typeof columnB === "boolean") {
          return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
        }

        // Handle numeric values without converting to strings
        if (typeof columnA === "number" && typeof columnB === "number") {
          return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
        }

        // Handle string values
        const stringColumnA = typeof columnA === "string" ? columnA : "";
        const stringColumnB = typeof columnB === "string" ? columnB : "";

        return sortOrder === "asc"
          ? stringColumnA.localeCompare(stringColumnB)
          : stringColumnB.localeCompare(stringColumnA);
      }
      return 0; // No sorting if sortColumn is null
    });

    // Replace the original items array with the sortedData
    sortedRecords.items = sortedData;

    return sortedRecords;
  };
  const sortedRecords = getProcessedRecord();

  return (
    <TableWrapper>
      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerTitle="Users Lists"
          headerCells={["Email", "Full Name", "Registered On", "Action"]}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
          columnMapping={columnMapping}
        />

        <TableBody>
          {records.items &&
            sortedRecords.items.map((record) => (
              <Row
                record={record}
                key={`${record.user.id}-${record.user.firstname}`}
                shadowLoginToUser={shadowLoginToUser}
              />
            ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={records.pagination.perPage}
              count={records.pagination.totalItems}
              rowsPerPageOptions={[]}
              showFirstButton={true}
              showLastButton={true}
              onPageChange={handlePageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableWrapper>
  );
}

TableIndex.propTypes = {
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  shadowLoginToUser: PropTypes.func.isRequired,
  records: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        userId: PropTypes.number,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        registeredAt: PropTypes.string,
        userBookings: PropTypes.arrayOf(
          PropTypes.shape({
            gymId: PropTypes.number,
            gymName: PropTypes.string,
            bookingId: PropTypes.number,
            timeSlotId: PropTypes.number,
            date: PropTypes.string,
            startTime: PropTypes.string,
            endtime: PropTypes.string,
          }),
        ),
      }),
    ),
    pagination: PropTypes.shape({
      perPage: PropTypes.number.isRequired,
      totalItems: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default TableIndex;
