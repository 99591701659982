import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import TableWrapper from "../../../../components/tables/table-wrapper.component";
import TableHeader from "./table-header.component";
import Row from "./row.component";

function TableIndex({ page, handlePageChange, setSortCate, sortCate, records }) {
  return (
    <TableWrapper>
      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerTitle="Hourly Booking List"
          setSortCate={setSortCate}
          sortCate={sortCate}
          headerCells={["Name", "Merchant", "Business", "Price", "Start At", "End At", "Status"]}
        />

        <TableBody>
          {records.items.map((record) => (
            <Row record={record} key={uuidv4()} />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={records.pagination.perPage}
              count={records.pagination.totalItems}
              rowsPerPageOptions={[]}
              showFirstButton={true}
              showLastButton={true}
              onPageChange={handlePageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableWrapper>
  );
}

TableIndex.propTypes = {
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  sortCate: PropTypes.shape({
    name: PropTypes.oneOf(["idle", "asc", "dsc"]),
    merchant: PropTypes.oneOf(["idle", "asc", "dsc"]),
    business: PropTypes.oneOf(["idle", "asc", "dsc"]),
    price: PropTypes.oneOf(["idle", "asc", "dsc"]),
  }).isRequired,
  setSortCate: PropTypes.func.isRequired,
  records: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        merchant: PropTypes.shape({
          id: PropTypes.number.isRequired,
          businessName: PropTypes.string.isRequired,
        }),
        user: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
        business: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
        booking: PropTypes.shape({
          status: PropTypes.string,
          startDate: PropTypes.string,
          endDate: PropTypes.string,
          price: PropTypes.string,
        }),
      }).isRequired,
    ).isRequired,
    pagination: PropTypes.shape({
      perPage: PropTypes.number.isRequired,
      totalItems: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default TableIndex;
