import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormLabel, Grid, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import PaddedView from "../../../../components/utils/padded-view.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import { updateStaff } from "../../../../services/staff/staff-slice.service";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  email: Yup.string().email().required().label("Email"),
});

function StaffDetails({ record }) {
  const [allowEdit, setAllowEdit] = useState(false);
  const dispatch = useDispatch();

  const onHandleSubmit = (values) => {
    setAllowEdit(false);
    dispatch(updateStaff(values));
  };

  return (
    <>
      <PaddedView>
        <Formik
          validationSchema={validationSchema}
          onSubmit={onHandleSubmit}
          initialValues={{
            firstName: record.firstName,
            lastName: record.lastName,
            email: record.email,
            staffId: record.id,
          }}
        >
          {({ handleSubmit, dirty }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        alignSelf: "flex-end",
                      }}
                    >
                      <FormLabel>Details</FormLabel>
                    </Grid>
                    <Grid item xs={2}>
                      {allowEdit ? (
                        <>
                          <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setAllowEdit(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAllowEdit(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        name="firstName"
                        placeholder="Enter First Name"
                        label="First Name"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        name="lastName"
                        placeholder="Enter Last Name"
                        label="Last Name"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        name="email"
                        placeholder="Enter email"
                        label="Email"
                        disabled={!allowEdit}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </PaddedView>
    </>
  );
}

StaffDetails.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    imagePath: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    deletable: PropTypes.bool.isRequired,
  }).isRequired,
};

export default StaffDetails;
