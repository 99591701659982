import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../../api.service";

export const searchMerchantAdditionalFees = createAsyncThunk(
  "merchants/additional_fees/index",
  async (payload) => {
    const { q, merchantsId } = payload;
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/additional_fees?page=${
        payload.page + 1
      }${q ? "&q=".concat(q) : ""}${merchantsId ? "&merchantsId=".concat(merchantsId) : ""}`,
    );
    return response;
  },
);

export const getMerchantAdditionalFees = createAsyncThunk(
  "merchants/additional_fees/get/{fee_id}",
  async (payload) => {
    const { feeId } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/additional_fees/${feeId}`,
    );
    return response;
  },
);

export const createAdditionalFees = createAsyncThunk(
  "merchants/additional_fees/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/additional_fees`,
      payload,
    );
    return response;
  },
);

export const updateAdditionalFees = createAsyncThunk(
  "merchants/additional_fees/update/{fee_id}",
  async (payload) => {
    const { feeId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/additional_fees/${feeId}`,
      payload,
    );
    return response;
  },
);

export const deleteAdditionalFees = createAsyncThunk(
  "merchants/additional_fees/delete/{fee_id}",
  async (payload) => {
    const { feeId } = payload;
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/additional_fees/${feeId}`,
    );
    return response;
  },
);

const merchantAdditionalFeesSlice = createSlice({
  name: "merchantAdditionalFees",
  initialState: {
    searchMerchantAdditionalFeesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMerchantAdditionalFeesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createAdditionalFeesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateAdditionalFeesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteAdditionalFeesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [searchMerchantAdditionalFees.pending]: (state) => {
      state.searchMerchantAdditionalFeesObj.status = "pending";
    },
    [searchMerchantAdditionalFees.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchMerchantAdditionalFeesObj.status = "succeeded";
      state.searchMerchantAdditionalFeesObj.data = data;
      state.searchMerchantAdditionalFeesObj.successMessage = message;
    },
    [searchMerchantAdditionalFees.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchMerchantAdditionalFeesObj.status = "failed";
      state.searchMerchantAdditionalFeesObj.errorMessage = message;
    },
    [getMerchantAdditionalFees.pending]: (state) => {
      state.getMerchantAdditionalFeesObj.status = "pending";
    },
    [getMerchantAdditionalFees.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantAdditionalFeesObj.status = "succeeded";
      state.getMerchantAdditionalFeesObj.data = data;
      state.getMerchantAdditionalFeesObj.successMessage = message;
    },
    [getMerchantAdditionalFees.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantAdditionalFeesObj.status = "failed";
      state.getMerchantAdditionalFeesObj.errorMessage = message;
    },
    [createAdditionalFees.pending]: (state) => {
      state.createAdditionalFeesObj.status = "pending";
    },
    [createAdditionalFees.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createAdditionalFeesObj.status = "succeeded";
      state.createAdditionalFeesObj.data = data;
      state.createAdditionalFeesObj.successMessage = message;
    },
    [createAdditionalFees.rejected]: (state, action) => {
      const { message } = action.error;

      state.createAdditionalFeesObj.status = "failed";
      state.createAdditionalFeesObj.errorMessage = message;
    },

    [updateAdditionalFees.pending]: (state) => {
      state.updateAdditionalFeesObj.status = "pending";
    },
    [updateAdditionalFees.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateAdditionalFeesObj.status = "succeeded";
      state.updateAdditionalFeesObj.data = data;
      state.updateAdditionalFeesObj.successMessage = message;
    },
    [updateAdditionalFees.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateAdditionalFeesObj.status = "failed";
      state.updateAdditionalFeesObj.errorMessage = message;
    },
    [deleteAdditionalFees.pending]: (state) => {
      state.deleteAdditionalFeesObj.status = "pending";
    },
    [deleteAdditionalFees.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteAdditionalFeesObj.status = "succeeded";
      state.deleteAdditionalFeesObj.data = data;
      state.deleteAdditionalFeesObj.successMessage = message;
    },
    [deleteAdditionalFees.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteAdditionalFeesObj.status = "failed";
      state.deleteAdditionalFeesObj.errorMessage = message;
    },
  },
});

export default merchantAdditionalFeesSlice.reducer;

// state
export const merchantAdditionalFeesSelector = (state) => state.merchantAdditionalFees;
