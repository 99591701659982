import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormLabel, Grid, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import Skeleton from "react-loading-skeleton";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import PaddedView from "../../../../../../../components/utils/padded-view.component";
import FormFieldText from "../../../../../../../components/forms/form-field-text.component";
import FormFieldNumber from "../../../../../../../components/forms/form-field-number.component";
import Spacer from "../../../../../../../components/utils/spacer.component";
import FormPrice from "../../../../../../../components/forms/form-price.component";
import OutlinedSelectTextField from "../../../../../../../components/forms/outlined-select-text-field.component";
import { updatePackagePlanDetail } from "../../../../../../../services/merchant/package/plan/plan-slice.service";
import FormMultiSelect from "../../../../../../../components/forms/form-multi-select.component";
import {
  classCategorySelector,
  getCategoryList,
} from "../../../../../../../services/category/class-category-slice.service";
import { SnackbarContext } from "../../../../../../../components/notification/snackbar.context";
import BusinessListAutoComplete from "../../../../components/business-list-autocomplete.component";

const validationSchema = Yup.object().shape({
  planId: Yup.number().required(),
  title: Yup.string()
    .required()
    .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allow")
    .label("Title"),
  priceCents: Yup.number().required().label("Price"),
  noOfSessions: Yup.number().required().label("Number of Sessions"),
  status: Yup.string().oneOf(["active", "pending", "inactive"]).label("Status"),
  categoryType: Yup.string().required().label("Category Type"),
  categoryIds: Yup.array().min(1).required().label("Category Ids"),
  category: Yup.object().shape({
    type: Yup.string().required().label("Category Type"),
    ids: Yup.array().min(1).required().label("Category Ids"),
  }),
});

function PlanDetail({ record }) {
  const [allowEdit, setAllowEdit] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getCategoryListObj } = useSelector(classCategorySelector);

  useEffect(() => {
    dispatch(getCategoryList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const onHandleSubmit = (values) => {
    setAllowEdit(false);
    values.category.type = values.categoryType;
    values.category.ids = values.categoryIds;
    const payloadValues = {
      ...values,
      businessesId: values.businessesId.map((item) => item.id),
      priceCents: parseFloat(values.priceCents * 100),
    };
    dispatch(updatePackagePlanDetail(payloadValues)).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({ type: "success", message: payload.message });
      }
    });
  };

  const getClassCategoriesSelection = () => {
    const itemList = [];
    getCategoryListObj.data.map((item) => itemList.push({ label: item.label, value: item.id }));
    return itemList;
  };

  return (
    <>
      <PaddedView>
        <Formik
          validationSchema={validationSchema}
          onSubmit={onHandleSubmit}
          initialValues={{
            merchantId: record.merchant.id,
            planId: record.id,
            title: record.title,
            priceCents: record.price.replace(",", ""),
            noOfSessions: record.numberOfSessions,
            status: record.status,
            categoryType: record.category.type,
            categoryIds: record.category.items.map((item) => item.id),
            businessesId: record.businesses.map((item) => ({
              id: item.id,
              option: item.name,
            })),
            category: {
              type: record.category.type,
              ids: record.category.items.map((item) => item.id),
            },
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Grid item container sx={{ width: 200 }}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      <FormLabel>Details</FormLabel>
                    </Grid>
                    <Grid item container xs={6} sx={{ justifyContent: "flex-end" }}>
                      {allowEdit ? (
                        <>
                          <IconButton size="small" onClick={handleSubmit}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setAllowEdit(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAllowEdit(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <Spacer size="m" />
                      <FormFieldText
                        name="title"
                        placeholder="Enter Title"
                        label="Title"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Spacer size="m" />
                      <FormPrice
                        name="priceCents"
                        placeholder="Enter Price"
                        label="Price"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormFieldNumber
                        name="noOfSessions"
                        placeholder="Enter number of sessions"
                        label="Number of Sessions"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <OutlinedSelectTextField
                        name="status"
                        label="Status"
                        disabled={!allowEdit}
                        selected={record.status}
                        selections={[
                          { type: "Active", value: "active" },
                          { type: "Pending", value: "pending" },
                          { type: "Inactive", value: "inactive" },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <OutlinedSelectTextField
                        name="categoryType"
                        label="Category Type"
                        disabled={!allowEdit}
                        selections={[{ type: "Fitness", value: "fitness" }]}
                      />
                    </Grid>
                    {getCategoryListObj.status === "succeeded" ? (
                      <Grid item xs={12}>
                        <FormMultiSelect
                          disabled={!allowEdit}
                          name="categoryIds"
                          label="Category(s)"
                          options={getClassCategoriesSelection()}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Skeleton width="100%" height={56} />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <BusinessListAutoComplete
                        disabled={!allowEdit}
                        name="businessesId"
                        placeholder="Business Accesses"
                        forEdit={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </PaddedView>
    </>
  );
}

PlanDetail.propTypes = {
  record: PropTypes.shape({
    deletable: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    imagePath: PropTypes.string.isRequired,
    numberOfPurchasers: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    priceCents: PropTypes.number.isRequired,
    numberOfSessions: PropTypes.number.isRequired,
    status: PropTypes.string,
    title: PropTypes.string.isRequired,
    merchant: PropTypes.shape({
      id: PropTypes.number.isRequired,
      businessName: PropTypes.string.isRequired,
    }),
    planDetails: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
      }),
    ).isRequired,
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ).isRequired,
    category: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
          imagePath: PropTypes.string,
        }),
      ),
    }).isRequired,
  }).isRequired,
};

export default PlanDetail;
