import React, { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

function OutlinedSelectTextField({ label, name, selections, disabled }) {
  const theme = useTheme();
  const { setFieldValue, values, setFieldTouched, touched, errors } = useFormikContext();
  const [currSelected, setCurrSelected] = useState(values[name]);
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (e) => {
    setCurrSelected(e.target.value);
    setFieldValue(name, e.target.value);
  };

  return (
    <FormControl
      fullWidth
      sx={{
        "& .MuiOutlinedInput-root": {
          borderTopLeftRadius: theme.shape.borderRadius[1],
          borderTopRightRadius: theme.shape.borderRadius[1],
          borderBottomLeftRadius: theme.shape.borderRadius[1],
          borderBottomRightRadius: theme.shape.borderRadius[1],
          backgroundColor: theme.palette.colors.bg.primary,
        },
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        onBlur={() => setFieldTouched(name)}
        label={label}
        disabled={disabled}
        value={currSelected}
        onChange={handleChange}
        error={showError}
      >
        {selections.map((selection) => (
          <MenuItem value={selection.value} key={`${selection.type}-${selection.value}`}>
            {selection.type}
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
    </FormControl>
  );
}

OutlinedSelectTextField.defaultProps = {
  disabled: false,
};

OutlinedSelectTextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selections: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired,
    }),
  ).isRequired,
};

export default OutlinedSelectTextField;
