import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { putReq } from "../../../../api.service";

export const updatePackagePlanItem = createAsyncThunk(
  "merchants/packages/plans/update/{plan_id}/item",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/packages/plans/${payload.planId}/items`,
      payload,
    );
    return response;
  },
);

const merchantPackagePlanItemSlice = createSlice({
  name: "packagePlanItem",
  initialState: {
    updatePackagePlanItemObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [updatePackagePlanItem.pending]: (state) => {
      state.updatePackagePlanItemObj.status = "pending";
    },
    [updatePackagePlanItem.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePackagePlanItemObj.status = "succeeded";
      state.updatePackagePlanItemObj.data = data;
      state.updatePackagePlanItemObj.successMessage = message;
    },
    [updatePackagePlanItem.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePackagePlanItemObj.status = "failed";
      state.updatePackagePlanItemObj.errorMessage = message;
    },
  },
});

export default merchantPackagePlanItemSlice.reducer;

// state
export const merchantPackagePlanItemSelector = (state) => state.merchantPackagePlanItem;
