import { Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Form from "../../../../components/forms/form.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import TableLoader from "../../../../components/tables/table-loader.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import {
  classCategorySelector,
  getCategoryList,
} from "../../../../services/category/class-category-slice.service";
import {
  fitnessClassSelector,
  getClasses,
  updateClass,
} from "../../../../services/merchant/fitness-class/class-slice.service";
import ClassEditModal from "../components/class-edit-modal.component";
import FilterOptions from "../components/filter-options.component";
import TableIndex from "../components/table-index.component";

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable().label("Search"),
  categoriesId: Yup.array().nullable().label("Categories"),
  businessesId: Yup.array().nullable().label("Businesses"),
  merchantsId: Yup.array().nullable().label("Merchants"),
  page: Yup.number().required(),
});

function ClassListScreen() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const { getClassesObj } = useSelector(fitnessClassSelector);
  const [payloadParams, setPayloadParams] = useState(null);
  const [sortCate, setSortCate] = useState({
    name: "idle",
    merchant: "idle",
    business: "idle",
    category: "idle",
  });
  const createSnackBar = useContext(SnackbarContext);
  const { getCategoryListObj } = useSelector(classCategorySelector);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updateItem, setUpdateItem] = useState({ classId: "", name: "", categoryId: "" });

  useEffect(() => {
    dispatch(getClasses({ page }));
    dispatch(getCategoryList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const getClassCategoriesSelection = () => {
    const itemList = [];
    getCategoryListObj.data.map((item) =>
      itemList.push({ type: item.label, label: item.label, value: item.id }),
    );
    return itemList;
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getClasses({ ...payloadParams, page: newPage }));
  };

  const updateClassDetail = (values) => {
    dispatch(updateClass(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        handlePageChange(null, page);
        setShowEditModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
    });
  };

  const processRecords = () => {
    const sortedData = { ...getClassesObj.data };

    if (sortCate.name === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.name < b.name) {
          return 1;
        }
        if (a.name > b.name) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.name === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.merchant === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.merchant.businessName < b.merchant.businessName) {
          return 1;
        }
        if (a.merchant.businessName > b.merchant.businessName) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.merchant === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.merchant.businessName > b.merchant.businessName) {
          return 1;
        }
        if (a.merchant.businessName < b.merchant.businessName) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.business === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.business.name < b.business.name) {
          return 1;
        }
        if (a.business.name > b.business.name) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.business === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.business.name > b.business.name) {
          return 1;
        }
        if (a.business.name < b.business.name) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.category === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.category.label < b.category.label) {
          return 1;
        }
        if (a.category.label > b.category.label) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.category === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.category.label > b.category.label) {
          return 1;
        }
        if (a.category.label < b.category.label) {
          return -1;
        }
        return 0;
      });
    }

    return sortedData;
  };

  const renderDataRecords = () => (
    <>
      {getClassesObj.data && getClassesObj.data.items && getClassesObj.data.items.length > 0 ? (
        <>
          <PaddedView>
            {getCategoryListObj.status === "succeeded" && (
              <ClassEditModal
                updateClassDetail={updateClassDetail}
                updateItem={updateItem}
                showModal={showEditModal}
                setShowModal={setShowEditModal}
                categoryList={getClassCategoriesSelection()}
              />
            )}

            <TableIndex
              setShowEditModal={setShowEditModal}
              setUpdateItem={setUpdateItem}
              page={page}
              handlePageChange={handlePageChange}
              records={processRecords()}
              sortCate={sortCate}
              setSortCate={setSortCate}
            />
          </PaddedView>
        </>
      ) : (
        <>
          <Typography>No Classes</Typography>
        </>
      )}
    </>
  );

  const onHandleSubmit = (values) => {
    const payload = {
      q: values.q,
      categoriesId: values.categoriesId.join(","),
      businessesId: values.businessesId.map((item) => item.id).join(","),
      merchantsId: values.merchantsId.map((item) => item.id).join(","),
      page,
    };
    setPage(0);
    setPayloadParams({
      q: values.q,
      categoriesId: values.categoriesId.join(","),
      businessesId: values.businessesId.map((item) => item.id).join(","),
      merchantsId: values.merchantsId.map((item) => item.id).join(","),
    });
    dispatch(getClasses(payload));
  };

  return (
    <>
      <Form
        initialValues={{ q: "", categoriesId: [], businessesId: [], merchantsId: [], page }}
        validationSchema={validationSchema}
        onSubmit={onHandleSubmit}
      >
        <FilterOptions getClassCategoriesSelection={getClassCategoriesSelection} />
      </Form>

      <Spacer size="l" />
      {getClassesObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </>
  );
}

export default ClassListScreen;
