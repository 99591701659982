import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, TableCell, TableRow, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function DisputeRow({ record, setShowModal, setRejectReason }) {
  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell>
        <Typography variant="body2">{record.id}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.employee}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ minWidth: "100px" }}>
          {record.createdAt}
        </Typography>
      </TableCell>
      <TableCell>
        <IconButton
          onClick={() => {
            setRejectReason(record.rejectReason);
            setShowModal(true);
          }}
        >
          <InfoOutlinedIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

DisputeRow.defaultProps = {
  record: {
    id: null,
    employee: null,
    rejectReason: "",
    createdAt: "",
  },
};

DisputeRow.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  setRejectReason: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    employee: PropTypes.string,
    rejectReason: PropTypes.string,
    createdAt: PropTypes.string,
  }),
};

export default DisputeRow;
