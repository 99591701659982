import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { FormLabel, Grid, IconButton } from "@mui/material";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import OutlinedSelectTextField from "../../../components/forms/outlined-select-text-field.component";
import PhoneNumberTextField from "../../../components/forms/phone-number-text-field.component";
import CustomizedSnackbars from "../../../components/notification/snackbar.component";
import PaddedView from "../../../components/utils/padded-view.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import {
  updateMerchantLeads,
  updateMerchantLeadsStatus,
} from "../../../services/lead/lead-slice.service";

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  phone: Yup.string()
    .required()
    .when({
      is: (value) => value?.length,
      then: (rule) => rule.min(10),
    })
    .label("Phone Number"),
  company: Yup.string().required().label("Company Name"),
  name: Yup.string().required().label("User Name"),
});

const validationSchemaStatus = Yup.object().shape({
  status: Yup.string().nullable().required().label("Status"),
});

function MerchantLeadsDetails({ record }) {
  const [allowEdit, setAllowEdit] = useState(false);
  const [allowEditStatus, setAllowEditStatus] = useState(false);
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const dispatch = useDispatch();

  const showErrorSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("error");
    setIsShowSnackBar(true);
  };

  const showSuccessSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("success");
    setIsShowSnackBar(true);
  };

  const onHandleSubmit = (values, { resetForm }) => {
    const { merchantId, name, phone, email, company } = values;
    if (values.status !== "rejected") {
      values.rejectReason = "";
    }
    dispatch(
      updateMerchantLeads({
        merchantId,
        name,
        phone,
        email,
        company,
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setAllowEdit(false);
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        resetForm();
        showErrorSnackBar(error.message);
      }
    });
  };

  const onHandleSubmitStatus = (values) => {
    const { merchantId, status } = values;
    dispatch(updateMerchantLeadsStatus({ merchantId, status })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setAllowEditStatus(false);
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  return (
    <>
      <PaddedView>
        <CustomizedSnackbars
          isShow={isShowSnackBar}
          setShowSnackbar={setIsShowSnackBar}
          message={snackBarMsg}
          type={snackBarType}
        />
        <Formik
          validationSchema={validationSchemaStatus}
          onSubmit={onHandleSubmitStatus}
          initialValues={{
            merchantId: record.id,
            status: record.status,
          }}
        >
          {({ handleSubmit, dirty }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        alignSelf: "flex-end",
                      }}
                    >
                      <FormLabel>Status</FormLabel>
                    </Grid>
                    <Grid item xs={2}>
                      {allowEditStatus ? (
                        <>
                          <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setAllowEditStatus(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAllowEditStatus(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <OutlinedSelectTextField
                    name="status"
                    label="Status"
                    selections={[
                      { type: "New", value: "new" },
                      { type: "Pending", value: "pending" },
                      { type: "Done", value: "done" },
                    ]}
                    disabled={!allowEditStatus}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Formik>

        <Formik
          validationSchema={validationSchema}
          onSubmit={onHandleSubmit}
          initialValues={{
            merchantId: record.id,
            name: record.name,
            phone: record.phone,
            email: record.email,
            company: record.company,
          }}
        >
          {({ handleSubmit, dirty }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        alignSelf: "flex-end",
                      }}
                    >
                      <FormLabel>Details</FormLabel>
                    </Grid>
                    <Grid item xs={2}>
                      {allowEdit ? (
                        <>
                          <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setAllowEdit(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAllowEdit(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        name="company"
                        placeholder="Enter Company Name"
                        label="Company Name"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        name="name"
                        placeholder="Enter User Name"
                        label="User Name"
                        disabled={!allowEdit}
                      />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                      <PhoneNumberTextField name="phone" disabled={!allowEdit} />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        name="email"
                        placeholder="Enter email"
                        label="Email"
                        disabled={!allowEdit}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </PaddedView>
    </>
  );
}

MerchantLeadsDetails.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.string,
    company: PropTypes.string,
  }).isRequired,
};

export default MerchantLeadsDetails;
