import { Box, Breadcrumbs, FormLabel, Grid, Link, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import SimpleBackdrop from "../../../components/utils/backdrop-loading.component";
import PaddedView from "../../../components/utils/padded-view.component";
import { createHighlight } from "../../../services/highlight/highlight-slice.service";
import { searchMerchantEventSession } from "../../../services/merchant/event/session/session.slice.service";
import EventListAutoComplete from "../components/event-list-autocomplete.component";
import SingleSelect from "../components/single-select.component";

const validationSchema = Yup.object().shape({
  eventSessionId: Yup.number().required().label("Event Session"),
});

function HighlightNewScreen() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [eventSelected, setEventSelected] = useState("");
  const navigate = useNavigate();
  const createSnackBar = useContext(SnackbarContext);
  let eventSessionOptions = [];

  const onHandleSubmit = (values, { resetForm }) => {
    setIsLoading(true);
    dispatch(createHighlight({ eventSessionId: values.eventSessionId })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "rejected") {
          createSnackBar({ type: "error", message: error.message });
          resetForm();
          setEventSelected("");
        }
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({ type: "success", message: payload.message });
          navigate("/highlight/list");
        }
      },
    );
  };

  useEffect(() => {
    dispatch(searchMerchantEventSession({ eventIds: eventSelected })).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        eventSessionOptions = payload.data.map((item) => ({
          label: `${item.startTime} - ${item.endTime} ${item.title ? `(${item.title})` : ""}`,
          value: item.id,
          id: item.id,
        }));
      }
    });
  }, [eventSelected]);

  return (
    <>
      <SimpleBackdrop isLoading={isLoading} />
      <Box>
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">New Highlight</Typography>
            </Grid>

            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link underline="hover" key="2" color="inherit" href="/highlight/list">
                    <Typography variant="body1">Highlight List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    New Highlight
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>

            <Formik
              validationSchema={validationSchema}
              initialValues={{
                selectedEvent: "",
                eventSessionId: "",
              }}
              onSubmit={onHandleSubmit}
            >
              {({ values }) => (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Grid item container sx={{ width: 200 }}>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            alignSelf: "center",
                          }}
                        >
                          <FormLabel>Details</FormLabel>
                        </Grid>
                      </Grid>

                      <Grid item container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <EventListAutoComplete
                            name="selectedEvent"
                            placeholder="Select an event"
                            disabled={false}
                            multiple={false}
                            setEventSelected={setEventSelected}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <SingleSelect
                            name="eventSessionId"
                            placeholder="Event Session"
                            disabled={values.selectedEvent === ""}
                            itemList={eventSessionOptions}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Grid container justifyContent="flex-end">
                            <Grid item sm={2} xs={12}>
                              <FormSubmitButton type="submit">
                                <Typography>Create</Typography>
                              </FormSubmitButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
        </PaddedView>
      </Box>
    </>
  );
}

export default HighlightNewScreen;
