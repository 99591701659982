import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postReq, setAxiosToken } from "../api.service";

export const login = createAsyncThunk("auth/login", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/login`,
    payload,
  );
  return response;
});

export const logout = createAsyncThunk("auth/logout", async () => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/employees/logout`);
  return response;
});

export const forgotPassword = createAsyncThunk("auth/forgot_password", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/forgot_password`,
    payload,
  );
  return response;
});

export const resetPassword = createAsyncThunk("auth/reset_password", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/reset_password`,
    payload,
  );
  return response;
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loginObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    logoutObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    registerObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    forgotPasswordObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    resetPasswordObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    resetUser: (state) => {
      state.user = null;
      state.loginObj.data = null;
      localStorage.removeItem("authToken");
      localStorage.removeItem("userDetail");
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loginObj.status = "pending";
    },
    [login.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.loginObj.status = "succeeded";
      state.loginObj.successMessage = message;
      state.loginObj.errorMessage = null;
      state.loginObj.data = data;

      state.user = data;
      localStorage.setItem("authToken", data.authToken);
      localStorage.setItem("userDetail", JSON.stringify(data.user));
      setAxiosToken(data.authToken);
    },
    [login.rejected]: (state, action) => {
      const { message } = action.error;

      state.loginObj.status = "failed";
      state.loginObj.successMessage = null;
      state.loginObj.errorMessage = message;
      state.loginObj.data = null;

      state.user = null;
      localStorage.removeItem("authToken");
      localStorage.removeItem("userDetail");
    },
    [logout.pending]: (state) => {
      state.logoutObj.status = "pending";
    },
    [logout.fulfilled]: (state, action) => {
      const { message } = action.payload;

      state.logoutObj.status = "succeeded";
      state.logoutObj.successMessage = message;

      state.user = null;
      state.loginObj.data = null;
      localStorage.removeItem("authToken");
      localStorage.removeItem("userDetail");
    },
    [logout.rejected]: (state, action) => {
      const { message } = action.error;

      state.logoutObj.status = "failed";
      state.logoutObj.errorMessage = message;
    },
    [forgotPassword.pending]: (state) => {
      state.forgotPasswordObj.status = "pending";
    },
    [forgotPassword.fulfilled]: (state, action) => {
      const { message } = action.payload;

      state.forgotPasswordObj.status = "succeeded";
      state.forgotPasswordObj.successMessage = message;

      state.user = null;
      state.forgotPasswordObj.data = null;
      localStorage.removeItem("authToken");
      localStorage.removeItem("userDetail");
    },
    [forgotPassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.forgotPasswordObj.status = "failed";
      state.forgotPasswordObj.errorMessage = message;
    },
    [resetPassword.pending]: (state) => {
      state.resetPasswordObj.status = "pending";
    },
    [resetPassword.fulfilled]: (state, action) => {
      const { message } = action.payload;

      state.resetPasswordObj.status = "succeeded";
      state.resetPasswordObj.successMessage = message;

      state.user = null;
      state.resetPasswordObj.data = null;
      localStorage.removeItem("authToken");
      localStorage.removeItem("userDetail");
    },
    [resetPassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.resetPasswordObj.status = "failed";
      state.resetPasswordObj.errorMessage = message;
    },
  },
});

export default authSlice.reducer;

// state
export const authSelector = (state) => state.auth;

export const { resetUser } = authSlice.actions;

// action
// export const {} = authSlice.actions;
