import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, Box, InputAdornment, Typography } from "@mui/material";
import Spacer from "../utils/spacer.component";

function FormFieldNumber({ name, placeholder, label, disabled }) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();

  const showError = touched[name] && typeof errors[name] === "string";

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleChange = (evt) => {
    const { value } = evt.target;
    if (value.match(/\./g)) {
      return;
    }
    setFieldValue(name, value);
  };

  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": {
            borderTopLeftRadius: theme.shape.borderRadius[1],
            borderTopRightRadius: theme.shape.borderRadius[1],
            borderBottomLeftRadius: theme.shape.borderRadius[1],
            borderBottomRightRadius: theme.shape.borderRadius[1],
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderTopLeftRadius: theme.shape.borderRadius[1],
              borderTopRightRadius: theme.shape.borderRadius[1],
              borderBottomLeftRadius: theme.shape.borderRadius[1],
              borderBottomRightRadius: theme.shape.borderRadius[1],
              backgroundColor: theme.palette.colors.bg.primary,
            },
          },
        }}
      >
        <TextField
          onKeyDown={keyPress}
          error={showError}
          variant="outlined"
          helperText={showError ? errors[name] : null}
          onBlur={() => setFieldTouched(name)}
          name={name}
          type="number"
          label={label}
          disabled={disabled}
          placeholder={placeholder}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
            endAdornment: name.includes("occurence") && (
              <InputAdornment position="start">
                <Spacer position="left" />
                <Typography>occurences</Typography>
              </InputAdornment>
            ),
          }}
          value={values[name]}
        />
      </Box>
    </>
  );
}

FormFieldNumber.defaultProps = {
  disabled: false,
};

FormFieldNumber.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default FormFieldNumber;
