import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, delReq, postReq } from "../api.service";

export const getHighlights = createAsyncThunk("highlights/index", async (payload) => {
  const { page } = payload;
  const response = getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/highlights?page=${page + 1}`,
  );
  return response;
});

export const createHighlight = createAsyncThunk("highlights/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/highlights`,
    payload,
  );
  return response;
});

export const deleteHighlight = createAsyncThunk(
  "highlights/delete/{highlight_id}",
  async (payload) => {
    const { highlightId } = payload;
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/highlights/${highlightId}`,
    );
    return response;
  },
);

const highlightSlice = createSlice({
  name: "highlight",
  initialState: {
    getHighlightsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createHighlightObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteHighlightObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getHighlights.pending]: (state) => {
      state.getHighlightsObj.status = "pending";
    },
    [getHighlights.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getHighlightsObj.status = "succeeded";
      state.getHighlightsObj.data = data;
      state.getHighlightsObj.successMessage = message;
    },
    [getHighlights.rejected]: (state, action) => {
      const { message } = action.error;

      state.getHighlightsObj.status = "failed";
      state.getHighlightsObj.errorMessage = message;
    },
    [createHighlight.pending]: (state) => {
      state.createHighlightObj.status = "pending";
    },
    [createHighlight.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createHighlightObj.status = "succeeded";
      state.createHighlightObj.data = data;
      state.createHighlightObj.successMessage = message;
    },
    [createHighlight.rejected]: (state, action) => {
      const { message } = action.error;

      state.createHighlightObj.status = "failed";
      state.createHighlightObj.errorMessage = message;
    },
    [deleteHighlight.pending]: (state) => {
      state.deleteHighlightObj.status = "pending";
    },
    [deleteHighlight.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteHighlightObj.status = "succeeded";
      state.deleteHighlightObj.data = data;
      state.deleteHighlightObj.successMessage = message;
    },
    [deleteHighlight.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteHighlightObj.status = "failed";
      state.deleteHighlightObj.errorMessage = message;
    },
  },
});

export default highlightSlice.reducer;

// state
export const highlightSelector = (state) => state.highlight;
