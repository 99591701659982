import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import FormFieldText from "../../../../components/forms/form-field-text.component";

export default function RejectedFormField({ disabled }) {
  const { values } = useFormikContext();
  return (
    values.status.toUpperCase().includes("REJECTED") && (
      <FormFieldText
        name="rejectReason"
        placeholder="Reject Reason"
        label="Reject Reason"
        disabled={disabled}
      />
    )
  );
}

RejectedFormField.defaultProps = {
  disabled: false,
};

RejectedFormField.propTypes = {
  disabled: PropTypes.bool,
};
