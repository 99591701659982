import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableRow,
  TableFooter,
  TablePagination,
  styled,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  useTheme,
  TableBody,
} from "@mui/material";
import { DebounceInput } from "react-debounce-input";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from "react-redux";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import TableHeader from "../../../components/tables/table-header.component";
import Spacer from "../../../components/utils/spacer.component";
import { notificationSelector } from "../../../services/notification/notification-slice.service";
import NotificationHistoryTableRowLoader from "../loader/notification-history-table-row-loader.component";
import NotificationHistoryRow from "./notification-history-row.component";
import TableSort from "../../../components/tables/table-sort.component";

const TableTopContainer = styled(Box)(({ theme }) => ({
  padding: "16px 16px 16px 16px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.colors.ui.tableBorder}`,
  alignItems: "center",
  "& .MuiButton-root": {
    padding: "8px 24px 8px 24px",
    borderTopLeftRadius: theme.shape.borderRadius[3],
    borderTopRightRadius: theme.shape.borderRadius[3],
    borderBottomLeftRadius: theme.shape.borderRadius[3],
    borderBottomRightRadius: theme.shape.borderRadius[3],
    backgroundColor: theme.palette.colors.brand.primary,
    textTransform: "none",
    ":hover": {
      backgroundColor: theme.palette.colors.brand.primary,
    },
  },
}));

function NotificationHistoryTableIndex({
  handlePageChange,
  page,
  searchKeyword,
  onChangeSearchKeyword,
  type,
}) {
  const theme = useTheme();
  const { getNotificationHistoryObj } = useSelector(notificationSelector);
  const columnMapping = {
    Name: "consumer.name",
    Header: "announcement.header",
    Consumer: "notifiableType",
    Slug: "popUp.slug",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const getTableName = () => {
    if (type === "announcement") {
      return "Announcement List";
    }
    if (type === "pop-up") {
      return "Pop Up List";
    }
    return null;
  };

  const getTableHeader = () => {
    if (type === "announcement") {
      return ["Name", "Header", "Consumer"];
    }
    if (type === "pop-up") {
      return ["Name", "Slug", "Consumer"];
    }
    return null;
  };

  const getSearchPlaceholder = () => {
    if (type === "announcement") {
      return "Search list by header";
    }
    if (type === "pop-up") {
      return "Search list by slug";
    }
    return null;
  };

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getNotificationHistoryObj.data && getNotificationHistoryObj.status === "succeeded") {
      records = { ...getNotificationHistoryObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableContent = () => {
    if (getNotificationHistoryObj.status === "succeeded") {
      const records = getProcessedRecord();

      return (
        <>
          <TableBody>
            {getNotificationHistoryObj.data.items &&
              records?.items.map((record) => (
                <NotificationHistoryRow record={record} key={record.id} />
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                page={page}
                rowsPerPage={getNotificationHistoryObj.data.pagination.perPage}
                count={getNotificationHistoryObj.data.pagination.totalItems}
                rowsPerPageOptions={[]}
                showFirstButton={true}
                showLastButton={true}
                onPageChange={handlePageChange}
              />
            </TableRow>
          </TableFooter>
        </>
      );
    }
    return <NotificationHistoryTableRowLoader />;
  };

  return (
    <TableWrapper>
      <TableTopContainer>
        <Typography variant="h6">{getTableName()}</Typography>
        <DebounceInput
          sx={{
            borderTopLeftRadius: theme.shape.borderRadius[1],
            borderTopRightRadius: theme.shape.borderRadius[1],
            borderBottomLeftRadius: theme.shape.borderRadius[1],
            borderBottomRightRadius: theme.shape.borderRadius[1],
            width: "400px",
            "& .MuiOutlinedInput-root": {
              height: "40px",
              borderTopLeftRadius: theme.shape.borderRadius[1],
              borderTopRightRadius: theme.shape.borderRadius[1],
              borderBottomLeftRadius: theme.shape.borderRadius[1],
              borderBottomRightRadius: theme.shape.borderRadius[1],
              backgroundColor: theme.palette.colors.bg.primary,
            },
          }}
          debounceTimeout={300}
          onChange={(e) => onChangeSearchKeyword(e.target.value)}
          element={TextField}
          value={searchKeyword}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
                <Spacer position="right" />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {searchKeyword && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onChangeSearchKeyword("")}>
                      <HighlightOffIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
          placeholder={getSearchPlaceholder()}
        />
      </TableTopContainer>

      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerTitle=""
          headerCells={getTableHeader()}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
          columnMapping={columnMapping}
        />
        {renderTableContent()}
      </Table>
    </TableWrapper>
  );
}

NotificationHistoryTableIndex.propTypes = {
  searchKeyword: PropTypes.string.isRequired,
  onChangeSearchKeyword: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default NotificationHistoryTableIndex;
