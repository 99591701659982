import { configureStore } from "@reduxjs/toolkit";
import accDeletionSlice from "./accDeletion/accDeletion-slice.service";
import accDeletionLogSliceService from "./accDeletion/log/accDeletion-log-slice.service";
import announcementSlice from "./announcement/announcement-slice.service";
import attachmentSlice from "./attachment/attachment-slice.service";
import authSlice from "./auth/auth-slice.service";
import healthCategorySlice from "./category/health-category-slice.service";
import classCategorySlice from "./category/class-category-slice.service";
import eventCategorySliceService from "./category/event-category-slice.service";
import wellnessCategorySlice from "./category/wellness-category-slice.service";
import dashboardSlice from "./dashboard/dashboard-slice.service";
import earningSliceService from "./earning/earning-slice.service";
import leadSliceService from "./lead/lead-slice.service";
import bannerMarketingSlice from "./marketing/banner/banner-slice.services";
import merchantBusinessAmenitySliceService from "./merchant/business/amenity/merchant-business-amenity-slice.service";
import merchantBusinessImageSliceService from "./merchant/business/image/merchant-business-image-slice.service";
import merchantBusinessSliceService from "./merchant/business/merchant-business-slice.service";
import merchantFitnessClassSliceService from "./merchant/fitness-class/class-slice.service";
import merchantFitnessClassSessionSliceService from "./merchant/fitness-class/sessions/session.slice.service";
import merchantHourlyBookingSlice from "./merchant/hourly-booking/hourly-booking-slice.service";
import merchantSliceService from "./merchant/merchant-slice.service";
import merchantPackageSliceService from "./merchant/package/package-slice.service";
import merchantAdditionalFeesSliceService from "./merchant/additional-fees/additional-fees-slice.service";
import merchantEventSliceService from "./merchant/event/event-slice.service";
import merchantEventSessionSliceService from "./merchant/event/session/session.slice.service";
import merchantPackagePlanDetailSliceService from "./merchant/package/plan/detail/plan-detail-slice.service";
import merchantPackagePlanSliceService from "./merchant/package/plan/plan-slice.service";
import merchantPackagePlanItemSliceService from "./merchant/package/plan/item/plan-item-slice.service";
import merchantStaffSliceService from "./merchant/staff/staff-slice.service";
import merchantSubscriptionPlanSliceService from "./merchant/subscription/plan/plan-slice.service";
import merchantSubscriptionSliceService from "./merchant/subscription/subscription-slice.service";
import notificationSlice from "./notification/notification-slice.service";
import popUpSlice from "./pop-up/pop-up-slice.service";
import promoCodeSliceServices from "./promo-code/promo-code-slice.services";
import roleSlice from "./role/role-slice.service";
import staffGymAccessSlice from "./staff/access/staff-gym-access-slice.service";
import staffImageSlice from "./staff/image/staff-image-slice.service";
import staffRoleSlice from "./staff/role/staff-role-slice.service";
import staffSlice from "./staff/staff-slice.service";
import statementSliceService from "./statement/statement-slice.service";
import userSlice from "./user/user-slice.service";
import creditSliceService from "./credit/credit-slice.service";
import highlightSliceService from "./highlight/highlight-slice.service";

const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    dashboard: dashboardSlice,
    staff: staffSlice,
    staffGymAccess: staffGymAccessSlice,
    staffImage: staffImageSlice,
    staffRole: staffRoleSlice,
    role: roleSlice,
    promoCode: promoCodeSliceServices,
    classCategory: classCategorySlice,
    wellnessCategory: wellnessCategorySlice,
    healthCategory: healthCategorySlice,
    eventCategory: eventCategorySliceService,
    bannerMarketing: bannerMarketingSlice,
    accDeletion: accDeletionSlice,
    accDeletionLog: accDeletionLogSliceService,
    announcement: announcementSlice,
    attachment: attachmentSlice,
    notification: notificationSlice,
    popUp: popUpSlice,
    merchant: merchantSliceService,
    merchantStaff: merchantStaffSliceService,
    merchantFitnessClass: merchantFitnessClassSliceService,
    merchantFitnessClassSession: merchantFitnessClassSessionSliceService,
    merchantPackage: merchantPackageSliceService,
    merchantPackagePlan: merchantPackagePlanSliceService,
    merchantPackagePlanDetail: merchantPackagePlanDetailSliceService,
    merchantPackagePlanItem: merchantPackagePlanItemSliceService,
    merchantSubscription: merchantSubscriptionSliceService,
    merchantSubscriptionPlan: merchantSubscriptionPlanSliceService,
    merchantBusiness: merchantBusinessSliceService,
    merchantBusinessImage: merchantBusinessImageSliceService,
    merchantBusinessAmenity: merchantBusinessAmenitySliceService,
    merchantHourlyBooking: merchantHourlyBookingSlice,
    merchantAdditionalFees: merchantAdditionalFeesSliceService,
    merchantEvent: merchantEventSliceService,
    merchantEventSession: merchantEventSessionSliceService,
    statement: statementSliceService,
    earning: earningSliceService,
    lead: leadSliceService,
    credit: creditSliceService,
    highlight: highlightSliceService,
  },
});

export default store;
