import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../../api.service";

export const createBanner = createAsyncThunk("banner/create_banner", async (payload) => {
  const response = postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/banners`,
    payload,
  );
  return response;
});

export const checkBannerName = createAsyncThunk("banner/check_banner_name", async (payload) => {
  const response = postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/banners/check_available_slug_name`,
    payload,
  );
  return response;
});

export const getBannerList = createAsyncThunk("banner/banner_list", async () => {
  const response = getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/banners?slug=`,
  );
  return response;
});

export const deleteBanner = createAsyncThunk("banner/delete_banner", async (payload) => {
  const response = delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/banners/${payload}`,
  );
  return response;
});

export const updateBanner = createAsyncThunk("banner/update_banner", async (payload) => {
  const response = putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/banners/${payload.bannerId}`,
    payload,
  );
  return response;
});

export const updateBannerImage = createAsyncThunk("banner/update_banner_image", async (payload) => {
  const response = putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/banners/${payload.bannerId}/image`,
    payload,
  );
  return response;
});

export const updateBannerOrder = createAsyncThunk("banner/update_banner_order", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/marketings/banners/${payload.itemId}/order_by?type=${payload.orderType}`,
    payload,
  );
  return response;
});

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    createBannerObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    checkBannerNameObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getBannerListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteBannerObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateBannerObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateBannerImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateBannerOrderObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [createBanner.pending]: (state) => {
      state.createBannerObj.status = "pending";
    },
    [createBanner.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createBannerObj.status = "succeeded";
      state.createBannerObj.data = data;
      state.createBannerObj.successMessage = message;
    },
    [createBanner.rejected]: (state, action) => {
      const { message } = action.error;

      state.createBannerObj.status = "failed";
      state.createBannerObj.errorMessage = message;
    },
    [checkBannerName.pending]: (state) => {
      state.checkBannerNameObj.status = "pending";
    },
    [checkBannerName.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.checkBannerNameObj.status = "succeeded";
      state.checkBannerNameObj.data = data;
      state.checkBannerNameObj.successMessage = message;
    },
    [checkBannerName.rejected]: (state, action) => {
      const { message } = action.error;

      state.checkBannerNameObj.status = "failed";
      state.checkBannerNameObj.errorMessage = message;
    },
    [getBannerList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBannerListObj.status = "succeeded";
      state.getBannerListObj.data = data;
      state.getBannerListObj.successMessage = message;
    },
    [getBannerList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBannerListObj.status = "failed";
      state.getBannerListObj.errorMessage = message;
    },
    [deleteBanner.pending]: (state) => {
      state.deleteBannerObj.status = "pending";
    },
    [deleteBanner.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteBannerObj.status = "succeeded";
      state.deleteBannerObj.data = data;
      state.deleteBannerObj.successMessage = message;
    },
    [deleteBanner.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteBannerObj.status = "failed";
      state.deleteBannerObj.errorMessage = message;
    },
    [updateBanner.pending]: (state) => {
      state.updateBannerObj.status = "pending";
    },
    [updateBanner.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateBannerObj.status = "succeeded";
      state.updateBannerObj.data = data;
      state.updateBannerObj.successMessage = message;
    },
    [updateBanner.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateBannerObj.status = "failed";
      state.updateBannerObj.errorMessage = message;
    },
    [updateBannerImage.pending]: (state) => {
      state.updateBannerImageObj.status = "pending";
    },
    [updateBannerImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateBannerImageObj.status = "succeeded";
      state.updateBannerImageObj.data = data;
      state.updateBannerImageObj.successMessage = message;
    },
    [updateBannerImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateBannerImageObj.status = "failed";
      state.updateBannerImageObj.errorMessage = message;
    },
    [updateBannerOrder.pending]: (state) => {
      state.updateBannerOrderObj.status = "pending";
    },
    [updateBannerOrder.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateBannerOrderObj.status = "succeeded";
      state.updateBannerOrderObj.data = data;
      state.updateBannerOrderObj.successMessage = message;
    },
    [updateBannerOrder.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateBannerOrderObj.status = "failed";
      state.updateBannerOrderObj.errorMessage = message;
    },
  },
});

export default bannerSlice.reducer;

// state
export const bannerSelector = (state) => state.bannerMarketing;

// action
// export const {} = bannerSlice.actions;
