import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { FormLabel, Grid, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import PaddedView from "../../../../components/utils/padded-view.component";
import FormCheckBoxes from "../../../../components/forms/form-checkboxes.component";
import { updateStaffPermission } from "../../../../services/staff/role/staff-role-slice.service";

const validationSchema = Yup.object().shape({
  staffId: Yup.number().isRequired,
  roles: Yup.array().nullable(),
});

function Permission({ options, staffId, record }) {
  const [allowEdit, setAllowEdit] = useState(false);
  const dispatch = useDispatch();

  const onHandleSubmit = (values) => {
    setAllowEdit(false);
    dispatch(updateStaffPermission(values));
  };

  return (
    <>
      <PaddedView>
        <Formik
          initialValues={{
            permissions: record,
            staffId,
          }}
          validationSchema={validationSchema}
          onSubmit={onHandleSubmit}
        >
          {({ handleSubmit }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        alignSelf: "flex-end",
                      }}
                    >
                      <FormLabel>Permission</FormLabel>
                    </Grid>
                    <Grid item xs={2}>
                      {allowEdit ? (
                        <>
                          <IconButton size="small" onClick={handleSubmit}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setAllowEdit(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAllowEdit(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FormCheckBoxes
                    options={options.map((option) => ({ id: option.name, name: option.name }))}
                    name="permissions"
                    disabled={!allowEdit}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </PaddedView>
    </>
  );
}

Permission.propTypes = {
  staffId: PropTypes.number.isRequired,
  record: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Permission;
