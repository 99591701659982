import React from "react";
import Routes from "./infrastructure/routes";

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
