import { FormHelperText, Popper, styled, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useDispatch, useSelector } from "react-redux";
import {
  getClassAutoComplete,
  fitnessClassSelector,
} from "../../../services/merchant/fitness-class/class-slice.service";

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[1],
  borderTopRightRadius: theme.shape.borderRadius[1],
  borderBottomLeftRadius: theme.shape.borderRadius[1],
  borderBottomRightRadius: theme.shape.borderRadius[1],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[1],
    borderTopRightRadius: theme.shape.borderRadius[1],
    borderBottomLeftRadius: theme.shape.borderRadius[1],
    borderBottomRightRadius: theme.shape.borderRadius[1],
    backgroundColor: theme.palette.colors.text.white,
  },
}));

function CustomPopper(props) {
  return <Popper {...props} placement="bottom" />;
}

export default function ClassListAutoComplete({ name, placeholder, disabled, isSpecific, label }) {
  const dispatch = useDispatch();
  const { getClassAutoCompleteObj } = useSelector(fitnessClassSelector);
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loadingMoreResults, setLoadingMoreResults] = useState(false);
  const [merchantsId, setMerchantsId] = useState(values.merchantsList.map((item) => item.id));
  const [businessesId, setBusinessesId] = useState(values.businessesList.map((item) => item.id));

  const loadMoreResults = () => {
    let newPage = page;

    if (newPage < totalPage) {
      setLoadingMoreResults(true);
      const newOptions = options;
      setOptions([...newOptions, { id: "loading", option: "loading" }]);
      newPage += 1;
      setPage(newPage);
      dispatch(
        getClassAutoComplete({
          merchantsId,
          businessesId,
          q: keyword,
          page: newPage,
        }),
      ).then(({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setLoadingMoreResults(false);
          setOptions([...newOptions, ...payload.data.items]);
        }
      });
    }
  };

  const onSearchKeywordChange = (q) => {
    setPage(1);
    setOptions([]);

    dispatch(getClassAutoComplete({ merchantsId, businessesId, q, page: 1 })).then(
      ({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setTotalPage(payload.data.pagination.totalPages);
          setOptions(payload.data.items);
        }
      },
    );
  };

  const listOnChange = (event, value) => {
    setFieldValue(name, value);
    onSearchKeywordChange("");
  };

  const getLoadingText = () => {
    if (getClassAutoCompleteObj.status === "succeeded") {
      return "No options";
    }

    return "Loading...";
  };

  useEffect(() => {
    onSearchKeywordChange("");
  }, []);

  useEffect(() => {
    const merchantsArr = values.merchantsList.map((item) => item.id);
    const businessesArr = values.businessesList.map((item) => item.id);
    if (merchantsArr.length > 0) {
      setFieldValue(
        name,
        values[name].filter((item) => merchantsArr.includes(item.merchantId)),
      );
    }

    if (businessesArr.length > 0) {
      setFieldValue(
        name,
        values[name].filter((item) => businessesArr.includes(item.businessId)),
      );
    }

    setMerchantsId(merchantsArr);
    setBusinessesId(businessesArr);
  }, [values.merchantsList, values.businessesList]);

  useEffect(() => {
    onSearchKeywordChange(keyword);
  }, [merchantsId, businessesId]);

  return (
    <>
      <Autocomplete
        filterOptions={(x) => x}
        loading={options.length === 0}
        loadingText={getLoadingText()}
        ListboxProps={{
          role: "list-box",
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            if (
              !loadingMoreResults &&
              listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight
            ) {
              loadMoreResults();
            }
          },
        }}
        PopperComponent={CustomPopper}
        disabled={disabled || !isSpecific}
        multiple
        freeSolo
        onChange={listOnChange}
        options={options}
        getOptionDisabled={(option) => option.option === "loading"}
        filterSelectedOptions
        value={isSpecific ? values[name] : []}
        disableCloseOnSelect
        getOptionLabel={(option) => option.option}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <DebounceInput
            {...params}
            debounceTimeout={500}
            error={showError}
            onBlur={() => setFieldTouched(name)}
            element={StyledTextField}
            placeholder={placeholder}
            label={label}
            variant="outlined"
            onChange={(e) => {
              setKeyword(e.target.value);
              onSearchKeywordChange(e.target.value);
            }}
          />
        )}
      />
      {showError && (
        <FormHelperText error sx={{ margin: "3px 14px 0px" }}>
          {errors[name]}
        </FormHelperText>
      )}
    </>
  );
}

ClassListAutoComplete.defaultProps = {
  disabled: false,
};

ClassListAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  isSpecific: PropTypes.bool.isRequired,
};
