import {
  Box,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TableHeader from "../../../components/tables/table-header.component";
import TableWrapper from "../../../components/tables/table-wrapper.component";

const ColumnSpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
});

const ButtonFlexEndBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "10px",
});

function AttachmentScreenLoader() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>Upload a new attachment</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Skeleton width="100%" height="180px" />
              </Grid>
              <Grid item xs={9}>
                <ColumnSpaceBetweenBox>
                  <Skeleton width="100%" height="55px" />
                  <ButtonFlexEndBox>
                    <Skeleton width="125px" borderRadius="30px" height="45px" />
                  </ButtonFlexEndBox>
                </ColumnSpaceBetweenBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableWrapper>
          <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
            <TableHeader
              headerTitle="Users Lists"
              headerCells={["ID", "Image", "Title", "Action"]}
            />

            <TableBody>
              <TableRow>
                <TableCell>
                  <Skeleton width="60%" />
                </TableCell>
                <TableCell>
                  <Skeleton width="60%" />
                </TableCell>
                <TableCell>
                  <Skeleton width="60%" />
                </TableCell>
                <TableCell width="150px">
                  <Grid container spacing={1}>
                    <Grid item>
                      <Skeleton height={20} width={20} circle={true} />
                    </Grid>
                    <Grid item>
                      <Skeleton height={20} width={20} circle={true} />
                    </Grid>
                    <Grid item>
                      <Skeleton height={20} width={20} circle={true} />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableWrapper>
      </Grid>
    </Grid>
  );
}

export default AttachmentScreenLoader;
