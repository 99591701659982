import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../../api.service";

export const getMerchantHourlyBookings = createAsyncThunk(
  "merchants/hourly_bookings/index",
  async (payload) => {
    const { page, q, businessesId, startAt, endAt, statuses } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/hourly_bookings?${
        page ? "page=".concat(page + 1) : "page=1"
      }${q ? "&q=".concat(q) : ""}${businessesId ? "&businessesId=".concat(businessesId) : ""}${
        startAt ? "&startAt=".concat(startAt) : ""
      }${endAt ? "&endAt=".concat(endAt) : ""}${statuses ? "&statuses=".concat(statuses) : ""}`,
    );
    return response;
  },
);

const merchantHourlyBookingSlice = createSlice({
  name: "merchantHourlyBooking",
  initialState: {
    getMerchantHourlyBookingsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getMerchantHourlyBookings.pending]: (state) => {
      state.getMerchantHourlyBookingsObj.status = "pending";
    },
    [getMerchantHourlyBookings.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantHourlyBookingsObj.status = "succeeded";
      state.getMerchantHourlyBookingsObj.data = data;
      state.getMerchantHourlyBookingsObj.successMessage = message;
    },
    [getMerchantHourlyBookings.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantHourlyBookingsObj.status = "failed";
      state.getMerchantHourlyBookingsObj.errorMessage = message;
    },
  },
});

export default merchantHourlyBookingSlice.reducer;

// state
export const merchantHourlyBookingSelector = (state) => state.merchantHourlyBooking;
