import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
  useTheme,
  Link,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Spacer from "../../../../components/utils/spacer.component";
import TableHeader from "../../../../components/tables/table-header.component";

const TableTopContainer = styled(Box)(({ theme }) => ({
  padding: "6px 16px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.colors.ui.tableBorder}`,
  alignItems: "flex-end",
}));

const TABLE_CELL_WIDTH = 150;
function Row({ record }) {
  const theme = useTheme();
  const [collapse, setCollapse] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Box sx={{ display: "flex" }}>
            <Avatar
              alt={record.user.name}
              src={record.user.imagePath}
              sx={{ width: 100, height: 100 }}
            />
            <Box sx={{ width: 50, height: 50 }} />
          </Box>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography
            variant="body2"
            noWrap={true}
            sx={{ width: TABLE_CELL_WIDTH, textTransform: "capitalize" }}
          >
            {record.user.name}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.user.phone}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH * 1.6 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={record.plan.imagePath}
              alt="plan"
              style={{ width: 60, borderRadius: theme.shape.borderRadius[1] }}
            />
            <Spacer position="right" />
            <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH * 1.6 }}>
              {record.plan.title}
            </Typography>
          </Box>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.sessions.claimedCount}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.sessions.noOfSessions}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography
            variant="body2"
            noWrap={true}
            sx={{ width: TABLE_CELL_WIDTH, textTransform: "capitalize" }}
          >
            {record.status}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
            {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <TableTopContainer>
              <Typography sx={{ fontWeight: "bold" }}>Merchant Business Name</Typography>
            </TableTopContainer>
            <List sx={{ listStyleType: "disc", pl: 4 }}>
              <Link color="inherit" href={`/merchants/edit?merchantId=${record.merchant.id}`}>
                <Typography sx={{ textTransform: "capitalize", fontWeight: "bold" }}>
                  {record.merchant.businessName}
                </Typography>
              </Link>
            </List>
            <Spacer size="m" />
            <TableTopContainer>
              <Typography sx={{ fontWeight: "bold" }}>Businesses</Typography>
            </TableTopContainer>
            {record.businesses.length > 0 &&
              record.businesses.map((business) => (
                <List sx={{ listStyleType: "disc", pl: 4 }} key={business.id}>
                  <ListItem sx={{ display: "list-item" }} disablePadding>
                    {business.name}
                  </ListItem>
                  <ListItem disablePadding>{`- ${business.location}`}</ListItem>
                </List>
              ))}

            <Spacer size="m" />
            <TableTopContainer>
              <Typography sx={{ fontWeight: "bold" }}>Items</Typography>
            </TableTopContainer>
            {record.items.length > 0 &&
              record.items.map((item) => (
                <List sx={{ listStyleType: "disc", pl: 4 }} key={item.id}>
                  <ListItem sx={{ display: "list-item" }} disablePadding>
                    {item.name}
                  </ListItem>
                </List>
              ))}
            <Spacer size="m" />
            <TableTopContainer>
              <Typography sx={{ fontWeight: "bold" }}>Category</Typography>
            </TableTopContainer>
            <List sx={{ listStyleType: "disc", pl: 4 }}>
              <Typography sx={{ textTransform: "capitalize", fontWeight: "bold" }}>
                {record.category.type}
              </Typography>
            </List>

            {record.category.items.length > 0 &&
              record.category.items.map((categories) => (
                <List sx={{ listStyleType: "disc", pl: 4 }} key={categories.id}>
                  <ListItem sx={{ display: "flex", alignItems: "center" }} disablePadding>
                    <img
                      src={categories.imagePath}
                      alt="categories"
                      style={{ width: 50, borderRadius: theme.shape.borderRadius[1] }}
                    />
                    <Spacer position="left" />
                    {categories.label}
                  </ListItem>
                </List>
              ))}
            <Spacer size="m" />
            <TableTopContainer>
              <Typography sx={{ fontWeight: "bold" }}>History</Typography>
            </TableTopContainer>
            <Table size="small">
              <TableHeader
                headerCells={[
                  "Redeem by",
                  "Date",
                  "Duration (Minutes)",
                  "Start Time",
                  "Class",
                  "Category",
                ]}
                nonSortableColumns={[
                  "Redeem by",
                  "Date",
                  "Duration (Minutes)",
                  "Start Time",
                  "Class",
                  "Category",
                ]}
              />
              <TableBody>
                {record.sessions.histories.length > 0 ? (
                  record.sessions.histories.map((item) => (
                    <TableRow key={item.session.id}>
                      <TableCell width="200px">
                        <Typography
                          variant="body2"
                          noWrap={true}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {`${item.redeemBy.firstName} ${item.redeemBy.lastName}`}
                        </Typography>
                      </TableCell>
                      <TableCell width="200px">
                        <Typography variant="body2" noWrap>
                          {item.session.date}
                        </Typography>
                      </TableCell>
                      <TableCell width="200px">
                        <Typography variant="body2">{item.session.durationMins}</Typography>
                      </TableCell>
                      <TableCell width="200px">
                        <Typography variant="body2">{item.session.startTime}</Typography>
                      </TableCell>
                      <TableCell width="200px">
                        <Typography variant="body2">{item.session.class.name}</Typography>
                      </TableCell>
                      <TableCell width="200px">
                        <Typography variant="body2">{item.session.class.category.label}</Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell width="200px">-</TableCell>
                    <TableCell width="200px">-</TableCell>
                    <TableCell width="200px">-</TableCell>
                    <TableCell width="200px">-</TableCell>
                    <TableCell width="200px">-</TableCell>
                    <TableCell width="200px">-</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Spacer size="xl" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    status: PropTypes.oneOf(["ongoing", "completed"]),
    merchant: PropTypes.shape({
      id: PropTypes.number.isRequired,
      businessName: PropTypes.string.isRequired,
    }),
    plan: PropTypes.shape({
      title: PropTypes.string.isRequired,
      imagePath: PropTypes.string.isRequired,
      details: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    sessions: PropTypes.shape({
      claimedCount: PropTypes.number.isRequired,
      noOfSessions: PropTypes.number.isRequired,
      histories: PropTypes.arrayOf(
        PropTypes.shape({
          redeemBy: PropTypes.shape({
            self: PropTypes.bool,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
          }),
          session: PropTypes.shape({
            id: PropTypes.number,
            date: PropTypes.string,
            durationMins: PropTypes.number,
            startTime: PropTypes.string,
            class: PropTypes.shape({
              name: PropTypes.string,
              category: PropTypes.shape({
                label: PropTypes.string,
              }),
            }),
          }).isRequired,
        }),
      ).isRequired,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        name: PropTypes.string,
        status: PropTypes.string,
        start_at: PropTypes.string,
        end_at: PropTypes.string,
      }),
    ).isRequired,
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        location: PropTypes.string,
      }),
    ).isRequired,
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      phone: PropTypes.string,
      imagePath: PropTypes.string,
    }).isRequired,
    category: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
          imagePath: PropTypes.string,
        }),
      ),
    }).isRequired,
  }).isRequired,
};

export default Row;
