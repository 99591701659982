import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { putReq } from "../../api.service";

export const updateStaffPermission = createAsyncThunk(
  "staffs/{staff_id}/permissions",
  async (payload) => {
    const { staffId, permissions } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/staffs/${staffId}/permissions`,
      { permissions },
    );
    return response;
  },
);

const staffRole = createSlice({
  name: "staffRole",
  initialState: {
    updateStaffPermissionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [updateStaffPermission.pending]: (state) => {
      state.updateStaffPermissionObj.status = "pending";
    },
    [updateStaffPermission.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.updateStaffPermissionObj.status = "succeeded";
      state.updateStaffPermissionObj.data = data;
      state.updateStaffPermissionObj.successMessage = message;
    },
    [updateStaffPermission.rejected]: (state, action) => {
      const { message } = action.error;
      state.updateStaffPermissionObj.status = "failed";
      state.updateStaffPermissionObj.errorMessage = message;
    },
  },
});

export default staffRole.reducer;

// state
export const staffRoleSelector = (state) => state.staffRole;

// action
// export const {  } = staffRole.actions;
