import React from "react";
import PropTypes from "prop-types";
import { Box, Checkbox, FormControlLabel, Grid, Typography, useTheme } from "@mui/material";

function NotificationHistoryConsumerFilter({ onChangeConsumerType, consumer }) {
  const theme = useTheme();

  const FilterList = [
    { label: "All", value: "all" },
    { label: "User", value: "user" },
    { label: "Personal Trainer", value: "trainer" },
  ];

  const handleCheckbox = (data) => {
    const isChecked = consumer.some((checkedCheckbox) => checkedCheckbox.value === data.value);
    if (isChecked) {
      if (consumer.filter((checkedCheckbox) => checkedCheckbox.value !== data.value).length === 0) {
        return;
      }
      onChangeConsumerType(
        consumer.filter((checkedCheckbox) => checkedCheckbox.value !== data.value),
      );
    } else if (data.value === "all") {
      onChangeConsumerType([data]);
    } else {
      const currentArr = consumer;
      const tempCheckedValue = currentArr.concat(data).filter((item) => item.value !== "all");
      onChangeConsumerType(tempCheckedValue);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Grid container spacing={3}>
        {FilterList.map((item) => (
          <Grid item key={item.value}>
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  sx={{
                    "&.Mui-checked": {
                      color: theme.palette.colors.brand.primary,
                    },
                    ":hover": { backgroundColor: "transparent" },
                  }}
                  onChange={() => handleCheckbox(item)}
                  checked={consumer.some((checkedCheckbox) => checkedCheckbox.value === item.value)}
                />
              }
              label={<Typography>{item.label}</Typography>}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

NotificationHistoryConsumerFilter.propTypes = {
  consumer: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChangeConsumerType: PropTypes.func.isRequired,
};

export default NotificationHistoryConsumerFilter;
