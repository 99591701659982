import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import React from "react";
import BorderCtaButton from "../../../../../components/button/border-cta-button.component";
import FormMultiSelect from "../../../../../components/forms/form-multi-select.component";
import FormSubmitButton from "../../../../../components/forms/form-submit-button.component";
import PaddedView from "../../../../../components/utils/padded-view.component";
import ClassListAutoComplete from "./class-list-autocomplete.component";

function FilterOptions() {
  const theme = useTheme();
  const { resetForm, handleSubmit } = useFormikContext();

  const handleClearForm = () => {
    resetForm();
    handleSubmit();
  };

  return (
    <>
      <PaddedView>
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.colors.bg.secondary,
            borderRadius: `${theme.shape.borderRadius[1]}px`,
          }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <PaddedView>
                      <ClassListAutoComplete
                        name="classesId"
                        placeholder="Classes"
                        multiple={true}
                      />
                    </PaddedView>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PaddedView>
                      <FormMultiSelect
                        name="statuses"
                        label="Statuses"
                        options={[
                          { label: "Draft", value: "draft" },
                          { label: "Publish", value: "publish" },
                          { label: "Cancelled", value: "cancelled" },
                        ]}
                      />
                    </PaddedView>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <FormSubmitButton type="submit">
                    <Typography>Search</Typography>
                  </FormSubmitButton>
                </PaddedView>
              </Grid>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <BorderCtaButton onClickButton={handleClearForm}>
                    <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                      Clear
                    </Typography>
                  </BorderCtaButton>
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
    </>
  );
}

export default FilterOptions;
