/* eslint-disable no-nested-ternary */
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Box, TableCell, TableHead, TableRow, Typography, styled } from "@mui/material";

const TableCellContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
});

function TableHeader({
  headerCells,
  headerTitle,
  sortColumn,
  sortOrder,
  onSortChange,
  columnMapping,
  nonSortableColumns,
}) {
  const isSortableColumn = (headCell) => !nonSortableColumns.includes(headCell);
  const renderSortIndicator = (column) => (
    <ArrowDropDownIcon
      style={{
        transform:
          sortColumn === columnMapping[column]
            ? sortOrder === "asc"
              ? "rotate(0deg)"
              : "rotate(180deg)"
            : "none",
      }}
    />
  );

  return (
    <TableHead>
      {headerTitle !== "" && (
        <TableRow>
          {headerCells.map((headCell, index) => (
            <TableCell key={uuidv4()}>
              <>{index === 0 && <Typography variant="h6">{headerTitle}</Typography>}</>
            </TableCell>
          ))}
        </TableRow>
      )}

      <TableRow>
        {headerCells &&
          headerCells.length > 0 &&
          headerCells.map((headCell) => (
            <TableCell
              key={headCell}
              sx={{
                textAlign: "center",
                cursor: headCell !== "Action" && isSortableColumn(headCell) && "pointer",
              }}
              onClick={() => {
                if (headCell !== "Action" && isSortableColumn(headCell)) {
                  onSortChange(headCell);
                }
              }}
            >
              <TableCellContainer>
                {headCell}
                {headCell !== "Action" &&
                  isSortableColumn(headCell) &&
                  renderSortIndicator(headCell)}
              </TableCellContainer>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

TableHeader.defaultProps = {
  headerTitle: "",
  sortColumn: null,
  sortOrder: "desc",
  onSortChange: () => {},
  columnMapping: {},
  nonSortableColumns: [],
};

TableHeader.propTypes = {
  headerCells: PropTypes.arrayOf(PropTypes.string).isRequired,
  headerTitle: PropTypes.string,
  sortColumn: PropTypes.string,
  sortOrder: PropTypes.oneOf(["asc", "desc"]),
  onSortChange: PropTypes.func,
  columnMapping: PropTypes.shape({}),
  nonSortableColumns: PropTypes.arrayOf(PropTypes.string),
};

export default TableHeader;
