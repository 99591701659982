import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography, Grid, styled, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const StyledButtonIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  padding: 0,
  top: 5,
  right: 5,
  backgroundColor: theme.palette.colors.brand.primary,
  width: 25,
  height: 25,
}));

const StyledCloseIcon = styled(CloseIcon)((props) => ({
  width: 17,
  height: 17,
  stroke: props.theme.palette.colors.brand.white,
  strokeWidth: 3,
}));

function FormImageUpload({ name, disabled, imageWidth, imageHeight }) {
  const theme = useTheme();
  const { setFieldValue, errors, setFieldError, setFieldTouched, touched, values } =
    useFormikContext();
  const [filePreview, setFilePreview] = useState(values[name]);
  const showError = disabled && touched[name] && typeof errors[name] === "string";

  const handleRemoveImage = () => {
    setFieldValue(name, "");
    setFieldError(name, "");
    setFilePreview("");
  };

  return (
    <>
      <Grid container flexDirection="column">
        {filePreview ? (
          <>
            <Grid item xs={12}>
              <Box
                sx={{
                  position: "relative",
                  width: 150,
                  "& .MuiIconButton-root": {
                    "&:hover": {
                      backgroundColor: theme.palette.colors.brand.primary,
                    },
                  },
                }}
              >
                <img
                  src={filePreview}
                  alt="subscription plan"
                  style={{
                    width: imageWidth,
                    height: typeof imageHeight === "string" ? "auto" : imageHeight,
                    borderRadius: theme.shape.borderRadius[1],
                    backgroundSize: "100% 100%",
                  }}
                />
                {!disabled && (
                  <StyledButtonIcon onClick={handleRemoveImage}>
                    <StyledCloseIcon />
                  </StyledButtonIcon>
                )}
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  setFieldError(name, "");
                }}
                onBlur={() => setFieldTouched(name)}
                disabled={disabled}
                component="label"
                sx={{
                  backgroundColor: "transparent",
                  borderTopLeftRadius: theme.shape.borderRadius[1],
                  borderTopRightRadius: theme.shape.borderRadius[1],
                  borderBottomLeftRadius: theme.shape.borderRadius[1],
                  borderBottomRightRadius: theme.shape.borderRadius[1],
                  border: `2px dashed ${theme.palette.colors.ui.disabled}`,
                  "&:hover": {
                    background: "transparent",
                  },
                }}
              >
                <Box
                  sx={{
                    height: imageWidth,
                    width: imageWidth,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: theme.palette.colors.ui.disabled,
                  }}
                >
                  <AddCircleOutlineRoundedIcon />
                </Box>
                <input
                  type="file"
                  name={name}
                  hidden
                  accept="image/*"
                  disabled={disabled}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  onBlur={() => setFieldTouched(name)}
                  onChange={(e) => {
                    const { files } = e.target;
                    if (files) {
                      const myFile = files[0]; // single file upload only
                      const withinFileSizeLimit = myFile && myFile.size <= 5000000; // 1mb = 1^6 bytes
                      const allowedFileType = myFile && SUPPORTED_FORMATS.includes(myFile.type);

                      if (myFile && withinFileSizeLimit && allowedFileType) {
                        const fileReader = new FileReader(); // to preview the image
                        fileReader.onload = () => {
                          if (fileReader.readyState === 2) {
                            setFieldValue(name, fileReader.result);
                            setFilePreview(fileReader.result);
                          }
                        };
                        fileReader.readAsDataURL(myFile);
                      } else {
                        if (!withinFileSizeLimit) {
                          setFieldError("image", "Image file cannot be more than 5mb");
                        } else if (!allowedFileType) {
                          setFieldError("image", `Only ${SUPPORTED_FORMATS} are allowed`);
                        }

                        setFilePreview("");
                      }
                    }
                  }}
                />
              </Button>
            </Grid>
          </>
        )}
        <Grid item>
          {showError && errors[name] && (
            <Typography sx={{ color: theme.palette.darkColors.text.error }}>
              {errors[name]}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
}

FormImageUpload.defaultProps = {
  disabled: false,
  imageHeight: "auto",
};

FormImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  imageWidth: PropTypes.number.isRequired,
  imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FormImageUpload;
