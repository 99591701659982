import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableRow,
  TableFooter,
  TablePagination,
  Typography,
  styled,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import TableWrapper from "../../../../components/tables/table-wrapper.component";
import TableHeader from "../../../../components/tables/table-header.component";
import MerchantBusinessRow from "./merchant-business-row.component";
import { merchantBusinessSelector } from "../../../../services/merchant/business/merchant-business-slice.service";
import TableSort from "../../../../components/tables/table-sort.component";

const TableTopContainer = styled(Box)(({ theme }) => ({
  padding: "16px 16px 16px 16px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.colors.ui.tableBorder}`,
  alignItems: "center",
}));

function MerchantBusinessListTableIndex({ handlePageChange, page, onToggleDelete, records }) {
  const { searchMerchantBusinessObj } = useSelector(merchantBusinessSelector);
  const columnMapping = {
    "Businesses Name": "name",
    "Merchant Name": "merchant.companyName",
    Location: "city",
    "Phone Number": "contactNo",
    Status: "status",
    "Created At": "createdAt",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);
  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let sortedRecords = null;
    sortedRecords = { ...records };

    const sortedData = [...sortedRecords.items].sort((a, b) => {
      if (sortColumn) {
        const columnA = parseValue(getColumnValue(a, sortColumn));
        const columnB = parseValue(getColumnValue(b, sortColumn));

        // Handle boolean values
        if (typeof columnA === "boolean" && typeof columnB === "boolean") {
          return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
        }

        // Handle numeric values without converting to strings
        if (typeof columnA === "number" && typeof columnB === "number") {
          return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
        }

        // Handle string values
        const stringColumnA = typeof columnA === "string" ? columnA : "";
        const stringColumnB = typeof columnB === "string" ? columnB : "";

        return sortOrder === "asc"
          ? stringColumnA.localeCompare(stringColumnB)
          : stringColumnB.localeCompare(stringColumnA);
      }
      return 0; // No sorting if sortColumn is null
    });

    // Replace the original items array with the sortedData
    sortedRecords.items = sortedData;

    return sortedRecords;
  };
  const sortedRecords = getProcessedRecord();

  return (
    <TableWrapper>
      <TableTopContainer>
        <Typography variant="h6">Business List</Typography>
      </TableTopContainer>
      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerCells={[
            "Businesses Name",
            "Merchant Name",
            "Location",
            "Phone Number",
            "Status",
            "Created At",
            "Action",
          ]}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
          columnMapping={columnMapping}
        />

        <TableBody>
          {records.items &&
            sortedRecords.items.map((record) => (
              <MerchantBusinessRow
                onToggleDelete={onToggleDelete}
                record={record}
                key={record.id}
              />
            ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={searchMerchantBusinessObj.data?.pagination.perPage || 1}
              count={searchMerchantBusinessObj.data?.pagination.totalItems || 1}
              rowsPerPageOptions={[]}
              showFirstButton={true}
              showLastButton={true}
              onPageChange={handlePageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableWrapper>
  );
}

MerchantBusinessListTableIndex.propTypes = {
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  onToggleDelete: PropTypes.func.isRequired,
  records: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
  }).isRequired,
};

export default MerchantBusinessListTableIndex;
