import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import { useSelector } from "react-redux";
import TableWrapper from "../../../../components/tables/table-wrapper.component";
import TableHeader from "../../../../components/tables/table-header.component";
import { accDeletionSelector } from "../../../../services/accDeletion/accDeletion-slice.service";
import Row from "./row.component";
import TableSort from "../../../../components/tables/table-sort.component";

function TableIndex({
  handlePageChange,
  page,
  handleOnDeletePress,
  setShowLogsModal,
  searchKeyword,
}) {
  const { getDeletedAccListObj } = useSelector(accDeletionSelector);
  const columnMapping = {
    Name: "accountable.name",
    Email: "accountable.email",
    Phone: "accountable.phone",
    Reason: "reason",
    "Other Reason": "reasonOthers",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getDeletedAccListObj.data && getDeletedAccListObj.status === "succeeded") {
      records = { ...getDeletedAccListObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderItemList = () => {
    if (getDeletedAccListObj.data.items) {
      const records = getProcessedRecord();

      return records?.items.map(
        (record) =>
          (record.accountable.name.toUpperCase().includes(searchKeyword.toUpperCase()) ||
            record.accountable.email.toUpperCase().includes(searchKeyword.toUpperCase()) ||
            record.accountable.phone.toUpperCase().includes(searchKeyword.toUpperCase())) && (
            <Row
              record={record}
              key={record.id}
              handleOnDeletePress={handleOnDeletePress}
              setShowLogsModal={setShowLogsModal}
            />
          ),
      );
    }
    return <></>;
  };

  return (
    <TableWrapper>
      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerTitle="Account Deletion List"
          headerCells={["Name", "Email", "Phone", "Reason", "Other Reason", "Action"]}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
          columnMapping={columnMapping}
        />
        <TableBody>{renderItemList()}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={getDeletedAccListObj?.data.pagination.perPage || 1}
              count={getDeletedAccListObj.data?.pagination.totalItems || 1}
              rowsPerPageOptions={[]}
              showFirstButton={true}
              showLastButton={true}
              onPageChange={handlePageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableWrapper>
  );
}

TableIndex.defaultProps = {
  searchKeyword: "",
};

TableIndex.propTypes = {
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handleOnDeletePress: PropTypes.func.isRequired,
  setShowLogsModal: PropTypes.func.isRequired,
  searchKeyword: PropTypes.string,
};

export default TableIndex;
