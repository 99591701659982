import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../../api.service";

export const getStaffAutoComplete = createAsyncThunk(
  "employees/merchants/staffs/auto_complete",
  async (payload) => {
    const { q, page, merchantId } = payload;
    const response = await getReq(
      `${
        process.env.REACT_APP_API_PRIVATE_V1
      }/employees/merchants/${merchantId}/staffs/auto_complete?page=${page}${
        q ? "&q=".concat(q) : ""
      }`,
    );
    return response;
  },
);

const merchantStaffSlice = createSlice({
  name: "merchantStaff",
  initialState: {
    getStaffAutoCompleteObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getStaffAutoComplete.pending]: (state) => {
      state.getStaffAutoCompleteObj.status = "pending";
    },
    [getStaffAutoComplete.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStaffAutoCompleteObj.status = "succeeded";
      state.getStaffAutoCompleteObj.data = data;
      state.getStaffAutoCompleteObj.successMessage = message;
    },
    [getStaffAutoComplete.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStaffAutoCompleteObj.status = "failed";
      state.getStaffAutoCompleteObj.errorMessage = message;
    },
  },
});

export default merchantStaffSlice.reducer;

// state
export const merchantStaffSelector = (state) => state.merchantStaff;
