import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Autocomplete, TextField, Typography, styled, Grid, useTheme } from "@mui/material";
import BorderButton from "./border-button.component";
import Spacer from "../../../components/utils/spacer.component";

const TextFieldContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "& .MuiTextField-root": {
    borderTopLeftRadius: theme.shape.borderRadius[1],
    borderTopRightRadius: theme.shape.borderRadius[1],
    borderBottomLeftRadius: theme.shape.borderRadius[1],
    borderBottomRightRadius: theme.shape.borderRadius[1],
    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: theme.shape.borderRadius[1],
      borderTopRightRadius: theme.shape.borderRadius[1],
      borderBottomLeftRadius: theme.shape.borderRadius[1],
      borderBottomRightRadius: theme.shape.borderRadius[1],
      backgroundColor: theme.palette.colors.bg.primary,
    },
  },
}));

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
});

function ImageFieldAutoComplete({ placeholder, label, options, formTitle, showSuccessSnackBar }) {
  const [image, setImage] = useState(null);
  const theme = useTheme();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextFieldContainer>
          <Typography sx={{ width: "250px" }}>{formTitle}</Typography>
          <Autocomplete
            fullWidth
            onChange={(event, value) => setImage(value)}
            options={options}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={label} placeholder={placeholder} />
            )}
            disablePortal
          />
        </TextFieldContainer>
      </Grid>
      {image && (
        <Grid item xs={12}>
          <RowBox>
            <img
              alt="preview"
              src={image.path}
              style={{
                width: "280px",
                borderRadius: theme.shape.borderRadius[1],
              }}
            />
            <Spacer size="s" position="left" />
            <BorderButton
              width="125px"
              onClickButton={() => {
                showSuccessSnackBar("Link has been copied to clipboard");
                navigator.clipboard.writeText(image.path);
              }}
            >
              <Typography sx={{ color: theme.palette.colors.brand.primary }}>Copy URL</Typography>
            </BorderButton>
          </RowBox>
        </Grid>
      )}
    </Grid>
  );
}

ImageFieldAutoComplete.defaultProps = {
  formTitle: "",
};

ImageFieldAutoComplete.propTypes = {
  formTitle: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showSuccessSnackBar: PropTypes.func.isRequired,
};

export default ImageFieldAutoComplete;
