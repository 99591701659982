import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, styled, TableCell, TableRow, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

function CategoryRow({ record, onClickDelete, categoryName }) {
  return (
    <TableRow key={record.id}>
      <TableCell width="150px">
        <Typography variant="body2" noWrap={true}>
          {record.id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.label}
        </Typography>
      </TableCell>
      <TableCell>
        <img
          src={record.imagePath}
          alt="attachment"
          style={{
            height: "100px",
            objectFit: "contain",
          }}
        />
      </TableCell>

      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.active ? "Active" : "Inactive"}
        </Typography>
      </TableCell>

      <TableCell width="150px">
        <RowBox>
          <Link to={`/category/${categoryName}/${record.id}/edit`} state={{ ...record }}>
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>

          <IconButton onClick={() => onClickDelete(record.id)} disabled={!record.deletable}>
            <DeleteIcon />
          </IconButton>
        </RowBox>
      </TableCell>
    </TableRow>
  );
}

CategoryRow.propTypes = {
  onClickDelete: PropTypes.func.isRequired,
  categoryName: PropTypes.string.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    active: PropTypes.bool,
    deletable: PropTypes.bool,
    imagePath: PropTypes.string,
  }).isRequired,
};

export default CategoryRow;
