import { Breadcrumbs, Grid, Stack, Typography, Link, Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import PaddedView from "../../../components/utils/padded-view.component";
import Form from "../../../components/forms/form.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BorderButton from "../components/border-button.component";
import Spacer from "../../../components/utils/spacer.component";
import FormTextEditor from "../../../components/forms/form-text-editor.component";
import {
  attachmentSelector,
  searchAttachment,
} from "../../../services/attachment/attachment-slice.service";
import ImageFieldAutoComplete from "../components/image-field-auto-complete.component";
import CustomizedSnackbars from "../../../components/notification/snackbar.component";
import { createAnnouncement } from "../../../services/announcement/announcement-slice.service";
import SimpleBackdrop from "../../../components/utils/backdrop-loading.component";
import AnnouncementDetailLoader from "../loader/annoucement-detail-loader.component";

const validationSchema = Yup.object().shape({
  header: Yup.string().required().min(2).label("Title"),
  subheader: Yup.string().required().min(2).label("Sub-Title"),
  content: Yup.string().required().label("Content"),
});

function NewAnnouncementScreen() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const { searchAttachmentObj } = useSelector(attachmentSelector);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const showSuccessSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("success");
    setIsShowSnackBar(true);
  };

  const showErrorSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("error");
    setIsShowSnackBar(true);
  };

  const onhandleSubmitForm = (values) => {
    setIsLoading(true);
    dispatch(createAnnouncement(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        showSuccessSnackBar(payload.message);
        navigate("/announcement/list");
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  useEffect(() => {
    dispatch(searchAttachment(""));
  }, []);

  const renderContent = () => {
    if (searchAttachmentObj.status === "succeeded") {
      return (
        <Form
          onSubmit={onhandleSubmitForm}
          initialValues={{ header: "", subheader: "", content: {} }}
          validationSchema={validationSchema}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h6">New Announcement</Typography>
                </Grid>
                <Grid item sx={{ alignSelf: "center" }}>
                  <Stack spacing={2} sx={{ paddingRight: 1 }}>
                    <Breadcrumbs separator=">" aria-label="breadcrumb">
                      <Link underline="hover" key="1" color="inherit" href="/">
                        <Typography variant="body1">Dashboard</Typography>
                      </Link>
                      <Link underline="hover" key="2" color="inherit" href="/announcement/list">
                        <Typography variant="body1">Announcements</Typography>
                      </Link>
                      <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                        New Announcements
                      </Typography>
                    </Breadcrumbs>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ImageFieldAutoComplete
                formTitle="Choose your image"
                name="images"
                placeholder="Type to search image..."
                label="Image Title"
                options={searchAttachmentObj.data}
                showSuccessSnackBar={showSuccessSnackBar}
              />
            </Grid>
            <Grid item xs={12}>
              <FormFieldText name="header" placeholder="Title" label="Title" />
            </Grid>
            <Grid item xs={12}>
              <FormFieldText name="subheader" placeholder="Subtitle" label="Subtitle" />
            </Grid>
            <Grid item xs={12}>
              <FormTextEditor name="content" placeholder="Type here..." />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                <FormSubmitButton width="100px">
                  <Typography>Save</Typography>
                </FormSubmitButton>
                <Spacer size="s" position="left" />
                <BorderButton width="100px" onClickButton={() => navigate("/announcement/list")}>
                  <Typography sx={{ color: theme.palette.colors.brand.primary }}>Cancel</Typography>
                </BorderButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      );
    }
    return <AnnouncementDetailLoader />;
  };

  return (
    <PaddedView>
      <SimpleBackdrop isLoading={isLoading} />
      <CustomizedSnackbars
        isShow={isShowSnackBar}
        setShowSnackbar={setIsShowSnackBar}
        message={snackBarMsg}
        type={snackBarType}
      />
      {renderContent()}
    </PaddedView>
  );
}

export default NewAnnouncementScreen;
