import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Breadcrumbs, Stack, Link, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import PaddedView from "../../../../components/utils/padded-view.component";
import AdditionalFeesDetail from "../components/additional-fees-detail.component";
import {
  getMerchantAdditionalFees,
  merchantAdditionalFeesSelector,
} from "../../../../services/merchant/additional-fees/additional-fees-slice.service";

function MerchantAdditionalFeesEditScreen() {
  const dispatch = useDispatch();
  const location = useLocation();
  const feeId = new URLSearchParams(location.search).get("feeId");

  const { getMerchantAdditionalFeesObj } = useSelector(merchantAdditionalFeesSelector);

  useEffect(() => {
    dispatch(getMerchantAdditionalFees({ feeId }));
  }, []);

  return (
    <>
      <Box>
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Additional Fees Edit</Typography>
            </Grid>
            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link
                    underline="hover"
                    key="2"
                    color="inherit"
                    href="/merchants/additional_fees/list"
                  >
                    <Typography variant="body1">Additional Fees List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    Additional Fees Edit
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
        </PaddedView>

        {getMerchantAdditionalFeesObj.status === "succeeded" ? (
          <>
            <PaddedView>
              <Grid item>
                <Typography variant="h5">
                  Merchant Business Name: {getMerchantAdditionalFeesObj.data.merchant.businessName}
                </Typography>
              </Grid>
            </PaddedView>

            <AdditionalFeesDetail record={getMerchantAdditionalFeesObj.data} />
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

export default MerchantAdditionalFeesEditScreen;
