import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";
import { useFormikContext } from "formik";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import BorderCtaButton from "../../../../components/button/border-cta-button.component";
import FormFieldDebounceText from "../../../../components/forms/form-field-debounce-text.component";
import FormMultiSelect from "../../../../components/forms/form-multi-select.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import PaddedView from "../../../../components/utils/padded-view.component";
import { downloadMerchantsEmailList } from "../../../../services/merchant/merchant-slice.service";
import FormDatePicker from "./form-date-picker.component";

function FilterOption() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { resetForm, handleSubmit, values } = useFormikContext();

  const handleClearForm = () => {
    resetForm();
    handleSubmit();
  };

  const handleDownloadMerchantsEmailList = () => {
    let createdStartAt = "";
    let createdEndAt = "";
    if (values.startDate !== null) {
      createdStartAt = format(new Date(values.startDate), "yyyy-MM-dd 00:00:00");
    }
    if (values.endDate !== null) {
      createdEndAt = format(new Date(values.endDate), "yyyy-MM-dd 23:59:59");
    }
    dispatch(
      downloadMerchantsEmailList({
        q: values.q,
        createdStartAt,
        createdEndAt,
        statuses: values.statuses,
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({ type: "success", message: payload.message });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  };

  return (
    <>
      <PaddedView>
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.colors.bg.secondary,
            borderRadius: `${theme.shape.borderRadius[1]}px`,
          }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <PaddedView>
                  <FormFieldDebounceText name="q" placeholder="Search for merchant name" />
                </PaddedView>
              </Grid>

              <Grid item xs={6}>
                <PaddedView>
                  <FormMultiSelect
                    name="statuses"
                    label="Statuses"
                    options={[
                      { label: "Pending", value: "pending" },
                      { label: "Unclaim", value: "unclaim" },
                      { label: "Approved", value: "approved" },
                      { label: "Rejected", value: "rejected" },
                    ]}
                  />
                </PaddedView>
              </Grid>

              <Grid item xs={6}>
                <PaddedView>
                  <FormDatePicker
                    name="startDate"
                    label="Created At (Start)"
                    disablePast={false}
                    width="100%"
                    placeholder="Start At"
                  />
                </PaddedView>
              </Grid>

              <Grid item xs={6}>
                <PaddedView>
                  <FormDatePicker
                    name="endDate"
                    label="Created At (End)"
                    disablePast={false}
                    width="100%"
                    placeholder="End At"
                  />
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <FormSubmitButton type="submit">
                    <Typography>Search</Typography>
                  </FormSubmitButton>
                </PaddedView>
              </Grid>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <BorderCtaButton onClickButton={handleClearForm}>
                    <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                      Clear
                    </Typography>
                  </BorderCtaButton>
                </PaddedView>
              </Grid>

              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <BorderCtaButton onClickButton={handleDownloadMerchantsEmailList}>
                    <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                      Download
                    </Typography>
                  </BorderCtaButton>
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
    </>
  );
}

export default FilterOption;
