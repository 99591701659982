import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import ClassDeleteConfirmationModal from "../../../../components/notification/confirmation-modal.component";
import {
  deleteClass,
  fitnessClassSelector,
  getClasses,
} from "../../../../services/merchant/fitness-class/class-slice.service";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";

const TABLE_CELL_WIDTH = 150;
function Row({ record, setUpdateItem, setShowEditModal }) {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const createSnackBar = useContext(SnackbarContext);
  const { deleteClassObj } = useSelector(fitnessClassSelector);

  const onConfirmDelete = () => {
    dispatch(deleteClass({ classId: record.id })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        dispatch(getClasses({ page: 0 }));
        createSnackBar({ type: "success", message: payload.message });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  };

  return (
    <>
      <ClassDeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title="Remove Class"
        message="Are you sure to remove this class?"
        onSubmit={onConfirmDelete}
        isDisabled={deleteClassObj.status === "pending"}
      />
      <TableRow>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.name}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.merchant.businessName}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.business.name}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.category.label}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: 150 }}>
          <IconButton
            onClick={() => {
              setUpdateItem(record);
              setShowEditModal(true);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            disabled={!record.deletable}
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  setUpdateItem: PropTypes.func.isRequired,
  setShowEditModal: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    merchant: PropTypes.shape({
      id: PropTypes.number.isRequired,
      businessName: PropTypes.string.isRequired,
    }),
    business: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    category: PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
    deletable: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Row;
