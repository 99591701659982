import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormikContext } from "formik";
import { Box, FormHelperText, useTheme } from "@mui/material";
import { format, isValid } from "date-fns";

export default function FormDatePicker({
  name,
  isTransparent,
  width,
  disablePast,
  placeholder,
  maxWidth,
  disabled,
}) {
  const theme = useTheme();
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (date) => {
    setFieldValue(name, isValid(date) ? format(date, "yyyy-MM-dd") : date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          "& .MuiTextField-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            width,
            maxWidth,
          },
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: isTransparent ? "transparent" : theme.palette.colors.text.white,
          },
        }}
      >
        <DatePicker
          inputFormat="dd/MM/yyyy"
          disablePast={disablePast}
          value={values[name]}
          onChange={handleChange}
          InputProps={{
            error: showError || false,
          }}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              onBlur={() => setFieldTouched(name)}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder,
              }}
            />
          )}
        />
        {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      </Box>
    </LocalizationProvider>
  );
}
FormDatePicker.defaultProps = {
  width: null,
  isTransparent: false,
  disablePast: true,
  placeholder: "dd/mm/yyyy",
  maxWidth: null,
  disabled: false,
};

FormDatePicker.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  disablePast: PropTypes.bool,
  width: PropTypes.string,
  isTransparent: PropTypes.bool,
  placeholder: PropTypes.string,
  maxWidth: PropTypes.string,
};
