import React, { useEffect, useState } from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSnackbars from "../../../components/notification/snackbar.component";
import SimpleBackdrop from "../../../components/utils/backdrop-loading.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import PopUpTypeSelectField from "../components/pop-up-type-select-field.component";
import Form from "../../../components/forms/form.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import PopUpSlugField from "../components/pop-up-slug-field.component";
import {
  createPopUp,
  deletePopUp,
  getPopUpList,
  popUpSelector,
} from "../../../services/pop-up/pop-up-slice.service";
import PopUpTableIndex from "../components/pop-up-table-index.component";
import AlertDialog from "../../../components/notification/dialog-boxes.component";
import AttachmentModal from "../components/attachment-modal.component";

const validationSchema = Yup.object().shape({
  type: Yup.string().required().label("Type"),
  slug: Yup.string().required().label("Slug"),
});

const FlexEndBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
});

function PopUpListScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const dispatch = useDispatch();
  const { createPopUpObj, deletePopUpObj } = useSelector(popUpSelector);
  const [page, setPage] = useState(0);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [popUpId, setPopUpId] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [attachmentArr, setAttachmentArr] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const showErrorSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("error");
    setIsShowSnackBar(true);
  };

  const showSuccessSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("success");
    setIsShowSnackBar(true);
  };

  const onRefreshPopUpList = () => {
    setPage(0);
    dispatch(getPopUpList({ page: 0, q: "" })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onCreatePopUp = (values, { resetForm }) => {
    setIsLoading(true);
    dispatch(createPopUp(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        onRefreshPopUpList();
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onClickDelete = (id) => {
    setPopUpId(id);
    setIsShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deletePopUp(popUpId)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setIsShowDeleteModal(false);
        onRefreshPopUpList();
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  useEffect(() => {
    dispatch(getPopUpList({ page, q: searchKeyword })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  }, []);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getPopUpList({ newPage, q: searchKeyword })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onPreviewClicked = (attachmentType, attachment) => {
    setAttachmentArr(attachment);
    if (attachmentType === "video") {
      setShowVideoModal(true);
    }
    if (attachmentType === "images") {
      setShowImageModal(true);
    }
  };

  const onChangeSearchKeyword = (keyword) => {
    setSearchKeyword(keyword);
    dispatch(getPopUpList({ page, q: keyword })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  return (
    <Box>
      <AttachmentModal
        setAttachment={setAttachmentArr}
        isShow={showVideoModal}
        setIsShow={setShowVideoModal}
        attachment={attachmentArr}
        type="video"
      />
      <AttachmentModal
        setAttachment={setAttachmentArr}
        isShow={showImageModal}
        setIsShow={setShowImageModal}
        attachment={attachmentArr}
        type="images"
      />
      <SimpleBackdrop isLoading={isLoading} />
      <CustomizedSnackbars
        isShow={isShowSnackBar}
        setShowSnackbar={setIsShowSnackBar}
        message={snackBarMsg}
        type={snackBarType}
      />
      <AlertDialog
        isShow={isShowDeleteModal}
        setIsShowAlert={setIsShowDeleteModal}
        isConfirmHandle={onConfirmDelete}
        title="Confirmation"
        message="Are you sure to delete ?"
        buttonText="Confirm"
        disabled={deletePopUpObj.status === "pending"}
      />
      <PaddedView>
        <Typography variant="h6">Pop Up</Typography>
        <Spacer size="m" position="top" />
        <Form
          initialValues={{ type: "", slug: "" }}
          onSubmit={onCreatePopUp}
          validationSchema={validationSchema}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PopUpTypeSelectField name="type" label="Choose type..." />
            </Grid>
            <Grid item xs={12}>
              <PopUpSlugField name="slug" placeholder="Slug" />
            </Grid>
            <Grid item xs={12}>
              <FlexEndBox>
                <FormSubmitButton width="125px" disabled={createPopUpObj.status === "pending"}>
                  <Typography>{createPopUpObj.status === "pending" ? "Loading" : "Add"}</Typography>
                </FormSubmitButton>
              </FlexEndBox>
            </Grid>
            <Grid item xs={12}>
              <PopUpTableIndex
                page={page}
                handlePageChange={handlePageChange}
                onClickDelete={onClickDelete}
                onPreviewClicked={onPreviewClicked}
                searchKeyword={searchKeyword}
                onChangeSearchKeyword={onChangeSearchKeyword}
              />
            </Grid>
          </Grid>
        </Form>
      </PaddedView>
    </Box>
  );
}

export default PopUpListScreen;
