import React, { useContext, useState } from "react";
import * as Yup from "yup";
import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import Form from "../../../components/forms/form.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import SimpleBackdrop from "../../../components/utils/backdrop-loading.component";
import MerchantBusinessAddressAutoComplete from "../components/merchant-business-edit/merchant-business-address-autocomplete.component";
import PhoneNumberTextField from "../../../components/forms/phone-number-text-field.component";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-multiline.component";
import MerchantBusinessImageList from "../components/merchant-business-create/merchant-business-image-list.component";
import {
  createMerchantBusiness,
  merchantBusinessSelector,
} from "../../../services/merchant/business/merchant-business-slice.service";

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  description: Yup.string().required().label("About Us"),
  location: Yup.string().required().label("Address"),
  locationLat: Yup.string().required().label("Address"),
  locationLong: Yup.string().required().label("Address"),
  city: Yup.string().label("City").required(),
  state: Yup.string().label("State").required(),
  postcode: Yup.string().label("Postcode").required(),
  contactNo: Yup.string()
    .required()
    .label("Phone number")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{8}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid.",
    ),
  images: Yup.array().of(Yup.object().shape({})).min(1).label("Images"),
});
export default function MerchantBusinessCreateScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const navigate = useNavigate();
  const { createMerchantBusinessObj } = useSelector(merchantBusinessSelector);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const merchantId = new URLSearchParams(location.search).get("merchantId");

  const onCreateMerchantBusiness = (values) => {
    setIsLoading(true);
    dispatch(createMerchantBusiness(values)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        navigate("/merchants/list");
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  };

  return (
    <Box>
      <SimpleBackdrop isLoading={isLoading} />
      <Form
        onSubmit={onCreateMerchantBusiness}
        validationSchema={validationSchema}
        initialValues={{
          merchantId,
          name: "",
          description: "",
          location: "",
          locationLat: "",
          locationLong: "",
          city: "",
          state: "",
          postcode: "",
          contactNo: "",
          images: [],
        }}
      >
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Create Business</Typography>
            </Grid>
            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link underline="hover" key="2" color="inherit" href="/merchants/business/list">
                    <Typography variant="body1">Business List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    Business Create
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
          <Spacer size="m" position="top" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MerchantBusinessImageList name="images" />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <FormFieldText label="Business Name" placeholder="Business Name" name="name" />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <PhoneNumberTextField
                label="Phone Number"
                placeholder="Phone Number"
                name="contactNo"
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <FormFieldTextMultiline
                rows={3}
                label="About Us"
                placeholder="Description About Us"
                name="description"
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <MerchantBusinessAddressAutoComplete name="location" />
            </Grid>

            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <FormSubmitButton
                width="125px"
                disabled={createMerchantBusinessObj.status === "pending"}
              >
                <Typography>Create</Typography>
              </FormSubmitButton>
            </Grid>
          </Grid>
        </PaddedView>
      </Form>
    </Box>
  );
}
