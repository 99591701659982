import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import React from "react";
import Spacer from "../../../components/utils/spacer.component";

function NotificationConsumerTypeSelection({ name }) {
  const theme = useTheme();
  const { values, setFieldValue, touched, errors } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";
  const ITEMLIST = [
    { label: "User", value: "user" },
    { label: "Personal Trainer", value: "trainer" },
  ];

  const handleCheckbox = (data) => {
    const isChecked = values[name].some((checkedCheckbox) => checkedCheckbox.value === data.value);
    if (isChecked) {
      if (data.value === "user") {
        setFieldValue("userList", []);
      }
      if (data.value === "trainer") {
        setFieldValue("trainerList", []);
      }
      setFieldValue(
        name,
        values[name].filter((checkedCheckbox) => checkedCheckbox.value !== data.value),
      );
    } else {
      setFieldValue(name, values[name].concat(data));
    }
  };

  return (
    <FormControl required error={showError}>
      <FormGroup>
        <Grid container spacing={2}>
          {ITEMLIST.map((item) => (
            <Grid item key={item.value}>
              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    sx={{
                      "&.Mui-checked": {
                        color: theme.palette.colors.brand.primary,
                      },
                      ":hover": { backgroundColor: "transparent" },
                    }}
                    onChange={() => handleCheckbox(item)}
                    checked={values[name].some(
                      (checkedCheckbox) => checkedCheckbox.value === item.value,
                    )}
                  />
                }
                label={<Typography>{item.label}</Typography>}
              />
            </Grid>
          ))}
        </Grid>
        {showError && <Spacer position="top" size="s" />}
        <FormHelperText error sx={{ marginLeft: "0px" }}>
          {errors[name]}
        </FormHelperText>
      </FormGroup>
    </FormControl>
  );
}

NotificationConsumerTypeSelection.propTypes = {
  name: PropTypes.string.isRequired,
};

export default NotificationConsumerTypeSelection;
