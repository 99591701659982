import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Typography, Grid, styled, IconButton } from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import EditIcon from "@mui/icons-material/Edit";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const StyledButtonIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  padding: 0,
  top: -5,
  right: -5,
  backgroundColor: theme.palette.colors.brand.primary,
  width: 30,
  height: 30,
}));

const StyledCloseIcon = styled(EditIcon)((props) => ({
  width: 22,
  height: 22,
  color: props.theme.palette.colors.brand.white,
  strokeWidth: 3,
}));

function AttachmentEditImageUpload({ name, imagePath }) {
  const theme = useTheme();
  const [filePreview, setFilePreview] = useState(imagePath);

  const { setFieldValue, errors, setFieldError, setFieldTouched, touched } = useFormikContext();

  const showError = touched[name] && typeof errors[name] === "string";

  const fileUpload = () => (
    <input
      type="file"
      name={name}
      hidden
      accept="image/*"
      onChange={(e) => {
        const { files } = e.target;
        if (files) {
          const myFile = files[0]; // single file upload only
          const withinFileSizeLimit = myFile && myFile.size <= 5000000; // 1mb = 1^6 bytes
          const allowedFileType = myFile && SUPPORTED_FORMATS.includes(myFile.type);

          if (myFile && withinFileSizeLimit && allowedFileType) {
            const fileReader = new FileReader(); // to preview the image
            fileReader.onload = () => {
              if (fileReader.readyState === 2) {
                setFieldValue(name, fileReader.result);
                setFilePreview(fileReader.result);
                setFieldValue("imageChanged", true);
              }
            };
            fileReader.readAsDataURL(myFile);
          } else {
            if (!withinFileSizeLimit) {
              setFieldError("image", "Image file cannot be more than 5mb");
            } else if (!allowedFileType) {
              setFieldError("image", `Only ${SUPPORTED_FORMATS} are allowed`);
            }

            setFilePreview("");
          }
        }
      }}
    />
  );

  return (
    <Grid container flexDirection="column" alignItems="center">
      {filePreview ? (
        <Grid item>
          <Box
            sx={{
              position: "relative",
              "& .MuiIconButton-root": {
                "&:hover": {
                  backgroundColor: theme.palette.colors.brand.primary,
                },
              },
            }}
          >
            <img
              src={filePreview}
              alt="gym sites"
              style={{
                height: "166px",
                width: "100%",
                borderRadius: theme.shape.borderRadius[1],
              }}
            />
            <StyledButtonIcon
              component="label"
              onClick={() => {
                setFieldError(name, "");
              }}
              onBlur={() => setFieldTouched(name)}
            >
              <StyledCloseIcon />
              {fileUpload()}
            </StyledButtonIcon>
          </Box>
        </Grid>
      ) : (
        <Grid item>
          <Button
            onClick={() => {
              setFieldError(name, "");
            }}
            onBlur={() => setFieldTouched(name)}
            component="label"
            sx={{
              backgroundColor: "transparent",
              borderTopLeftRadius: theme.shape.borderRadius[1],
              borderTopRightRadius: theme.shape.borderRadius[1],
              borderBottomLeftRadius: theme.shape.borderRadius[1],
              borderBottomRightRadius: theme.shape.borderRadius[1],
              border: `2px dashed ${theme.palette.colors.ui.disabled}`,
              "&:hover": {
                background: "transparent",
              },
            }}
          >
            <Box
              sx={{
                height: "150px",
                width: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: theme.palette.colors.ui.disabled,
              }}
            >
              <AddCircleOutlineRoundedIcon />
            </Box>
            {fileUpload()}
          </Button>
        </Grid>
      )}
      <Grid item>
        {showError && errors[name] && (
          <Typography sx={{ color: theme.palette.darkColors.text.error }}>
            {errors[name]}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

AttachmentEditImageUpload.defaultProps = {
  imagePath: "",
};

AttachmentEditImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  imagePath: PropTypes.string,
};

export default AttachmentEditImageUpload;
