import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../api.service";

export const getRoleOptions = createAsyncThunk("roles", async () => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/employees/roles/options`);
  return response;
});

const roleSlice = createSlice({
  name: "role",
  initialState: {
    getRoleOptionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getRoleOptions.pending]: (state) => {
      state.getRoleOptionsObj.status = "pending";
    },
    [getRoleOptions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.getRoleOptionsObj.status = "succeeded";
      state.getRoleOptionsObj.data = data;
      state.getRoleOptionsObj.successMessage = message;
    },
    [getRoleOptions.rejected]: (state, action) => {
      const { message } = action.error;
      state.getRoleOptionsObj.status = "failed";
      state.getRoleOptionsObj.errorMessage = message;
    },
  },
});

export default roleSlice.reducer;

// state
export const roleSelector = (state) => state.role;
