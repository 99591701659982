import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api.service";

export const getAnnouncementList = createAsyncThunk("announcement/list", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/announcements?q=${payload.q}&page=${
      payload.page + 1
    }`,
    payload,
  );
  return response;
});

export const createAnnouncement = createAsyncThunk("announcement/new", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/announcements`,
    payload,
  );
  return response;
});

export const deleteAnnouncement = createAsyncThunk("announcement/delete", async (payload) => {
  const response = await delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/announcements/${payload}`,
  );
  return response;
});

export const getAnnouncementDetail = createAsyncThunk("announcement/detail", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/announcements/${payload}`,
  );
  return response;
});

export const updateAnnouncement = createAsyncThunk("announcement/update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/announcements/${payload.id}`,
    payload,
  );
  return response;
});

const announcementSlice = createSlice({
  name: "announcement",
  initialState: {
    getAnnouncementListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createAnnouncementObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteAnnouncementObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getAnnouncementDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateAnnouncementObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getAnnouncementList.pending]: (state) => {
      state.getAnnouncementListObj.status = "pending";
    },
    [getAnnouncementList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getAnnouncementListObj.status = "succeeded";
      state.getAnnouncementListObj.data = data;
      state.getAnnouncementListObj.successMessage = message;
    },
    [getAnnouncementList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getAnnouncementListObj.status = "failed";
      state.getAnnouncementListObj.errorMessage = message;
    },
    [createAnnouncement.pending]: (state) => {
      state.createAnnouncementObj.status = "pending";
    },
    [createAnnouncement.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createAnnouncementObj.status = "succeeded";
      state.createAnnouncementObj.data = data;
      state.createAnnouncementObj.successMessage = message;
    },
    [createAnnouncement.rejected]: (state, action) => {
      const { message } = action.error;

      state.createAnnouncementObj.status = "failed";
      state.createAnnouncementObj.errorMessage = message;
    },
    [deleteAnnouncement.pending]: (state) => {
      state.deleteAnnouncementObj.status = "pending";
    },
    [deleteAnnouncement.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteAnnouncementObj.status = "succeeded";
      state.deleteAnnouncementObj.data = data;
      state.deleteAnnouncementObj.successMessage = message;
    },
    [deleteAnnouncement.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteAnnouncementObj.status = "failed";
      state.deleteAnnouncementObj.errorMessage = message;
    },
    [getAnnouncementDetail.pending]: (state) => {
      state.getAnnouncementDetailObj.status = "pending";
    },
    [getAnnouncementDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getAnnouncementDetailObj.status = "succeeded";
      state.getAnnouncementDetailObj.data = data;
      state.getAnnouncementDetailObj.successMessage = message;
    },
    [getAnnouncementDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getAnnouncementDetailObj.status = "failed";
      state.getAnnouncementDetailObj.errorMessage = message;
    },
    [updateAnnouncement.pending]: (state) => {
      state.updateAnnouncementObj.status = "pending";
    },
    [updateAnnouncement.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateAnnouncementObj.status = "succeeded";
      state.updateAnnouncementObj.data = data;
      state.updateAnnouncementObj.successMessage = message;

      state.getAnnouncementDetailObj.data = data;
    },
    [updateAnnouncement.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateAnnouncementObj.status = "failed";
      state.updateAnnouncementObj.errorMessage = message;
    },
  },
});

export default announcementSlice.reducer;

// state
export const announcementSelector = (state) => state.announcement;

export const { resetUser } = announcementSlice.actions;

// action
// export const {} = authSlice.actions;
