import { Box, Grid, Modal, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import BorderCtaButton from "../../../../../../../components/button/border-cta-button.component";
import CtaButton from "../../../../../../../components/button/cta-button.component";
import FormFieldTextMultiline from "../../../../../../../components/forms/form-field-text-multiline.component";
import Form from "../../../../../../../components/forms/form.component";
import { SnackbarContext } from "../../../../../../../components/notification/snackbar.context";
import Spacer from "../../../../../../../components/utils/spacer.component";
import {
  createPackageDetail,
  merchantPackagePlanDetailSelector,
  setReduxStorePackageDetails,
} from "../../../../../../../services/merchant/package/plan/detail/plan-detail-slice.service";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: "50px",
  outline: "none",
}));

const validationSchema = Yup.object().shape({
  description: Yup.string().required().label("Description"),
});

export default function AddDescriptionConfirmationModal({
  title,
  showModal,
  setShowModal,
  planId,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { reduxStorePackageDetails, createPackageDetailObj } = useSelector(
    merchantPackagePlanDetailSelector,
  );
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSubmit = (values) => {
    dispatch(createPackageDetail({ planId, description: values.description })).then(
      ({ meta, payload, error }) => {
        if (meta.requestStatus === "fulfilled") {
          setShowModal(false);
          dispatch(
            setReduxStorePackageDetails([
              ...reduxStorePackageDetails,
              { id: payload.data.id, text: payload.data.description },
            ]),
          );
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "350px" : "500px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="xl" position="top" />
            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <Form
                initialValues={{
                  description: "",
                }}
                onSubmit={handleSubmit}
                innerRef={formRef}
                validationSchema={validationSchema}
              >
                <FormFieldTextMultiline
                  rows={3}
                  label="Description"
                  placeholder="Enter package details"
                  name="description"
                />
              </Form>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="xl" position="top" />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "45%" }}>
                <BorderCtaButton onClickButton={handleCancel}>
                  <Typography sx={{ color: theme.palette.colors.brand.primary }}>Cancel</Typography>
                </BorderCtaButton>
              </Box>
              <Box sx={{ width: "45%" }}>
                <CtaButton
                  onClickButton={() => formRef.current.handleSubmit()}
                  disabled={createPackageDetailObj.status === "pending"}
                >
                  <Typography type="WhiteColor">Confirm</Typography>
                </CtaButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

AddDescriptionConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  planId: PropTypes.number.isRequired,
};
