import {
  Box,
  Link,
  styled,
  useMediaQuery,
  useTheme,
  Grid,
  Typography,
  Stack,
  Breadcrumbs,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import BorderCtaButton from "../../../components/button/border-cta-button.component";
import Form from "../../../components/forms/form.component";
import SimpleBackdrop from "../../../components/utils/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  getStatementDetail,
  statementSelector,
  closeStatement,
  disputeStatement,
  pendingStatement,
  setReduxStoreStatementDetails,
} from "../../../services/statement/statement-slice.service";
import ClosePendingConfirmationModal from "../components/close-pending-confirmation-modal.component";
import DisputeLog from "../components/dispute-log.component";
import RejectReasonModal from "../components/reject-reason-modal.component";
import RejectStatementModal from "../components/reject-statement-modal.component";
import StatementDetails from "../components/statement-details.component";
import TransactionTable from "../components/transaction-table.component";
import TableLoader from "../../../components/tables/table-loader.component";
import CtaButton from "../../../components/button/cta-button.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const validationSchema = Yup.object().shape({
  reason: Yup.string().required().label("Reason"),
});

function StatementDetailScreen() {
  const location = useLocation();
  const statementId = new URLSearchParams(location.search).get("statementId");
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const [showRejectStatementModal, setShowRejectStatementModal] = useState(false);
  const [showRejectReasonModal, setShowRejectReasonModal] = useState(false);
  const [showClosePendingStatementModal, setShowClosePendingStatementModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const {
    getStatementDetailObj,
    closeStatementObj,
    pendingStatementObj,
    reduxStoreStatementDetails,
  } = useSelector(statementSelector);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [closedOrPendingModal, setClosedOrPendingModal] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setReduxStoreStatementDetails({}));
    dispatch(getStatementDetail(statementId)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      } else if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        dispatch(setReduxStoreStatementDetails(payload.data));
      }
    });
  }, []);

  const onRejectStatement = (values, { resetForm }) => {
    setIsLoading(true);
    dispatch(disputeStatement({ statementId, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        setShowRejectStatementModal(false);
        dispatch(setReduxStoreStatementDetails(payload.data));
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onCloseOrPendingStatement = (status) => {
    if (status === "closed") {
      setIsLoading(true);
      dispatch(closeStatement({ statementId })).then(({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setShowClosePendingStatementModal(false);
          dispatch(setReduxStoreStatementDetails(payload.data));
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    } else if (status === "pending") {
      setIsLoading(true);
      dispatch(pendingStatement({ statementId })).then(({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setShowClosePendingStatementModal(false);
          dispatch(setReduxStoreStatementDetails(payload.data));
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  };

  const renderStatus = (status) => {
    if (status === "dispute") {
      return (
        <Typography
          variant="h6"
          sx={{ textTransform: "uppercase", color: theme.palette.colors.text.error }}
        >
          {status}
        </Typography>
      );
    }
    if (status === "open") {
      return (
        <Typography
          variant="h6"
          sx={{ textTransform: "uppercase", color: theme.palette.colors.text.success }}
        >
          {status}
        </Typography>
      );
    }
    if (status === "pending") {
      return (
        <Typography
          variant="h6"
          sx={{ textTransform: "uppercase", color: theme.palette.colors.text.link }}
        >
          {status}
        </Typography>
      );
    }
    return (
      <Typography
        variant="h6"
        sx={{ textTransform: "uppercase", color: theme.palette.colors.text.purple }}
      >
        {status}
      </Typography>
    );
  };

  const renderDisputeAndCloseButton = () => (
    <RowBox>
      {reduxStoreStatementDetails.status === "pending" && (
        <BorderCtaButton width="125px" onClickButton={() => setShowRejectStatementModal(true)}>
          <Typography sx={{ color: theme.palette.colors.text.error }}>Reject</Typography>
        </BorderCtaButton>
      )}

      {reduxStoreStatementDetails.status === "dispute" && (
        <BorderCtaButton
          width="125px"
          onClickButton={() => {
            setShowClosePendingStatementModal(true);
            setClosedOrPendingModal("pending");
          }}
        >
          <Typography sx={{ color: theme.palette.colors.text.link }}>Pending</Typography>
        </BorderCtaButton>
      )}

      <Spacer size="s" position="left" />
      {reduxStoreStatementDetails.status === "pending" && (
        <CtaButton
          width="125px"
          onClickButton={() => {
            setShowClosePendingStatementModal(true);
            setClosedOrPendingModal("closed");
          }}
        >
          <Typography sx={{ color: theme.palette.colors.text.white }}>Close</Typography>
        </CtaButton>
      )}
    </RowBox>
  );

  const renderContent = () => {
    if (getStatementDetailObj.status === "succeeded") {
      const statement = getStatementDetailObj.data;
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Box>
                <Typography variant="h4">Statement ID: {statement.id}</Typography>
                {renderStatus(reduxStoreStatementDetails.status)}
              </Box>
              {!isMobile && renderDisputeAndCloseButton()}
            </SpaceBetweenBox>
          </Grid>
          {isMobile && (
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                {renderDisputeAndCloseButton()}
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <StatementDetails />
          </Grid>
          {statement.disputeLog.length > 0 && (
            <Grid item xs={12}>
              <RejectReasonModal
                showModal={showRejectReasonModal}
                setShowModal={setShowRejectReasonModal}
                reason={rejectReason}
              />
              <DisputeLog
                setShowModal={setShowRejectReasonModal}
                setRejectReason={setRejectReason}
                setShowRejectStatementModal={setShowRejectStatementModal}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TransactionTable />
          </Grid>
        </Grid>
      );
    }
    return <TableLoader />;
  };

  return (
    <PaddedView>
      <SimpleBackdrop isLoading={isLoading} />
      <Form
        initialValues={{ reason: "" }}
        onSubmit={onRejectStatement}
        validationSchema={validationSchema}
      >
        <RejectStatementModal
          showModal={showRejectStatementModal}
          setShowModal={setShowRejectStatementModal}
        />
      </Form>
      <ClosePendingConfirmationModal
        showModal={showClosePendingStatementModal}
        setShowModal={setShowClosePendingStatementModal}
        isLoading={
          closeStatementObj.status === "pending" || pendingStatementObj.status === "pending"
        }
        onConfirmClicked={() => onCloseOrPendingStatement(closedOrPendingModal)}
        type={closedOrPendingModal}
      />
      <Grid container justifyContent="flex-end">
        <Grid item sx={{ alignSelf: "center" }}>
          <Stack spacing={2} sx={{ paddingRight: 1 }}>
            <Breadcrumbs separator=">" aria-label="breadcrumb">
              <Link underline="hover" key="1" color="inherit" href="/">
                <Typography variant="body1">Dashboard</Typography>
              </Link>
              <Link underline="hover" key="2" color="inherit" href="/statements/list">
                <Typography variant="body1">Statement List</Typography>
              </Link>
              <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                Statement Detail
              </Typography>
            </Breadcrumbs>
          </Stack>
        </Grid>
      </Grid>

      <Spacer size="xl" position="top" />
      {renderContent()}
    </PaddedView>
  );
}

export default StatementDetailScreen;
