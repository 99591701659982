import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";

import TableWrapper from "../../../../components/tables/table-wrapper.component";
import TableHeader from "../../../../components/tables/table-header.component";
import UserBookingDetailRow from "./user-booking-detail-row.component";
import TableSort from "../../../../components/tables/table-sort.component";

function UserBookingDetailTableIndex({ records, handlePageChange, page }) {
  const columnMapping = {
    "Order Type": "type",
    "Gym Name": "location.name",
    "Booking Date": "date",
    Time: "time",
    Category: "session.class.category.label",
    Title: "session.class.name",
    "Promo Code": "promoCode",
    Status: "status",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);
  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let sortedRecords = null;
    sortedRecords = { ...records };

    const sortedData = [...sortedRecords.items].sort((a, b) => {
      if (sortColumn) {
        const columnA = parseValue(getColumnValue(a, sortColumn));
        const columnB = parseValue(getColumnValue(b, sortColumn));

        // Handle boolean values
        if (typeof columnA === "boolean" && typeof columnB === "boolean") {
          return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
        }

        // Handle numeric values without converting to strings
        if (typeof columnA === "number" && typeof columnB === "number") {
          return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
        }

        // Handle string values
        const stringColumnA = typeof columnA === "string" ? columnA : "";
        const stringColumnB = typeof columnB === "string" ? columnB : "";

        return sortOrder === "asc"
          ? stringColumnA.localeCompare(stringColumnB)
          : stringColumnB.localeCompare(stringColumnA);
      }
      return 0; // No sorting if sortColumn is null
    });

    // Replace the original items array with the sortedData
    sortedRecords.items = sortedData;

    return sortedRecords;
  };
  const sortedRecords = getProcessedRecord();
  return (
    <TableWrapper>
      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerTitle="All Bookings"
          headerCells={[
            "Order Type",
            "Gym Name",
            "Booking Date",
            "Time",
            "Category",
            "Title",
            "Trainer",
            "Promo Code",
            "Status",
          ]}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
          columnMapping={columnMapping}
          nonSortableColumns={["Trainer"]}
        />

        <TableBody>
          {records.items &&
            sortedRecords.items.map((record) => (
              <UserBookingDetailRow record={record} key={record.id} />
            ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={records.pagination.perPage}
              count={records.pagination.totalItems}
              rowsPerPageOptions={[]}
              showFirstButton={true}
              showLastButton={true}
              onPageChange={handlePageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableWrapper>
  );
}

UserBookingDetailTableIndex.propTypes = {
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  records: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        date: PropTypes.string,
        status: PropTypes.string,
        time: PropTypes.string,
        location: PropTypes.shape({
          name: PropTypes.string,
          address: PropTypes.string,
          lat: PropTypes.string,
          long: PropTypes.string,
          imagePath: PropTypes.string,
        }),
        session: PropTypes.shape({
          trainers: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              firstName: PropTypes.string,
              lastName: PropTypes.string,
              imagePath: PropTypes.string,
            }),
          ),
          class: PropTypes.shape({
            name: PropTypes.string,
            category: PropTypes.shape({
              label: PropTypes.string,
            }),
          }),
        }),
        promoCode: PropTypes.string,
      }),
    ),
    pagination: PropTypes.shape({
      perPage: PropTypes.number.isRequired,
      totalItems: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default UserBookingDetailTableIndex;
