import { Box, Breadcrumbs, Grid, Link, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PaddedView from "../../../components/utils/padded-view.component";
import { getMerchantLeadDetail, leadSelector } from "../../../services/lead/lead-slice.service";
import MerchantLeadsDetails from "../components/merchant-leads-details.component";
import MerchantLeadsEditLoader from "../loader/merchant-leads-edit-loader.component";

function MerchantLeadsEditScreen() {
  const dispatch = useDispatch();
  const location = useLocation();
  const merchantId = new URLSearchParams(location.search).get("merchantId");
  const { getMerchantLeadDetailObj } = useSelector(leadSelector);

  useEffect(() => {
    dispatch(getMerchantLeadDetail({ merchantId }));
  }, []);

  return (
    <>
      <Box>
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Merchant Lead Edit</Typography>
            </Grid>
            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link underline="hover" key="2" color="inherit" href="/leads/merchants">
                    <Typography variant="body1">Merchant Lead List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    Merchant Lead Edit
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
        </PaddedView>
      </Box>

      {getMerchantLeadDetailObj.status === "succeeded" ? (
        <>
          <MerchantLeadsDetails record={getMerchantLeadDetailObj.data} />
        </>
      ) : (
        <MerchantLeadsEditLoader />
      )}
    </>
  );
}

export default MerchantLeadsEditScreen;
