import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../../../api.service";

export const searchMerchantEventSession = createAsyncThunk(
  "merchants/event/session/index",
  async (payload) => {
    const { eventIds } = payload;
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/events/sessions?${
        eventIds ? "eventIds=".concat(eventIds) : ""
      }`,
    );
    return response;
  },
);

const merchantEventSessionSlice = createSlice({
  name: "merchantEventSession",
  initialState: {
    searchMerchantEventSessionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [searchMerchantEventSession.pending]: (state) => {
      state.searchMerchantEventSessionObj.status = "pending";
    },
    [searchMerchantEventSession.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchMerchantEventSessionObj.status = "succeeded";
      state.searchMerchantEventSessionObj.data = data;
      state.searchMerchantEventSessionObj.successMessage = message;
    },
    [searchMerchantEventSession.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchMerchantEventSessionObj.status = "failed";
      state.searchMerchantEventSessionObj.errorMessage = message;
    },
  },
});

export default merchantEventSessionSlice.reducer;

// state
export const merchantEventSessionSelector = (state) => state.merchantEventSession;
