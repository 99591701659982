import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  IconButton,
  Collapse,
  TableRow,
  Typography,
  Table,
  TableBody,
  Box,
  Avatar,
  Link,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { useFormikContext } from "formik";
import Spacer from "../../../../components/utils/spacer.component";
import TableHeader from "../../../../components/tables/table-header.component";

function Row({ record, handleOnDeletePress, setShowLogsModal }) {
  const [collapse, setCollapse] = useState(false);
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <TableRow
        key={`${record.id}-${record.accountable.id}`}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link href={`/users/${record.accountable.id}`} style={{ textDecoration: "none" }}>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Avatar alt="user-image" src={record.accountable.imagePath} />
                <Spacer position="right" size="s" />
                <Typography variant="body2" noWrap={true} sx={{ width: "180px" }}>
                  {record.accountable.name}
                </Typography>
              </Box>
            </Link>
          </Box>
        </TableCell>
        <TableCell sx={{ width: "150px" }}>
          <Typography variant="body2" noWrap={true} sx={{ width: "150px" }}>
            {record.accountable.email}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Typography variant="body2" noWrap={true} sx={{ width: "100px" }}>
            {record.accountable.phone}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "175px" }}>
          <Typography variant="body2" noWrap={false} sx={{ width: "175px" }}>
            {record.reason}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "175px" }}>
          <Typography variant="body2" noWrap={false} sx={{ width: "175px" }}>
            {record.reasonOthers || "-"}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "150px" }}>
          <IconButton
            onClick={() => {
              setFieldValue("accountDeletionId", record.id);
              setShowLogsModal(true);
            }}
          >
            <AddCommentIcon />
          </IconButton>
          <IconButton onClick={() => handleOnDeletePress(record.id)}>
            <DeleteIcon />
          </IconButton>
          <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
            {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Spacer size="m" />
            <Typography>Logs</Typography>
            <Spacer />

            <Table size="small">
              <TableHeader
                headerCells={["Employee", "Notes"]}
                nonSortableColumns={["Employee", "Notes"]}
              />
              <TableBody>
                {record.logs.length > 0 ? (
                  record.logs.map((logs) => (
                    <TableRow key={logs.id}>
                      <TableCell sx={{ width: "200px" }}>
                        <Typography variant="body2" noWrap={false}>
                          {logs.employee.employee.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap={false}>
                          {logs.notes}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow colSpan={5}>
                    <TableCell sx={{ width: "200px" }}>-</TableCell>
                    <TableCell>-</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Spacer size="m" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  handleOnDeletePress: PropTypes.func.isRequired,
  setShowLogsModal: PropTypes.func.isRequired,
  record: PropTypes.shape({
    accountable: PropTypes.shape({
      email: PropTypes.string,
      id: PropTypes.number,
      imagePath: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
    }),
    id: PropTypes.number,
    reason: PropTypes.string,
    reasonOthers: PropTypes.string,
    logs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        notes: PropTypes.string,
        employee: PropTypes.shape({
          id: PropTypes.number,
          employee: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
          }),
        }),
      }),
    ),
  }).isRequired,
};

export default Row;
