import React from "react";
import { Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";

import FormFieldDebounceText from "../../../../components/forms/form-field-debounce-text.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import BorderCtaButton from "../../../../components/button/border-cta-button.component";
import FormDatePicker from "../../../statement/components/form-date-picker.component";

function FilterOption() {
  const theme = useTheme();
  const { resetForm, handleSubmit } = useFormikContext();

  const handleClearForm = () => {
    resetForm();
    handleSubmit();
  };

  return (
    <>
      <PaddedView>
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.colors.bg.secondary,
            borderRadius: `${theme.shape.borderRadius[1]}px`,
          }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <PaddedView>
                  <FormFieldDebounceText
                    name="q"
                    placeholder="Search for user, email, phone number"
                  />
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <PaddedView>
              <FormDatePicker
                name="registeredStartDate"
                disablePast={false}
                width="100%"
                placeholder="Registered Start Date"
              />
            </PaddedView>
          </Grid>
          <Grid item xs={6}>
            <PaddedView>
              <FormDatePicker
                name="registeredEndDate"
                disablePast={false}
                width="100%"
                placeholder="Registered End Date"
              />
            </PaddedView>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <FormSubmitButton type="submit">
                    <Typography>Search</Typography>
                  </FormSubmitButton>
                </PaddedView>
              </Grid>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <BorderCtaButton onClickButton={handleClearForm}>
                    <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                      Clear
                    </Typography>
                  </BorderCtaButton>
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
    </>
  );
}

export default FilterOption;
