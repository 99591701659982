import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api.service";

export const uploadAttachment = createAsyncThunk("attachment/upload", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/attachments`,
    payload,
  );
  return response;
});

export const getAttachmentList = createAsyncThunk("attachment/list", async (page) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/attachments?page=${page + 1}`,
  );
  return response;
});

export const deleteAttachment = createAsyncThunk("attachment/delete", async (payload) => {
  const response = await delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/attachments/${payload}`,
  );
  return response;
});

export const searchAttachment = createAsyncThunk("attachment/search", async (query) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/attachments/query?q=${query}`,
  );
  return response;
});

export const getAttachmentDetail = createAsyncThunk("attachment/details", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/attachments/${payload}`,
  );
  return response;
});

export const updateAttachment = createAsyncThunk("attachment/update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/attachments/${payload.id}`,
    payload,
  );
  return response;
});

const attachmentSlice = createSlice({
  name: "attachment",
  initialState: {
    uploadAttachmentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getAttachmentListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteAttachmentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchAttachmentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getAttachmentDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateAttachmentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [uploadAttachment.pending]: (state) => {
      state.uploadAttachmentObj.status = "pending";
    },
    [uploadAttachment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.uploadAttachmentObj.status = "succeeded";
      state.uploadAttachmentObj.data = data;
      state.uploadAttachmentObj.successMessage = message;

      state.getAttachmentListObj.data = data;
    },
    [uploadAttachment.rejected]: (state, action) => {
      const { message } = action.error;

      state.uploadAttachmentObj.status = "failed";
      state.uploadAttachmentObj.errorMessage = message;
    },
    [getAttachmentList.pending]: (state) => {
      state.getAttachmentListObj.status = "pending";
    },
    [getAttachmentList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getAttachmentListObj.status = "succeeded";
      state.getAttachmentListObj.data = data;
      state.getAttachmentListObj.successMessage = message;
    },
    [getAttachmentList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getAttachmentListObj.status = "failed";
      state.getAttachmentListObj.errorMessage = message;
    },
    [deleteAttachment.pending]: (state) => {
      state.deleteAttachmentObj.status = "pending";
    },
    [deleteAttachment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteAttachmentObj.status = "succeeded";
      state.deleteAttachmentObj.data = data;
      state.deleteAttachmentObj.successMessage = message;

      state.getAttachmentListObj.data = data;
    },
    [deleteAttachment.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteAttachmentObj.status = "failed";
      state.deleteAttachmentObj.errorMessage = message;
    },
    [searchAttachment.pending]: (state) => {
      state.searchAttachmentObj.status = "pending";
    },
    [searchAttachment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchAttachmentObj.status = "succeeded";
      state.searchAttachmentObj.data = data;
      state.searchAttachmentObj.successMessage = message;
    },
    [searchAttachment.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchAttachmentObj.status = "failed";
      state.searchAttachmentObj.errorMessage = message;
    },
    [getAttachmentDetail.pending]: (state) => {
      state.getAttachmentDetailObj.status = "pending";
    },
    [getAttachmentDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getAttachmentDetailObj.status = "succeeded";
      state.getAttachmentDetailObj.data = data;
      state.getAttachmentDetailObj.successMessage = message;
    },
    [getAttachmentDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getAttachmentDetailObj.status = "failed";
      state.getAttachmentDetailObj.errorMessage = message;
    },
    [updateAttachment.pending]: (state) => {
      state.updateAttachmentObj.status = "pending";
    },
    [updateAttachment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateAttachmentObj.status = "succeeded";
      state.updateAttachmentObj.data = data;
      state.updateAttachmentObj.successMessage = message;
    },
    [updateAttachment.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateAttachmentObj.status = "failed";
      state.updateAttachmentObj.errorMessage = message;
    },
  },
});

export default attachmentSlice.reducer;

// state
export const attachmentSelector = (state) => state.attachment;
