import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import TableWrapper from "../../../../components/tables/table-wrapper.component";
import TableHeader from "./table-header.component";
import Row from "./row.component";

function TableIndex({ page, handlePageChange, setSortCate, sortCate, records }) {
  return (
    <TableWrapper>
      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerTitle="Purchasers List"
          setSortCate={setSortCate}
          sortCate={sortCate}
          headerCells={[
            "Image",
            "Name",
            "Phone",
            "Plan",
            "Claimed",
            "Total Sessions",
            "Status",
            "Action",
          ]}
        />

        <TableBody>
          {records.items.map((record) => (
            <Row record={record} key={uuidv4()} />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={records.pagination.perPage}
              count={records.pagination.totalItems}
              rowsPerPageOptions={[]}
              showFirstButton={true}
              showLastButton={true}
              onPageChange={handlePageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableWrapper>
  );
}

TableIndex.propTypes = {
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  sortCate: PropTypes.shape({
    name: PropTypes.oneOf(["idle", "asc", "dsc"]),
    phone: PropTypes.oneOf(["idle", "asc", "dsc"]),
    plan: PropTypes.oneOf(["idle", "asc", "dsc"]),
    claimed: PropTypes.oneOf(["idle", "asc", "dsc"]),
    sessions: PropTypes.oneOf(["idle", "asc", "dsc"]),
    status: PropTypes.oneOf(["idle", "asc", "dsc"]),
  }).isRequired,
  setSortCate: PropTypes.func.isRequired,
  records: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        status: PropTypes.oneOf(["ongoing", "completed"]),
        plan: PropTypes.shape({
          title: PropTypes.string.isRequired,
          imagePath: PropTypes.string.isRequired,
          details: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
        sessions: PropTypes.shape({
          claimedCount: PropTypes.number.isRequired,
          noOfSessions: PropTypes.number.isRequired,
          histories: PropTypes.arrayOf(
            PropTypes.shape({
              redeemby: PropTypes.shape({
                self: PropTypes.bool,
                firstName: PropTypes.string,
                lastName: PropTypes.string,
              }),
              session: PropTypes.shape({
                id: PropTypes.number,
                date: PropTypes.string,
                durationMins: PropTypes.number,
                startTime: PropTypes.string,
                class: PropTypes.shape({
                  name: PropTypes.string,
                  category: PropTypes.shape({
                    label: PropTypes.string,
                  }),
                }),
              }).isRequired,
            }),
          ).isRequired,
        }).isRequired,
        businesses: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            location: PropTypes.string,
          }),
        ).isRequired,
        user: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          phone: PropTypes.string,
          imagePath: PropTypes.string,
        }).isRequired,
        category: PropTypes.shape({
          type: PropTypes.string,
          model: PropTypes.string,
          items: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              label: PropTypes.string,
              imagePath: PropTypes.string,
            }),
          ),
        }).isRequired,
      }).isRequired,
    ).isRequired,
    pagination: PropTypes.shape({
      perPage: PropTypes.number.isRequired,
      totalItems: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default TableIndex;
