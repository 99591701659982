import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, patchReq, putReq } from "../api.service";

export const getMerchantLeads = createAsyncThunk("leads/merchant/index", async (payload) => {
  const { q, page, statuses } = payload;
  const response = getReq(
    `${
      process.env.REACT_APP_API_PRIVATE_V1
    }/employees/leads?type=merchant&statuses=${statuses}&page=${page + 1}${
      q ? "&q=".concat(q) : ""
    }`,
  );
  return response;
});

export const getMerchantLeadDetail = createAsyncThunk("leads/merchant/detail", async (payload) => {
  const { merchantId } = payload;
  const response = getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/employees/leads/${merchantId}`);
  return response;
});

export const getMerchantLeadsCSV = createAsyncThunk("leads/merchant/csv", async (payload) => {
  const { q, statuses } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/leads/csv?type=merchant${
      q ? "&q=".concat(q) : ""
    }${statuses ? "&statuses=".concat(statuses) : ""}`,
  );
  return response;
});

export const updateMerchantLeads = createAsyncThunk("leads/merchant/update", async (payload) => {
  const { merchantId, name, phone, email, company } = payload;
  const response = putReq(`${process.env.REACT_APP_API_PRIVATE_V1}/employees/leads/${merchantId}`, {
    name,
    phone,
    email,
    company,
  });
  return response;
});

export const updateMerchantLeadsStatus = createAsyncThunk(
  "leads/merchant/update",
  async (payload) => {
    const { merchantId, status } = payload;
    const response = patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/leads/${merchantId}/status`,
      {
        status,
      },
    );
    return response;
  },
);

const leadSlice = createSlice({
  name: "lead",
  initialState: {
    getMerchantLeadsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMerchantLeadDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMerchantLeadsCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateMerchantLeadsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateMerchantLeadsStatusObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getMerchantLeads.pending]: (state) => {
      state.getMerchantLeadsObj.status = "pending";
    },
    [getMerchantLeads.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantLeadsObj.status = "succeeded";
      state.getMerchantLeadsObj.data = data;
      state.getMerchantLeadsObj.successMessage = message;
    },
    [getMerchantLeads.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantLeadsObj.status = "failed";
      state.getMerchantLeadsObj.errorMessage = message;
    },
    [getMerchantLeadDetail.pending]: (state) => {
      state.getMerchantLeadDetailObj.status = "pending";
    },
    [getMerchantLeadDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantLeadDetailObj.status = "succeeded";
      state.getMerchantLeadDetailObj.data = data;
      state.getMerchantLeadDetailObj.successMessage = message;
    },
    [getMerchantLeadDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantLeadDetailObj.status = "failed";
      state.getMerchantLeadDetailObj.errorMessage = message;
    },
    [getMerchantLeadsCSV.pending]: (state) => {
      state.getMerchantLeadsCSVObj.status = "pending";
    },
    [getMerchantLeadsCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantLeadsCSVObj.status = "succeeded";
      state.getMerchantLeadsCSVObj.data = data;
      state.getMerchantLeadsCSVObj.successMessage = message;
    },
    [getMerchantLeadsCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantLeadsCSVObj.status = "failed";
      state.getMerchantLeadsCSVObj.errorMessage = message;
    },
    [updateMerchantLeads.pending]: (state) => {
      state.updateMerchantLeadsObj.status = "pending";
    },
    [updateMerchantLeads.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateMerchantLeadsObj.status = "succeeded";
      state.updateMerchantLeadsObj.data = data;
      state.updateMerchantLeadsObj.successMessage = message;
    },
    [updateMerchantLeads.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateMerchantLeadsObj.status = "failed";
      state.updateMerchantLeadsObj.errorMessage = message;
    },
    [updateMerchantLeadsStatus.pending]: (state) => {
      state.updateMerchantLeadsStatusObj.status = "pending";
    },
    [updateMerchantLeadsStatus.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateMerchantLeadsStatusObj.status = "succeeded";
      state.updateMerchantLeadsStatusObj.data = data;
      state.updateMerchantLeadsStatusObj.successMessage = message;
    },
    [updateMerchantLeadsStatus.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateMerchantLeadsStatusObj.status = "failed";
      state.updateMerchantLeadsStatusObj.errorMessage = message;
    },
  },
});

export default leadSlice.reducer;

// state
export const leadSelector = (state) => state.lead;
