import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import * as Yup from "yup";
import Form from "../../../../components/forms/form.component";
import FilterOptions from "../components/filter-options.component";
import Spacer from "../../../../components/utils/spacer.component";
import TableIndex from "../components/table-index.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import TableLoader from "../../../../components/tables/table-loader.component";
import {
  getMerchantPackagePurchasers,
  merchantPackageSelector,
} from "../../../../services/merchant/package/package-slice.service";

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable().label("Search"),
  plansId: Yup.array().nullable().label("Plans"),
  merchantsId: Yup.array().nullable().label("Plans"),
  statuses: Yup.array().nullable().label("Statuses"),
  page: Yup.number().required(),
});

function PurchasersListScreen() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const { getMerchantPackagePurchasersObj } = useSelector(merchantPackageSelector);
  const [payloadParams, setPayloadParams] = useState(null);
  const [sortCate, setSortCate] = useState({
    name: "idle",
    phone: "idle",
    plan: "idle",
    claimed: "idle",
    sessions: "idle",
    status: "idle",
  });

  useEffect(() => {
    dispatch(getMerchantPackagePurchasers({ page, statuses: "ongoing,completed" }));
  }, []);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getMerchantPackagePurchasers({ ...payloadParams, page: newPage }));
  };

  const processRecords = () => {
    const sortedData = { ...getMerchantPackagePurchasersObj.data };

    if (sortCate.name === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.user.name < b.user.name) {
          return 1;
        }
        if (a.user.name > b.user.name) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.name === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.user.name > b.user.name) {
          return 1;
        }
        if (a.user.name < b.user.name) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.phone === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.user.phone < b.user.phone) {
          return 1;
        }
        if (a.user.phone > b.user.phone) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.phone === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.user.phone > b.user.phone) {
          return 1;
        }
        if (a.user.phone < b.user.phone) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.plan === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.plan.title < b.plan.title) {
          return 1;
        }
        if (a.plan.title > b.plan.title) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.plan === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.plan.title > b.plan.title) {
          return 1;
        }
        if (a.plan.title < b.plan.title) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.claimed === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.sessions.claimedCount < b.sessions.claimedCount) {
          return 1;
        }
        if (a.sessions.claimedCount > b.sessions.claimedCount) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.claimed === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.sessions.claimedCount > b.sessions.claimedCount) {
          return 1;
        }
        if (a.sessions.claimedCount < b.sessions.claimedCount) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.sessions === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.sessions.noOfSessions < b.sessions.noOfSessions) {
          return 1;
        }
        if (a.sessions.noOfSessions > b.sessions.noOfSessions) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.sessions === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.sessions.noOfSessions > b.sessions.noOfSessions) {
          return 1;
        }
        if (a.sessions.noOfSessions < b.sessions.noOfSessions) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.status === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.status < b.status) {
          return 1;
        }
        if (a.status > b.status) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.status === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.status > b.status) {
          return 1;
        }
        if (a.status < b.status) {
          return -1;
        }
        return 0;
      });
    }

    return sortedData;
  };

  const renderDataRecords = () => (
    <>
      {getMerchantPackagePurchasersObj.data &&
      getMerchantPackagePurchasersObj.data.items &&
      getMerchantPackagePurchasersObj.data.items.length > 0 ? (
        <>
          <PaddedView>
            <TableIndex
              page={page}
              handlePageChange={handlePageChange}
              records={processRecords()}
              sortCate={sortCate}
              setSortCate={setSortCate}
            />
          </PaddedView>
        </>
      ) : (
        <>
          <Typography>No purchasers yet.</Typography>
        </>
      )}
    </>
  );

  const onHandleSubmit = (values) => {
    const payload = {
      q: values.q,
      statuses: values.statuses.toString(),
      plansId: values.plansId.map((plan) => plan.id),
      merchantsId: values.merchantsId.map((item) => item.id),
      page,
    };
    setPage(0);
    setPayloadParams({
      q: values.q,
      statuses: values.statuses.toString(),
      plansId: values.plansId.map((plan) => plan.id),
      merchantsId: values.merchantsId.map((item) => item.id),
    });
    dispatch(getMerchantPackagePurchasers(payload));
  };

  return (
    <>
      <Form
        initialValues={{ q: "", statuses: [], plansId: [], merchantsId: [], page }}
        validationSchema={validationSchema}
        onSubmit={onHandleSubmit}
      >
        <FilterOptions />
      </Form>

      <Spacer size="l" />
      {getMerchantPackagePurchasersObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </>
  );
}

export default PurchasersListScreen;
