import { Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import Skeleton from "react-loading-skeleton";
import TableHeader from "../../../components/tables/table-header.component";

import DetailCardContainer from "../../../components/utils/detail-card-container.component";

export default function PromoRedemptionListLoader() {
  return (
    <Grid item xs={12}>
      <DetailCardContainer>
        <Grid item xs={12}>
          <Typography variant="h5">Redemption List</Typography>
        </Grid>
        <Table>
          <TableHeader
            headerCells={[
              "Redemption ID",
              "User Id",
              "Username",
              "Status",
              "Date Redeemed",
              "Discount Amount",
              "Purchase Item",
            ]}
          />
          <TableBody>
            <TableRow
              sx={{
                "& .MuiTableCell-root": {
                  borderBottom: "no-set",
                },
              }}
            >
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DetailCardContainer>
    </Grid>
  );
}
