import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api.service";

export const getUsersList = createAsyncThunk("user/get_users_list", async (page) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users?page=${page + 1}`,
  );
  return response;
});

export const getUserAutoCompleteSuggestion = createAsyncThunk(
  "user/auto_complete",
  async (payload) => {
    const { q, registeredStartDate, registeredEndDate, page } = payload;

    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users?${
        page ? "page=".concat(page) : "page=1"
      }${q ? "&q=".concat(q) : ""}${
        registeredStartDate ? "&registeredStartDate=".concat(registeredStartDate) : ""
      }${registeredEndDate ? "&registeredEndDate=".concat(registeredEndDate) : ""}`,
    );
    return response;
  },
);

export const getUsersBooking = createAsyncThunk("user/get_users_bookings_list", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/${payload.userId}/bookings?page=${
      payload.page + 1
    }`,
  );
  return response;
});

export const getUser = createAsyncThunk("user/get_user", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/${payload}`,
  );
  return response;
});

export const updateUserDetails = createAsyncThunk("user/update_user_details", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/${payload.userId}`,
    payload.params,
  );
  return response;
});

export const deleteUserImage = createAsyncThunk("user/delete_image", async (payload) => {
  const response = await delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/${payload}/remove_image`,
  );
  return response;
});

export const shadowLoginAsUser = createAsyncThunk("user/shadow_login", async (userId) => {
  const response = postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/${userId}/shadow_login`,
  );
  return response;
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    getUsersListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getUserAutoCompleteSuggestionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getUsersBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getUserObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateUserDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteUserImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    shadowLoginAsUserObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getUsersList.pending]: (state) => {
      state.getUsersListObj.status = "pending";
    },
    [getUsersList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getUsersListObj.status = "succeeded";
      state.getUsersListObj.data = data;
      state.getUsersListObj.successMessage = message;
    },
    [getUsersList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getUsersListObj.status = "failed";
      state.getUsersListObj.errorMessage = message;
    },
    [getUserAutoCompleteSuggestion.pending]: (state) => {
      state.getUserAutoCompleteSuggestionObj.status = "pending";
    },
    [getUserAutoCompleteSuggestion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getUserAutoCompleteSuggestionObj.status = "succeeded";
      state.getUserAutoCompleteSuggestionObj.data = data;
      state.getUserAutoCompleteSuggestionObj.successMessage = message;
    },
    [getUserAutoCompleteSuggestion.rejected]: (state, action) => {
      const { message } = action.error;

      state.getUserAutoCompleteSuggestionObj.status = "failed";
      state.getUserAutoCompleteSuggestionObj.errorMessage = message;
    },
    [getUser.pending]: (state) => {
      state.getUserObj.status = "pending";
    },
    [getUser.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getUserObj.status = "succeeded";
      state.getUserObj.data = data;
      state.getUserObj.successMessage = message;
    },
    [getUser.rejected]: (state, action) => {
      const { message } = action.error;

      state.getUserObj.status = "failed";
      state.getUserObj.errorMessage = message;
    },
    [updateUserDetails.pending]: (state) => {
      state.updateUserDetailsObj.status = "pending";
    },
    [updateUserDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateUserDetailsObj.status = "succeeded";
      state.updateUserDetailsObj.data = data;
      state.updateUserDetailsObj.successMessage = message;
    },
    [updateUserDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateUserDetailsObj.status = "failed";
      state.updateUserDetailsObj.errorMessage = message;
    },
    [deleteUserImage.pending]: (state) => {
      state.deleteUserImageObj.status = "pending";
    },
    [deleteUserImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteUserImageObj.status = "succeeded";
      state.deleteUserImageObj.data = data;
      state.deleteUserImageObj.successMessage = message;

      state.getUserObj.data.imagePath = "";
    },
    [deleteUserImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteUserImageObj.status = "failed";
      state.deleteUserImageObj.errorMessage = message;
    },
    [getUsersBooking.pending]: (state) => {
      state.getUsersBookingObj.status = "pending";
    },
    [getUsersBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getUsersBookingObj.status = "succeeded";
      state.getUsersBookingObj.data = data;
      state.getUsersBookingObj.successMessage = message;
    },
    [getUsersBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.getUsersBookingObj.status = "failed";
      state.getUsersBookingObj.errorMessage = message;
    },
    [shadowLoginAsUser.pending]: (state) => {
      state.shadowLoginAsUserObj.status = "pending";
    },
    [shadowLoginAsUser.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.shadowLoginAsUserObj.status = "succeeded";
      state.shadowLoginAsUserObj.data = data;
      state.shadowLoginAsUserObj.successMessage = message;
    },
    [shadowLoginAsUser.rejected]: (state, action) => {
      const { message } = action.error;

      state.shadowLoginAsUserObj.status = "failed";
      state.shadowLoginAsUserObj.errorMessage = message;
    },
  },
});

export default userSlice.reducer;

// state
export const userSelector = (state) => state.user;
