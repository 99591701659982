import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TableLoader from "../../../components/tables/table-loader.component";
import Spacer from "../../../components/utils/spacer.component";
import { getUsersBooking, userSelector } from "../../../services/user/user-slice.service";
import UserBookingDetailTableIndex from "../components/user-booking/user-booking-detail-table-index.component";

function UserBookingDetailScreen() {
  const location = useLocation();
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const userId = location.state.id;
  const { getUsersBookingObj } = useSelector(userSelector);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getUsersBooking({ userId, page: newPage }));
  };

  useEffect(() => {
    dispatch(getUsersBooking({ userId, page }));
  }, []);

  const renderDataRecords = () => {
    if (getUsersBookingObj.status === "succeeded") {
      return (
        <UserBookingDetailTableIndex
          records={getUsersBookingObj?.data}
          handlePageChange={handlePageChange}
          page={page}
        />
      );
    }

    return <TableLoader />;
  };

  return (
    <>
      <Spacer size="l" />
      {renderDataRecords()}
    </>
  );
}

export default UserBookingDetailScreen;
