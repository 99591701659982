import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableLoader from "../../../components/tables/table-loader.component";
import PaddedView from "../../../components/utils/padded-view.component";
import {
  deleteHighlight,
  getHighlights,
  highlightSelector,
} from "../../../services/highlight/highlight-slice.service";
import HighlightsListTableIndex from "../components/highlights-list-table-index.component";

function HighlightsListScreen() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const { getHighlightsObj } = useSelector(highlightSelector);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getHighlights({ page: newPage }));
  };

  const handleOnPressDelete = (highlightId) => {
    dispatch(deleteHighlight({ highlightId })).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        dispatch(getHighlights({ page }));
      }
    });
  };

  const renderDataRecords = () => {
    const records = getHighlightsObj.data;
    return (
      <>
        {getHighlightsObj.data.items.length > 0 ? (
          <PaddedView>
            <HighlightsListTableIndex
              handlePageChange={handlePageChange}
              page={page}
              onToggleDelete={handleOnPressDelete}
              records={records}
            />
          </PaddedView>
        ) : (
          <PaddedView>
            <Typography>No highlights created yet.</Typography>
          </PaddedView>
        )}
      </>
    );
  };

  useEffect(() => {
    dispatch(getHighlights({ page }));
  }, []);

  return (
    <>
      {getHighlightsObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </>
  );
}

export default HighlightsListScreen;
