import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/tables/table-header.component";
import TableLoader from "../../../components/tables/table-loader.component";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import {
  getStatementTransactionList,
  statementSelector,
} from "../../../services/statement/statement-slice.service";
import TransactionRow from "./transaction-row.component";
import TableSort from "../../../components/tables/table-sort.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function TransactionTable() {
  const location = useLocation();
  const statementId = new URLSearchParams(location.search).get("statementId");
  const dispatch = useDispatch();
  const { getStatementTransactionListObj } = useSelector(statementSelector);
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const columnMapping = {
    "Transaction Id": "id",
    "Date Created": "createdAt",
    Amount: "amount",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  useEffect(() => {
    dispatch(getStatementTransactionList({ statementId, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onPageChange = (e, newPage) => {
    dispatch(getStatementTransactionList({ statementId, page: newPage })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
    setPage(newPage);
  };

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Check if the original value starts with a "-"
      if (value.startsWith("-")) {
        // Remove non-digit characters and parse the string as a negative number
        return -parseFloat(value.replace(/\D/g, "")) || value;
      }

      // Remove non-digit characters and parse the string as a number
      return parseFloat(value.replace(/\D/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (
      getStatementTransactionListObj.data &&
      getStatementTransactionListObj.status === "succeeded"
    ) {
      records = { ...getStatementTransactionListObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderContent = () => {
    if (getStatementTransactionListObj.status === "succeeded") {
      const records = getProcessedRecord();

      return (
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Transactions List</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Table>
            <TableHeader
              headerCells={["Transaction Id", "Description / Commission", "Date Created", "Amount"]}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              onSortChange={onSortChange}
              columnMapping={columnMapping}
              nonSortableColumns={["Description / Commission"]}
            />
            <TableBody>
              {records.items.map((item) => (
                <TransactionRow record={item} key={item.id} />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getStatementTransactionListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </TableWrapper>
      );
    }
    return <TableLoader />;
  };

  return <>{renderContent()}</>;
}
