import {
  Box,
  Table,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import TableHeader from "../../../../components/tables/table-header.component";
import TableWrapper from "../../../../components/tables/table-wrapper.component";
import { merchantAdditionalFeesSelector } from "../../../../services/merchant/additional-fees/additional-fees-slice.service";
import AdditionalFeesRow from "./additional-fees-row.component";
import TableSort from "../../../../components/tables/table-sort.component";

const TableTopContainer = styled(Box)(({ theme }) => ({
  padding: "16px 16px 16px 16px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.colors.ui.tableBorder}`,
  alignItems: "center",
}));

function AdditionalFeesListTableIndex({ handlePageChange, page, onToggleDelete, records }) {
  const { searchMerchantAdditionalFeesObj } = useSelector(merchantAdditionalFeesSelector);
  const columnMapping = {
    ID: "id",
    Description: "description",
    Price: "price",
    Status: "active",
    "Created at": "createdAt",
    Merchant: "merchant.businessName",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);
  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let sortedRecords = null;
    sortedRecords = { ...records };

    const sortedData = [...sortedRecords.items].sort((a, b) => {
      if (sortColumn) {
        const columnA = parseValue(getColumnValue(a, sortColumn));
        const columnB = parseValue(getColumnValue(b, sortColumn));

        // Handle boolean values
        if (typeof columnA === "boolean" && typeof columnB === "boolean") {
          return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
        }

        // Handle numeric values without converting to strings
        if (typeof columnA === "number" && typeof columnB === "number") {
          return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
        }

        // Handle string values
        const stringColumnA = typeof columnA === "string" ? columnA : "";
        const stringColumnB = typeof columnB === "string" ? columnB : "";

        return sortOrder === "asc"
          ? stringColumnA.localeCompare(stringColumnB)
          : stringColumnB.localeCompare(stringColumnA);
      }
      return 0; // No sorting if sortColumn is null
    });

    // Replace the original items array with the sortedData
    sortedRecords.items = sortedData;

    return sortedRecords;
  };
  const sortedRecords = getProcessedRecord();
  return (
    <TableWrapper>
      <TableTopContainer>
        <Typography variant="h6">Additional Fees List</Typography>
      </TableTopContainer>
      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerCells={["ID", "Description", "Price", "Status", "Created at", "Merchant", "Action"]}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
          columnMapping={columnMapping}
        />
        {sortedRecords.items?.map((record) => (
          <TableBody key={record.id}>
            <AdditionalFeesRow record={record} onToggleDelete={onToggleDelete} />
          </TableBody>
        ))}
        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={searchMerchantAdditionalFeesObj.data?.pagination.perPage || 1}
              count={searchMerchantAdditionalFeesObj.data?.pagination.totalItems || 1}
              rowsPerPageOptions={[]}
              showFirstButton={true}
              showLastButton={true}
              onPageChange={handlePageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableWrapper>
  );
}

AdditionalFeesListTableIndex.propTypes = {
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  onToggleDelete: PropTypes.func.isRequired,
  records: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string,
        price: PropTypes.string,
        active: PropTypes.bool,
        createdAt: PropTypes.string,
        merchant: PropTypes.shape({
          id: PropTypes.number,
          businessName: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
};

export default AdditionalFeesListTableIndex;
