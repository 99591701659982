export const colors = {
  brand: {
    primary: "#1A7B64",
    secondary: "#FFD279",
    white: "#FFFFFF",
  },
  ui: {
    primary: "#262626",
    secondary: "#6B6B6B",
    disabled: "#AAAAAA",
    error: "#D0421B",
    success: "#138000",
    white: "#FFFFFF",
    border: "#C2C2C2",
    indicator: "#AAAAAA",
    tableBorder: "#F0EFF0",
  },
  bg: {
    primary: "#FFFFFF",
    secondary: "#F0F3F9",
  },
  timeSlot: {
    selected: "#E60013",
    unselected: "#AAAAAA",
  },
  tab: {
    selected: "#E60013",
    unselected: "#AAAAAA",
    bg: "#FAF8F8",
  },
  loading: {
    backgroundColor: "#FFFFFF",
    foregroundColor: "#AAAAAA",
    imageBg: "#AAAAAA",
    dot1: "#262626",
    dot2: "#6B6B6B",
    dot3: "#FFFFFF",
  },
  text: {
    primary: "#262626",
    secondary: "#6B6B6B",
    tertiary: "#707070",
    placeholder: "#AAAAAA",
    white: "#FFFFFF",
    disabled: "#AAAAAA",
    error: "#D0421B",
    success: "#138000",
    link: "#3874cb",
    purple: "#9C6BDE",
  },
};
