import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { Button, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function FormSubmitButton({ children, width, disabled }) {
  const theme = useTheme();
  const { handleSubmit } = useFormikContext();

  return (
    <Box
      sx={{
        "& .MuiButton-root": {
          borderTopLeftRadius: theme.shape.borderRadius[3],
          borderTopRightRadius: theme.shape.borderRadius[3],
          borderBottomLeftRadius: theme.shape.borderRadius[3],
          borderBottomRightRadius: theme.shape.borderRadius[3],
          textTransform: "none",
          padding: 1,
          "&:hover": {
            backgroundColor: theme.palette.colors.brand.primary,
          },
        },
      }}
    >
      <Button
        variant="contained"
        disabled={disabled}
        onClick={handleSubmit}
        sx={{ backgroundColor: theme.palette.colors.brand.primary, width }}
      >
        {children}
      </Button>
    </Box>
  );
}

FormSubmitButton.defaultProps = {
  width: "100%",
  disabled: false,
};

FormSubmitButton.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormSubmitButton;
