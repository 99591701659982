import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSnackbars from "../../../components/notification/snackbar.component";
import SimpleBackdrop from "../../../components/utils/backdrop-loading.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";

import {
  deleteBanner,
  getBannerList,
  updateBannerOrder,
  bannerSelector,
} from "../../../services/marketing/banner/banner-slice.services";

import AlertDialog from "../../../components/notification/dialog-boxes.component";
import BannerImage from "../components/banners-image.component";
import BannerLoader from "../loaders/banners-list-loader.component";

function BannerListScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const dispatch = useDispatch();
  const { deleteBannerObj, getBannerListObj } = useSelector(bannerSelector);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [bannerId, setBannerId] = useState("");

  const showErrorSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("error");
    setIsShowSnackBar(true);
  };

  const showSuccessSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("success");
    setIsShowSnackBar(true);
  };

  const onRefreshBannerList = () => {
    dispatch(getBannerList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onClickDelete = (id) => {
    setBannerId(id);
    setIsShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteBanner(bannerId)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setIsShowDeleteModal(false);
        onRefreshBannerList();
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onOrderUpdate = ({ itemId, orderType }) => {
    setIsLoading(true);
    dispatch(updateBannerOrder({ itemId, orderType })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        onRefreshBannerList();
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const renderActiveContent = () => {
    if (getBannerListObj.status === "succeeded") {
      return (
        <Grid container spacing={3}>
          {getBannerListObj.data.active.map((item, index) => (
            <Grid item xs={isMobile ? 12 : 4} key={item.id}>
              <BannerImage
                item={item}
                index={index + 1}
                isLast={index === getBannerListObj.data.active.length - 1}
                onDeleteBanner={onClickDelete}
                onOrderUpdate={onOrderUpdate}
                inactive={false}
              />
            </Grid>
          ))}
        </Grid>
      );
    }
    return <BannerLoader />;
  };

  const renderInactiveContent = () => {
    if (getBannerListObj.status === "succeeded") {
      return (
        <Grid container spacing={3}>
          {getBannerListObj.data.inactive.map((item, index) => (
            <Grid item xs={isMobile ? 12 : 4} key={item.id}>
              <BannerImage
                index={index + 1}
                isLast={index === item.length - 1}
                item={item}
                onDeleteBanner={onClickDelete}
                onOrderUpdate={onOrderUpdate}
                inactive={true}
              />
            </Grid>
          ))}
        </Grid>
      );
    }
    return <BannerLoader />;
  };

  useEffect(() => {
    dispatch(getBannerList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  }, []);

  return (
    <Box>
      <SimpleBackdrop isLoading={isLoading} />
      <CustomizedSnackbars
        isShow={isShowSnackBar}
        setShowSnackbar={setIsShowSnackBar}
        message={snackBarMsg}
        type={snackBarType}
      />
      <AlertDialog
        isShow={isShowDeleteModal}
        setIsShowAlert={setIsShowDeleteModal}
        isConfirmHandle={onConfirmDelete}
        title="Confirmation"
        message="Are you sure you want to delete the banner?"
        buttonText="Confirm"
        disabled={deleteBannerObj.status === "pending"}
      />
      <PaddedView>
        <Typography variant="h5">Banners</Typography>
        <Spacer size="m" position="top" />
        <Typography variant="h5">Active</Typography>
        <Spacer size="m" position="top" />
        {renderActiveContent()}
        <Spacer size="m" position="top" />
        <Typography variant="h5">Inactive</Typography>
        <Spacer size="m" position="top" />
        {renderInactiveContent()}
      </PaddedView>
    </Box>
  );
}

export default BannerListScreen;
