import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import * as Yup from "yup";
import Form from "../../../../components/forms/form.component";
import FilterOptions from "../components/filter-options.component";
import Spacer from "../../../../components/utils/spacer.component";
import TableIndex from "../components/table-index.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import TableLoader from "../../../../components/tables/table-loader.component";
import {
  getMerchantSubscriptionSubscribers,
  merchantSubscriptionSelector,
} from "../../../../services/merchant/subscription/subscription-slice.service";

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable().label("Search"),
  plansId: Yup.array().nullable().label("Plans"),
  merchantsId: Yup.array().nullable().label("Plans"),
  statuses: Yup.array().nullable().label("Statuses"),
  page: Yup.number().required(),
});

function SubscribersListScreen() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const { getMerchantSubscriptionSubscribersObj } = useSelector(merchantSubscriptionSelector);
  const [payloadParams, setPayloadParams] = useState(null);
  const [sortCate, setSortCate] = useState({
    name: "idle",
    plan: "idle",
    price: "idle",
    validity: "idle",
    tenure: "idle",
    status: "idle",
  });

  useEffect(() => {
    dispatch(getMerchantSubscriptionSubscribers({ page, statuses: "" }));
  }, []);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getMerchantSubscriptionSubscribers({ ...payloadParams, page: newPage }));
  };

  const processRecords = () => {
    const sortedData = { ...getMerchantSubscriptionSubscribersObj.data };

    if (sortCate.name === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.user.name < b.user.name) {
          return 1;
        }
        if (a.user.name > b.user.name) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.name === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.user.name > b.user.name) {
          return 1;
        }
        if (a.user.name < b.user.name) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.plan === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.plan.title < b.plan.title) {
          return 1;
        }
        if (a.plan.title > b.plan.title) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.plan === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.plan.title > b.plan.title) {
          return 1;
        }
        if (a.plan.title < b.plan.title) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.price === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.price < b.price) {
          return 1;
        }
        if (a.price > b.price) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.price === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.price > b.price) {
          return 1;
        }
        if (a.price < b.price) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.validity === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.plan.numberOfDays < b.plan.numberOfDays) {
          return 1;
        }
        if (a.plan.numberOfDays > b.plan.numberOfDays) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.validity === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.plan.numberOfDays > b.plan.numberOfDays) {
          return 1;
        }
        if (a.plan.numberOfDays < b.plan.numberOfDays) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.status === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.status < b.status) {
          return 1;
        }
        if (a.status > b.status) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.status === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.status > b.status) {
          return 1;
        }
        if (a.status < b.status) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.tenure === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.start_at < b.start_at) {
          return 1;
        }
        if (a.start_at > b.start_at) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.tenure === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.start_at > b.start_at) {
          return 1;
        }
        if (a.start_at < b.start_at) {
          return -1;
        }
        return 0;
      });
    }

    return sortedData;
  };

  const renderDataRecords = () => (
    <>
      {getMerchantSubscriptionSubscribersObj.data &&
      getMerchantSubscriptionSubscribersObj.data.items &&
      getMerchantSubscriptionSubscribersObj.data.items.length > 0 ? (
        <>
          <PaddedView>
            <TableIndex
              page={page}
              handlePageChange={handlePageChange}
              records={processRecords()}
              sortCate={sortCate}
              setSortCate={setSortCate}
            />
          </PaddedView>
        </>
      ) : (
        <>
          <Typography>No subscribers yet.</Typography>
        </>
      )}
    </>
  );

  const onHandleSubmit = (values) => {
    const payload = {
      q: values.q,
      statuses: values.statuses.toString(),
      plansId: values.plansId.map((plan) => plan.id),
      merchantsId: values.merchantsId.map((item) => item.id),
      page,
    };
    setPage(0);
    setPayloadParams({
      q: values.q,
      statuses: values.statuses.toString(),
      plansId: values.plansId.map((plan) => plan.id),
      merchantsId: values.merchantsId.map((item) => item.id),
    });
    dispatch(getMerchantSubscriptionSubscribers(payload));
  };

  return (
    <>
      <Form
        initialValues={{ q: "", statuses: [], plansId: [], merchantsId: [], page }}
        validationSchema={validationSchema}
        onSubmit={onHandleSubmit}
      >
        <FilterOptions />
      </Form>

      <Spacer size="l" />
      {getMerchantSubscriptionSubscribersObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </>
  );
}

export default SubscribersListScreen;
