import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { Box, Breadcrumbs, Grid, Link, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import Form from "../../../components/forms/form.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import {
  createMerchant,
  merchantSelector,
} from "../../../services/merchant/merchant-slice.service";
import SimpleBackdrop from "../../../components/utils/backdrop-loading.component";

const validationSchema = Yup.object().shape({
  businessName: Yup.string().required().label("Business Name"),
});

export default function MerchantCreateScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const navigate = useNavigate();
  const { createMerchantObj } = useSelector(merchantSelector);
  const [isLoading, setIsLoading] = useState(false);

  const onCreateMerchant = (values) => {
    setIsLoading(true);
    dispatch(createMerchant(values)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        navigate("/merchants/list");
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  };

  return (
    <Box>
      <SimpleBackdrop isLoading={isLoading} />
      <Form
        onSubmit={onCreateMerchant}
        validationSchema={validationSchema}
        initialValues={{
          businessName: "",
        }}
      >
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Create Merchant</Typography>
            </Grid>
            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link underline="hover" key="2" color="inherit" href="/merchants/list">
                    <Typography variant="body1">Merchant List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    Merchant Create
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>

          <Spacer size="m" position="top" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormFieldText
                label="Business Name"
                placeholder="Business Name"
                name="businessName"
              />
            </Grid>

            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <FormSubmitButton width="125px" disabled={createMerchantObj.status === "pending"}>
                <Typography>Create</Typography>
              </FormSubmitButton>
            </Grid>
          </Grid>
        </PaddedView>
      </Form>
    </Box>
  );
}
