import { Box, Breadcrumbs, Grid, Link, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getMerchantPackagePlan,
  merchantPackagePlanSelector,
} from "../../../../../services/merchant/package/plan/plan-slice.service";
import PaddedView from "../../../../../components/utils/padded-view.component";
import PlanDetail from "../components/plan/detail/plan-detail.component";
import ImagePlan from "../components/plan/image/image-plan.component";
import PlanDescription from "../components/plan/description/plan-description.component";
import { setReduxStorePackageDetails } from "../../../../../services/merchant/package/plan/detail/plan-detail-slice.service";
import PlanItem from "../components/plan/item/plan-item.component";

function PlanEditScreen() {
  const dispatch = useDispatch();
  const params = useParams();
  const { getMerchantPackagePlanObj } = useSelector(merchantPackagePlanSelector);

  useEffect(() => {
    dispatch(getMerchantPackagePlan({ planId: params.plan_id })).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        dispatch(setReduxStorePackageDetails(payload.data.planDetails));
      }
    });
  }, []);

  return (
    <>
      <Box>
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Plan Edit</Typography>
            </Grid>
            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link
                    underline="hover"
                    key="2"
                    color="inherit"
                    href="/merchants/packages/plans/list"
                  >
                    <Typography variant="body1">Plan List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    Plan Edit
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
        </PaddedView>

        {getMerchantPackagePlanObj.status === "succeeded" ? (
          <>
            <PaddedView>
              <Grid item>
                <Typography variant="h5">
                  Merchant Business Name: {getMerchantPackagePlanObj.data.merchant.businessName}
                </Typography>
              </Grid>
            </PaddedView>
            <ImagePlan
              imagePath={getMerchantPackagePlanObj.data.imagePath}
              planId={getMerchantPackagePlanObj.data.id}
            />
            <PlanDetail record={getMerchantPackagePlanObj.data} />
            <PlanItem record={getMerchantPackagePlanObj.data} />
            <PlanDescription record={getMerchantPackagePlanObj.data} />
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

export default PlanEditScreen;
