import { TableCell, TableRow, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const TABLE_CELL_WIDTH = 150;
function Row({ record }) {
  return (
    <>
      <TableRow>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.user.name}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.merchant.businessName}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.business.name}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            RM {record.booking.price}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.booking.startDate}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.booking.endDate}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography
            variant="body2"
            noWrap={true}
            sx={{ width: TABLE_CELL_WIDTH, textTransform: "capitalize" }}
          >
            {record.booking.status.replace("_", " ")}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    merchant: PropTypes.shape({
      id: PropTypes.number.isRequired,
      businessName: PropTypes.string.isRequired,
    }),
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    business: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    booking: PropTypes.shape({
      status: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      price: PropTypes.string,
    }),
  }).isRequired,
};

export default Row;
