import React from "react";
import { TableRow, TableBody, TableCell, Grid } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function PopUpTableLoader() {
  return (
    <TableBody>
      <TableRow>
        <TableCell sx={{ width: "150px" }}>
          <Skeleton width="60%" />
        </TableCell>
        <TableCell>
          <Skeleton width="60%" />
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Skeleton height={20} width={20} circle={true} />
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Grid container spacing={1}>
            <Grid item>
              <Skeleton height={20} width={20} circle={true} />
            </Grid>
            <Grid item>
              <Skeleton height={20} width={20} circle={true} />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

export default PopUpTableLoader;
