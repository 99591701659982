import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import * as Yup from "yup";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Spacer from "../../../components/utils/spacer.component";
import AttachmentEditImageUpload from "./attachment-edit-image.component";
import { attachmentSelector } from "../../../services/attachment/attachment-slice.service";
import Form from "../../../components/forms/form.component";

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[3],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  width: isMobile ? "350px" : "450px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const editValidationSchema = Yup.object().shape({
  title: Yup.string().required().label("Title"),
  path: Yup.string().required().label("Image"),
  imageChanged: Yup.bool(),
});

export default function AttachmentEditModal({
  isShow,
  setIsShow,
  editAttachmentId,
  setEditAttachmentId,
  onConfirmUpdate,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getAttachmentDetailObj } = useSelector(attachmentSelector);

  const handleCancel = () => {
    setEditAttachmentId("");
    setIsShow(false);
  };

  const renderForm = () => {
    if (getAttachmentDetailObj.status === "succeeded") {
      return (
        <Form
          initialValues={{
            title: getAttachmentDetailObj.data.title,
            imageChanged: false,
            path: getAttachmentDetailObj.data.path,
            id: editAttachmentId,
          }}
          onSubmit={onConfirmUpdate}
          validationSchema={editValidationSchema}
        >
          <Grid item xs={12}>
            <Grid container>
              <AttachmentEditImageUpload name="path" imagePath={getAttachmentDetailObj.data.path} />
            </Grid>
            <Spacer size="s" position="top" />
          </Grid>
          <Grid item xs={12}>
            <FormFieldText name="title" placeholder="Write the title here" label="Title" />
          </Grid>

          <Grid item xs={12} paddingTop="20px">
            <Spacer size="xl" position="top" />
            <FormSubmitButton disabled={getAttachmentDetailObj.status === "pending"}>
              <Typography
                sx={{ color: theme.palette.colors.brand.white, fontWeight: "bold" }}
                variant="h6"
              >
                {getAttachmentDetailObj.status === "pending" ? "Loading" : "Save"}
              </Typography>
            </FormSubmitButton>
          </Grid>
        </Form>
      );
    }
    return (
      <>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Skeleton width="166px" height="166px" />
          </Grid>
          <Spacer size="xs" position="top" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton height="40px" />
        </Grid>
        <Grid item xs={12} paddingTop="20px">
          <Spacer size="xl" position="top" />
          <Skeleton height="40px" />
        </Grid>
      </>
    );
  };

  return (
    <Modal open={isShow} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>
        <Grid container paddingY="20px" spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h5" textAlign="start">
              Edit Attachment
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container justifyContent="flex-end">
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </Grid>
          </Grid>
        </Grid>
        {renderForm()}
      </ModalBox>
    </Modal>
  );
}

AttachmentEditModal.propTypes = {
  onConfirmUpdate: PropTypes.func.isRequired,
  editAttachmentId: PropTypes.string.isRequired,
  setEditAttachmentId: PropTypes.func.isRequired,
  isShow: PropTypes.bool.isRequired,
  setIsShow: PropTypes.func.isRequired,
};
