import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../../api.service";

export const getMerchantPackagePurchasers = createAsyncThunk(
  "merchants/package/purchaser",
  async (payload) => {
    const { q, merchantsId, plansId, statuses } = payload;
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/packages?page=${
        payload.page + 1
      }${q ? "&q=".concat(q) : ""}${merchantsId ? "&merchantsId=".concat(merchantsId) : ""}${
        plansId ? "&plansId=".concat(plansId) : ""
      }${statuses ? "&statuses=".concat(statuses) : ""}`,
    );
    return response;
  },
);

const merchantPackageSlice = createSlice({
  name: "merchantPackage",
  initialState: {
    getMerchantPackagePurchasersObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getMerchantPackagePurchasers.pending]: (state) => {
      state.getMerchantPackagePurchasersObj.status = "pending";
    },
    [getMerchantPackagePurchasers.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantPackagePurchasersObj.status = "succeeded";
      state.getMerchantPackagePurchasersObj.data = data;
      state.getMerchantPackagePurchasersObj.successMessage = message;
    },
    [getMerchantPackagePurchasers.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantPackagePurchasersObj.status = "failed";
      state.getMerchantPackagePurchasersObj.errorMessage = message;
    },
  },
});

export default merchantPackageSlice.reducer;

// state
export const merchantPackageSelector = (state) => state.merchantPackage;
