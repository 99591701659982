import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableRow,
  TableFooter,
  TablePagination,
  styled,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  useTheme,
  TableBody,
  FormHelperText,
} from "@mui/material";
import { DebounceInput } from "react-debounce-input";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import TableHeader from "../../../components/tables/table-header.component";
import Spacer from "../../../components/utils/spacer.component";
import AnnouncementRow from "./announcement-row.component";
import { announcementSelector } from "../../../services/announcement/announcement-slice.service";
import AnnouncementTableRowLoader from "../loader/announcement-table-row-loader.component";

const TableTopContainer = styled(Box)(({ theme }) => ({
  padding: "16px 16px 16px 16px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.colors.ui.tableBorder}`,
  alignItems: "center",
  "& .MuiButton-root": {
    padding: "8px 24px 8px 24px",
    borderTopLeftRadius: theme.shape.borderRadius[3],
    borderTopRightRadius: theme.shape.borderRadius[3],
    borderBottomLeftRadius: theme.shape.borderRadius[3],
    borderBottomRightRadius: theme.shape.borderRadius[3],
    backgroundColor: theme.palette.colors.brand.primary,
    textTransform: "none",
    ":hover": {
      backgroundColor: theme.palette.colors.brand.primary,
    },
  },
}));

function AnnouncementTableIndex({ handlePageChange, page, searchKeyword, onChangeSearchKeyword }) {
  const theme = useTheme();
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const { getAnnouncementListObj } = useSelector(announcementSelector);

  const showError = touched.announcementsId && typeof errors.announcementsId === "string";

  const handleCheckbox = (data) => {
    setFieldTouched("announcementsId");
    const isChecked = values.announcementsId.some((checkedCheckbox) => checkedCheckbox === data);
    if (isChecked) {
      setFieldValue(
        "announcementsId",
        values.announcementsId.filter((checkedCheckbox) => checkedCheckbox !== data),
      );
    } else {
      setFieldValue("announcementsId", values.announcementsId.concat(data));
    }
  };

  const renderTableContent = () => {
    if (getAnnouncementListObj.status === "succeeded") {
      return (
        <>
          <TableBody>
            {getAnnouncementListObj.data.items &&
              getAnnouncementListObj.data.items.map((record) => (
                <AnnouncementRow record={record} key={record.id} onClickCheckbox={handleCheckbox} />
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                page={page}
                rowsPerPage={getAnnouncementListObj.data.pagination.perPage}
                count={getAnnouncementListObj.data.pagination.totalItems}
                rowsPerPageOptions={[]}
                showFirstButton={true}
                showLastButton={true}
                onPageChange={handlePageChange}
              />
            </TableRow>
          </TableFooter>
        </>
      );
    }
    return <AnnouncementTableRowLoader />;
  };

  return (
    <TableWrapper>
      <TableTopContainer>
        <Box>
          <Typography variant="h6">Announcement List</Typography>
          {showError && <FormHelperText error>{errors.announcementsId}</FormHelperText>}
        </Box>
        <DebounceInput
          sx={{
            borderTopLeftRadius: theme.shape.borderRadius[1],
            borderTopRightRadius: theme.shape.borderRadius[1],
            borderBottomLeftRadius: theme.shape.borderRadius[1],
            borderBottomRightRadius: theme.shape.borderRadius[1],
            width: "400px",
            "& .MuiOutlinedInput-root": {
              height: "40px",
              borderTopLeftRadius: theme.shape.borderRadius[1],
              borderTopRightRadius: theme.shape.borderRadius[1],
              borderBottomLeftRadius: theme.shape.borderRadius[1],
              borderBottomRightRadius: theme.shape.borderRadius[1],
              backgroundColor: theme.palette.colors.bg.primary,
            },
          }}
          debounceTimeout={300}
          onChange={(e) => onChangeSearchKeyword(e.target.value)}
          element={TextField}
          value={searchKeyword}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
                <Spacer position="right" />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {searchKeyword && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onChangeSearchKeyword("")}>
                      <HighlightOffIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
          placeholder="Search list by header"
        />
      </TableTopContainer>

      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader headerTitle="" headerCells={["ID", "Header", "Created At", "Action"]} />
        {renderTableContent()}
      </Table>
    </TableWrapper>
  );
}

AnnouncementTableIndex.propTypes = {
  searchKeyword: PropTypes.string.isRequired,
  onChangeSearchKeyword: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

export default AnnouncementTableIndex;
