import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSnackbars from "../../../components/notification/snackbar.component";
import SimpleBackdrop from "../../../components/utils/backdrop-loading.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  deleteCategory,
  getCategoryList,
  healthCategorySelector,
} from "../../../services/category/health-category-slice.service";
import CategoryTableIndex from "../components/category-table-index.component";
import AlertDialog from "../../../components/notification/dialog-boxes.component";

function HealthCategoryListScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const dispatch = useDispatch();
  const { deleteCategoryObj, getCategoryListObj } = useSelector(healthCategorySelector);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const categoryName = "health";

  const showErrorSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("error");
    setIsShowSnackBar(true);
  };

  const showSuccessSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("success");
    setIsShowSnackBar(true);
  };

  const onRefreshCategoryList = () => {
    dispatch(getCategoryList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onClickDelete = (id) => {
    setCategoryId(id);
    setIsShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteCategory(categoryId)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setIsShowDeleteModal(false);
        onRefreshCategoryList();
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  useEffect(() => {
    dispatch(getCategoryList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  }, []);

  return (
    <Box>
      <SimpleBackdrop isLoading={isLoading} />
      <CustomizedSnackbars
        isShow={isShowSnackBar}
        setShowSnackbar={setIsShowSnackBar}
        message={snackBarMsg}
        type={snackBarType}
      />
      <AlertDialog
        isShow={isShowDeleteModal}
        setIsShowAlert={setIsShowDeleteModal}
        isConfirmHandle={onConfirmDelete}
        title="Confirmation"
        message="Are you sure you want to delete the category?"
        buttonText="Confirm"
        disabled={deleteCategoryObj.status === "pending"}
      />
      <PaddedView>
        <Typography variant="h6">Categories</Typography>
        <Spacer size="m" position="top" />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CategoryTableIndex
              onClickDelete={onClickDelete}
              categoryName={categoryName}
              categoryListObj={getCategoryListObj}
            />
          </Grid>
        </Grid>
      </PaddedView>
    </Box>
  );
}

export default HealthCategoryListScreen;
