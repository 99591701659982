import LoginIcon from "@mui/icons-material/Login";
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  IconButton,
  Collapse,
  TableRow,
  Typography,
  Table,
  TableBody,
  styled,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import Spacer from "../../../../components/utils/spacer.component";
import TableHeader from "../../../../components/tables/table-header.component";
import CtaButton from "../../../../components/button/cta-button.component";

const TableTopContainer = styled(Box)(({ theme }) => ({
  padding: "6px 16px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.colors.ui.tableBorder}`,
  alignItems: "flex-end",
}));

function MerchantRow({ record, onToggleDelete, shadowLoginToMerchant }) {
  const [collapse, setCollapse] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <TableRow
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell>
          <Typography variant="body2">{record.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {`${record.firstName ? record.firstName : "-"} ${
              record.lastName ? record.lastName : "-"
            }`}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{record.phoneNo ? record.phoneNo : "-"}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
            {record.status}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
            {record.createdAt}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <IconButton
            disabled={record.status !== "approved" && record.status !== "pending"}
            onClick={() => shadowLoginToMerchant(record.id)}
          >
            <LoginIcon />
          </IconButton>

          <Link
            to={{ pathname: "/merchants/edit", search: `merchantId=${record.id}` }}
            state={{ ...record }}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>
          <IconButton disabled={!record.deletable} onClick={() => onToggleDelete(record.id)}>
            <DeleteIcon />
          </IconButton>
          <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
            {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <TableTopContainer>
              <Typography>Bank Details</Typography>
              <CtaButton
                width="150px"
                onClickButton={() =>
                  navigate({
                    pathname: `/merchants/${record.id}/banks`,
                  })
                }
              >
                <Typography variant="body2">Update Bank Details</Typography>
              </CtaButton>
            </TableTopContainer>
            <Table size="small">
              <TableHeader
                headerCells={["Bank Name", "Bank Account", "Account Holder Name"]}
                nonSortableColumns={["Bank Name", "Bank Account", "Account Holder Name"]}
              />
              <TableBody>
                <TableRow>
                  <TableCell width="250px">
                    <Typography variant="body2" noWrap={true}>
                      {record.bank.name ? record.bank.name : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell width="200px">
                    <Typography variant="body2" noWrap={true}>
                      {record.bank.account ? record.bank.account : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell width="200px">
                    <Typography variant="body2" noWrap={true}>
                      {record.bank.holderName ? record.bank.holderName : "-"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Spacer size="m" />
            <TableTopContainer>
              <Typography>Businesses</Typography>
              <CtaButton
                width="150px"
                onClickButton={() =>
                  navigate({
                    pathname: "/merchants/business/create",
                    search: `merchantId=${record.id}`,
                  })
                }
              >
                <Typography variant="body2">Create Business</Typography>
              </CtaButton>
            </TableTopContainer>
            <Table size="small">
              <TableHeader
                headerCells={["Business Name", "Address", "Status", "Action"]}
                nonSortableColumns={["Business Name", "Address", "Status"]}
              />
              <TableBody>
                {record.businesses.length > 0 ? (
                  record.businesses.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell width="250px">
                        <Typography variant="body2" noWrap={true}>
                          {item.name}
                        </Typography>
                      </TableCell>
                      <TableCell width="400px">
                        <Typography variant="body2" noWrap>
                          {item.location}
                        </Typography>
                      </TableCell>
                      <TableCell width="200px">
                        <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
                          {item.status.replace("_", " ")}
                        </Typography>
                      </TableCell>
                      <TableCell width="100px">
                        <Link
                          to={{
                            pathname: "/merchants/business/edit",
                            search: `merchantId=${record.id}&businessId=${item.id}`,
                          }}
                        >
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell width="250px">-</TableCell>
                    <TableCell width="400px">-</TableCell>
                    <TableCell width="200px">-</TableCell>
                    <TableCell width="100px">-</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Spacer size="xl" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

MerchantRow.defaultProps = {
  record: {
    id: null,
    name: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    status: "",
    rejections: [],
    bank: {
      name: "",
      account: "",
      holderName: "",
    },
  },
};

MerchantRow.propTypes = {
  onToggleDelete: PropTypes.func.isRequired,
  shadowLoginToMerchant: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNo: PropTypes.string,
    status: PropTypes.string,
    rejections: PropTypes.arrayOf(
      PropTypes.shape({
        reason: PropTypes.string,
        rejectedBy: PropTypes.string,
      }),
    ),
    bank: PropTypes.shape({
      name: PropTypes.string,
      account: PropTypes.string,
      holderName: PropTypes.string,
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        location: PropTypes.string,
        status: PropTypes.string,
        createdAt: PropTypes.string,
      }),
    ),
    createdAt: PropTypes.string,
    deletable: PropTypes.bool.isRequired,
  }),
};

export default MerchantRow;
