import React from "react";
import { Grid, Table, TableHead, TableRow, TableBody, TableCell } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import TableWrapper from "./table-wrapper.component";

function TableLoader() {
  const renderTableRow = () => (
    <TableRow>
      <TableCell sx={{ width: 150 }}>
        <Skeleton width={150} />
      </TableCell>
      <TableCell sx={{ width: 150 }}>
        <Skeleton width={150} />
      </TableCell>
      <TableCell sx={{ width: 150 }}>
        <Skeleton width={150} />
      </TableCell>
      <TableCell sx={{ width: 150 }}>
        <Skeleton width={150} />
      </TableCell>
      <TableCell>
        <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
          <Grid item>
            <Skeleton height={20} width={20} circle={true} />
          </Grid>

          <Grid item>
            <Skeleton height={20} width={20} circle={true} />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );

  return (
    <TableWrapper>
      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 150 }}>
              <Skeleton width={150} />
            </TableCell>
            <TableCell sx={{ width: 150 }}>
              <Skeleton width={150} />
            </TableCell>
            <TableCell sx={{ width: 150 }}>
              <Skeleton width={150} />
            </TableCell>
            <TableCell sx={{ width: 150 }}>
              <Skeleton width={150} />
            </TableCell>
            <TableCell sx={{ width: 150 }}>
              <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
                <Grid item>
                  <Skeleton width={150} />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <>{renderTableRow()}</>
          <>{renderTableRow()}</>
          <>{renderTableRow()}</>
          <>{renderTableRow()}</>
          <>{renderTableRow()}</>
        </TableBody>
      </Table>
    </TableWrapper>
  );
}

export default TableLoader;
