import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, postReq, delReq, putReq, patchReq } from "../../../api.service";

export const getMerchantPackagePlans = createAsyncThunk(
  "merchants/package/plan/index",
  async (payload) => {
    const { q, merchantsId } = payload;
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/packages/plans?page=${
        payload.page + 1
      }${q ? "&q=".concat(q) : ""}${merchantsId ? "&merchantsId=".concat(merchantsId) : ""}`,
    );
    return response;
  },
);

export const getMerchantPackagePlan = createAsyncThunk(
  "merchants/package/plan",
  async (payload) => {
    const { planId } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/packages/plans/${planId}`,
    );
    return response;
  },
);

export const getMerchantPackagePlansAutoCompleteSuggestion = createAsyncThunk(
  "merchants/package/plan/autocomplete",
  async (payload) => {
    const { keyword, page, merchantsId } = payload;
    const response = getReq(
      `${
        process.env.REACT_APP_API_PRIVATE_V1
      }/employees/merchants/packages/plans/package_auto_complete?page=${page}${
        keyword ? "&q=".concat(keyword) : ""
      }${merchantsId ? "&merchantsId=".concat(merchantsId) : ""}`,
    );
    return response;
  },
);

export const createPackagePlan = createAsyncThunk("merchants/packages/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/packages/plans`,
    payload,
  );
  return response;
});

export const deletePackagePlan = createAsyncThunk(
  "merchants/packages/plans/delete/{plan_id}",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/packages/plans/${payload.planId}`,
    );
    return response;
  },
);

export const updatePackagePlanDetail = createAsyncThunk(
  "merchants/packages/plans/update/{plan_id}",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/packages/plans/${payload.planId}`,
      payload,
    );
    return response;
  },
);

export const updatePackagePlanImage = createAsyncThunk(
  "merchants/packages/plans/update/image/{plan_id}",
  async (payload) => {
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/packages/plans/${payload.planId}/image`,
      payload,
    );
    return response;
  },
);

const merchantPackagePlanSlice = createSlice({
  name: "merchantPackagePlan",
  initialState: {
    getMerchantPackagePlansObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMerchantPackagePlanObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMerchantPackagePlansAutoCompleteSuggestionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createPackagePlanObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deletePackagePlanObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePackagePlanDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePackagePlanImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getMerchantPackagePlans.pending]: (state) => {
      state.getMerchantPackagePlansObj.status = "pending";
    },
    [getMerchantPackagePlans.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantPackagePlansObj.status = "succeeded";
      state.getMerchantPackagePlansObj.data = data;
      state.getMerchantPackagePlansObj.successMessage = message;
    },
    [getMerchantPackagePlans.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantPackagePlansObj.status = "failed";
      state.getMerchantPackagePlansObj.errorMessage = message;
    },
    [getMerchantPackagePlan.pending]: (state) => {
      state.getMerchantPackagePlanObj.status = "pending";
    },
    [getMerchantPackagePlan.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantPackagePlanObj.status = "succeeded";
      state.getMerchantPackagePlanObj.data = data;
      state.getMerchantPackagePlanObj.successMessage = message;
    },
    [getMerchantPackagePlan.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantPackagePlanObj.status = "failed";
      state.getMerchantPackagePlanObj.errorMessage = message;
    },
    [getMerchantPackagePlansAutoCompleteSuggestion.pending]: (state) => {
      state.getMerchantPackagePlansAutoCompleteSuggestionObj.status = "pending";
    },
    [getMerchantPackagePlansAutoCompleteSuggestion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantPackagePlansAutoCompleteSuggestionObj.status = "succeeded";
      state.getMerchantPackagePlansAutoCompleteSuggestionObj.data = data;
      state.getMerchantPackagePlansAutoCompleteSuggestionObj.successMessage = message;
    },
    [getMerchantPackagePlansAutoCompleteSuggestion.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantPackagePlansAutoCompleteSuggestionObj.status = "failed";
      state.getMerchantPackagePlansAutoCompleteSuggestionObj.errorMessage = message;
    },
    [createPackagePlan.pending]: (state) => {
      state.createPackagePlanObj.status = "pending";
    },
    [createPackagePlan.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createPackagePlanObj.status = "succeeded";
      state.createPackagePlanObj.data = data;
      state.createPackagePlanObj.successMessage = message;
    },
    [createPackagePlan.rejected]: (state, action) => {
      const { message } = action.error;

      state.createPackagePlanObj.status = "failed";
      state.createPackagePlanObj.errorMessage = message;
    },
    [deletePackagePlan.pending]: (state) => {
      state.deletePackagePlanObj.status = "pending";
    },
    [deletePackagePlan.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deletePackagePlanObj.status = "succeeded";
      state.deletePackagePlanObj.data = data;
      state.deletePackagePlanObj.successMessage = message;
    },
    [deletePackagePlan.rejected]: (state, action) => {
      const { message } = action.error;

      state.deletePackagePlanObj.status = "failed";
      state.deletePackagePlanObj.errorMessage = message;
    },
    [updatePackagePlanDetail.pending]: (state) => {
      state.updatePackagePlanDetailObj.status = "pending";
    },
    [updatePackagePlanDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePackagePlanDetailObj.status = "succeeded";
      state.updatePackagePlanDetailObj.data = data;
      state.updatePackagePlanDetailObj.successMessage = message;
    },
    [updatePackagePlanDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePackagePlanDetailObj.status = "failed";
      state.updatePackagePlanDetailObj.errorMessage = message;
    },
    [updatePackagePlanImage.pending]: (state) => {
      state.updatePackagePlanImageObj.status = "pending";
    },
    [updatePackagePlanImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePackagePlanImageObj.status = "succeeded";
      state.updatePackagePlanImageObj.data = data;
      state.updatePackagePlanImageObj.successMessage = message;
    },
    [updatePackagePlanImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePackagePlanImageObj.status = "failed";
      state.updatePackagePlanImageObj.errorMessage = message;
    },
  },
});

export default merchantPackagePlanSlice.reducer;

// state
export const merchantPackagePlanSelector = (state) => state.merchantPackagePlan;
