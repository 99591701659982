import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import FormFieldText from "../../../../../../../components/forms/form-field-text.component";
import Form from "../../../../../../../components/forms/form.component";
import { SnackbarContext } from "../../../../../../../components/notification/snackbar.context";
import {
  deletePackageDetail,
  updatePackageDetail,
} from "../../../../../../../services/merchant/package/plan/detail/plan-detail-slice.service";

const validationSchema = Yup.object().shape({
  description: Yup.string().required().label("Description"),
});

const PackageDescriptionDetail = ({ detail, planId, allowEditDetail }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const createSnackBar = useContext(SnackbarContext);
  const [deleted, setDeleted] = useState(false);
  const formRef = useRef();

  const handleSubmit = (values) => {
    dispatch(
      updatePackageDetail({ planId, detailId: detail.id, description: values.description }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const handleDelete = () => {
    setDeleted(true);
    dispatch(deletePackageDetail({ planId, detailId: detail.id })).then(
      ({ meta, payload, error }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  return (
    <>
      {!deleted && (
        <Grid container item xs={12} display="flex" alignItems="center">
          <Grid item xs={11}>
            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <Form
                initialValues={{
                  description: detail.text,
                }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                innerRef={formRef}
              >
                <FormFieldText
                  name="description"
                  placeholder="Enter package details"
                  label="Description"
                  disabled={!allowEditDetail}
                />
              </Form>
            </Box>
          </Grid>
          {allowEditDetail && (
            <>
              <Grid item xs={0.5}>
                <IconButton onClick={() => formRef.current.handleSubmit()}>
                  <CheckCircleIcon style={{ color: theme.palette.colors.brand.primary }} />
                </IconButton>
              </Grid>
              <Grid item xs={0.5}>
                <IconButton onClick={handleDelete}>
                  <RemoveCircleIcon style={{ color: theme.palette.colors.ui.error }} />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

PackageDescriptionDetail.propTypes = {
  detail: PropTypes.shape({
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  planId: PropTypes.number.isRequired,
  allowEditDetail: PropTypes.bool.isRequired,
};

export default PackageDescriptionDetail;
