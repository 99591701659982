import { Box, Breadcrumbs, FormLabel, Grid, Link, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormFieldNumber from "../../../../../components/forms/form-field-number.component";
import FormFieldText from "../../../../../components/forms/form-field-text.component";
import FormPrice from "../../../../../components/forms/form-price.component";
import FormSubmitButton from "../../../../../components/forms/form-submit-button.component";
import OutlinedSelectTextField from "../../../../../components/forms/outlined-select-text-field.component";
import { SnackbarContext } from "../../../../../components/notification/snackbar.context";
import SimpleBackdrop from "../../../../../components/utils/backdrop-loading.component";
import PaddedView from "../../../../../components/utils/padded-view.component";
import Spacer from "../../../../../components/utils/spacer.component";
import { createSubscriptionPlan } from "../../../../../services/merchant/subscription/plan/plan-slice.service";
import BusinessListAutoComplete from "../../components/business-list-autocomplete.component";
import MerchantListAutoComplete from "../../components/merchant-list-autocomplete.component";
import FormImageUpload from "../components/plan/image/form-image-upload.component";

const validationSchema = Yup.object().shape({
  merchantId: Yup.number().required().label("Merchant Id"),
  title: Yup.string()
    .required()
    .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allow")
    .min(2)
    .label("Title"),
  description: Yup.string().required().min(2).label("Description"),
  shortDescription: Yup.string().required().min(2).label("Short Description"),
  numberOfDays: Yup.number().required().min(1).label("No of days"),
  businessesId: Yup.array().required().min(1).label("Business Access"),
  price: Yup.number().required().label("Price"),
  status: Yup.string().required().label("Status"),
  image: Yup.string().required().label("Image"),
});

function PlanNewScreen() {
  const dispatch = useDispatch();
  const [filePreview, setFilePreview] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const createSnackBar = useContext(SnackbarContext);

  const onHandleSubmit = (values) => {
    setIsLoading(true);
    const payloadValues = {
      ...values,
      businessesId: values.businessesId.map((item) => item.id),
      priceCents: parseFloat(values.price * 100),
    };
    dispatch(createSubscriptionPlan(payloadValues)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
      if (meta.requestStatus === "fulfilled") {
        navigate("/merchants/subscriptions/plans/list");
      }
    });
  };

  return (
    <>
      <SimpleBackdrop isLoading={isLoading} />
      <Box>
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">New Plan</Typography>
            </Grid>

            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link
                    underline="hover"
                    key="2"
                    color="inherit"
                    href="/merchants/subscriptions/plans/list"
                  >
                    <Typography variant="body1">Plan List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    New Plan
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>

            <Formik
              validationSchema={validationSchema}
              initialValues={{
                merchantId: null,
                selectedMerchant: null,
                title: "",
                description: "",
                shortDescription: "",
                numberOfDays: "",
                businessesId: [],
                price: "",
                status: "active",
                image: "",
              }}
              onSubmit={onHandleSubmit}
            >
              {({ isValid }) => (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Grid item container sx={{ width: 200 }}>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            alignSelf: "center",
                          }}
                        >
                          <FormLabel>Plan Image</FormLabel>
                        </Grid>
                        <FormImageUpload
                          name="image"
                          filePreview={filePreview}
                          setFilePreview={setFilePreview}
                          allowEdit={true}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Grid item container sx={{ width: 200 }}>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            alignSelf: "center",
                          }}
                        >
                          <FormLabel>Details</FormLabel>
                        </Grid>
                      </Grid>

                      <Grid item container spacing={2}>
                        <Grid item xs={12} sm={5}>
                          <Spacer size="m" />
                          <MerchantListAutoComplete
                            name="selectedMerchant"
                            placeholder="Select a merchant"
                            disabled={false}
                            multiple={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <Spacer size="m" />
                          <FormFieldText
                            name="title"
                            placeholder="Enter Title"
                            label="Title"
                            disabled={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <FormFieldNumber
                            name="numberOfDays"
                            placeholder="Enter Number of Days"
                            label="Number of Days"
                            disabled={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <FormPrice
                            name="price"
                            placeholder="Enter Price"
                            label="Price"
                            disabled={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <OutlinedSelectTextField
                            name="status"
                            label="Status"
                            disabled={false}
                            selected="active"
                            selections={[
                              { type: "Active", value: "active" },
                              { type: "Pending", value: "pending" },
                              { type: "Inactive", value: "inactive" },
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Spacer size="m" />
                          <FormFieldText
                            name="description"
                            placeholder="Enter Description"
                            label="Description"
                            disabled={false}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormFieldText
                            name="shortDescription"
                            placeholder="Enter Short Description"
                            label="Short Description"
                            disabled={false}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <BusinessListAutoComplete
                            disabled={false}
                            name="businessesId"
                            placeholder="Business Accesses"
                            forEdit={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Grid container justifyContent="flex-end">
                            <Grid item sm={2} xs={12}>
                              <FormSubmitButton type="submit" disabled={!isValid}>
                                <Typography>Create</Typography>
                              </FormSubmitButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
        </PaddedView>
      </Box>
    </>
  );
}

export default PlanNewScreen;
