import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq, putReq } from "../../../api.service";

export const addSession = createAsyncThunk(
  "employees/merchants/fitness_classes/session/add",
  async (payload) => {
    const { classId, startAt, endAt, amountCents, maxCapacity, staffsId } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/fitness_classes/sessions`,
      {
        classId,
        startAt,
        endAt,
        amountCents,
        maxCapacity,
        staffsId,
      },
    );
    return response;
  },
);

export const addRecurrentSessions = createAsyncThunk(
  "employees/merchants/fitness_classes/session/recurrent",
  async (payload) => {
    const {
      classId,
      startDate,
      endDate,
      startTime,
      endTime,
      weeklyRepeat,
      occurenceType,
      occurenceNumber,
      amountCents,
      maxCapacity,
      staffsId,
    } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/fitness_classes/sessions/recurrent`,
      {
        classId,
        startDate,
        endDate,
        startTime,
        endTime,
        weeklyRepeat,
        occurenceType,
        occurenceNumber,
        amountCents,
        maxCapacity,
        staffsId,
      },
    );
    return response;
  },
);

export const getSessionList = createAsyncThunk(
  "employees/merchants/fitness_classes/session/list",
  async (payload) => {
    const { classesId, statuses, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/fitness_classes/sessions?page=${
        page + 1
      }${statuses ? "&statuses=".concat(statuses) : ""}${
        classesId ? "&classesId=".concat(classesId) : ""
      }`,
    );
    return response;
  },
);

export const getSessionDetail = createAsyncThunk(
  "employees/merchants/fitness_classes/session/detail",
  async (payload) => {
    const { sessionId } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/fitness_classes/sessions/${sessionId}`,
    );
    return response;
  },
);

export const updateSessionStatus = createAsyncThunk(
  "employees/merchants/fitness_classes/session/status",
  async (payload) => {
    const { sessionId, status } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/fitness_classes/sessions/${sessionId}/status`,
      { status },
    );
    return response;
  },
);

export const updateSession = createAsyncThunk(
  "employees/merchants/fitness_classes/session/update",
  async (payload) => {
    const { sessionId, startAt, endAt, amountCents, maxCapacity, staffsId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/fitness_classes/sessions/${sessionId}`,
      {
        startAt,
        endAt,
        amountCents,
        maxCapacity,
        staffsId,
      },
    );
    return response;
  },
);

const fitnessClassSessionSlice = createSlice({
  name: "fitnessClassSession",
  initialState: {
    addSessionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    addRecurrentSessionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSessionListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSessionDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateSessionStatusObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateSessionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [addSession.pending]: (state) => {
      state.addSessionObj.status = "pending";
    },
    [addSession.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.addSessionObj.status = "succeeded";
      state.addSessionObj.data = data;
      state.addSessionObj.successMessage = message;
    },
    [addSession.rejected]: (state, action) => {
      const { message } = action.error;

      state.addSessionObj.status = "failed";
      state.addSessionObj.errorMessage = message;
    },
    [addRecurrentSessions.pending]: (state) => {
      state.addRecurrentSessionsObj.status = "pending";
    },
    [addRecurrentSessions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.addRecurrentSessionsObj.status = "succeeded";
      state.addRecurrentSessionsObj.data = data;
      state.addRecurrentSessionsObj.successMessage = message;
    },
    [addRecurrentSessions.rejected]: (state, action) => {
      const { message } = action.error;

      state.addRecurrentSessionsObj.status = "failed";
      state.addRecurrentSessionsObj.errorMessage = message;
    },
    [getSessionList.pending]: (state) => {
      state.getSessionListObj.status = "pending";
    },
    [getSessionList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSessionListObj.status = "succeeded";
      state.getSessionListObj.data = data;
      state.getSessionListObj.successMessage = message;
    },
    [getSessionList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSessionListObj.status = "failed";
      state.getSessionListObj.errorMessage = message;
    },
    [getSessionDetail.pending]: (state) => {
      state.getSessionDetailObj.status = "pending";
    },
    [getSessionDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSessionDetailObj.status = "succeeded";
      state.getSessionDetailObj.data = data;
      state.getSessionDetailObj.successMessage = message;
    },
    [getSessionDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSessionDetailObj.status = "failed";
      state.getSessionDetailObj.errorMessage = message;
    },
    [updateSessionStatus.pending]: (state) => {
      state.updateSessionStatusObj.status = "pending";
    },
    [updateSessionStatus.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateSessionStatusObj.status = "succeeded";
      state.updateSessionStatusObj.data = data;
      state.updateSessionStatusObj.successMessage = message;
    },
    [updateSessionStatus.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateSessionStatusObj.status = "failed";
      state.updateSessionStatusObj.errorMessage = message;
    },
    [updateSession.pending]: (state) => {
      state.updateSessionObj.status = "pending";
    },
    [updateSession.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateSessionObj.status = "succeeded";
      state.updateSessionObj.data = data;
      state.updateSessionObj.successMessage = message;
    },
    [updateSession.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateSessionObj.status = "failed";
      state.updateSessionObj.errorMessage = message;
    },
  },
});

export default fitnessClassSessionSlice.reducer;

// state
export const fitnessClassSessionSelector = (state) => state.merchantFitnessClassSession;
