import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Button, Drawer, IconButton, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import { logout, resetUser } from "../../services/auth/auth-slice.service";

import KatchLogo from "../../assets/images/katch-logo";
import PaddedView from "../../components/utils/padded-view.component";
import Spacer from "../../components/utils/spacer.component";
import DrawerItems from "./components/drawer-items.component";

const drawerWidth = "250px";

function AdminDashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(true);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = () => {
    dispatch(logout()).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        navigate("/login");
      }
      if (meta.requestStatus === "rejected") {
        dispatch(resetUser());
        navigate("/login");
      }
    });
  };

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
      >
        <Toolbar>
          <Box sx={{ display: { xs: "none", sm: "block", md: "block" } }}>
            <Spacer />
            <KatchLogo />
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block", md: "block" } }}>
            <Spacer position="right" size="xl" />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Button
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                backgroundColor: theme.palette.colors.brand.primary,
                borderTopLeftRadius: theme.shape.borderRadius[1],
                borderTopRightRadius: theme.shape.borderRadius[1],
                borderBottomLeftRadius: theme.shape.borderRadius[1],
                borderBottomRightRadius: theme.shape.borderRadius[1],
                minWidth: "45px",
                "&:hover": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  opacity: 0.8,
                },
              }}
            >
              <MenuIcon />
            </Button>
          </Box>
          <Box>
            <IconButton sx={{ color: "inherit" }} onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* ====== Drawer Section ====== */}
      {isMobile ? (
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: theme.palette.darkColors.bg.secondary,
            },
          }}
        >
          <Box sx={{ display: { xs: "block" } }}>
            <PaddedView>
              <Spacer />
              <KatchLogo />
            </PaddedView>
          </Box>
          <DrawerItems />
          <Spacer size="l" />
        </Drawer>
      ) : (
        <Drawer
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: "none", sm: "block" },
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              marginTop: "76px",
              backgroundColor: theme.palette.darkColors.bg.secondary,
            },
          }}
        >
          <DrawerItems />
          <Spacer size="l" />
        </Drawer>
      )}

      {/* ====== Content Section ====== */}
      <Box sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}>
        <Box
          sx={{
            marginLeft: !isMobile && drawerOpen ? "240px" : "0px",

            backgroundColor: theme.palette.darkColors.bg.white,
            borderTopLeftRadius: theme.shape.borderRadius[1],
            borderTopRightRadius: theme.shape.borderRadius[1],
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <PaddedView multiples={2}>
            <Outlet />
          </PaddedView>
        </Box>
      </Box>
    </>
  );
}

export default AdminDashboard;
