import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api.service";

export const getEarningList = createAsyncThunk("employees/earning/list", async (payload) => {
  const { startAt, endAt, page } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/earnings?${
      page ? "page=".concat(page) : "page=1"
    }${startAt ? "&startAt=".concat(startAt) : ""}${endAt ? "&endAt=".concat(endAt) : ""}`,
  );
  return response;
});

export const getEarningSummary = createAsyncThunk("employees/earning/summary", async (payload) => {
  const { startAt, endAt } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/earnings/summary?${
      startAt ? "&startAt=".concat(startAt) : ""
    }${endAt ? "&endAt=".concat(endAt) : ""}`,
  );
  return response;
});

const earningSlice = createSlice({
  name: "earning",
  initialState: {
    getEarningListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getEarningSummaryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getEarningList.pending]: (state) => {
      state.getEarningListObj.status = "pending";
    },
    [getEarningList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEarningListObj.status = "succeeded";
      state.getEarningListObj.data = data;
      state.getEarningListObj.successMessage = message;
    },
    [getEarningList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEarningListObj.status = "failed";
      state.getEarningListObj.errorMessage = message;
    },
    [getEarningSummary.pending]: (state) => {
      state.getEarningSummaryObj.status = "pending";
    },
    [getEarningSummary.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEarningSummaryObj.status = "succeeded";
      state.getEarningSummaryObj.data = data;
      state.getEarningSummaryObj.successMessage = message;
    },
    [getEarningSummary.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEarningSummaryObj.status = "failed";
      state.getEarningSummaryObj.errorMessage = message;
    },
  },
});

export default earningSlice.reducer;

// state
export const earningSelector = (state) => state.earning;
