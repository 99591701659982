import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, InputAdornment, Box, IconButton } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Spacer from "../utils/spacer.component";

function FormFieldText({
  name,
  placeholder,
  label,
  type,
  isCode,
  showIcon,
  numberOnly,
  isSlug,
  handleSlugChange,
  disabled,
}) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const showError = touched[name] && typeof errors[name] === "string";

  const getType = (inputType) => {
    const tempType = inputType;
    if (tempType === "password" && isShowPassword) {
      return "string";
    }
    return tempType;
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": {
            borderTopLeftRadius: theme.shape.borderRadius[1],
            borderTopRightRadius: theme.shape.borderRadius[1],
            borderBottomLeftRadius: theme.shape.borderRadius[1],
            borderBottomRightRadius: theme.shape.borderRadius[1],
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderTopLeftRadius: theme.shape.borderRadius[1],
              borderTopRightRadius: theme.shape.borderRadius[1],
              borderBottomLeftRadius: theme.shape.borderRadius[1],
              borderBottomRightRadius: theme.shape.borderRadius[1],
              backgroundColor: theme.palette.colors.bg.primary,
            },
          },
        }}
      >
        <TextField
          onKeyDown={keyPress}
          error={showError}
          variant="outlined"
          helperText={showError ? errors[name] : null}
          onBlur={() => setFieldTouched(name)}
          name={name}
          type={getType(type || name)}
          label={label}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(e) => {
            if (isSlug) {
              setFieldValue(name, e.target.value.toLowerCase());
              handleSlugChange(e.target.value.toLowerCase());
            } else if (numberOnly) {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                setFieldValue(name, e.target.value);
              }
            } else if (isCode) {
              setFieldValue(name, e.target.value.toUpperCase().replace(" ", ""));
            } else {
              setFieldValue(name, e.target.value);
            }
          }}
          InputProps={{
            startAdornment: (
              <>
                {showIcon && name === "email" && (
                  <InputAdornment position="start">
                    <PersonIcon />
                    <Spacer position="right" />
                  </InputAdornment>
                )}
                {showIcon && name.toUpperCase().includes("PASSWORD") && (
                  <InputAdornment position="start">
                    <LockIcon />
                    <Spacer position="right" />
                  </InputAdornment>
                )}
              </>
            ),
            endAdornment: name.toUpperCase().includes("PASSWORD") && (
              <InputAdornment position="start">
                <IconButton
                  onClick={() => {
                    setIsShowPassword(!isShowPassword);
                  }}
                >
                  {isShowPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={values[name]}
        />
      </Box>
    </>
  );
}

FormFieldText.defaultProps = {
  type: null,
  isCode: false,
  isSlug: false,
  numberOnly: false,
  disabled: false,
  label: "",
  showIcon: true,
  handleSlugChange: null,
};

FormFieldText.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  isCode: PropTypes.bool,
  isSlug: PropTypes.bool,
  numberOnly: PropTypes.bool,
  showIcon: PropTypes.bool,
  handleSlugChange: PropTypes.func,
};

export default FormFieldText;
