import React from "react";
import { Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";
import PaddedView from "../../../components/utils/padded-view.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BorderCtaButton from "../../../components/button/border-cta-button.component";
import FormFieldDebounceText from "../../../components/forms/form-field-debounce-text.component";
import FormMultiSelect from "../../../components/forms/form-multi-select.component";
import { leadSelector } from "../../../services/lead/lead-slice.service";

function FilterOption() {
  const theme = useTheme();
  const { resetForm, handleSubmit } = useFormikContext();
  const { getMerchantLeadsCSVObj } = useSelector(leadSelector);

  const handleClearForm = () => {
    resetForm();
    handleSubmit();
  };

  return (
    <>
      <PaddedView>
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.colors.bg.secondary,
            borderRadius: `${theme.shape.borderRadius[1]}px`,
          }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <PaddedView>
                  <FormFieldDebounceText
                    name="q"
                    label="Search"
                    placeholder="Search for username, email, phone number or company"
                  />
                </PaddedView>
              </Grid>

              <Grid item xs={6}>
                <PaddedView>
                  <FormMultiSelect
                    name="statuses"
                    label="*Statuses"
                    options={[
                      {
                        value: "new",
                        label: "New",
                      },
                      {
                        value: "pending",
                        label: "Pending",
                      },
                      {
                        value: "done",
                        label: "Done",
                      },
                    ]}
                  />
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <FormSubmitButton type="submit">
                    <Typography>Search</Typography>
                  </FormSubmitButton>
                </PaddedView>
              </Grid>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <BorderCtaButton onClickButton={handleClearForm}>
                    <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                      Clear
                    </Typography>
                  </BorderCtaButton>
                </PaddedView>
              </Grid>

              <Grid item xs={12} sm={3}>
                <PaddedView>
                  {getMerchantLeadsCSVObj.status === "succeeded" ? (
                    <>
                      <CSVLink
                        data={getMerchantLeadsCSVObj.data}
                        filename={`merchant_leads_${moment().format("D-MM-YYYY")}.csv`}
                        style={{ textDecoration: "none" }}
                      >
                        <BorderCtaButton disabled={getMerchantLeadsCSVObj.status !== "succeeded"}>
                          <Typography
                            sx={{
                              color:
                                getMerchantLeadsCSVObj.status === "succeeded"
                                  ? theme.palette.colors.brand.primary
                                  : theme.palette.colors.text.disabled,
                            }}
                          >
                            Download CSV
                          </Typography>
                        </BorderCtaButton>
                      </CSVLink>
                    </>
                  ) : (
                    <>
                      <BorderCtaButton disabled={true}>
                        <Typography
                          sx={{
                            color: theme.palette.colors.text.disabled,
                          }}
                        >
                          Download CSV
                        </Typography>
                      </BorderCtaButton>
                    </>
                  )}
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
    </>
  );
}

export default FilterOption;
