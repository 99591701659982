import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import TableWrapper from "../../../../../components/tables/table-wrapper.component";
import TableHeader from "./table-header.component";
import Row from "./row.component";

function TableIndex({
  page,
  handlePageChange,
  setSortCate,
  sortCate,
  records,
  handleUpdateSessionStatus,
}) {
  return (
    <TableWrapper>
      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerTitle="Session List"
          setSortCate={setSortCate}
          sortCate={sortCate}
          headerCells={["Class Name", "Time", "Price", "Capacity", "Status", "Action", "CTA", ""]}
        />

        <TableBody>
          {records.items.map((record) => (
            <Row
              record={record}
              key={uuidv4()}
              handleUpdateSessionStatus={handleUpdateSessionStatus}
            />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={records.pagination.perPage}
              count={records.pagination.totalItems}
              rowsPerPageOptions={[]}
              showFirstButton={true}
              showLastButton={true}
              onPageChange={handlePageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableWrapper>
  );
}

TableIndex.propTypes = {
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handleUpdateSessionStatus: PropTypes.func.isRequired,
  sortCate: PropTypes.shape({
    className: PropTypes.oneOf(["idle", "asc", "dsc"]),
    time: PropTypes.oneOf(["idle", "asc", "dsc"]),
    price: PropTypes.oneOf(["idle", "asc", "dsc"]),
    status: PropTypes.oneOf(["idle", "asc", "dsc"]),
  }).isRequired,
  setSortCate: PropTypes.func.isRequired,
  records: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        merchant: PropTypes.shape({
          id: PropTypes.number.isRequired,
          businessName: PropTypes.string.isRequired,
        }),
        business: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
        class: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
        category: PropTypes.shape({
          label: PropTypes.string.isRequired,
        }),
        startAt: PropTypes.string.isRequired,
        endAt: PropTypes.string.isRequired,
        price: PropTypes.string.isRequired,
        currentCapacity: PropTypes.number.isRequired,
        maxCapacity: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        allowPublish: PropTypes.bool.isRequired,
        staffs: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            phone: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            image_path: PropTypes.string.isRequired,
          }),
        ),
      }).isRequired,
    ).isRequired,
    pagination: PropTypes.shape({
      perPage: PropTypes.number.isRequired,
      totalItems: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default TableIndex;
