import React from "react";
import PropTypes from "prop-types";
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  OutlinedInput,
  Checkbox,
  Chip,
  Box,
  FormHelperText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import { v4 as uuidv4 } from "uuid";

function FormMultiSelect({ name, label, options, disabled }) {
  const theme = useTheme();
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  const ITEM_HEIGHT = 100;
  const ITEM_PADDING_TOP = 8;

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  return (
    <>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel>{label}</InputLabel>
        <Select
          disabled={disabled}
          multiple
          value={values[name]}
          onChange={handleChange}
          error={showError}
          input={
            <OutlinedInput
              label={label}
              sx={{
                borderTopLeftRadius: theme.shape.borderRadius[1],
                borderTopRightRadius: theme.shape.borderRadius[1],
                borderBottomLeftRadius: theme.shape.borderRadius[1],
                borderBottomRightRadius: theme.shape.borderRadius[1],
                backgroundColor: theme.palette.colors.bg.primary,
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderTopLeftRadius: theme.shape.borderRadius[1],
                  borderTopRightRadius: theme.shape.borderRadius[1],
                  borderBottomLeftRadius: theme.shape.borderRadius[1],
                  borderBottomRightRadius: theme.shape.borderRadius[1],
                  backgroundColor: theme.palette.colors.bg.primary,
                },
              }}
            />
          }
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={options.find((option) => option.value === value).label} />
              ))}
            </Box>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                marginTop: 4,
                borderRadius: theme.shape.borderRadius[1],
                maxHeight: ITEM_HEIGHT * 2 + ITEM_PADDING_TOP,
                width: 250,
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={uuidv4()} value={option.value}>
              <Checkbox checked={values[name].includes(option.value)} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
    </>
  );
}

FormMultiSelect.defaultProps = {
  disabled: false,
};

FormMultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  ).isRequired,
};

export default FormMultiSelect;
