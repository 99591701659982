import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import BorderCtaButton from "../../../../components/button/border-cta-button.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormMultiSelect from "../../../../components/forms/form-multi-select.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import { classCategorySelector } from "../../../../services/category/class-category-slice.service";
import BusinessListAutoComplete from "./business-list-autocomplete.component";
import MerchantListAutoComplete from "./merchant-list-autocomplete.component";

function FilterOptions({ getClassCategoriesSelection }) {
  const theme = useTheme();
  const { resetForm, handleSubmit } = useFormikContext();
  const { getCategoryListObj } = useSelector(classCategorySelector);

  const handleClearForm = () => {
    resetForm();
    handleSubmit();
  };

  return (
    <>
      <PaddedView>
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.colors.bg.secondary,
            borderRadius: `${theme.shape.borderRadius[1]}px`,
          }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <PaddedView>
                      <MerchantListAutoComplete
                        name="merchantsId"
                        placeholder="Merchants"
                        multiple={true}
                      />
                    </PaddedView>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PaddedView>
                      <BusinessListAutoComplete
                        name="businessesId"
                        placeholder="Merchant Businesses"
                        forSearch={true}
                        multiple={true}
                      />
                    </PaddedView>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {getCategoryListObj.status === "succeeded" ? (
                    <Grid item xs={12} sm={6}>
                      <PaddedView>
                        <FormMultiSelect
                          disabled={false}
                          name="categoriesId"
                          label="Category(s)"
                          options={getClassCategoriesSelection()}
                        />
                      </PaddedView>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6}>
                      <PaddedView>
                        <Skeleton width="100%" height={50} />
                      </PaddedView>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <PaddedView>
                      <FormFieldText
                        name="q"
                        placeholder="Search for class by name"
                        label="Search"
                      />
                    </PaddedView>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <FormSubmitButton type="submit">
                    <Typography>Search</Typography>
                  </FormSubmitButton>
                </PaddedView>
              </Grid>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <BorderCtaButton onClickButton={handleClearForm}>
                    <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                      Clear
                    </Typography>
                  </BorderCtaButton>
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
    </>
  );
}

FilterOptions.propTypes = {
  getClassCategoriesSelection: PropTypes.func.isRequired,
};

export default FilterOptions;
