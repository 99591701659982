import { TableCell, TableRow, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function TransactionRow({ record }) {
  const theme = useTheme();
  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell>
        <Typography variant="body2">{record.id}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.description}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ minWidth: "100px" }}>
          {record.createdAt}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          sx={{
            minWidth: "100px",
            color: record.amount.includes("-")
              ? theme.palette.colors.text.error
              : theme.palette.colors.text.success,
          }}
        >
          {record.amount.includes("-") ? "-" : "+"} RM
          {record.amount.replace("-", "")}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

TransactionRow.defaultProps = {
  record: {
    id: null,
    amount: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
  },
};

TransactionRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    amount: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
  }),
};

export default TransactionRow;
