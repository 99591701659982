import React from "react";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  InputAdornment,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
});

const LabelContainer = styled(Box)({
  width: "200px",
  height: "40px",
  display: "flex",
  alignItems: "center",
});

const EndAdornmentButton = styled(Button)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[3],
  borderTopRightRadius: theme.shape.borderRadius[3],
  borderBottomLeftRadius: theme.shape.borderRadius[3],
  borderBottomRightRadius: theme.shape.borderRadius[3],
  textTransform: "none",
  padding: 0,
  backgroundColor: theme.palette.colors.brand.primary,
  height: "30px",
  width: "50px",
  "&:hover": {
    backgroundColor: theme.palette.colors.brand.primary,
  },
}));

function PopUpSlugField({ name, placeholder, showSaveButton }) {
  const theme = useTheme();
  const { setFieldValue, values, errors, setFieldTouched, touched, handleSubmit } =
    useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <CenterRowBox>
      <LabelContainer>
        <Typography>Slug</Typography>
      </LabelContainer>
      <TextField
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          minWidth: "300px",
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: theme.palette.colors.bg.primary,
          },
        }}
        onKeyDown={keyPress}
        size="small"
        error={showError}
        variant="outlined"
        helperText={showError ? errors[name] : null}
        onBlur={() => setFieldTouched(name)}
        name={name}
        placeholder={placeholder}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        value={values[name]}
        InputProps={{
          endAdornment: showSaveButton && (
            <InputAdornment position="end">
              <EndAdornmentButton onClick={handleSubmit}>
                <Typography
                  sx={{
                    color: theme.palette.colors.text.white,
                    fontSize: "14px",
                  }}
                >
                  Save
                </Typography>
              </EndAdornmentButton>
            </InputAdornment>
          ),
        }}
      />
    </CenterRowBox>
  );
}

PopUpSlugField.defaultProps = {
  showSaveButton: false,
};

PopUpSlugField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  showSaveButton: PropTypes.bool,
};

export default PopUpSlugField;
