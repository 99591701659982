import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useDispatch } from "react-redux";
import { TableCell, TableRow, Typography, Avatar, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import StaffDeletionModalConfirmation from "../staff-edit/staff-deletion-modal-confirmation.component";
import { deleteStaff, getStaffs } from "../../../../services/staff/staff-slice.service";

const TABLE_CELL_WIDTH = 150;

function Row({ record }) {
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const dispatch = useDispatch();

  const handleOnPressDelete = (value) => {
    dispatch(deleteStaff({ staffId: value })).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        dispatch(getStaffs({ page: 0 }));
      }
    });
  };

  return (
    <>
      <StaffDeletionModalConfirmation
        record={record}
        showDeletionModal={showDeletionModal}
        setShowDeletionModal={setShowDeletionModal}
        handleOnPressDelete={handleOnPressDelete}
      />
      <TableRow>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Avatar alt={record.firstName} src={record.imagePath} sx={{ width: 100, height: 100 }} />
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.firstName} {record.lastName}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {record.email}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Typography variant="body2" noWrap={true} sx={{ width: TABLE_CELL_WIDTH }}>
            {moment(record.createdAt).format("D MMM YYYY")}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: TABLE_CELL_WIDTH }}>
          <Link
            to={{
              pathname: `/staffs/${record.id}/edit`,
            }}
            style={{ textDecoration: "none" }}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>
          <IconButton
            disabled={!record.deletable}
            onClick={() => {
              setShowDeletionModal(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    imagePath: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    deletable: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Row;
