import {
  Box,
  Pagination,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { format } from "date-fns";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/tables/table-header.component";
import TableLoader from "../../../components/tables/table-loader.component";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  getStatementList,
  statementSelector,
} from "../../../services/statement/statement-slice.service";
import FilterOptions from "../components/filter-options.component";
import StatementRow from "../components/statement-row.component";
import TableSort from "../../../components/tables/table-sort.component";

const validationSchema = Yup.object().shape({
  statementId: Yup.number().nullable().label("Statement ID"),
  merchantsId: Yup.array().nullable().label("Merchant"),
  paidStartDate: Yup.date().nullable().label("Start Date").typeError("Invalid date"),
  paidEndDate: Yup.date()
    .min(Yup.ref("paidStartDate"), "End date can't be before start date")
    .label("End Date")
    .nullable(),
});

const AntTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.colors.brand.primary,
    height: "2px",
  },
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.colors.text.secondary,
  fontSize: "18px",
  "&.Mui-selected": {
    color: theme.palette.colors.brand.primary,
  },
}));

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function StatementListScreen() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();
  const { getStatementListObj } = useSelector(statementSelector);
  const [page, setPage] = useState(1);
  const [currentStatus, setCurrentStatus] = useState("");
  const columnMapping = {
    "Statement ID": "id",
    "Merchant Name": "merchant.businessName",
    Status: "status",
    Amount: "amount",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onSearchStatement = (values) => {
    let paidStartDate = "";
    let paidEndDate = "";
    if (values.paidStartDate !== null) {
      paidStartDate = format(new Date(values.paidStartDate), "yyyy-MM-dd");
    }
    if (values.paidEndDate !== null) {
      paidEndDate = format(new Date(values.paidEndDate), "yyyy-MM-dd");
    }

    const payload = {
      statementId: values.statementId,
      merchantsId: values.merchantsId.map((item) => item.id),
      paidStartDate,
      paidEndDate,
    };
    setPage(1);
    dispatch(
      getStatementList({
        ...payload,
        status: currentStatus,
        page: 1,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    let paidStartDate = "";
    let paidEndDate = "";
    if (formRef.current.values.paidStartDate !== null) {
      paidStartDate = format(new Date(formRef.current.values.paidStartDate), "yyyy-MM-dd");
    }
    if (formRef.current.values.paidEndDate !== null) {
      paidEndDate = format(new Date(formRef.current.values.paidEndDate), "yyyy-MM-dd");
    }

    const payload = {
      statementId: formRef.current.values.statementId,
      merchantsId: formRef.current.values.merchantsId.map((item) => item.id),
      paidStartDate,
      paidEndDate,
    };
    setPage(newPage);
    dispatch(
      getStatementList({
        ...payload,
        status: currentStatus,
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    onPageChange(1);
  }, []);

  const onChangeStatus = (e, status) => {
    let paidStartDate = "";
    let paidEndDate = "";
    if (formRef.current.values.paidStartDate !== null) {
      paidStartDate = format(new Date(formRef.current.values.paidStartDate), "yyyy-MM-dd");
    }
    if (formRef.current.values.paidEndDate !== null) {
      paidEndDate = format(new Date(formRef.current.values.paidEndDate), "yyyy-MM-dd");
    }

    const payload = {
      statementId: formRef.current.values.statementId,
      merchantsId: formRef.current.values.merchantsId.map((item) => item.id),
      paidStartDate,
      paidEndDate,
    };
    dispatch(getStatementList({ ...payload, status, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    setCurrentStatus(status);
  };

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getStatementListObj.data && getStatementListObj.status === "succeeded") {
      records = { ...getStatementListObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderContent = () => {
    if (getStatementListObj.status === "succeeded") {
      const records = getProcessedRecord();

      return (
        <>
          <Table>
            <TableHeader
              headerCells={["Statement ID", "Merchant Name", "Status", "Amount", "Action", ""]}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              onSortChange={onSortChange}
              columnMapping={columnMapping}
              nonSortableColumns={[""]}
            />
            <TableBody>
              {records.items.map((item) => (
                <StatementRow record={item} key={item.id} />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getStatementListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }

    if (getStatementListObj.status === "failed") {
      return (
        <Table>
          <TableBody>
            <TableRow
              sx={{
                "& .MuiTableCell-root": {
                  borderBottom: "0px",
                },
              }}
            >
              <TableCell>
                <Typography>No statement found.</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }

    return <TableLoader />;
  };

  return (
    <PaddedView>
      <Form
        innerRef={formRef}
        onSubmit={onSearchStatement}
        validationSchema={validationSchema}
        initialValues={{
          merchantsId: [],
          statementId: "",
          paidStartDate: null,
          paidEndDate: null,
        }}
      >
        <FilterOptions />
      </Form>
      <Spacer position="top" size="l" />
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">Statements List</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <Spacer position="top" size="m" />
        {getStatementListObj.status === "succeeded" && (
          <AntTabs value={currentStatus} onChange={onChangeStatus}>
            <AntTab value="" label="All" />
            <AntTab value="open" label="Open" />
            <AntTab value="pending" label="Pending" />
            <AntTab value="closed" label="Closed" />
            <AntTab value="dispute" label="Dispute" />
          </AntTabs>
        )}
        {renderContent()}
      </TableWrapper>{" "}
    </PaddedView>
  );
}
