import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { FormLabel, Grid, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import PaddedView from "../../../../../../../components/utils/padded-view.component";
import FormFieldText from "../../../../../../../components/forms/form-field-text.component";
import FormFieldNumber from "../../../../../../../components/forms/form-field-number.component";
import Spacer from "../../../../../../../components/utils/spacer.component";
import FormPrice from "../../../../../../../components/forms/form-price.component";
import OutlinedSelectTextField from "../../../../../../../components/forms/outlined-select-text-field.component";
import { updateSubscriptionPlanDetail } from "../../../../../../../services/merchant/subscription/plan/plan-slice.service";
import { SnackbarContext } from "../../../../../../../components/notification/snackbar.context";
import BusinessListAutoComplete from "../../../../components/business-list-autocomplete.component";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required()
    .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allow")
    .label("Title"),
  description: Yup.string().required().label("Description"),
  shortDescription: Yup.string().required().label("Short Description"),
  numberOfDays: Yup.number().required().label("Number of Days"),
  priceCents: Yup.number().required().label("Price"),
  status: Yup.string().oneOf(["active", "pending", "inactive"]).label("Status"),
  planId: Yup.number().required(),
});

function PlanDetail({ record }) {
  const [allowEdit, setAllowEdit] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);

  const onHandleSubmit = (values) => {
    setAllowEdit(false);
    const payloadValues = {
      ...values,
      businessesId: values.businessesId.map((item) => item.id),
      priceCents: parseFloat(values.priceCents * 100),
    };
    dispatch(updateSubscriptionPlanDetail(payloadValues)).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({ type: "success", message: payload.message });
      }
    });
  };

  return (
    <>
      <PaddedView>
        <Formik
          validationSchema={validationSchema}
          onSubmit={onHandleSubmit}
          initialValues={{
            merchantId: record.merchant.id,
            title: record.title,
            description: record.description,
            shortDescription: record.shortDescription,
            numberOfDays: record.numberOfDays,
            priceCents: record.price.replace(",", ""),
            status: record.status,
            planId: record.id,
            businessesId: record.businesses.map((item) => ({
              id: item.id,
              option: item.name,
            })),
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Grid item container sx={{ width: 200 }}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      <FormLabel>Details</FormLabel>
                    </Grid>
                    <Grid item container xs={6} sx={{ justifyContent: "flex-end" }}>
                      {allowEdit ? (
                        <>
                          <IconButton size="small" onClick={handleSubmit}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setAllowEdit(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAllowEdit(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <Spacer size="m" />
                      <FormFieldText
                        name="title"
                        placeholder="Enter Title"
                        label="Title"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Spacer size="m" />
                      <FormFieldNumber
                        name="numberOfDays"
                        placeholder="Enter Number of Days"
                        label="Number of Days"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormPrice
                        name="priceCents"
                        placeholder="Enter Price"
                        label="Price"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <OutlinedSelectTextField
                        name="status"
                        label="Status"
                        disabled={!allowEdit}
                        selected={record.status}
                        selections={[
                          { type: "Active", value: "active" },
                          { type: "Pending", value: "pending" },
                          { type: "Inactive", value: "inactive" },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Spacer size="m" />
                      <FormFieldText
                        name="description"
                        placeholder="Enter Description"
                        label="Description"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormFieldText
                        name="shortDescription"
                        placeholder="Enter Short Description"
                        label="Short Description"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <BusinessListAutoComplete
                        disabled={!allowEdit}
                        name="businessesId"
                        placeholder="Business Accesses"
                        forEdit={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </PaddedView>
    </>
  );
}

PlanDetail.propTypes = {
  record: PropTypes.shape({
    deletable: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    imagePath: PropTypes.string.isRequired,
    numberOfSubscribers: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    numberOfDays: PropTypes.number.isRequired,
    status: PropTypes.string,
    title: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    merchant: PropTypes.shape({
      id: PropTypes.number.isRequired,
      businessName: PropTypes.string.isRequired,
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default PlanDetail;
