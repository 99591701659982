import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import MerchantDeleteConfirmationModal from "../../../components/notification/confirmation-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableLoader from "../../../components/tables/table-loader.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  deleteMerchant,
  getMerchants,
  merchantSelector,
  shadowLoginAsMerchant,
} from "../../../services/merchant/merchant-slice.service";
import FilterOption from "../components/merchant-list/filter-option.component";
import MerchantListTableIndex from "../components/merchant-list/merchant-list-table-index.component";

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable().label("Search"),
  startDate: Yup.date().label("Start date").nullable().typeError("Invalid date"),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "End date can't be before start date")
    .label("End date")
    .nullable()
    .when("startDate", {
      is: (startDate) => startDate,
      then: Yup.date()
        .min(Yup.ref("startDate"), "End date can't be before start date")
        .label("End date")
        .typeError("End date is required")
        .required(),
    }),
  statuses: Yup.array().nullable().label("Statuses"),
});

export default function MerchantListScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [page, setPage] = useState(0);
  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idToDelete, setIDToDelete] = useState(null);
  const { deleteMerchantObj, getMerchantsObj } = useSelector(merchantSelector);

  useEffect(() => {
    dispatch(getMerchants({ q, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  }, []);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(
      getMerchants({ q, page: newPage, createdStartAt: startAt, createdEndAt: endAt, statuses }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  };

  const onToggleDelete = (merchantId) => {
    setIDToDelete(merchantId);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    dispatch(deleteMerchant(idToDelete)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setQ("");
        setPage(0);
        setShowDeleteModal(false);
        createSnackBar({ type: "success", message: payload.message });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  };

  const onHandleSubmit = (values) => {
    let createdStartAt = "";
    let createdEndAt = "";
    if (values.startDate !== null) {
      createdStartAt = format(new Date(values.startDate), "yyyy-MM-dd 00:00:00");
    }
    if (values.endDate !== null) {
      createdEndAt = format(new Date(values.endDate), "yyyy-MM-dd 23:59:59");
    }
    setQ(values.q);
    setPage(0);
    setStartAt(createdStartAt);
    setEndAt(createdEndAt);
    setStatuses(values.statuses);
    dispatch(
      getMerchants({
        q: values.q,
        page: 0,
        createdStartAt,
        createdEndAt,
        statuses: values.statuses,
      }),
    );
  };

  const shadowLoginToMerchant = (merchantId) => {
    dispatch(shadowLoginAsMerchant(merchantId)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        window.open(`https://merchant.katch.asia/?authToken=${payload.data.authToken}`, "_blank");
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  };

  const renderDataRecords = () => {
    const records = getMerchantsObj.data;

    return (
      <>
        {records.items.length !== 0 ? (
          <PaddedView>
            <MerchantListTableIndex
              handlePageChange={handlePageChange}
              page={page}
              onToggleDelete={onToggleDelete}
              records={records}
              shadowLoginToMerchant={shadowLoginToMerchant}
            />
          </PaddedView>
        ) : (
          <Typography>No merchants.</Typography>
        )}
      </>
    );
  };

  return (
    <Box>
      <MerchantDeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title="Remove merchant"
        message="Are you sure to remove this merchant?"
        onSubmit={onConfirmDelete}
        isDisabled={deleteMerchantObj.status === "pending"}
      />
      <Form
        initialValues={{
          q: "",
          startDate: null,
          endDate: null,
          statuses: [],
        }}
        onSubmit={onHandleSubmit}
        validationSchema={validationSchema}
      >
        <FilterOption />
      </Form>
      <Spacer size="l" />
      {getMerchantsObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </Box>
  );
}
