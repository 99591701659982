import React from "react";
import PropTypes from "prop-types";
import { Button, Typography, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

function VerificationBlocks({ title, submitHandle }) {
  const theme = useTheme();

  return (
    <Grid>
      <Box
        sx={{
          "& .MuiButton-root": {
            borderTopLeftRadius: theme.shape.borderRadius[3],
            borderTopRightRadius: theme.shape.borderRadius[3],
            borderBottomLeftRadius: theme.shape.borderRadius[3],
            borderBottomRightRadius: theme.shape.borderRadius[3],
            textTransform: "none",
            padding: 1,
            "&:hover": {
              backgroundColor: theme.palette.colors.brand.primary,
            },
          },
        }}
      >
        <Button
          variant="contained"
          onClick={() => submitHandle()}
          sx={{ backgroundColor: theme.palette.colors.brand.primary, width: "100%" }}
        >
          <Typography>{title}</Typography>
        </Button>
      </Box>
    </Grid>
  );
}

VerificationBlocks.propTypes = {
  submitHandle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default VerificationBlocks;
