import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Grid, FormLabel, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import * as Yup from "yup";

import PaddedView from "../../../../../../../components/utils/padded-view.component";
import FormImageUpload from "./form-image-upload.component";
import Spacer from "../../../../../../../components/utils/spacer.component";
import { updateSubscriptionPlanImage } from "../../../../../../../services/merchant/subscription/plan/plan-slice.service";
import { SnackbarContext } from "../../../../../../../components/notification/snackbar.context";

const validationSchema = Yup.object().shape({
  image: Yup.string().required().label("Image"),
});

function ImagePlan({ imagePath, planId }) {
  const [filePreview, setFilePreview] = useState(imagePath);
  const [allowEdit, setAllowEdit] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);

  const onHandleSubmit = (values) => {
    setAllowEdit(false);
    const { image } = values;
    dispatch(updateSubscriptionPlanImage({ planId, image })).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({ type: "success", message: payload.message });
      }
    });
  };

  return (
    <>
      <PaddedView>
        <Formik
          onSubmit={onHandleSubmit}
          validationSchema={validationSchema}
          initialValues={{
            image: "",
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Grid container sx={{ width: 200 }}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      <FormLabel>Plan Image</FormLabel>
                    </Grid>
                    <Grid item container xs={6} sx={{ justifyContent: "flex-end" }}>
                      {allowEdit ? (
                        <>
                          <IconButton disabled={!uploadedImage} size="small" onClick={handleSubmit}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setAllowEdit(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAllowEdit(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Spacer size="m" />
                  <FormImageUpload
                    name="image"
                    filePreview={filePreview}
                    setFilePreview={setFilePreview}
                    allowEdit={allowEdit}
                    setUploadedImage={setUploadedImage}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </PaddedView>
    </>
  );
}

ImagePlan.propTypes = {
  imagePath: PropTypes.string.isRequired,
  planId: PropTypes.number.isRequired,
};

export default ImagePlan;
