import React from "react";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player";
import ImageBanner from "./image-banner.component";

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[1],
  boxShadow: 24,
  paddingTop: "50px",
  paddingBottom: "50px",
  paddingLeft: "50px",
  paddingRight: "50px",
  outline: "none",
  width: isMobile ? "375px" : "60%",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
  position: "absolute",
  right: 20,
  top: 20,
}));

const NoDataBox = styled(Box)({
  height: "250px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
});

export default function AttachmentModal({ isShow, setIsShow, attachment, type, setAttachment }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancel = () => {
    setAttachment([]);
    setIsShow(false);
  };

  const renderContent = () => {
    if (attachment.length === 0) {
      return (
        <Grid item xs={12}>
          <NoDataBox>No uploaded attachment</NoDataBox>
        </Grid>
      );
    }
    if (type === "images") {
      return (
        <Grid item xs={12}>
          <ImageBanner attachment={attachment} />
        </Grid>
      );
    }
    if (type === "video") {
      return (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {attachment.map((i) => (
              <ReactPlayer
                key={i.id}
                url={i.path}
                controls
                height={isMobile ? "210.94px" : "350px"}
              />
            ))}
          </Box>
        </Grid>
      );
    }
    return null;
  };

  return (
    <Modal open={isShow} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CloseIconButton onClick={handleCancel}>
              <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
            </CloseIconButton>
          </Grid>
          {renderContent()}
        </Grid>
      </ModalBox>
    </Modal>
  );
}

AttachmentModal.defaultProps = {
  attachment: PropTypes.arrayOf(PropTypes.shape({})),
  type: "",
};

AttachmentModal.propTypes = {
  setAttachment: PropTypes.func.isRequired,
  isShow: PropTypes.bool.isRequired,
  setIsShow: PropTypes.func.isRequired,
  attachment: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string,
};
