import React from "react";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Box, TableContainer, Paper } from "@mui/material";

function TableWrapper({ children }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        "& .MuiPaper-root": {
          borderTopLeftRadius: theme.shape.borderRadius[1],
          borderTopRightRadius: theme.shape.borderRadius[1],
          borderBottomLeftRadius: theme.shape.borderRadius[1],
          borderBottomRightRadius: theme.shape.borderRadius[1],
        },
      }}
    >
      <TableContainer component={Paper}>{children}</TableContainer>
    </Box>
  );
}

TableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableWrapper;
