import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import FormFieldTextMultiline from "../../../../components/forms/form-field-text-multiline.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import { accDeletionLogSelector } from "../../../../services/accDeletion/log/accDeletion-log-slice.service";

const StyledButtonBox = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    width: "100%",
    borderTopLeftRadius: theme.shape.borderRadius[3],
    borderTopRightRadius: theme.shape.borderRadius[3],
    borderBottomLeftRadius: theme.shape.borderRadius[3],
    borderBottomRightRadius: theme.shape.borderRadius[3],
    textTransform: "none",
    backgroundColor: theme.palette.colors.brand.primary,
    "&:hover": {
      backgroundColor: theme.palette.colors.brand.primary,
    },
    "&:disabled": {
      backgroundColor: theme.palette.colors.ui.disabled,
    },
  },
}));

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[3],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  width: isMobile ? "350px" : "450px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

export default function AddLogsModal({ isShow, setIsShowAlert }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { addLogsObj } = useSelector(accDeletionLogSelector);
  const { resetForm } = useFormikContext();

  const handleCancel = () => {
    resetForm();
    setIsShowAlert(false);
  };

  return (
    <Modal open={isShow} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Box sx={{ width: "24px" }} />
              <Typography variant="h5" textAlign="center">
                Add Notes
              </Typography>
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormFieldTextMultiline rows={6} name="notes" placeholder="Add notes for this user." />
          </Grid>
          <Grid item xs={12}>
            <StyledButtonBox>
              <FormSubmitButton disabled={addLogsObj.status === "pending"}>
                <Typography
                  sx={{ color: theme.palette.colors.brand.white, fontWeight: "bold" }}
                  variant="h6"
                >
                  {addLogsObj.status === "pending" ? "Loading" : "Submit"}
                </Typography>
              </FormSubmitButton>
            </StyledButtonBox>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

AddLogsModal.propTypes = {
  isShow: PropTypes.bool.isRequired,
  setIsShowAlert: PropTypes.func.isRequired,
};
