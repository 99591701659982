import React, { useEffect, useState } from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import CustomizedSnackbars from "../../../components/notification/snackbar.component";
import SimpleBackdrop from "../../../components/utils/backdrop-loading.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import PopUpTypeSelectField from "../components/pop-up-type-select-field.component";
import Form from "../../../components/forms/form.component";
import PopUpSlugField from "../components/pop-up-slug-field.component";
import {
  deleteAttachment,
  getPopUpDetail,
  popUpSelector,
  updatePopUpDetail,
  uploadAttachment,
} from "../../../services/pop-up/pop-up-slice.service";
import PopUpImagesUpload from "../components/pop-up-images-upload.component";
import BorderButton from "../../announcement/components/border-button.component";
import PopUpDetailLoader from "../loader/pop-up-detail-loader.component";
import PopUpVideoUpload from "../components/pop-up-video-upload.component";

const validationSchema = Yup.object().shape({
  type: Yup.string().required().label("Type"),
  slug: Yup.string().required().label("Slug"),
});

const FlexEndBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
});

const BrandColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
}));

function PopUpDetailScreen() {
  const location = useLocation();
  const popUpId = location.state.id;
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const dispatch = useDispatch();
  const { getPopUpDetailObj } = useSelector(popUpSelector);
  const navigate = useNavigate();

  const showErrorSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("error");
    setIsShowSnackBar(true);
  };

  const showSuccessSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("success");
    setIsShowSnackBar(true);
  };

  useEffect(() => {
    dispatch(getPopUpDetail(popUpId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  }, []);

  const onAttachmentUpload = (path) => {
    setIsLoading(true);
    dispatch(uploadAttachment({ popUpId, path })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled" && payload.data.type === "images") {
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onAttachmentDelete = (attachmentId) => {
    setIsLoading(true);
    dispatch(deleteAttachment({ popUpId, attachmentId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onUpdatePopUp = (values) => {
    setIsLoading(true);
    dispatch(updatePopUpDetail(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const renderContent = () => {
    if (getPopUpDetailObj.status === "succeeded") {
      return (
        <Form
          initialValues={{
            type: getPopUpDetailObj.data.type,
            slug: getPopUpDetailObj.data.slug,
            popUpId,
          }}
          onSubmit={onUpdatePopUp}
          validationSchema={validationSchema}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PopUpTypeSelectField name="type" label="Choose type..." disabled />
            </Grid>
            <Grid item xs={12}>
              <PopUpSlugField name="slug" placeholder="Slug" showSaveButton />
            </Grid>
            <Grid item xs={12}>
              {getPopUpDetailObj.data.type === "images" && (
                <PopUpImagesUpload
                  name="images"
                  images={getPopUpDetailObj.data.attachments}
                  onImageUpload={onAttachmentUpload}
                  onAttachmentDelete={onAttachmentDelete}
                />
              )}
              {getPopUpDetailObj.data.type === "video" && (
                <PopUpVideoUpload
                  name="video"
                  video={getPopUpDetailObj.data.attachments}
                  onVideoUpload={onAttachmentUpload}
                  onAttachmentDelete={onAttachmentDelete}
                />
              )}
            </Grid>
          </Grid>
        </Form>
      );
    }
    return <PopUpDetailLoader />;
  };

  return (
    <Box>
      <SimpleBackdrop isLoading={isLoading} />
      <CustomizedSnackbars
        isShow={isShowSnackBar}
        setShowSnackbar={setIsShowSnackBar}
        message={snackBarMsg}
        type={snackBarType}
      />

      <PaddedView>
        <Typography variant="h6">Edit Pop Up</Typography>
        <Spacer size="m" position="top" />
        {renderContent()}
        <Spacer size="xl" position="top" />
        <FlexEndBox>
          <BorderButton width="125px" onClickButton={() => navigate("/pop_up")}>
            <BrandColorText>Back</BrandColorText>
          </BorderButton>
        </FlexEndBox>
      </PaddedView>
    </Box>
  );
}

export default PopUpDetailScreen;
