import { Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TableHeader from "../../../components/tables/table-header.component";
import TableWrapper from "../../../components/tables/table-wrapper.component";

function AnnouncementTableLoader() {
  return (
    <TableWrapper>
      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerTitle="Announcement List"
          headerCells={["ID", "Header", "Created At", "Action"]}
        />

        <TableBody>
          <TableRow>
            <TableCell>
              <Skeleton width="60%" />
            </TableCell>
            <TableCell>
              <Skeleton width="60%" />
            </TableCell>
            <TableCell>
              <Skeleton width="60%" />
            </TableCell>

            <TableCell width="200px">
              <Grid container spacing={1}>
                <Grid item>
                  <Skeleton height={20} width={20} circle={true} />
                </Grid>
                <Grid item>
                  <Skeleton height={20} width={20} circle={true} />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableWrapper>
  );
}

export default AnnouncementTableLoader;
