import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, postReq, putReq } from "../../../../api.service";

export const deletePackageDetail = createAsyncThunk(
  "merchants/packages/plans/delete/{plan_id}/detail",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/packages/plans/${payload.planId}/details/${payload.detailId}`,
    );
    return response;
  },
);

export const updatePackageDetail = createAsyncThunk(
  "merchants/packages/plans/update/{plan_id}/detail",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/packages/plans/${payload.planId}/details/${payload.detailId}`,
      { description: payload.description },
    );
    return response;
  },
);

export const createPackageDetail = createAsyncThunk(
  "merchants/packages/plans/create/{plan_id}/detail",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/packages/plans/${payload.planId}/details`,
      { description: payload.description },
    );
    return response;
  },
);

const merchantPackagePlanDetailSlice = createSlice({
  name: "packagePlanDetail",
  initialState: {
    reduxStorePackageDetails: [],
    deletePackageDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePackageDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createPackageDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    setReduxStorePackageDetails: (state, action) => {
      const { payload } = action;
      state.reduxStorePackageDetails = payload;
    },
  },
  extraReducers: {
    [deletePackageDetail.pending]: (state) => {
      state.deletePackageDetailObj.status = "pending";
    },
    [deletePackageDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deletePackageDetailObj.status = "succeeded";
      state.deletePackageDetailObj.data = data;
      state.deletePackageDetailObj.successMessage = message;
    },
    [deletePackageDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.deletePackageDetailObj.status = "failed";
      state.deletePackageDetailObj.errorMessage = message;
    },
    [updatePackageDetail.pending]: (state) => {
      state.updatePackageDetailObj.status = "pending";
    },
    [updatePackageDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePackageDetailObj.status = "succeeded";
      state.updatePackageDetailObj.data = data;
      state.updatePackageDetailObj.successMessage = message;
    },
    [updatePackageDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePackageDetailObj.status = "failed";
      state.updatePackageDetailObj.errorMessage = message;
    },
    [createPackageDetail.pending]: (state) => {
      state.createPackageDetailObj.status = "pending";
    },
    [createPackageDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createPackageDetailObj.status = "succeeded";
      state.createPackageDetailObj.data = data;
      state.createPackageDetailObj.successMessage = message;
    },
    [createPackageDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.createPackageDetailObj.status = "failed";
      state.createPackageDetailObj.errorMessage = message;
    },
  },
});

export default merchantPackagePlanDetailSlice.reducer;

// state
export const merchantPackagePlanDetailSelector = (state) => state.merchantPackagePlanDetail;

export const { setReduxStorePackageDetails } = merchantPackagePlanDetailSlice.actions;
