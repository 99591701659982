import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import CtaButton from "../button/cta-button.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[2],
  boxShadow: 24,
  padding: "50px",
  width: "90%",
  maxWidth: "450px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.text.primary,
  strokeWidth: 2,
  stroke: theme.palette.colors.text.primary,
}));

function ConfirmationModal({
  showModal,
  setShowModal,
  onSubmit,
  message,
  buttonText,
  title,
  isDisabled,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CloseIconButton onClick={() => setShowModal(false)}>
            <CloseIcon />
          </CloseIconButton>
        </Box>

        <Grid container spacing={4}>
          {title && (
            <Grid item xs={12}>
              <Typography variant="h5" textAlign="center">
                {title}
              </Typography>
            </Grid>
          )}

          {message && (
            <Grid item xs={12}>
              <Typography sx={{ color: theme.palette.colors.text.secondary }} textAlign="center">
                {message}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <CtaButton onClickButton={onSubmit} disabled={isDisabled}>
              <Typography>{buttonText}</Typography>
            </CtaButton>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

ConfirmationModal.defaultProps = {
  title: null,
  message: null,
  buttonText: "Confirm",
  isDisabled: false,
};

ConfirmationModal.propTypes = {
  isDisabled: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  title: PropTypes.string,
};

export default ConfirmationModal;
