import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import UserListScreen from "../../features/user/screens/user-list.screen";
import { getUserPermission, getUserRole, isLogin } from "../utils/index";
import EarningListScreen from "../../features/earning/screens/earning-list.screen";
import ClassCategoryListScreen from "../../features/category/screens/class-category-list.screen";
import ClassCategoryEditScreen from "../../features/category/screens/class-category-edit.screen";
import BannerListScreen from "../../features/marketing/screens/banners-marketing-list.screen";
import BannerCreateScreen from "../../features/marketing/screens/banners-marketing-create.screen";
import MerchantListScreen from "../../features/merchant/screens/merchant-list.screen";
import MerchantCreateScreen from "../../features/merchant/screens/merchant-create.screen";
import MerchantLeadsListScreen from "../../features/lead/screens/merchant-leads-list.screen";
import StaffListScreen from "../../features/staff/screens/staff-list.screen";
import StaffNewScreen from "../../features/staff/screens/staff-new.screen";

function PrivateRoute() {
  const location = useLocation();
  const redirectHandle = () => {
    if (isLogin() && getUserRole().some((role) => role === "super-admin")) {
      if (location.pathname === "/") {
        return <EarningListScreen />;
      }
    }

    if (isLogin() && getUserPermission() && location.pathname === "/") {
      switch (getUserPermission()[0]) {
        case "finance-read":
          return <EarningListScreen />;
        case "finance-write":
          return <EarningListScreen />;
        case "general-read":
          return <ClassCategoryListScreen />;
        case "general-write":
          return <ClassCategoryEditScreen />;
        case "marketing-read":
          return <BannerListScreen />;
        case "marketing-write":
          return <BannerCreateScreen />;
        case "merchant-read":
          return <MerchantListScreen />;
        case "merchant-write":
          return <MerchantCreateScreen />;
        case "sale-read":
          return <MerchantLeadsListScreen />;
        case "sale-write":
          return <MerchantLeadsListScreen />;
        case "staff-read":
          return <StaffListScreen />;
        case "staff-write":
          return <StaffNewScreen />;
        case "user-read":
          return <UserListScreen />;
        case "user-write":
          return <UserListScreen />;
        default:
          return <EarningListScreen />;
      }
    }

    if (isLogin()) {
      return <Outlet />;
    }
    return <Navigate to="/login" />;
  };

  return redirectHandle();
}

export default PrivateRoute;
