import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import React from "react";
import PaddedView from "../../../components/utils/padded-view.component";
import FormFieldDebounceText from "../../../components/forms/form-field-debounce-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import MerchantListAutoComplete from "./merchant-list-autocomplete.component";
import BorderCtaButton from "../../../components/button/border-cta-button.component";
import FormDatePicker from "./form-date-picker.component";

function FilterOptions() {
  const theme = useTheme();
  const { resetForm, handleSubmit } = useFormikContext();

  const handleClearForm = () => {
    resetForm();
    handleSubmit();
  };

  return (
    <>
      <PaddedView>
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.colors.bg.secondary,
            borderRadius: `${theme.shape.borderRadius[1]}px`,
          }}
        >
          <Grid item xs={6}>
            <PaddedView>
              <FormFieldDebounceText name="statementId" placeholder="Statement ID" label="Search" />
            </PaddedView>
          </Grid>
          <Grid item xs={6}>
            <PaddedView>
              <MerchantListAutoComplete name="merchantsId" placeholder="Merchant" multiple={true} />
            </PaddedView>
          </Grid>

          <Grid item xs={6}>
            <PaddedView>
              <FormDatePicker
                name="paidStartDate"
                disablePast={false}
                width="100%"
                placeholder="Paid Start Date"
              />
            </PaddedView>
          </Grid>
          <Grid item xs={6}>
            <PaddedView>
              <FormDatePicker
                name="paidEndDate"
                disablePast={false}
                width="100%"
                placeholder="Paid End Date"
              />
            </PaddedView>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <FormSubmitButton type="submit">
                    <Typography>Search</Typography>
                  </FormSubmitButton>
                </PaddedView>
              </Grid>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <BorderCtaButton onClickButton={handleClearForm}>
                    <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                      Clear
                    </Typography>
                  </BorderCtaButton>
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
      <></>
    </>
  );
}

export default FilterOptions;
