import { useTheme } from "@mui/material/styles";
import React from "react";
import { useNavigate } from "react-router-dom";
import BadgeIcon from "@mui/icons-material/Badge";
import CampaignIcon from "@mui/icons-material/Campaign";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import StoreIcon from "@mui/icons-material/Store";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItemButton,
  Typography,
  styled,
} from "@mui/material";
import Spacer from "../../../components/utils/spacer.component";
import { getUserPermission, getUserRole } from "../../utils";

const AccordionDetailsWithoutPadding = styled(AccordionDetails)(({ theme }) => ({
  padding: "0px",
  backgroundColor: theme.palette.darkColors.bg.secondary,
  paddingLeft: theme.spacing(4),
}));

function DrawerItems() {
  const theme = useTheme();
  const navigate = useNavigate();

  const renderUserList = () => (
    <Accordion
      disableGutters
      elevation={0}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
      >
        <PersonIcon sx={{ color: "white" }} />
        <Spacer position="right" size="m" />
        <Typography sx={{ color: theme.palette.darkColors.text.primary }}>Users</Typography>
      </AccordionSummary>
      <AccordionDetailsWithoutPadding>
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some((permission) => permission === "user-read")) && (
          <ListItemButton onClick={() => navigate("/users")}>
            <Typography sx={{ color: theme.palette.darkColors.text.primary }}>User List</Typography>
          </ListItemButton>
        )}
      </AccordionDetailsWithoutPadding>
      <AccordionDetailsWithoutPadding>
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some((permission) => permission === "user-read")) && (
          <ListItemButton onClick={() => navigate("/acc_deletion/list?type=user")}>
            <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
              Account Deletion
            </Typography>
          </ListItemButton>
        )}
      </AccordionDetailsWithoutPadding>
    </Accordion>
  );

  const renderCategories = () => (
    <>
      <Accordion
        disableGutters
        elevation={0}
        sx={{
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <DashboardIcon sx={{ color: theme.palette.darkColors.ui.white }} />
            <Spacer position="right" size="m" />
            <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
              Categories
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetailsWithoutPadding>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Spacer position="right" size="m" />
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Fitness
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetailsWithoutPadding>
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "general-write")) && (
                <ListItemButton onClick={() => navigate("/category/fitness/new")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Create Category
                  </Typography>
                </ListItemButton>
              )}

              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "general-read")) && (
                <ListItemButton onClick={() => navigate("/category/fitness/list")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Category List
                  </Typography>
                </ListItemButton>
              )}
            </AccordionDetailsWithoutPadding>
          </Accordion>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Spacer position="right" size="m" />
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Wellness
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetailsWithoutPadding>
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "general-write")) && (
                <ListItemButton onClick={() => navigate("/category/wellness/new")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Create Category
                  </Typography>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "general-read")) && (
                <ListItemButton onClick={() => navigate("/category/wellness/list")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Category List
                  </Typography>
                </ListItemButton>
              )}
            </AccordionDetailsWithoutPadding>
          </Accordion>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Spacer position="right" size="m" />
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Health
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetailsWithoutPadding>
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "general-write")) && (
                <ListItemButton onClick={() => navigate("/category/health/new")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Create Category
                  </Typography>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "general-read")) && (
                <ListItemButton onClick={() => navigate("/category/health/list")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Category List
                  </Typography>
                </ListItemButton>
              )}
            </AccordionDetailsWithoutPadding>
          </Accordion>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Spacer position="right" size="m" />
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>Event</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetailsWithoutPadding>
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "general-write")) && (
                <ListItemButton onClick={() => navigate("/category/event/new")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Create Category
                  </Typography>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "general-read")) && (
                <ListItemButton onClick={() => navigate("/category/event/list")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Category List
                  </Typography>
                </ListItemButton>
              )}
            </AccordionDetailsWithoutPadding>
          </Accordion>
        </AccordionDetailsWithoutPadding>
      </Accordion>
    </>
  );

  const renderStatements = () => (
    <ListItemButton onClick={() => navigate("/statements/list")}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <RequestQuoteIcon sx={{ color: "white" }} />
        <Spacer position="right" size="m" />
        <Typography sx={{ color: theme.palette.darkColors.text.primary }}>Statements</Typography>
      </Box>
    </ListItemButton>
  );

  const renderEarnings = () => (
    <ListItemButton onClick={() => navigate("/earnings/list")}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <MonetizationOnIcon sx={{ color: "white" }} />
        <Spacer position="right" size="m" />
        <Typography sx={{ color: theme.palette.darkColors.text.primary }}>Earnings</Typography>
      </Box>
    </ListItemButton>
  );

  const renderMarketing = () => (
    <>
      <Accordion
        disableGutters
        elevation={0}
        sx={{
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <CampaignIcon sx={{ color: theme.palette.darkColors.ui.white }} />
            <Spacer position="right" size="m" />
            <Typography sx={{ color: theme.palette.darkColors.text.primary }}>Marketing</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetailsWithoutPadding>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Spacer position="right" size="m" />
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Banners
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetailsWithoutPadding>
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "marketing-write")) && (
                <ListItemButton onClick={() => navigate("/marketing/banners/new")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Create Banner
                  </Typography>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "marketing-read")) && (
                <ListItemButton onClick={() => navigate("/marketing/banners/list")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Banner List
                  </Typography>
                </ListItemButton>
              )}
            </AccordionDetailsWithoutPadding>
          </Accordion>
        </AccordionDetailsWithoutPadding>
        <AccordionDetailsWithoutPadding>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Spacer position="right" size="m" />
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Promo Code
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetailsWithoutPadding>
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "marketing-write")) && (
                <ListItemButton onClick={() => navigate("/promo_codes/new")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Create Promo Code
                  </Typography>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "marketing-read")) && (
                <ListItemButton onClick={() => navigate("/promo_codes/list")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Promo Code List
                  </Typography>
                </ListItemButton>
              )}
            </AccordionDetailsWithoutPadding>
          </Accordion>
        </AccordionDetailsWithoutPadding>
        <AccordionDetailsWithoutPadding>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Spacer position="right" size="m" />
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Notification
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetailsWithoutPadding>
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "marketing-write")) && (
                <ListItemButton onClick={() => navigate("/notifications")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Create Notification
                  </Typography>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "marketing-read")) && (
                <ListItemButton onClick={() => navigate("/notifications/history")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Notification History
                  </Typography>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "marketing-read")) && (
                <ListItemButton onClick={() => navigate("/pop_up")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Pop Up
                  </Typography>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "marketing-write")) && (
                <ListItemButton onClick={() => navigate("/announcement/new")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    New Announcement
                  </Typography>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "marketing-read")) && (
                <ListItemButton onClick={() => navigate("/announcement/list")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Announcement List
                  </Typography>
                </ListItemButton>
              )}
            </AccordionDetailsWithoutPadding>
          </Accordion>
        </AccordionDetailsWithoutPadding>
        <AccordionDetailsWithoutPadding>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Spacer position="right" size="m" />
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Highlight
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetailsWithoutPadding>
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "marketing-write")) && (
                <ListItemButton onClick={() => navigate("/highlight/new")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Create Highlight
                  </Typography>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "marketing-read")) && (
                <ListItemButton onClick={() => navigate("/highlight/list")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Highlight List
                  </Typography>
                </ListItemButton>
              )}
            </AccordionDetailsWithoutPadding>
          </Accordion>
        </AccordionDetailsWithoutPadding>
        <AccordionDetailsWithoutPadding>
          {(getUserRole().some((role) => role === "super-admin") ||
            getUserPermission().some((permission) => permission === "marketing-read")) && (
            <ListItemButton onClick={() => navigate("/attachments")}>
              <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                Attachments
              </Typography>
            </ListItemButton>
          )}
        </AccordionDetailsWithoutPadding>
      </Accordion>
    </>
  );

  const renderStaff = () => (
    <Accordion
      disableGutters
      elevation={0}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
      >
        <BadgeIcon sx={{ color: "white" }} />
        <Spacer position="right" size="m" />
        <Typography sx={{ color: theme.palette.darkColors.text.primary }}>Staffs</Typography>
      </AccordionSummary>
      <AccordionDetailsWithoutPadding>
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some((permission) => permission === "staff-write")) && (
          <ListItemButton onClick={() => navigate("/staffs/new")}>
            <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
              Create Staff
            </Typography>
          </ListItemButton>
        )}
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some((permission) => permission === "staff-read")) && (
          <ListItemButton onClick={() => navigate("/staffs/list")}>
            <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
              Staff List
            </Typography>
          </ListItemButton>
        )}
      </AccordionDetailsWithoutPadding>
    </Accordion>
  );

  const renderLeads = () => (
    <Accordion
      disableGutters
      elevation={0}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
      >
        <PhoneIcon sx={{ color: "white" }} />
        <Spacer position="right" size="m" />
        <Typography sx={{ color: theme.palette.darkColors.text.primary }}>Leads</Typography>
      </AccordionSummary>
      <AccordionDetailsWithoutPadding>
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some(
            (permission) => permission === "sale-read" || permission === "sale-write",
          )) && (
          <ListItemButton onClick={() => navigate("/leads/merchants")}>
            <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
              Merchant Leads
            </Typography>
          </ListItemButton>
        )}
      </AccordionDetailsWithoutPadding>
    </Accordion>
  );

  const renderCredits = () => (
    <Accordion
      disableGutters
      elevation={0}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
      >
        <CardGiftcardIcon sx={{ color: "white" }} />
        <Spacer position="right" size="m" />
        <Typography sx={{ color: theme.palette.darkColors.text.primary }}>Credits</Typography>
      </AccordionSummary>
      <AccordionDetailsWithoutPadding>
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some(
            (permission) => permission === "finance-read" || permission === "finance-write",
          )) && (
          <>
            <ListItemButton onClick={() => navigate("/credits/transactions/new")}>
              <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                Add Credit
              </Typography>
            </ListItemButton>

            <ListItemButton onClick={() => navigate("/credits/transactions/deduct")}>
              <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                Deduct Credit
              </Typography>
            </ListItemButton>

            <ListItemButton onClick={() => navigate("/credits/transactions/list")}>
              <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                Transaction List
              </Typography>
            </ListItemButton>
          </>
        )}
      </AccordionDetailsWithoutPadding>
    </Accordion>
  );

  const renderMerchant = () => (
    <Accordion
      disableGutters
      elevation={0}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
      >
        <StoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />
        <Spacer position="right" size="m" />
        <Typography sx={{ color: theme.palette.darkColors.text.primary }}>Merchants</Typography>
      </AccordionSummary>
      <AccordionDetailsWithoutPadding>
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some((permission) => permission === "merchant-write")) && (
          <ListItemButton onClick={() => navigate("/merchants/create")}>
            <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
              Create Merchant
            </Typography>
          </ListItemButton>
        )}

        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some((permission) => permission === "merchant-read")) && (
          <ListItemButton onClick={() => navigate("/merchants/list")}>
            <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
              Merchant List
            </Typography>
          </ListItemButton>
        )}

        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some((permission) => permission === "merchant-read")) && (
          <ListItemButton onClick={() => navigate("/merchants/business/list")}>
            <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
              Businesses
            </Typography>
          </ListItemButton>
        )}
      </AccordionDetailsWithoutPadding>

      <AccordionDetailsWithoutPadding>
        <Accordion
          disableGutters
          elevation={0}
          sx={{
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                Additional Fees
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetailsWithoutPadding sx={{ paddingLeft: theme.spacing(2) }}>
            {(getUserRole().some((role) => role === "super-admin") ||
              getUserPermission().some((permission) => permission === "merchant-read")) && (
              <ListItemButton onClick={() => navigate("/merchants/additional_fees/new")}>
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Create Additional Fees
                </Typography>
              </ListItemButton>
            )}
          </AccordionDetailsWithoutPadding>
          <AccordionDetailsWithoutPadding sx={{ paddingLeft: theme.spacing(2) }}>
            {(getUserRole().some((role) => role === "super-admin") ||
              getUserPermission().some((permission) => permission === "merchant-read")) && (
              <ListItemButton onClick={() => navigate("/merchants/additional_fees/list")}>
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Additional Fees List
                </Typography>
              </ListItemButton>
            )}
          </AccordionDetailsWithoutPadding>
        </Accordion>
      </AccordionDetailsWithoutPadding>

      <AccordionDetailsWithoutPadding>
        <Accordion
          disableGutters
          elevation={0}
          sx={{
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                Packages
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetailsWithoutPadding sx={{ paddingLeft: theme.spacing(2) }}>
            {(getUserRole().some((role) => role === "super-admin") ||
              getUserPermission().some((permission) => permission === "merchant-read")) && (
              <ListItemButton onClick={() => navigate("/merchants/packages/purchasers")}>
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Purchasers
                </Typography>
              </ListItemButton>
            )}
          </AccordionDetailsWithoutPadding>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Spacer position="right" size="m" />
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>Plans</Typography>
              </Box>
            </AccordionSummary>

            <AccordionDetailsWithoutPadding>
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "merchant-write")) && (
                <ListItemButton onClick={() => navigate("/merchants/packages/plans/new")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Create Plan
                  </Typography>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "merchant-read")) && (
                <ListItemButton onClick={() => navigate("/merchants/packages/plans/list")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Plan List
                  </Typography>
                </ListItemButton>
              )}
            </AccordionDetailsWithoutPadding>
          </Accordion>
        </Accordion>
      </AccordionDetailsWithoutPadding>
      <AccordionDetailsWithoutPadding>
        <Accordion
          disableGutters
          elevation={0}
          sx={{
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                Subscriptions
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetailsWithoutPadding sx={{ paddingLeft: theme.spacing(2) }}>
            {(getUserRole().some((role) => role === "super-admin") ||
              getUserPermission().some((permission) => permission === "merchant-read")) && (
              <ListItemButton onClick={() => navigate("/merchants/subscriptions/subscribers")}>
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Subscribers
                </Typography>
              </ListItemButton>
            )}
          </AccordionDetailsWithoutPadding>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Spacer position="right" size="m" />
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>Plans</Typography>
              </Box>
            </AccordionSummary>

            <AccordionDetailsWithoutPadding>
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "merchant-write")) && (
                <ListItemButton onClick={() => navigate("/merchants/subscriptions/plans/new")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Create Plan
                  </Typography>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "merchant-read")) && (
                <ListItemButton onClick={() => navigate("/merchants/subscriptions/plans/list")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Plan List
                  </Typography>
                </ListItemButton>
              )}
            </AccordionDetailsWithoutPadding>
          </Accordion>
        </Accordion>
      </AccordionDetailsWithoutPadding>
      <AccordionDetailsWithoutPadding>
        <Accordion
          disableGutters
          elevation={0}
          sx={{
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                Fitness Classes
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetailsWithoutPadding sx={{ paddingLeft: theme.spacing(2) }}>
            {(getUserRole().some((role) => role === "super-admin") ||
              getUserPermission().some((permission) => permission === "merchant-write")) && (
              <ListItemButton onClick={() => navigate("/merchants/fitness_class/new")}>
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Create Class
                </Typography>
              </ListItemButton>
            )}
          </AccordionDetailsWithoutPadding>
          <AccordionDetailsWithoutPadding sx={{ paddingLeft: theme.spacing(2) }}>
            {(getUserRole().some((role) => role === "super-admin") ||
              getUserPermission().some((permission) => permission === "merchant-read")) && (
              <ListItemButton onClick={() => navigate("/merchants/fitness_class/list")}>
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Class List
                </Typography>
              </ListItemButton>
            )}
          </AccordionDetailsWithoutPadding>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Spacer position="right" size="m" />
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Sessions
                </Typography>
              </Box>
            </AccordionSummary>

            <AccordionDetailsWithoutPadding>
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "merchant-write")) && (
                <ListItemButton onClick={() => navigate("/merchants/fitness_class/sessions/new")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Create Session
                  </Typography>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "super-admin") ||
                getUserPermission().some((permission) => permission === "merchant-read")) && (
                <ListItemButton onClick={() => navigate("/merchants/fitness_class/sessions/list")}>
                  <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                    Session List
                  </Typography>
                </ListItemButton>
              )}
            </AccordionDetailsWithoutPadding>
          </Accordion>
        </Accordion>
      </AccordionDetailsWithoutPadding>
      <AccordionDetailsWithoutPadding>
        <Accordion
          disableGutters
          elevation={0}
          sx={{
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.darkColors.ui.white }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ backgroundColor: theme.palette.darkColors.bg.secondary }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                Hourly Bookings
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetailsWithoutPadding sx={{ paddingLeft: theme.spacing(2) }}>
            {(getUserRole().some((role) => role === "super-admin") ||
              getUserPermission().some((permission) => permission === "merchant-read")) && (
              <ListItemButton onClick={() => navigate("/merchants/hourly_booking/list")}>
                <Typography sx={{ color: theme.palette.darkColors.text.primary }}>
                  Booking List
                </Typography>
              </ListItemButton>
            )}
          </AccordionDetailsWithoutPadding>
        </Accordion>
      </AccordionDetailsWithoutPadding>
    </Accordion>
  );

  return (
    <>
      <List sx={{ paddingBottom: "100px" }}>
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some(
            (permission) => permission === "finance-read" || permission === "finance-write",
          )) &&
          renderEarnings()}
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some(
            (permission) => permission === "finance-read" || permission === "finance-write",
          )) &&
          renderStatements()}
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some(
            (permission) => permission === "general-read" || permission === "general-write",
          )) &&
          renderCategories()}

        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some(
            (permission) => permission === "merchant-read" || permission === "merchant-write",
          )) &&
          renderMerchant()}
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some(
            (permission) => permission === "user-read" || permission === "user-write",
          )) &&
          renderUserList()}
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some(
            (permission) => permission === "staff-read" || permission === "staff-write",
          )) &&
          renderStaff()}
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some(
            (permission) => permission === "marketing-read" || permission === "marketing-write",
          )) &&
          renderMarketing()}
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some(
            (permission) => permission === "sale-read" || permission === "sale-write",
          )) &&
          renderLeads()}
        {(getUserRole().some((role) => role === "super-admin") ||
          getUserPermission().some(
            (permission) => permission === "finance-read" || permission === "finance-write",
          )) &&
          renderCredits()}
      </List>
    </>
  );
}

export default DrawerItems;
