import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableHead, TableRow, IconButton, Typography, Box } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { v4 as uuidv4 } from "uuid";
import Spacer from "../../../../components/utils/spacer.component";

function TableHeader({ headerTitle, headerCells, sortCate, setSortCate }) {
  const renderTableCell = (headCell) => {
    switch (headCell) {
      case "Name":
        return (
          <>
            <Box sx={{ display: "flex", paddingTop: 1 }}>
              <Typography>{headCell}</Typography>
              <Spacer position="right" />
              <IconButton
                size="small"
                sx={{ marginTop: "-5px", marginLeft: "-10px" }}
                onClick={() =>
                  setSortCate({
                    name: sortCate.name === "asc" ? "dsc" : "asc",
                    email: "idle",
                    role: "idle",
                    createdAt: "idle",
                  })
                }
              >
                {sortCate.name === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Box>
          </>
        );
      case "Email":
        return (
          <>
            <Box sx={{ display: "flex", paddingTop: 1 }}>
              <Typography>{headCell}</Typography>
              <Spacer position="right" />
              <IconButton
                size="small"
                sx={{ marginTop: "-5px", marginLeft: "-10px" }}
                onClick={() =>
                  setSortCate({
                    name: "idle",
                    email: sortCate.email === "asc" ? "dsc" : "asc",
                    role: "idle",
                    createdAt: "idle",
                  })
                }
              >
                {sortCate.email === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Box>
          </>
        );
      case "Created At":
        return (
          <>
            <Box sx={{ display: "flex", paddingTop: 1 }}>
              <Typography>{headCell}</Typography>
              <Spacer position="right" />
              <IconButton
                size="small"
                sx={{ marginTop: "-5px", marginLeft: "-10px" }}
                onClick={() =>
                  setSortCate({
                    name: "idle",
                    email: "idle",
                    role: "idle",
                    createdAt: sortCate.createdAt === "asc" ? "dsc" : "asc",
                  })
                }
              >
                {sortCate.createdAt === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Box>
          </>
        );
      default:
        return <Typography>{headCell}</Typography>;
    }
  };

  return (
    <>
      <TableHead>
        {!!headerCells.length && (
          <>
            <TableRow>
              {headerCells.map((headCell, index) => (
                <TableCell key={uuidv4()}>
                  <>{index === 0 && <Typography variant="h6">{headerTitle}</Typography>}</>
                </TableCell>
              ))}
            </TableRow>
            <TableRow
              sx={{
                "& .MuiTableCell-root": {
                  borderTop: "1px solid #e0e0e0",
                },
              }}
            >
              {headerCells.map((headCell) => (
                <TableCell key={headCell}>{renderTableCell(headCell)}</TableCell>
              ))}
            </TableRow>
          </>
        )}
      </TableHead>
    </>
  );
}

TableHeader.defaultProps = {
  setSortCate: () => {},
  sortCate: {},
};

TableHeader.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  headerCells: PropTypes.arrayOf(
    PropTypes.oneOf(["Image", "Name", "Email", "Role", "Gym Access", "Created At", "Action"]),
  ).isRequired,
  sortCate: PropTypes.shape({
    name: PropTypes.oneOf(["idle", "asc", "dsc"]),
    email: PropTypes.oneOf(["idle", "asc", "dsc"]),
    role: PropTypes.oneOf(["idle", "asc", "dsc"]),
    createdAt: PropTypes.oneOf(["idle", "asc", "dsc"]),
  }),
  setSortCate: PropTypes.func,
};

export default TableHeader;
