import { Box, Grid, styled, Typography } from "@mui/material";
import React from "react";

import Skeleton from "react-loading-skeleton";
import CenteredRowBox from "../../../components/utils/centered-row-box.component";
import DetailCardContainer from "../../../components/utils/detail-card-container.component";
import Spacer from "../../../components/utils/spacer.component";
import PromoRedemptionListLoader from "./promo-redemption-list-loader.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

const FlexStartRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

export default function PromoCodeEditLoader() {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Typography variant="h4">Edit Promo Code</Typography>
          </SpaceBetweenBox>
        </Grid>
        <Spacer size="l" position="top" />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DetailCardContainer>
              <Grid item xs={12}>
                <CenteredRowBox>
                  <Typography>Code</Typography>
                  <Spacer size="s" position="left" />
                </CenteredRowBox>
                <Spacer size="xs" position="top" />
                <Skeleton width="100%" height="40px" />
              </Grid>
            </DetailCardContainer>
          </Grid>
          <Grid item xs={12}>
            <DetailCardContainer>
              <Grid item xs={12}>
                <CenteredRowBox>
                  <Typography>Title</Typography>
                  <Spacer size="s" position="left" />
                </CenteredRowBox>
                <Spacer size="xs" position="top" />
                <Skeleton width="100%" height="40px" />
              </Grid>
            </DetailCardContainer>
          </Grid>
          <Grid item xs={12}>
            <DetailCardContainer>
              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                <CenteredRowBox>
                  <Typography>Description</Typography>
                  <Spacer size="s" position="left" />
                </CenteredRowBox>
                <Spacer size="xs" position="top" />
                <Skeleton width="100%" height="200px" />
              </Box>
            </DetailCardContainer>
          </Grid>
          <Grid item xs={12}>
            <DetailCardContainer>
              <CenteredRowBox>
                <Typography>Promo Period</Typography>
              </CenteredRowBox>
              <Spacer size="m" position="top" />
              <FlexStartRowBox>
                <Grid item xs={6}>
                  <Typography>Start Date</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={6}>
                  <Typography>End Date</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
              <Spacer size="l" position="top" />
              <FlexStartRowBox>
                <Grid item xs={6}>
                  <Typography>Start Time</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={6}>
                  <Typography>End Time</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
            </DetailCardContainer>
          </Grid>
          <Grid item xs={12}>
            <DetailCardContainer>
              <CenteredRowBox>
                <Typography>Promo Details</Typography>
              </CenteredRowBox>
              <Spacer size="m" position="top" />
              <FlexStartRowBox>
                <Grid item xs={4}>
                  <Typography>Type</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={4}>
                  <Typography>Discount</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={4}>
                  <Typography>Max cap amount</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
              <Spacer size="l" position="top" />
              <FlexStartRowBox>
                <Grid item xs={4}>
                  <Typography>Max No. Claims</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={4}>
                  <Typography>Max No. Claims Per User</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={4}>
                  <Typography>Min Spent Amount</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
              <Spacer size="l" position="top" />
              <FlexStartRowBox>
                <Grid item xs={4}>
                  <Typography>New User Only</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={4}>
                  <Typography>Hourly Booking Specific</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={4} />
              </FlexStartRowBox>
              <Spacer size="l" position="top" />
              <FlexStartRowBox>
                <Grid item xs={4}>
                  <Typography>User Specific</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={12}>
                  <Typography>Specific User List</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
              <Spacer size="l" position="top" />
              <FlexStartRowBox>
                <Grid item xs={4}>
                  <Typography>Merchant Specific</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={12}>
                  <Typography>Specific Merchant List</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
              <Spacer size="l" position="top" />
              <FlexStartRowBox>
                <Grid item xs={4}>
                  <Typography>Business Specific</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={12}>
                  <Typography>Specific Business List</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
              <Spacer size="l" position="top" />
              <FlexStartRowBox>
                <Grid item xs={4}>
                  <Typography>Class Specific</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={12}>
                  <Typography>Specific Class List</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
              <Spacer size="l" position="top" />
              <FlexStartRowBox>
                <Grid item xs={4}>
                  <Typography>Package Plan Specific</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={12}>
                  <Typography>Specific Package Plan List</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
              <Spacer size="l" position="top" />
              <FlexStartRowBox>
                <Grid item xs={4}>
                  <Typography>Subscription Plan Specific</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
                <Spacer size="xl" position="left" />
                <Grid item xs={12}>
                  <Typography>Specific Subscription Plan List</Typography>
                  <Spacer size="xs" position="top" />
                  <Skeleton width="100%" height="40px" />
                </Grid>
              </FlexStartRowBox>
            </DetailCardContainer>
          </Grid>
          <PromoRedemptionListLoader />
          <Grid item xs={12} />
        </Grid>
      </Grid>
    </Grid>
  );
}
