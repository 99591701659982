import { Box, Breadcrumbs, FormLabel, Grid, Link, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormPrice from "../../../../components/forms/form-price.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import SimpleBackdrop from "../../../../components/utils/backdrop-loading.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import { addCreditTransactions } from "../../../../services/credit/credit-slice.service";
import UserListAutoComplete from "../components/user-list-autocomplete.component";

const validationSchema = Yup.object().shape({
  users: Yup.array().label("Users").nullable(),
  price: Yup.number().required().label("Price"),
});

function CreditTransactionsNewScreen() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const createSnackBar = useContext(SnackbarContext);

  const onHandleSubmit = (values) => {
    setIsLoading(true);
    const payloadValues = {
      ...values,
      userIds: values.users.map((item) => item.id),
      amountCents: parseFloat(values.price * 100),
    };
    dispatch(addCreditTransactions(payloadValues)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({ type: "success", message: payload.message });
        navigate("/credits/transactions/list");
      }
    });
  };

  return (
    <>
      <SimpleBackdrop isLoading={isLoading} />
      <Box>
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Add Credit</Typography>
            </Grid>

            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link underline="hover" key="2" color="inherit" href="/credits/transactions/list">
                    <Typography variant="body1">Credit Transactions List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    Add Credit
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>

            <Formik
              validationSchema={validationSchema}
              initialValues={{
                users: [],
                price: "",
              }}
              onSubmit={onHandleSubmit}
            >
              {({ isValid }) => (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Grid item container sx={{ width: 200 }}>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            alignSelf: "center",
                          }}
                        >
                          <FormLabel>Details</FormLabel>
                        </Grid>
                      </Grid>

                      <Grid item container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Spacer size="m" />
                          <UserListAutoComplete
                            name="users"
                            placeholder="Select users"
                            multiple={true}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Spacer size="m" />
                          <FormPrice
                            name="price"
                            placeholder="Enter Price"
                            label="Price"
                            disabled={false}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Grid container justifyContent="flex-end">
                            <Grid item sm={2} xs={12}>
                              <FormSubmitButton type="submit" disabled={!isValid}>
                                <Typography>Add</Typography>
                              </FormSubmitButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
        </PaddedView>
      </Box>
    </>
  );
}

export default CreditTransactionsNewScreen;
