import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { putReq, getReq, postReq, delReq } from "../../api.service";

export const searchMerchantBusiness = createAsyncThunk(
  "merchant/business/search",
  async (payload) => {
    const { page, q, createdStartAt, createdEndAt, statuses } = payload;
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/businesses?page=${page + 1}${
        q ? "&q=".concat(q) : ""
      }${createdStartAt ? "&createdStartAt=".concat(createdStartAt) : ""}${
        createdEndAt ? "&createdEndAt=".concat(createdEndAt) : ""
      }${statuses ? "&statuses=".concat(statuses) : ""}`,
    );
    return response;
  },
);

export const createMerchantBusiness = createAsyncThunk(
  "merchant/business/create",
  async (payload) => {
    const response = postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${payload.merchantId}/businesses/`,
      payload,
    );
    return response;
  },
);

export const getMerchantBusinessDetail = createAsyncThunk(
  "merchant/business/detail",
  async (payload) => {
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${payload.merchantId}/businesses/${payload.businessId}`,
    );
    return response;
  },
);

export const updateMerchantBusinessDetail = createAsyncThunk(
  "merchant/business/update",
  async (payload) => {
    const response = putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${payload.merchantId}/businesses/${payload.businessId}`,
      payload,
    );
    return response;
  },
);

export const deleteMerchantBusiness = createAsyncThunk(
  "merchant/business/delete",
  async (payload) => {
    const response = delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/${payload.merchantId}/businesses/${payload.businessId}`,
    );
    return response;
  },
);

export const getMerchantBusinessAutoCompleteSuggestion = createAsyncThunk(
  "merchant/business/auto_complete",
  async (payload) => {
    const { q, merchantsId, page } = payload;

    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/businesses/auto_complete?${
        page ? "page=".concat(page) : "page=1"
      }${q ? "&q=".concat(q) : ""}${merchantsId ? "&merchantsId=".concat(merchantsId) : ""}`,
    );
    return response;
  },
);

export const downloadMerchantBusinessesEmailList = createAsyncThunk(
  "merchant/business/email_list",
  async (payload) => {
    const { q, createdStartAt, createdEndAt, statuses } = payload;
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/businesses/email_list?${
        q ? "q=".concat(q) : ""
      }${createdStartAt ? "&createdStartAt=".concat(createdStartAt) : ""}${
        createdEndAt ? "&createdEndAt=".concat(createdEndAt) : ""
      }${statuses ? "&statuses=".concat(statuses) : ""}`,
    );
    return response;
  },
);

const merchantBusinessSlice = createSlice({
  name: "merchantBusiness",
  initialState: {
    searchMerchantBusinessObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createMerchantBusinessObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMerchantBusinessDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateMerchantBusinessDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteMerchantBusinessObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMerchantBusinessAutoCompleteSuggestionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    downloadMerchantBusinessesEmailListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [searchMerchantBusiness.pending]: (state) => {
      state.searchMerchantBusinessObj.status = "pending";
    },
    [searchMerchantBusiness.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchMerchantBusinessObj.status = "succeeded";
      state.searchMerchantBusinessObj.data = data;
      state.searchMerchantBusinessObj.successMessage = message;
    },
    [searchMerchantBusiness.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchMerchantBusinessObj.status = "failed";
      state.searchMerchantBusinessObj.errorMessage = message;
    },
    [createMerchantBusiness.pending]: (state) => {
      state.createMerchantBusinessObj.status = "pending";
    },
    [createMerchantBusiness.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createMerchantBusinessObj.status = "succeeded";
      state.createMerchantBusinessObj.data = data;
      state.createMerchantBusinessObj.successMessage = message;
    },
    [createMerchantBusiness.rejected]: (state, action) => {
      const { message } = action.error;

      state.createMerchantBusinessObj.status = "failed";
      state.createMerchantBusinessObj.errorMessage = message;
    },
    [getMerchantBusinessDetail.pending]: (state) => {
      state.getMerchantBusinessDetailObj.status = "pending";
    },
    [getMerchantBusinessDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantBusinessDetailObj.status = "succeeded";
      state.getMerchantBusinessDetailObj.data = data;
      state.getMerchantBusinessDetailObj.successMessage = message;
    },
    [getMerchantBusinessDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantBusinessDetailObj.status = "failed";
      state.getMerchantBusinessDetailObj.errorMessage = message;
    },
    [updateMerchantBusinessDetail.pending]: (state) => {
      state.updateMerchantBusinessDetailObj.status = "pending";
    },
    [updateMerchantBusinessDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateMerchantBusinessDetailObj.status = "succeeded";
      state.updateMerchantBusinessDetailObj.data = data;
      state.updateMerchantBusinessDetailObj.successMessage = message;

      state.getMerchantBusinessDetailObj.data = data;
    },
    [updateMerchantBusinessDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateMerchantBusinessDetailObj.status = "failed";
      state.updateMerchantBusinessDetailObj.errorMessage = message;
    },
    [deleteMerchantBusiness.pending]: (state) => {
      state.deleteMerchantBusinessObj.status = "pending";
    },
    [deleteMerchantBusiness.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteMerchantBusinessObj.status = "succeeded";
      state.deleteMerchantBusinessObj.data = data;
      state.deleteMerchantBusinessObj.successMessage = message;

      state.searchMerchantBusinessObj.data = data;
    },
    [deleteMerchantBusiness.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteMerchantBusinessObj.status = "failed";
      state.deleteMerchantBusinessObj.errorMessage = message;
    },
    [getMerchantBusinessAutoCompleteSuggestion.pending]: (state) => {
      state.getMerchantBusinessAutoCompleteSuggestionObj.status = "pending";
    },
    [getMerchantBusinessAutoCompleteSuggestion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantBusinessAutoCompleteSuggestionObj.status = "succeeded";
      state.getMerchantBusinessAutoCompleteSuggestionObj.data = data;
      state.getMerchantBusinessAutoCompleteSuggestionObj.successMessage = message;

      state.searchMerchantBusinessObj.data = data;
    },
    [getMerchantBusinessAutoCompleteSuggestion.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantBusinessAutoCompleteSuggestionObj.status = "failed";
      state.getMerchantBusinessAutoCompleteSuggestionObj.errorMessage = message;
    },
    [downloadMerchantBusinessesEmailList.pending]: (state) => {
      state.downloadMerchantBusinessesEmailListObj.status = "pending";
    },
    [downloadMerchantBusinessesEmailList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.downloadMerchantBusinessesEmailListObj.status = "succeeded";
      state.downloadMerchantBusinessesEmailListObj.data = data;
      state.downloadMerchantBusinessesEmailListObj.successMessage = message;
    },
    [downloadMerchantBusinessesEmailList.rejected]: (state, action) => {
      const { message } = action.error;

      state.downloadMerchantBusinessesEmailListObj.status = "failed";
      state.downloadMerchantBusinessesEmailListObj.errorMessage = message;
    },
  },
});

export default merchantBusinessSlice.reducer;

// state
export const merchantBusinessSelector = (state) => state.merchantBusiness;
