import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../../../api.service";

export const getMerchantBusinessAmenities = createAsyncThunk(
  "merchant/business/amenity/index",
  async () => {
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/businesses/amenities`,
    );
    return response;
  },
);

const merchantBusinessAmenitySlice = createSlice({
  name: "merchantBusinessAmenity",
  initialState: {
    getMerchantBusinessAmenitiesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getMerchantBusinessAmenities.pending]: (state) => {
      state.getMerchantBusinessAmenitiesObj.status = "pending";
    },
    [getMerchantBusinessAmenities.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantBusinessAmenitiesObj.status = "succeeded";
      state.getMerchantBusinessAmenitiesObj.data = data;
      state.getMerchantBusinessAmenitiesObj.successMessage = message;
    },
    [getMerchantBusinessAmenities.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantBusinessAmenitiesObj.status = "failed";
      state.getMerchantBusinessAmenitiesObj.errorMessage = message;
    },
  },
});

export default merchantBusinessAmenitySlice.reducer;

// state
export const merchantBusinessAmenitySelector = (state) => state.merchantBusinessAmenity;
