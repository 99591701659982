import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import * as Yup from "yup";
import { format } from "date-fns";
import {
  userSelector,
  getUsersList,
  getUserAutoCompleteSuggestion,
  shadowLoginAsUser,
} from "../../../services/user/user-slice.service";
import Spacer from "../../../components/utils/spacer.component";
import TableIndex from "../components/user-list/table-index.component";

import Form from "../../../components/forms/form.component";
import FilterOption from "../components/user-list/filter-option.component";
import PaddedView from "../../../components/utils/padded-view.component";
import TableLoader from "../../../components/tables/table-loader.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable().label("Search"),
  registeredStartDate: Yup.date().label("Start date").nullable().typeError("Invalid date"),
  registeredEndDate: Yup.date()
    .min(Yup.ref("registeredStartDate"), "End date can't be before start date")
    .label("End date")
    .nullable()
    .when("registeredStartDate", {
      is: (startDate) => startDate,
      then: Yup.date()
        .min(Yup.ref("registeredStartDate"), "End date can't be before start date")
        .label("End date")
        .typeError("End date is required")
        .required(),
    }),
});

function UserListScreen() {
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchRegisteredStartDate, setSearchRegisteredStartDate] = useState(null);
  const [searchRegisteredEndDate, setSearchRegisteredEndDate] = useState(null);
  const { getUsersListObj, getUserAutoCompleteSuggestionObj } = useSelector(userSelector);
  const createSnackBar = useContext(SnackbarContext);

  useEffect(() => {
    dispatch(getUsersList(page));
  }, []);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);

    let registeredStartDate = "";
    let registeredEndDate = "";

    if (searchRegisteredStartDate !== null || searchRegisteredStartDate !== "") {
      registeredStartDate = format(new Date(searchRegisteredStartDate), "yyyy-MM-dd");
    }
    if (searchRegisteredEndDate !== null) {
      registeredEndDate = format(new Date(searchRegisteredEndDate), "yyyy-MM-dd");
    }

    const payload = {
      q: searchKeyword,
      registeredStartDate,
      registeredEndDate,
    };

    if (
      (searchKeyword.length > 0 && searchKeyword !== "") ||
      registeredStartDate !== "" ||
      registeredEndDate !== ""
    ) {
      dispatch(getUserAutoCompleteSuggestion({ ...payload, page: newPage }));
      return;
    }

    dispatch(getUsersList(newPage));
  };

  const onHandleSubmit = (values) => {
    let registeredStartDate = "";
    let registeredEndDate = "";

    if (values.registeredStartDate !== null) {
      registeredStartDate = format(new Date(values.registeredStartDate), "yyyy-MM-dd");
      setSearchRegisteredStartDate(registeredStartDate);
    }
    if (values.registeredEndDate !== null) {
      registeredEndDate = format(new Date(values.registeredEndDate), "yyyy-MM-dd");
      setSearchRegisteredEndDate(registeredEndDate);
    }

    const payload = {
      q: values.q,
      registeredStartDate,
      registeredEndDate,
    };

    setSearchKeyword(values.q);
    dispatch(getUserAutoCompleteSuggestion({ ...payload, page }));
  };

  const shadowLoginToUser = (userId) => {
    dispatch(shadowLoginAsUser(userId)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        window.open(`https://www.katch.asia/home/?authToken=${payload.data.authToken}`, "_blank");
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  };

  const renderDataRecords = () => {
    let records = null;

    if (getUserAutoCompleteSuggestionObj.data) {
      records = getUserAutoCompleteSuggestionObj.data;
    } else if (getUsersListObj.data) {
      records = getUsersListObj.data;
    }

    return (
      <>
        {records ? (
          <PaddedView>
            <TableIndex
              page={page}
              handlePageChange={handlePageChange}
              records={records}
              shadowLoginToUser={shadowLoginToUser}
            />
          </PaddedView>
        ) : (
          <Typography>No users.</Typography>
        )}
      </>
    );
  };

  return (
    <>
      <Form
        initialValues={{ q: "", registeredStartDate: null, registeredEndDate: null }}
        onSubmit={onHandleSubmit}
        validationSchema={validationSchema}
      >
        <FilterOption />
      </Form>
      <Spacer size="l" />
      {getUsersListObj.status === "succeeded" ||
      getUserAutoCompleteSuggestionObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </>
  );
}

export default UserListScreen;
