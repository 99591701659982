import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import PaddedView from "../../../../../../../components/utils/padded-view.component";
import { merchantPackagePlanDetailSelector } from "../../../../../../../services/merchant/package/plan/detail/plan-detail-slice.service";
import PackageDescriptionAddModal from "./package-description-add-modal.component";
import PackageDescriptionDetail from "./package-description-detail.component";

function PlanDescription({ record }) {
  const [allowEditDetail, setAllowEditDetail] = useState(false);
  const { reduxStorePackageDetails } = useSelector(merchantPackagePlanDetailSelector);
  return (
    <PaddedView>
      <Grid container spacing={2}>
        <PackageDescriptionAddModal
          planId={record.id}
          allowEditDetail={allowEditDetail}
          setAllowEditDetail={setAllowEditDetail}
        />

        {reduxStorePackageDetails.length > 0 && (
          <>
            {reduxStorePackageDetails.map((detail) => (
              <PackageDescriptionDetail
                key={detail.id}
                detail={detail}
                planId={record.id}
                allowEditDetail={allowEditDetail}
              />
            ))}
          </>
        )}
      </Grid>
    </PaddedView>
  );
}

PlanDescription.propTypes = {
  record: PropTypes.shape({
    deletable: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    imagePath: PropTypes.string.isRequired,
    numberOfPurchasers: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    priceCents: PropTypes.number.isRequired,
    numberOfSessions: PropTypes.number.isRequired,
    status: PropTypes.string,
    title: PropTypes.string.isRequired,
    merchant: PropTypes.shape({
      id: PropTypes.number.isRequired,
      businessName: PropTypes.string.isRequired,
    }),
    planDetails: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
      }),
    ).isRequired,
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ).isRequired,
    category: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
          imagePath: PropTypes.string,
        }),
      ),
    }).isRequired,
  }).isRequired,
};

export default PlanDescription;
