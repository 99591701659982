import React, { useState } from "react";
import { Grid, Typography, Box, useMediaQuery, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import KatchLogo from "../../../assets/images/katch-logo";

import { login } from "../../../services/auth/auth-slice.service";

import LoginBackdrop from "../../../assets/images/login-backdrop.png";
import BackdropImage from "../../../components/utils/backdrop-image.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import Form from "../../../components/forms/form.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import CustomizedSnackbars from "../../../components/notification/snackbar.component";

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(4).label("Password"),
});

function LoginScreen() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");

  const handleSubmit = (values) => {
    dispatch(login(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        navigate("/");
      }
      if (meta.requestStatus === "rejected") {
        setSnackBarMsg(error.message);
        setSnackBarType("error");
        setIsShowSnackBar(true);
      }
    });
  };

  return (
    <BackdropImage bgImg={LoginBackdrop}>
      <CustomizedSnackbars
        isShow={isShowSnackBar}
        setShowSnackbar={setIsShowSnackBar}
        message={snackBarMsg}
        type={snackBarType}
      />
      <Grid container sx={{ height: "100vh", flexDirection: "column", fle: 1 }}>
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid item sx={{ height: isMobile ? 45 : 60, textAlign: isMobile ? "center" : null }}>
            <KatchLogo />
          </Grid>
          <Grid
            item
            sx={{
              height: `calc(100vh - ${isMobile ? "45px - 16px - 16px" : "60px - 32px - 32px"})`,
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              <Box>
                <Box
                  sx={{
                    backgroundColor: theme.palette.darkColors.bg.tertiary,
                    borderRadius: `${theme.shape.borderRadius[2]}px`,
                    flex: 1,
                    maxWidth: 500,
                  }}
                >
                  <Form
                    initialValues={{ email: "", password: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <Typography variant="h5">Sign in</Typography>
                      <Spacer size="xs" />
                      <Typography variant="body2">Katch Admin Panel</Typography>

                      <Spacer size="l" />
                      <FormFieldText name="email" placeholder="Email" label="Email" />

                      <Spacer size="m" />
                      <FormFieldText name="password" placeholder="Password" label="Password" />

                      <Grid>
                        <Grid container justifyContent="flex-end">
                          <Grid>
                            <Link
                              sx={{ paddingLeft: 1 }}
                              underline="always"
                              key="1"
                              variant="body2"
                              href="/forgot_password"
                            >
                              Forgot Password
                            </Link>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Spacer size="l" />
                      <FormSubmitButton>
                        <Typography>Login</Typography>
                      </FormSubmitButton>
                    </PaddedView>
                  </Form>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </BackdropImage>
  );
}

export default LoginScreen;
