import React, { useState } from "react";
import { Grid, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";

import KatchLogo from "../../../assets/images/katch-logo";

import { forgotPassword, resetPassword } from "../../../services/auth/auth-slice.service";

import LoginBackdrop from "../../../assets/images/login-backdrop.png";
import BackdropImage from "../../../components/utils/backdrop-image.component";
import PaddedView from "../../../components/utils/padded-view.component";
import EmailForm from "../components/enter-email-form.component";
import VerificationForm from "../components/enter-verification-form.component";
import PasswordUpdate from "../components/password-updated.component";
import CustomizedSnackbars from "../../../components/notification/snackbar.component";
import LoadingScreen from "../../../components/utils/backdrop-loading.component";

function LoginScreen() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentPage, setCurrentPage] = useState("Enter Email");
  const [userEmail, setUserEmail] = useState("");
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (values) => {
    setIsLoading(true);
    dispatch(forgotPassword(values)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setCurrentPage("Enter Verification Code");
      } else {
        setSnackBarMsg(error.message);
        setSnackBarType("error");
        setIsShowSnackBar(true);
      }
    });
  };

  const resendVerificationCode = (values) => {
    dispatch(forgotPassword(values)).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        setCurrentPage("Enter Verification Code");
        setSnackBarMsg("New Verification Code has been sent");
        setSnackBarType("success");
        setIsShowSnackBar(true);
      }
    });
  };

  const finalSubmissionHandle = (values) => {
    setIsLoading(true);
    values.email = userEmail;
    dispatch(resetPassword(values)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setCurrentPage("Password Updated");
      } else {
        setSnackBarMsg(error.message);
        setSnackBarType("error");
        setIsShowSnackBar(true);
      }
    });
  };

  const backToVerification = () => {
    setCurrentPage("");
  };

  const renderContent = () => {
    if (currentPage === "Enter Verification Code") {
      return (
        <VerificationForm
          userEmail={userEmail}
          resendVerificationCode={resendVerificationCode}
          backButtonHandle={backToVerification}
          handleSubmit={finalSubmissionHandle}
        />
      );
    }

    if (currentPage === "Password Updated") {
      return <PasswordUpdate />;
    }
    return <EmailForm handleSubmit={handleSubmit} setUserEmail={setUserEmail} />;
  };

  return (
    <BackdropImage bgImg={LoginBackdrop}>
      <CustomizedSnackbars
        isShow={isShowSnackBar}
        setShowSnackbar={setIsShowSnackBar}
        message={snackBarMsg}
        type={snackBarType}
      />
      <LoadingScreen isLoading={isLoading} />
      <Grid container sx={{ height: "100vh", flexDirection: "column", fle: 1 }}>
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid item sx={{ height: isMobile ? 45 : 60, textAlign: isMobile ? "center" : null }}>
            <KatchLogo />
          </Grid>
          <Grid
            item
            sx={{
              height: `calc(100vh - ${isMobile ? "45px - 16px - 16px" : "60px - 32px - 32px"})`,
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              <Box>
                <Box
                  sx={{
                    backgroundColor: theme.palette.darkColors.bg.tertiary,
                    borderRadius: `${theme.shape.borderRadius[2]}px`,
                    flex: 1,
                    maxWidth: 500,
                    minWidth: 350,
                  }}
                >
                  {renderContent()}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </BackdropImage>
  );
}

export default LoginScreen;
