import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Box, Typography, Grid, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import PaddedView from "../../../components/utils/padded-view.component";
import LoadingSpinner from "../../../components/utils/backdrop-loading.component";
import CustomizedSnackbars from "../../../components/notification/snackbar.component";
import Spacer from "../../../components/utils/spacer.component";
import ImageUrlConverter from "../components/image-url-converter.component";
import Form from "../../../components/forms/form.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import {
  attachmentSelector,
  deleteAttachment,
  getAttachmentDetail,
  getAttachmentList,
  updateAttachment,
  uploadAttachment,
} from "../../../services/attachment/attachment-slice.service";
import AttachmentTableIndex from "../components/attachment-table-index.component";
import AlertDialog from "../../../components/notification/dialog-boxes.component";
import AttachmentScreenLoader from "../loaders/attachment-screen-loader.component";
import AttachmentEditModal from "../components/attachment-edit-modal.component";

const validationSchema = Yup.object().shape({
  title: Yup.string().required().label("Title"),
  path: Yup.string().required().label("Image"),
});

const ColumnSpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
});

const ButtonFlexEndBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "10px",
});

function AttachmentScreen() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [filePreview, setFilePreview] = useState("");
  const { getAttachmentListObj, deleteAttachmentObj } = useSelector(attachmentSelector);
  const [page, setPage] = useState(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [onClickedAttachmentId, setOnClickedAttachmentId] = useState("");
  const [showAttachmentEditModal, setShowAttachmentEditModal] = useState(false);
  const [editAttachmentId, setEditAttachmentId] = useState("");

  const showErrorSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("error");
    setIsShowSnackBar(true);
  };

  const showSuccessSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("success");
    setIsShowSnackBar(true);
  };

  const onEditClicked = (attachmentId) => {
    dispatch(getAttachmentDetail(attachmentId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
    setEditAttachmentId(attachmentId.toString());
    setShowAttachmentEditModal(true);
  };

  const refreshAttachmentList = () => {
    dispatch(getAttachmentList(page)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onConfirmUpdate = (values) => {
    setIsLoading(true);
    dispatch(updateAttachment(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowAttachmentEditModal(false);
        refreshAttachmentList();
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  useEffect(() => {
    refreshAttachmentList();
  }, []);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getAttachmentList(newPage)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onUploadAttachment = (values, { resetForm }) => {
    setIsLoading(true);
    dispatch(uploadAttachment(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        setFilePreview("");
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onHandleDelete = (attachmentId) => {
    setOnClickedAttachmentId(attachmentId);
    setShowDeleteDialog(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteAttachment(onClickedAttachmentId)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteDialog(false);
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const renderUploadAttachment = () => (
    <Form
      initialValues={{ title: "", path: "" }}
      onSubmit={onUploadAttachment}
      validationSchema={validationSchema}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>Upload a new attachment</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <ImageUrlConverter
                name="path"
                filePreview={filePreview}
                setFilePreview={setFilePreview}
              />
            </Grid>
            <Grid item xs={9}>
              <ColumnSpaceBetweenBox>
                <FormFieldText name="title" placeholder="Write the title here" label="Title" />
                <ButtonFlexEndBox>
                  <FormSubmitButton width="125px">
                    <Typography>Add</Typography>
                  </FormSubmitButton>
                </ButtonFlexEndBox>
              </ColumnSpaceBetweenBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );

  const renderContent = () => {
    if (getAttachmentListObj.status === "succeeded") {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {renderUploadAttachment()}
          </Grid>
          <Grid item xs={12}>
            <AttachmentTableIndex
              showSuccessSnackBar={showSuccessSnackBar}
              records={getAttachmentListObj.data}
              page={page}
              handlePageChange={handlePageChange}
              onHandleDelete={onHandleDelete}
              onEditClicked={onEditClicked}
            />
            <AttachmentEditModal
              onConfirmUpdate={onConfirmUpdate}
              editAttachmentId={editAttachmentId}
              setEditAttachmentId={setEditAttachmentId}
              isShow={showAttachmentEditModal}
              setIsShow={setShowAttachmentEditModal}
            />
          </Grid>
        </Grid>
      );
    }
    return <AttachmentScreenLoader />;
  };

  return (
    <Box>
      <CustomizedSnackbars
        isShow={isShowSnackBar}
        setShowSnackbar={setIsShowSnackBar}
        message={snackBarMsg}
        type={snackBarType}
      />
      <AlertDialog
        isShow={showDeleteDialog}
        setIsShowAlert={setShowDeleteDialog}
        isConfirmHandle={onConfirmDelete}
        title="Confirmation"
        message="Are you sure to delete ?"
        buttonText="Confirm"
        disabled={deleteAttachmentObj.status === "pending"}
      />
      <LoadingSpinner isLoading={isLoading} />
      <PaddedView>
        <Typography variant="h6">Attachments</Typography>
        <Spacer size="m" position="top" />
        {renderContent()}
      </PaddedView>
    </Box>
  );
}

export default AttachmentScreen;
