import { Grid } from "@mui/material";
import React from "react";
import FormFieldNumber from "../../../../../components/forms/form-field-number.component";
import FormPickerDateTime from "../../../../../components/forms/form-picker-date-time.component";
import FormPrice from "../../../../../components/forms/form-price.component";
import ClassListAutoComplete from "./class-list-autocomplete.component";
import StaffListAutoComplete from "./staff-list-autocomplete.component";

function OneOffCreateSessionForm() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={10}>
        <ClassListAutoComplete name="selectedClass" placeholder="Class" multiple={false} />
      </Grid>
      <Grid item xs={12} sm={5}>
        <FormPickerDateTime name="startAt" label="*Start At" disablePast={true} />
      </Grid>
      <Grid item xs={12} sm={5}>
        <FormPickerDateTime name="endAt" label="*End At" disablePast={true} />
      </Grid>
      <Grid item xs={12} sm={5}>
        <FormFieldNumber name="maxCapacity" placeholder="Enter maximum capacity" label="Capacity" />
      </Grid>
      <Grid item xs={12} sm={5}>
        <FormPrice name="price" placeholder="Enter price" label="Price" />
      </Grid>
      <Grid item xs={12}>
        <StaffListAutoComplete
          disabled={false}
          name="staffsId"
          placeholder="Staffs"
          forEdit={true}
        />
      </Grid>
    </Grid>
  );
}

export default OneOffCreateSessionForm;
