import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow, Typography } from "@mui/material";

function NotificationHistoryRow({ record }) {
  return (
    <TableRow key={record.id}>
      <TableCell width="300px">
        <Typography variant="body2" noWrap={true} sx={{ textTransform: "capitalize" }}>
          {record.consumer.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.type === "announcement" ? record.announcement.header : record.popUp.slug}
        </Typography>
      </TableCell>
      <TableCell width="200px">
        <Typography variant="body2" noWrap={true}>
          {record.notifiableType.toUpperCase().includes("TRAINER") ? "Trainer" : "User"}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

NotificationHistoryRow.defaultProps = {
  record: {
    consumer: {
      id: null,
      name: "",
    },
    announcement: {
      content: "",
      createdAt: "",
      header: "",
      id: null,
      subheader: "",
    },
    id: null,
    notifiableType: "",
    popUp: {
      id: null,
      type: "",
      slug: "",
      attachments: [
        {
          id: null,
          type: "",
          path: "",
        },
      ],
    },
    read: false,
    type: "",
  },
};

NotificationHistoryRow.propTypes = {
  record: PropTypes.shape({
    announcement: PropTypes.shape({
      content: PropTypes.string,
      createdAt: PropTypes.string,
      header: PropTypes.string,
      id: PropTypes.number,
      subheader: PropTypes.string,
    }),
    consumer: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    id: PropTypes.number,
    notifiableType: PropTypes.string,
    popUp: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      slug: PropTypes.string,
      attachments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          type: PropTypes.string,
          path: PropTypes.string,
        }),
      ),
    }),
    read: PropTypes.bool,
    type: PropTypes.string,
  }),
};

export default NotificationHistoryRow;
