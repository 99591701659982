import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Box,
  Breadcrumbs,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Link,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { Field, FieldArray, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../../../components/button/cta-button.component";
import FormFieldNumber from "../../../../../components/forms/form-field-number.component";
import FormFieldText from "../../../../../components/forms/form-field-text.component";
import FormMultiSelect from "../../../../../components/forms/form-multi-select.component";
import FormPrice from "../../../../../components/forms/form-price.component";
import FormSubmitButton from "../../../../../components/forms/form-submit-button.component";
import OutlinedSelectTextField from "../../../../../components/forms/outlined-select-text-field.component";
import { SnackbarContext } from "../../../../../components/notification/snackbar.context";
import SimpleBackdrop from "../../../../../components/utils/backdrop-loading.component";
import PaddedView from "../../../../../components/utils/padded-view.component";
import Spacer from "../../../../../components/utils/spacer.component";
import {
  classCategorySelector,
  getCategoryList,
} from "../../../../../services/category/class-category-slice.service";
import { createPackagePlan } from "../../../../../services/merchant/package/plan/plan-slice.service";
import BusinessListAutoComplete from "../../components/business-list-autocomplete.component";
import MerchantListAutoComplete from "../../components/merchant-list-autocomplete.component";
import FormImageUpload from "../components/plan/image/form-image-upload.component";
import SubscriptionPlanSpecificListAutoComplete from "../../components/subscription-plan-specific-list-autocomplete.component";

const validationSchema = Yup.object().shape({
  merchantId: Yup.number().required().label("Merchant"),
  title: Yup.string()
    .required()
    .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allow")
    .min(2)
    .label("Title"),
  categoryType: Yup.string().required().label("Category Type"),
  categoryIds: Yup.array().min(1).required().label("Category(s)"),
  noOfSessions: Yup.number().required().min(1).label("No of sessions"),
  businessesId: Yup.array().min(1).required().label("Business Access"),
  subscriptionPlansId: Yup.array().label("Subscription Plans"),
  details: Yup.array()
    .of(Yup.string().required("Description is required"))
    .min(1)
    .required()
    .label("Descriptions"),
  price: Yup.number().required().min(1).label("Price"),
  status: Yup.string().required().label("Status"),
  image: Yup.string().required().label("Image"),
});

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const DetailTextField = styled(Field)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius[0],
  padding: "10px",
  font: "inherit",
  backgroundColor: theme.palette.colors.bg.primary,
  border: "2px solid #D0D0D0",
  "& .MuiOutlinedInput-root": {
    borderRadius: theme.shape.borderRadius[0],
    backgroundColor: "transparent",
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
  "&:hover": {
    border: "2px solid black",
  },
}));

function PlanNewScreen() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [filePreview, setFilePreview] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const createSnackBar = useContext(SnackbarContext);
  const { getCategoryListObj } = useSelector(classCategorySelector);

  useEffect(() => {
    dispatch(getCategoryList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const getClassCategoriesSelection = () => {
    const itemList = [];
    getCategoryListObj.data.map((item) => itemList.push({ label: item.label, value: item.id }));
    return itemList;
  };

  const onHandleSubmit = (values) => {
    setIsLoading(true);
    const payloadValues = {
      ...values,
      category: {
        type: values.categoryType,
        ids: values.categoryIds,
      },
      businessesId: values.businessesId.map((item) => item.id),
      subscriptionPlansId: values.subscriptionPlansId.map((item) => item.id),
      priceCents: parseFloat(values.price * 100),
    };

    dispatch(createPackagePlan(payloadValues)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
      if (meta.requestStatus === "fulfilled") {
        navigate("/merchants/packages/plans/list");
      }
    });
  };

  return (
    <>
      <SimpleBackdrop isLoading={isLoading} />
      <Box>
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">New Plan</Typography>
            </Grid>

            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link
                    underline="hover"
                    key="2"
                    color="inherit"
                    href="/merchants/packages/plans/list"
                  >
                    <Typography variant="body1">Plan List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    New Plan
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>

            <Formik
              validationSchema={validationSchema}
              initialValues={{
                merchantId: null,
                selectedMerchant: null,
                title: "",
                price: 0,
                noOfSessions: 0,
                categoryType: "fitness",
                categoryIds: [],
                businessesId: [],
                subscriptionPlansId: [],
                status: "pending",
                image: "",
                details: [],
              }}
              onSubmit={onHandleSubmit}
            >
              {({ isValid, values, errors }) => (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Grid item container sx={{ width: 200 }}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          alignSelf: "center",
                        }}
                      >
                        <FormLabel>Plan Image</FormLabel>
                      </Grid>
                      <FormImageUpload
                        name="image"
                        filePreview={filePreview}
                        setFilePreview={setFilePreview}
                        allowEdit={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Grid item container sx={{ width: 200 }}>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          alignSelf: "center",
                        }}
                      >
                        <FormLabel>Details</FormLabel>
                      </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                      <Grid item xs={12} sm={5}>
                        <Spacer size="m" />
                        <MerchantListAutoComplete
                          name="selectedMerchant"
                          placeholder="Select a merchant"
                          disabled={false}
                          multiple={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <Spacer size="m" />
                        <FormFieldText
                          name="title"
                          placeholder="Enter Title"
                          label="Title"
                          disabled={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <FormPrice
                          name="price"
                          placeholder="Enter Price"
                          label="Price"
                          disabled={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <FormFieldNumber
                          name="noOfSessions"
                          placeholder="Enter number of sessions"
                          label="Number of Sessions"
                          disabled={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <OutlinedSelectTextField
                          name="status"
                          label="Status"
                          disabled={false}
                          selections={[
                            { type: "Active", value: "active" },
                            { type: "Pending", value: "pending" },
                            { type: "Inactive", value: "inactive" },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <OutlinedSelectTextField
                          name="categoryType"
                          label="Category Type"
                          disabled={false}
                          selections={[{ type: "Fitness", value: "fitness" }]}
                        />
                      </Grid>
                      {getCategoryListObj.status === "succeeded" ? (
                        <Grid item xs={12}>
                          <FormMultiSelect
                            disabled={false}
                            name="categoryIds"
                            label="Category(s)"
                            options={getClassCategoriesSelection()}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <Skeleton width="100%" height={56} />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <BusinessListAutoComplete
                          disabled={false}
                          name="businessesId"
                          placeholder="Business Accesses"
                          forEdit={false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SubscriptionPlanSpecificListAutoComplete
                          disabled={false}
                          name="subscriptionPlansId"
                          placeholder="Subscription Plans"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sm={12} spacing={2}>
                    <FieldArray name="details">
                      {({ push, remove }) => (
                        <>
                          <Grid item xs={12}>
                            <FormContainer>
                              <LabelContainer>
                                <SpaceBetweenBox>
                                  <FormLabel>Descriptions</FormLabel>
                                  <Spacer position="left" />
                                  <CtaButton onClickButton={() => push("")}>
                                    <Typography>Add</Typography>
                                  </CtaButton>
                                </SpaceBetweenBox>
                              </LabelContainer>
                            </FormContainer>
                          </Grid>

                          {values.details.map((_, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Grid container item key={index} display="flex" alignItems="center">
                              <Grid item xs={11.5}>
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                  <DetailTextField
                                    name={`details[${index}]`}
                                    placeholder="Enter package details"
                                    type="text"
                                  />
                                  {errors.details && (
                                    <FormHelperText error sx={{ marginLeft: "10px" }}>
                                      {errors.details[0]}
                                    </FormHelperText>
                                  )}
                                </Box>
                              </Grid>
                              <Grid item xs={0.5}>
                                <IconButton
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  <RemoveCircleIcon
                                    sx={{ color: theme.palette.colors.text.error }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid container justifyContent="flex-end">
                      <Grid item sm={2} xs={12}>
                        <FormSubmitButton type="submit" disabled={!isValid}>
                          <Typography>Create</Typography>
                        </FormSubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </Grid>
        </PaddedView>
      </Box>
    </>
  );
}

export default PlanNewScreen;
