import { Box, FormHelperText, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format } from "date-fns";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

function FormPickerDateTime({ name, label, disabled, disablePast }) {
  const { setFieldValue, values, touched, errors } = useFormikContext();
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (date) => {
    setFieldValue(name, format(date, "yyyy-MM-dd HH:mm:ss"));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <DateTimePicker
          disablePast={disablePast}
          disabled={disabled}
          inputFormat="dd/MM/yyyy   HH:mm:ss"
          value={values[name]}
          views={["year", "month", "day", "hours", "minutes", "seconds"]}
          onChange={(date) => {
            handleChange(date);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={showError}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderTopLeftRadius: theme.shape.borderRadius[1],
                  borderTopRightRadius: theme.shape.borderRadius[1],
                  borderBottomLeftRadius: theme.shape.borderRadius[1],
                  borderBottomRightRadius: theme.shape.borderRadius[1],
                  backgroundColor: theme.palette.colors.bg.primary,
                },
              }}
            />
          )}
        />
        {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      </Box>
    </LocalizationProvider>
  );
}

FormPickerDateTime.defaultProps = {
  disabled: false,
  disablePast: false,
};

FormPickerDateTime.propTypes = {
  disablePast: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default FormPickerDateTime;
