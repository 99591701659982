import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api.service";

export const createPopUp = createAsyncThunk("pop_up/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/pop_ups`,
    payload,
  );
  return response;
});

export const getPopUpList = createAsyncThunk("pop_up/list", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/pop_ups?page=${payload.page + 1}&q=${
      payload.q
    }`,
  );
  return response;
});

export const deletePopUp = createAsyncThunk("pop_up/delete", async (payload) => {
  const response = await delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/pop_ups/${payload}`,
  );
  return response;
});

export const getPopUpDetail = createAsyncThunk("pop_up/detail", async (popUpId) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/pop_ups/${popUpId}`,
  );
  return response;
});

export const updatePopUpDetail = createAsyncThunk("pop_up/update_detail", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/pop_ups/${payload.popUpId}`,
    payload,
  );
  return response;
});

export const uploadAttachment = createAsyncThunk("pop_up/upload_attachment", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/pop_ups/${payload.popUpId}/attachments`,
    payload,
  );
  return response;
});

export const deleteAttachment = createAsyncThunk("pop_up/delete_attachment", async (payload) => {
  const response = await delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/pop_ups/${payload.popUpId}/attachments/${payload.attachmentId}`,
    payload,
  );
  return response;
});

const popUpSlice = createSlice({
  name: "popUp",
  initialState: {
    createPopUpObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPopUpListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deletePopUpObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPopUpDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePopUpDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    uploadAttachmentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteAttachmentObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [createPopUp.pending]: (state) => {
      state.createPopUpObj.status = "pending";
    },
    [createPopUp.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createPopUpObj.status = "succeeded";
      state.createPopUpObj.data = data;
      state.createPopUpObj.successMessage = message;
    },
    [createPopUp.rejected]: (state, action) => {
      const { message } = action.error;

      state.createPopUpObj.status = "failed";
      state.createPopUpObj.errorMessage = message;
    },
    [getPopUpList.pending]: (state) => {
      state.getPopUpListObj.status = "pending";
    },
    [getPopUpList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPopUpListObj.status = "succeeded";
      state.getPopUpListObj.data = data;
      state.getPopUpListObj.successMessage = message;
    },
    [getPopUpList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPopUpListObj.status = "failed";
      state.getPopUpListObj.errorMessage = message;
    },
    [deletePopUp.pending]: (state) => {
      state.deletePopUpObj.status = "pending";
    },
    [deletePopUp.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deletePopUpObj.status = "succeeded";
      state.deletePopUpObj.data = data;
      state.deletePopUpObj.successMessage = message;
    },
    [deletePopUp.rejected]: (state, action) => {
      const { message } = action.error;

      state.deletePopUpObj.status = "failed";
      state.deletePopUpObj.errorMessage = message;
    },
    [getPopUpDetail.pending]: (state) => {
      state.getPopUpDetailObj.status = "pending";
    },
    [getPopUpDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPopUpDetailObj.status = "succeeded";
      state.getPopUpDetailObj.data = data;
      state.getPopUpDetailObj.successMessage = message;
    },
    [getPopUpDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPopUpDetailObj.status = "failed";
      state.getPopUpDetailObj.errorMessage = message;
    },
    [updatePopUpDetail.pending]: (state) => {
      state.updatePopUpDetailObj.status = "pending";
    },
    [updatePopUpDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePopUpDetailObj.status = "succeeded";
      state.updatePopUpDetailObj.data = data;
      state.updatePopUpDetailObj.successMessage = message;
    },
    [updatePopUpDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePopUpDetailObj.status = "failed";
      state.updatePopUpDetailObj.errorMessage = message;
    },
    [uploadAttachment.pending]: (state) => {
      state.uploadAttachmentObj.status = "pending";
    },
    [uploadAttachment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.uploadAttachmentObj.status = "succeeded";
      state.uploadAttachmentObj.data = data;
      state.uploadAttachmentObj.successMessage = message;

      state.getPopUpDetailObj.data = data;
    },
    [uploadAttachment.rejected]: (state, action) => {
      const { message } = action.error;

      state.uploadAttachmentObj.status = "failed";
      state.uploadAttachmentObj.errorMessage = message;
    },
    [deleteAttachment.pending]: (state) => {
      state.deleteAttachmentObj.status = "pending";
    },
    [deleteAttachment.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteAttachmentObj.status = "succeeded";
      state.deleteAttachmentObj.data = data;
      state.deleteAttachmentObj.successMessage = message;

      state.getPopUpDetailObj.data = data;
    },
    [deleteAttachment.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteAttachmentObj.status = "failed";
      state.deleteAttachmentObj.errorMessage = message;
    },
  },
});

export default popUpSlice.reducer;

// state
export const popUpSelector = (state) => state.popUp;
