import {
  Box,
  Grid,
  IconButton,
  Link,
  styled,
  useTheme,
  Table,
  TableBody,
  Pagination,
  TextField,
  Typography,
  Stack,
  Breadcrumbs,
} from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";

import {
  getPromoCodeDetail,
  updatePromoCode,
  deletePromoCode,
  getPromoCodeRedemptionList,
  promoCodeSelector,
} from "../../../services/promo-code/promo-code-slice.services";
import PromoDetailsForm from "../components/promo-details-form.component";
import PromoPeriodForm from "../components/promo-period-form.component";
import PromoCodeRedemptionRow from "../components/promo-code-redemption-row.component";
import PromoCodeEditLoader from "../loader/promo-edit-loader.component";
import PromoRedemptionListLoader from "../loader/promo-redemption-list-loader.component";
import DetailCardContainer from "../../../components/utils/detail-card-container.component";
import PaddedView from "../../../components/utils/padded-view.component";
import CenteredRowBox from "../../../components/utils/centered-row-box.component";
import TableHeader from "../../../components/tables/table-header.component";
import Spacer from "../../../components/utils/spacer.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import SimpleBackdrop from "../../../components/utils/backdrop-loading.component";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-multiline.component";
import BorderCtaButton from "../../../components/button/border-cta-button.component";
import ConfirmationModal from "../../../components/notification/confirmation-modal.component";
import FormFieldTextWithCounter from "../../../components/forms/form-field-text-with-counter.component";
import TableSort from "../../../components/tables/table-sort.component";

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginTop: "20px",
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.text.white,
  },
}));

const titleValidationSchema = Yup.object().shape({
  title: Yup.string().required().max(50).label("Title"),
});

const descriptionValidationSchema = Yup.object().shape({
  description: Yup.string().required().label("Description"),
});

const promoPeriodValidationSchema = Yup.object().shape({
  startDate: Yup.date().label("Start Date").nullable().typeError("Invalid date"),
  startTime: Yup.date().required().label("Start Time"),
  endDate: Yup.date()
    .label("End Date")
    .min(Yup.ref("startDate"), "End date can't earlier than start date")
    .required(),
  endTime: Yup.date().label("End Time").required(),
});

const promoRulesValidationSchema = Yup.object().shape({
  type: Yup.string().label("Type").required(),
  discount: Yup.number()
    .required()
    .when("type", {
      is: "percentage",
      then: Yup.number().integer().min(0).max(100).required(),
    })
    .when("type", {
      is: "fixed amount",
      then: Yup.number().min(1).required(),
    })
    .label("Discount"),
  maxCapAmount: Yup.number().when("type", {
    is: "percentage",
    then: Yup.number().min(0).required(),
  }),
  maxNoClaims: Yup.number().min(0).required(),
  maxNoClaimsPerUser: Yup.number().min(0).required(),
  newUserOnly: Yup.string().label("New User Only").required(),
  minSpentAmount: Yup.number().min(0).required(),
  userSpecific: Yup.bool().label("Users Specific").required(),
  usersList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("userSpecific", {
      is: true,
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific User List"),
  merchantSpecific: Yup.bool().label("Merchants Specific").required(),
  merchantsList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("merchantSpecific", {
      is: true,
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific Merchant List"),
  businessSpecific: Yup.bool().label("Business Specific").required(),
  businessesList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("businessSpecific", {
      is: true,
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific Business List"),
  classSpecific: Yup.bool().label("Class Specific").required(),
  classesList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("classSpecific", {
      is: true,
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific Class List"),
  packagePlanSpecific: Yup.bool().label("Package Plan Specific").required(),
  packagePlansList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("packagePlanSpecific", {
      is: true,
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific Package Plan List"),
  subscriptionPlanSpecific: Yup.bool().label("Subscription Plan Specific").required(),
  subscriptionPlansList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("subscriptionPlanSpecific", {
      is: true,
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific Subscription Plan List"),
  hourlyBookingSpecific: Yup.bool().label("Hourly Booking Specific").required(),
});

export default function PromoCodeEditScreen() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const createSnackBar = useContext(SnackbarContext);
  const location = useLocation();
  const navigate = useNavigate();
  const promoCodeId = new URLSearchParams(location.search).get("promoCodeId");
  const { getPromoCodeDetailObj, deletePromoCodeObj, getPromoCodeRedemptionListObj } =
    useSelector(promoCodeSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState({
    title: false,
    description: false,
    promoPeriod: false,
    promoRules: false,
  });
  const formRef = useRef();
  const titleFormRef = useRef();
  const descriptionFormRef = useRef();
  const [page, setPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const columnMapping = {
    "Redemption ID": "id",
    "User Id": "userId",
    Username: "username",
    Status: "status",
    "Date Redeemed": "dateRedeemed",
    "Discount Amount": "discountAmount",
    "Purchase Item": "purchaseItem",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  useEffect(() => {
    dispatch(getPromoCodeDetail(promoCodeId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    dispatch(getPromoCodeRedemptionList({ promoCodeId, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const onUpdatePromoCode = (values) => {
    setIsLoading(true);
    const payloadValues = { ...values };

    if (values.params === "promoRules") {
      if (!values.userSpecific) {
        payloadValues.usersList = [];
      } else {
        payloadValues.usersList = payloadValues.usersList.map((item) => item.id);
      }
      if (!values.merchantSpecific) {
        payloadValues.merchantsList = [];
      } else {
        payloadValues.merchantsList = payloadValues.merchantsList.map((item) => item.id);
      }
      if (!values.businessSpecific) {
        payloadValues.businessesList = [];
      } else {
        payloadValues.businessesList = payloadValues.businessesList.map((item) => item.id);
      }
      if (!values.classSpecific) {
        payloadValues.classesList = [];
      } else {
        payloadValues.classesList = payloadValues.classesList.map((item) => item.id);
      }
      if (!values.subscriptionPlanSpecific) {
        payloadValues.subscriptionPlansList = [];
      } else {
        payloadValues.subscriptionPlansList = payloadValues.subscriptionPlansList.map(
          (item) => item.id,
        );
      }
      if (!values.packagePlanSpecific) {
        payloadValues.packagePlansList = [];
      } else {
        payloadValues.packagePlansList = payloadValues.packagePlansList.map((item) => item.id);
      }
      if (values.type === "fixed amount") payloadValues.maxCapAmount = values.discount;
    } else if (values.params === "promoPeriod") {
      payloadValues.startAt = `${format(new Date(values.startDate), "yyyy-MM-dd")} ${format(
        new Date(values.startTime),
        "HH:mm:00",
      )}`;
      payloadValues.endAt = `${format(new Date(values.endDate), "yyyy-MM-dd")} ${format(
        new Date(values.endTime),
        "HH:mm:00",
      )}`;
    }

    if (values.params !== "promoRules") {
      dispatch(
        updatePromoCode({
          ...payloadValues,
          promoCodeId,
        }),
      ).then(({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setIsEditing((i) => ({ ...i, [values.params]: false }));
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    } else {
      dispatch(
        updatePromoCode({
          ...payloadValues,
          promoCodeId,
        }),
      ).then(({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setIsEditing((i) => ({ ...i, [values.params]: false }));
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deletePromoCode(promoCodeId)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        navigate("/promo_codes/list");
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getPromoCodeRedemptionList({ promoCodeId, page: newPage })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove all non-numbers then parse the string as a number
      return parseFloat(value.replace(/[^\d]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (
      getPromoCodeRedemptionListObj.data &&
      getPromoCodeRedemptionListObj.status === "succeeded"
    ) {
      records = { ...getPromoCodeRedemptionListObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderContent = () => {
    const records = getProcessedRecord();

    return (
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Typography variant="h4">Edit Promo Code</Typography>
              {getPromoCodeDetailObj.data.deletable && (
                <BorderCtaButton width="100px" onClickButton={() => setShowDeleteModal(true)}>
                  <Typography sx={{ color: theme.palette.colors.text.error }}>Delete</Typography>
                </BorderCtaButton>
              )}
            </SpaceBetweenBox>
          </Grid>
          <Spacer size="l" position="top" />

          <ConfirmationModal
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
            title="Delete Promo code"
            message="Are you sure to delete this promo code?"
            onSubmit={onConfirmDelete}
            isDisabled={deletePromoCodeObj.status === "pending"}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DetailCardContainer>
                <Grid item xs={12}>
                  <Typography>Code</Typography>
                  <Spacer size="xs" position="top" />
                  <StyledTextField
                    disabled={true}
                    maxLength={6}
                    value={getPromoCodeDetailObj.data.code}
                    width="100%"
                  />
                </Grid>
              </DetailCardContainer>
            </Grid>
            <Grid item xs={12}>
              <Form
                innerRef={titleFormRef}
                validationSchema={titleValidationSchema}
                onSubmit={onUpdatePromoCode}
                initialValues={{
                  params: "title",
                  title: getPromoCodeDetailObj.data.title,
                }}
              >
                <DetailCardContainer>
                  <Grid item xs={12}>
                    <CenteredRowBox>
                      <Typography>Title</Typography>
                      <Spacer size="s" position="left" />
                      <IconButton
                        sx={{ color: theme.palette.colors.text.primary }}
                        disabled={isEditing.title}
                        onClick={() => setIsEditing((i) => ({ ...i, title: true }))}
                      >
                        <ModeEditIcon />
                      </IconButton>
                    </CenteredRowBox>
                    <Spacer size="xs" position="top" />
                    <FormFieldTextWithCounter
                      disabled={!isEditing.title}
                      maxLength={50}
                      name="title"
                      placeholder="Enter Promo Code Title"
                      width="100%"
                    />
                    {isEditing.title && (
                      <ButtonContainer>
                        <BorderCtaButton
                          width="100px"
                          onClickButton={() => {
                            titleFormRef.current.resetForm();
                            setIsEditing((i) => ({ ...i, title: false }));
                          }}
                        >
                          <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                            Cancel
                          </Typography>
                        </BorderCtaButton>
                        <Spacer size="s" position="left" />
                        <FormSubmitButton width="100px">
                          <Typography>Save</Typography>
                        </FormSubmitButton>
                      </ButtonContainer>
                    )}
                  </Grid>
                </DetailCardContainer>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Form
                innerRef={descriptionFormRef}
                validationSchema={descriptionValidationSchema}
                onSubmit={onUpdatePromoCode}
                initialValues={{
                  params: "description",
                  description: getPromoCodeDetailObj.data.description,
                }}
              >
                <DetailCardContainer>
                  <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                    <CenteredRowBox>
                      <Typography>Description</Typography>
                      <Spacer size="s" position="left" />
                      <IconButton
                        sx={{ color: theme.palette.colors.text.primary }}
                        disabled={isEditing.description}
                        onClick={() => setIsEditing((i) => ({ ...i, description: true }))}
                      >
                        <ModeEditIcon />
                      </IconButton>
                    </CenteredRowBox>
                    <Spacer size="xs" position="top" />
                    <FormFieldTextMultiline
                      disabled={!isEditing.description}
                      name="description"
                      placeholder="Enter promo code description here..."
                      rows={5}
                    />
                    {isEditing.description && (
                      <ButtonContainer>
                        <BorderCtaButton
                          width="100px"
                          onClickButton={() => {
                            descriptionFormRef.current.resetForm();
                            setIsEditing((i) => ({ ...i, description: false }));
                          }}
                        >
                          <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                            Cancel
                          </Typography>
                        </BorderCtaButton>
                        <Spacer size="s" position="left" />
                        <FormSubmitButton width="100px">
                          <Typography>Save</Typography>
                        </FormSubmitButton>
                      </ButtonContainer>
                    )}
                  </Box>
                </DetailCardContainer>
              </Form>
            </Grid>
            <Form
              validationSchema={promoPeriodValidationSchema}
              onSubmit={onUpdatePromoCode}
              initialValues={{
                params: "promoPeriod",
                startDate: new Date(getPromoCodeDetailObj.data.startAt),
                endDate: new Date(getPromoCodeDetailObj.data.endAt),
                startTime: new Date(getPromoCodeDetailObj.data.startAt),
                endTime: new Date(getPromoCodeDetailObj.data.endAt),
              }}
            >
              <PromoPeriodForm
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                forCreateScreen={false}
              />
            </Form>
            <Form
              innerRef={formRef}
              validationSchema={promoRulesValidationSchema}
              onSubmit={onUpdatePromoCode}
              initialValues={{
                params: "promoRules",
                type: getPromoCodeDetailObj.data.type.replace("_", " "),
                discount: parseFloat(getPromoCodeDetailObj.data.discount.replace(",", "")),
                maxCapAmount: parseFloat(getPromoCodeDetailObj.data.maxCapAmount.replace(",", "")),
                maxNoClaims: getPromoCodeDetailObj.data.maxNoClaims,
                maxNoClaimsPerUser: getPromoCodeDetailObj.data.maxNoClaimsPerUser,
                newUserOnly: getPromoCodeDetailObj.data.newUserOnly,
                minSpentAmount: parseFloat(
                  getPromoCodeDetailObj.data.minSpentAmount.replace(",", ""),
                ),
                userSpecific: getPromoCodeDetailObj.data.userSpecific,
                usersList: getPromoCodeDetailObj.data.usersList,
                merchantSpecific: getPromoCodeDetailObj.data.merchantSpecific,
                merchantsList: getPromoCodeDetailObj.data.merchantsList,
                businessSpecific: getPromoCodeDetailObj.data.businessSpecific,
                businessesList: getPromoCodeDetailObj.data.businessesList,
                hourlyBookingSpecific: getPromoCodeDetailObj.data.hourlyBookingSpecific,
                subscriptionPlanSpecific: getPromoCodeDetailObj.data.subscriptionPlanSpecific,
                subscriptionPlansList: getPromoCodeDetailObj.data.subscriptionPlansList,
                packagePlanSpecific: getPromoCodeDetailObj.data.packagePlanSpecific,
                packagePlansList: getPromoCodeDetailObj.data.packagePlansList,
                classSpecific: getPromoCodeDetailObj.data.classSpecific,
                classesList: getPromoCodeDetailObj.data.classesList,
              }}
            >
              <PromoDetailsForm
                forCreateScreen={false}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Form>
            {getPromoCodeRedemptionListObj.status === "succeeded" ? (
              <Grid item xs={12}>
                <DetailCardContainer>
                  <Grid item xs={12}>
                    <Typography variant="h5">Redemption List</Typography>
                  </Grid>
                  <Table>
                    <TableHeader
                      headerCells={[
                        "Redemption ID",
                        "User Id",
                        "Username",
                        "Status",
                        "Date Redeemed",
                        "Discount Amount",
                        "Purchase Item",
                      ]}
                      sortColumn={sortColumn}
                      sortOrder={sortOrder}
                      onSortChange={onSortChange}
                      columnMapping={columnMapping}
                    />
                    <TableBody>
                      {records?.items.map((item) => (
                        <PromoCodeRedemptionRow record={item} key={item.id} />
                      ))}
                    </TableBody>
                  </Table>
                  <CustomFooter>
                    <Pagination
                      sx={{
                        "&& .Mui-selected": {
                          backgroundColor: theme.palette.colors.brand.primary,
                          color: theme.palette.colors.text.white,
                        },
                      }}
                      page={page}
                      onChange={onPageChange}
                      count={getPromoCodeRedemptionListObj.data.pagination.totalPages}
                      variant="outlined"
                    />
                  </CustomFooter>
                </DetailCardContainer>
              </Grid>
            ) : (
              <PromoRedemptionListLoader />
            )}

            <Grid item xs={12} />
            <Grid item xs={12} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <PaddedView multiples={3}>
      <SimpleBackdrop isLoading={isLoading} />
      <Grid item display="flex" justifyContent="end">
        <Stack spacing={2} sx={{ paddingRight: 1 }}>
          <Breadcrumbs separator=">" aria-label="breadcrumb">
            <Link underline="hover" key="1" color="inherit" href="/">
              <Typography variant="body1">Dashboard</Typography>
            </Link>
            <Link underline="hover" key="2" color="inherit" href="/promo_codes/list">
              <Typography variant="body1">Promo Code List</Typography>
            </Link>
            <Typography variant="body1" sx={{ textDecoration: "underline" }}>
              Promo Code Edit
            </Typography>
          </Breadcrumbs>
        </Stack>
      </Grid>
      <Link href="/promo_codes/list" style={{ textDecoration: "none" }}>
        <Typography sx={{ color: theme.palette.colors.text.secondary }}>{`< Back`}</Typography>
      </Link>
      <Spacer size="xl" position="top" />
      {getPromoCodeDetailObj.status === "succeeded" ? renderContent() : <PromoCodeEditLoader />}
    </PaddedView>
  );
}
