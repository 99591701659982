import { Breadcrumbs, Grid, Stack, Typography, Link, Box, useTheme } from "@mui/material";
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PaddedView from "../../../components/utils/padded-view.component";
import BorderButton from "../components/border-button.component";
import Spacer from "../../../components/utils/spacer.component";

function AnnouncementDetailLoader() {
  const theme = useTheme();

  return (
    <PaddedView>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Edit Announcement</Typography>
            </Grid>
            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link underline="hover" key="2" color="inherit" href="/announcement/list">
                    <Typography variant="body1">Announcements</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    Edit Announcements
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography sx={{ width: "200px" }}>Choose your image</Typography>
              <Skeleton width="300px" height="55px" />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Skeleton width="100%" height="55px" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton width="100%" height="55px" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton width="100%" height="300px" />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
            <Skeleton width="100px" height="38px" borderRadius="35px" />
            <Spacer size="s" position="left" />
            <BorderButton width="100px">
              <Typography sx={{ color: theme.palette.colors.brand.primary }}>Cancel</Typography>
            </BorderButton>
          </Box>
        </Grid>
      </Grid>
    </PaddedView>
  );
}

export default AnnouncementDetailLoader;
