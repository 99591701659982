import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import Spacer from "../../../components/utils/spacer.component";
import {
  accDeletionSelector,
  deleteUser,
  getDeletedAccList,
} from "../../../services/accDeletion/accDeletion-slice.service";
import { addLogs } from "../../../services/accDeletion/log/accDeletion-log-slice.service";
import AlertDialog from "../../../components/notification/dialog-boxes.component";
import AddLogsModal from "../components/acc-deletion-user-list/add-logs-modal.component";
import Form from "../../../components/forms/form.component";
import CustomizedSnackbars from "../../../components/notification/snackbar.component";
import TableLoader from "../../../components/tables/table-loader.component";
import TableIndex from "../components/acc-deletion-user-list/table-index.component";
import SearchEntries from "../components/acc-deletion-user-list/search-entries.component";

const validationSchema = Yup.object().shape({
  notes: Yup.string().required().min(2).label("Notes"),
  accountDeletionId: Yup.string().required(),
});

function AccDeletionUserList() {
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const dispatch = useDispatch();
  const { getDeletedAccListObj, deleteUserObj } = useSelector(accDeletionSelector);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");

  const showSuccessSnackBar = (message) => {
    setIsShowSnackBar(true);
    setSnackBarType("success");
    setSnackBarMsg(message);
  };

  const showErrorSnackBar = (message) => {
    setIsShowSnackBar(true);
    setSnackBarType("error");
    setSnackBarMsg(message);
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(
      getDeletedAccList({ type: new URLSearchParams(location.search).get("type"), page: newPage }),
    );
  };

  const handleConfirmDelete = () => {
    dispatch(deleteUser(deleteId)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        showSuccessSnackBar(payload.message);
        dispatch(
          getDeletedAccList({ type: new URLSearchParams(location.search).get("type"), page: 0 }),
        );
        setShowConfirmationModal(false);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const handleOnDeletePress = (userId) => {
    setDeleteId(userId);
    setShowConfirmationModal(true);
  };

  const onSubmitLogs = (values, { resetForm }) => {
    dispatch(addLogs(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        showSuccessSnackBar(payload.message);
        dispatch(
          getDeletedAccList({ type: new URLSearchParams(location.search).get("type"), page }),
        );
        resetForm();
        setShowLogsModal(false);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  useEffect(() => {
    dispatch(
      getDeletedAccList({ type: new URLSearchParams(location.search).get("type"), page: 0 }),
    );
  }, [location]);

  const renderDataRecords = () => {
    if (getDeletedAccListObj.status === "succeeded") {
      return (
        <TableIndex
          handleOnDeletePress={handleOnDeletePress}
          handlePageChange={handlePageChange}
          page={page}
          searchKeyword={searchKeyword}
          setShowLogsModal={setShowLogsModal}
        />
      );
    }

    return <TableLoader />;
  };

  return (
    <>
      <CustomizedSnackbars
        isShow={isShowSnackBar}
        setShowSnackbar={setIsShowSnackBar}
        message={snackBarMsg}
        type={snackBarType}
      />
      <AlertDialog
        isShow={showConfirmationModal}
        setIsShowAlert={setShowConfirmationModal}
        isConfirmHandle={handleConfirmDelete}
        title="Confirm Delete?"
        message="Are you sure to delete this user?"
        buttonText="Confirm"
        disabled={deleteUserObj.status === "pending"}
      />
      <Form
        initialValues={{ notes: "", accountDeletionId: "" }}
        onSubmit={onSubmitLogs}
        validationSchema={validationSchema}
      >
        <AddLogsModal isShow={showLogsModal} setIsShowAlert={setShowLogsModal} />
        <SearchEntries
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          placeholder="Search by name, phone and email"
        />
        <Spacer size="l" />
        {renderDataRecords()}
        <Spacer size="l" />
      </Form>
    </>
  );
}

export default AccDeletionUserList;
