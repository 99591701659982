import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postReq } from "../../api.service";

export const addLogs = createAsyncThunk("accDeletion/add_logs", async (payload) => {
  const response = postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/account_deletions/${payload.accountDeletionId}/logs`,
    payload,
  );
  return response;
});

const accDeletionLogSlice = createSlice({
  name: "accDeletionLog",
  initialState: {
    addLogsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [addLogs.pending]: (state) => {
      state.addLogsObj.status = "pending";
    },
    [addLogs.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.addLogsObj.status = "succeeded";
      state.addLogsObj.data = data;
      state.addLogsObj.successMessage = message;
    },
    [addLogs.rejected]: (state, action) => {
      const { message } = action.error;

      state.addLogsObj.status = "failed";
      state.addLogsObj.errorMessage = message;
    },
  },
});

export default accDeletionLogSlice.reducer;

// state
export const accDeletionLogSelector = (state) => state.accDeletionLog;
