import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, postReq, delReq, putReq, patchReq } from "../../../api.service";

export const getMerchantSubscriptionPlans = createAsyncThunk(
  "merchants/subscription/plan/index",
  async (payload) => {
    const { q, merchantsId } = payload;
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/subscriptions/plans?page=${
        payload.page + 1
      }${q ? "&q=".concat(q) : ""}${merchantsId ? "&merchantsId=".concat(merchantsId) : ""}`,
    );
    return response;
  },
);

export const getMerchantSubscriptionPlan = createAsyncThunk(
  "merchants/subscription/plan",
  async (payload) => {
    const { planId } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/subscriptions/plans/${planId}`,
    );
    return response;
  },
);

export const getMerchantSubscriptionPlansAutoCompleteSuggestion = createAsyncThunk(
  "merchants/subscription/plan/autocomplete",
  async (payload) => {
    const response = getReq(
      `${
        process.env.REACT_APP_API_PRIVATE_V1
      }/employees/merchants/subscriptions/plans/auto_complete?q=${payload.keyword}&page=${
        payload.page
      }${payload.merchantsId ? "&merchantsId=".concat(payload.merchantsId) : ""}`,
    );
    return response;
  },
);

export const createSubscriptionPlan = createAsyncThunk(
  "merchants/subscriptions/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/subscriptions/plans`,
      payload,
    );
    return response;
  },
);

export const deleteSubscriptionPlan = createAsyncThunk(
  "merchants/subscriptions/plans/delete/{plan_id}",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/subscriptions/plans/${payload.planId}`,
    );
    return response;
  },
);

export const updateSubscriptionPlanDetail = createAsyncThunk(
  "merchants/subscriptions/plans/update/{plan_id}",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/subscriptions/plans/${payload.planId}`,
      payload,
    );
    return response;
  },
);

export const updateSubscriptionPlanImage = createAsyncThunk(
  "merchants/subscriptions/plans/update/image/{plan_id}",
  async (payload) => {
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/merchants/subscriptions/plans/${payload.planId}/image`,
      payload,
    );
    return response;
  },
);

const merchantSubscriptionPlanSlice = createSlice({
  name: "merchantSubscriptionPlan",
  initialState: {
    getMerchantSubscriptionPlansObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMerchantSubscriptionPlanObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMerchantSubscriptionPlansAutoCompleteSuggestionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createSubscriptionPlanObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteSubscriptionPlanObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateSubscriptionPlanDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateSubscriptionPlanImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getMerchantSubscriptionPlans.pending]: (state) => {
      state.getMerchantSubscriptionPlansObj.status = "pending";
    },
    [getMerchantSubscriptionPlans.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantSubscriptionPlansObj.status = "succeeded";
      state.getMerchantSubscriptionPlansObj.data = data;
      state.getMerchantSubscriptionPlansObj.successMessage = message;
    },
    [getMerchantSubscriptionPlans.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantSubscriptionPlansObj.status = "failed";
      state.getMerchantSubscriptionPlansObj.errorMessage = message;
    },
    [getMerchantSubscriptionPlan.pending]: (state) => {
      state.getMerchantSubscriptionPlanObj.status = "pending";
    },
    [getMerchantSubscriptionPlan.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantSubscriptionPlanObj.status = "succeeded";
      state.getMerchantSubscriptionPlanObj.data = data;
      state.getMerchantSubscriptionPlanObj.successMessage = message;
    },
    [getMerchantSubscriptionPlan.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantSubscriptionPlanObj.status = "failed";
      state.getMerchantSubscriptionPlanObj.errorMessage = message;
    },
    [getMerchantSubscriptionPlansAutoCompleteSuggestion.pending]: (state) => {
      state.getMerchantSubscriptionPlansAutoCompleteSuggestionObj.status = "pending";
    },
    [getMerchantSubscriptionPlansAutoCompleteSuggestion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMerchantSubscriptionPlansAutoCompleteSuggestionObj.status = "succeeded";
      state.getMerchantSubscriptionPlansAutoCompleteSuggestionObj.data = data;
      state.getMerchantSubscriptionPlansAutoCompleteSuggestionObj.successMessage = message;
    },
    [getMerchantSubscriptionPlansAutoCompleteSuggestion.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMerchantSubscriptionPlansAutoCompleteSuggestionObj.status = "failed";
      state.getMerchantSubscriptionPlansAutoCompleteSuggestionObj.errorMessage = message;
    },
    [createSubscriptionPlan.pending]: (state) => {
      state.createSubscriptionPlanObj.status = "pending";
    },
    [createSubscriptionPlan.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createSubscriptionPlanObj.status = "succeeded";
      state.createSubscriptionPlanObj.data = data;
      state.createSubscriptionPlanObj.successMessage = message;
    },
    [createSubscriptionPlan.rejected]: (state, action) => {
      const { message } = action.error;

      state.createSubscriptionPlanObj.status = "failed";
      state.createSubscriptionPlanObj.errorMessage = message;
    },
    [deleteSubscriptionPlan.pending]: (state) => {
      state.deleteSubscriptionPlanObj.status = "pending";
    },
    [deleteSubscriptionPlan.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteSubscriptionPlanObj.status = "succeeded";
      state.deleteSubscriptionPlanObj.data = data;
      state.deleteSubscriptionPlanObj.successMessage = message;
    },
    [deleteSubscriptionPlan.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteSubscriptionPlanObj.status = "failed";
      state.deleteSubscriptionPlanObj.errorMessage = message;
    },
    [updateSubscriptionPlanDetail.pending]: (state) => {
      state.updateSubscriptionPlanDetailObj.status = "pending";
    },
    [updateSubscriptionPlanDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateSubscriptionPlanDetailObj.status = "succeeded";
      state.updateSubscriptionPlanDetailObj.data = data;
      state.updateSubscriptionPlanDetailObj.successMessage = message;
    },
    [updateSubscriptionPlanDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateSubscriptionPlanDetailObj.status = "failed";
      state.updateSubscriptionPlanDetailObj.errorMessage = message;
    },
    [updateSubscriptionPlanImage.pending]: (state) => {
      state.updateSubscriptionPlanImageObj.status = "pending";
    },
    [updateSubscriptionPlanImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateSubscriptionPlanImageObj.status = "succeeded";
      state.updateSubscriptionPlanImageObj.data = data;
      state.updateSubscriptionPlanImageObj.successMessage = message;
    },
    [updateSubscriptionPlanImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateSubscriptionPlanImageObj.status = "failed";
      state.updateSubscriptionPlanImageObj.errorMessage = message;
    },
  },
});

export default merchantSubscriptionPlanSlice.reducer;

// state
export const merchantSubscriptionPlanSelector = (state) => state.merchantSubscriptionPlan;
