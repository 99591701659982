import { FormHelperText, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { format } from "date-fns";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

export default function FormTimePicker({ name, isTransparent, label, disabled }) {
  const theme = useTheme();
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (date) => {
    setFieldValue(name, date);
  };

  const startAt = new Date(
    `${format(values[name], "yyyy-MM-dd")} ${format(values[name], "04:00:00")}`,
  );
  const currentAt = new Date(values[name]);
  const endAt = new Date(
    `${format(values[name], "yyyy-MM-dd")} ${format(values[name], "23:31:00")}`,
  );
  const validTimeInterval = startAt < currentAt && endAt > currentAt;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        minTime={new Date(0, 0, 0, 4)}
        maxTime={new Date(0, 0, 0, 23, 31)}
        value={values[name]}
        disabled={disabled}
        onChange={handleChange}
        InputProps={{ error: showError }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            sx={{
              backgroundColor: isTransparent ? "transparent" : theme.palette.colors.bg.primary,
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderTopLeftRadius: theme.shape.borderRadius[1],
                borderTopRightRadius: theme.shape.borderRadius[1],
                borderBottomLeftRadius: theme.shape.borderRadius[1],
                borderBottomRightRadius: theme.shape.borderRadius[1],
              },
            }}
            variant="outlined"
            onBlur={() => setFieldTouched(name)}
          />
        )}
      />
      {!validTimeInterval && (
        <FormHelperText error>Only select between 4am - 11.30pm</FormHelperText>
      )}
      {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
    </LocalizationProvider>
  );
}
FormTimePicker.defaultProps = {
  isTransparent: false,
  disabled: false,
};

FormTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isTransparent: PropTypes.bool,
  disabled: PropTypes.bool,
};
