import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as Yup from "yup";

import Spacer from "../../../components/utils/spacer.component";
import Form from "../../../components/forms/form.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";

const validationSchema = Yup.object().shape({
  password: Yup.string().required().min(4).label("New Password"),
  confirmPassword: Yup.string().required().min(4).label("Confirm New Password"),
});

function NewPasswordForm({ handleSubmit, showAlert }) {
  const theme = useTheme();

  return (
    <Form
      initialValues={{ password: "", confirmPassword: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <FormFieldText name="password" placeholder="New Password" label="New Password" />

      <Spacer size="l" />
      <FormFieldText
        name="confirmPassword"
        placeholder="Confirm New Password"
        label="Confirm New Password"
        type="password"
      />

      <Spacer size="l" />
      <FormSubmitButton>
        <Typography>Confirm</Typography>
      </FormSubmitButton>
      {showAlert && (
        <Typography variant="body2" sx={{ color: theme.palette.colors.brand.primary }}>
          Those password are not match. Please try again.
        </Typography>
      )}
      <Spacer size="l" />
    </Form>
  );
}

NewPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
};

export default NewPasswordForm;
