import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Box, FormHelperText, InputLabel, styled, Typography, useTheme } from "@mui/material";

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
});

const LabelContainer = styled(Box)({
  width: "200px",
  height: "40px",
  display: "flex",
  alignItems: "center",
});

function PopUpTypeSelectField({ name, label, disabled }) {
  const theme = useTheme();
  const { setFieldValue, values, errors, setFieldTouched, touched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  return (
    <CenterRowBox>
      <LabelContainer>
        <Typography>Type of file</Typography>
      </LabelContainer>
      <FormControl sx={{ minWidth: "300px" }} size="small">
        <InputLabel error={showError}>{label}</InputLabel>
        <Select
          disabled={disabled}
          onBlur={() => setFieldTouched(name)}
          error={showError}
          label={label}
          value={values[name]}
          onChange={handleChange}
          sx={{
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
          }}
          inputProps={{ placeholder: "hello" }}
        >
          <MenuItem value="images">Image</MenuItem>
          <MenuItem value="video">Video</MenuItem>
        </Select>
        {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      </FormControl>
    </CenterRowBox>
  );
}

PopUpTypeSelectField.defaultProps = {
  disabled: false,
};

PopUpTypeSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default PopUpTypeSelectField;
