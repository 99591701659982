import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, Box, InputAdornment } from "@mui/material";
import Spacer from "../utils/spacer.component";

function FormPrice({ name, placeholder, label, disabled, minus }) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();

  const showError = touched[name] && typeof errors[name] === "string";

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
    if (e?.key === "-" || e?.key === "+") {
      e.preventDefault();
    }
  };

  const handleChange = (evt) => {
    const { value } = evt.target;
    if (value.match(/\./g)) {
      const [, decimal] = value.split(".");
      if (decimal?.length > 2) {
        return;
      }
    }
    setFieldValue(name, value);
  };

  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": {
            borderTopLeftRadius: theme.shape.borderRadius[1],
            borderTopRightRadius: theme.shape.borderRadius[1],
            borderBottomLeftRadius: theme.shape.borderRadius[1],
            borderBottomRightRadius: theme.shape.borderRadius[1],
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderTopLeftRadius: theme.shape.borderRadius[1],
              borderTopRightRadius: theme.shape.borderRadius[1],
              borderBottomLeftRadius: theme.shape.borderRadius[1],
              borderBottomRightRadius: theme.shape.borderRadius[1],
              backgroundColor: theme.palette.colors.bg.primary,
            },
          },
        }}
      >
        <TextField
          onKeyDown={keyPress}
          error={showError}
          variant="outlined"
          helperText={showError ? errors[name] : null}
          onBlur={() => setFieldTouched(name)}
          name={name}
          type="number"
          label={label}
          disabled={disabled}
          placeholder={placeholder}
          onChange={handleChange}
          InputProps={{
            startAdornment: minus && (
              <InputAdornment position="start">
                <RemoveIcon />
                <Spacer position="right" />
              </InputAdornment>
            ),
          }}
          value={values[name]}
        />
      </Box>
    </>
  );
}

FormPrice.defaultProps = {
  disabled: false,
  minus: false,
};

FormPrice.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  minus: PropTypes.bool,
};

export default FormPrice;
