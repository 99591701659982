import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import TableWrapper from "../../../../components/tables/table-wrapper.component";
import TableHeader from "./table-header.component";
import Row from "./row.component";

function TableIndex({ page, handlePageChange, sortCate, setSortCate, records }) {
  return (
    <>
      <TableWrapper>
        <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
          <TableHeader
            headerTitle="Staffs List"
            setSortCate={setSortCate}
            sortCate={sortCate}
            headerCells={["Image", "Name", "Email", "Created At", "Action"]}
          />

          <TableBody>
            {records.items.map((record) => (
              <Row record={record} key={uuidv4()} />
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                page={page}
                rowsPerPage={records.pagination.perPage}
                count={records.pagination.totalItems}
                rowsPerPageOptions={[]}
                showFirstButton={true}
                showLastButton={true}
                onPageChange={handlePageChange}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableWrapper>
      <></>
    </>
  );
}

TableIndex.propTypes = {
  page: PropTypes.number.isRequired,
  sortCate: PropTypes.shape({
    name: PropTypes.oneOf(["idle", "asc", "dsc"]),
    email: PropTypes.oneOf(["idle", "asc", "dsc"]),
    createdAt: PropTypes.oneOf(["idle", "asc", "dsc"]),
  }).isRequired,
  setSortCate: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  records: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        imagePath: PropTypes.string,
        createdAt: PropTypes.string.isRequired,
        deletable: PropTypes.bool.isRequired,
      }),
    ).isRequired,
    pagination: PropTypes.shape({
      perPage: PropTypes.number.isRequired,
      totalItems: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default TableIndex;
