import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

function AttachmentRow({ record, showSuccessSnackBar, onHandleDelete, onEditClicked }) {
  const theme = useTheme();

  return (
    <TableRow key={record.id}>
      <TableCell width="150px">
        <Typography variant="body2" noWrap={true}>
          {record.id}
        </Typography>
      </TableCell>
      <TableCell width="250px">
        <img
          src={record.path}
          alt="attachment"
          style={{
            height: "100px",
            width: "80%",
            borderRadius: theme.shape.borderRadius[1],
            objectFit: "contain",
          }}
        />
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.title}
        </Typography>
      </TableCell>

      <TableCell sx={{ width: "200px" }}>
        <RowBox>
          <Tooltip title="Copy Link">
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(record.path);
                showSuccessSnackBar("Link has been copied to clipboard");
              }}
            >
              <InsertLinkIcon sx={{ transform: "rotate(125deg)" }} />
            </IconButton>
          </Tooltip>

          <IconButton onClick={() => onEditClicked(record.id)}>
            <EditIcon />
          </IconButton>

          <IconButton onClick={() => onHandleDelete(record.id)}>
            <DeleteIcon />
          </IconButton>
        </RowBox>
      </TableCell>
    </TableRow>
  );
}

AttachmentRow.propTypes = {
  onEditClicked: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired,
  showSuccessSnackBar: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    path: PropTypes.string,
  }).isRequired,
};

export default AttachmentRow;
