import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import BorderCtaButton from "../../../components/button/border-cta-button.component";
import CtaButton from "../../../components/button/cta-button.component";
import { highlightSelector } from "../../../services/highlight/highlight-slice.service";

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[3],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  width: isMobile ? "350px" : "550px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

function DeletionModalConfirmation({
  record,
  showDeletionModal,
  setShowDeletionModal,
  handleOnPressDelete,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { deleteHighlightObj } = useSelector(highlightSelector);

  const handleCancel = () => {
    setShowDeletionModal(false);
  };

  const handleConfirm = () => {
    handleOnPressDelete(record.id);
  };

  return (
    <>
      <Modal open={showDeletionModal} onClose={handleCancel}>
        <ModalBox isMobile={isMobile}>
          <Grid container spacing={1}>
            <Grid container justifyContent="flex-end">
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </Grid>
            <Grid item container spacing={6}>
              <Grid item xs={12}>
                <Typography variant="h6" textAlign="center">
                  Confirm to proceed removing highlight
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <BorderCtaButton onClickButton={handleCancel}>
                          <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                            Cancel
                          </Typography>
                        </BorderCtaButton>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CtaButton
                          onClickButton={handleConfirm}
                          disabled={deleteHighlightObj.status === "pending"}
                        >
                          <Typography>Confirm</Typography>
                        </CtaButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ModalBox>
      </Modal>
      <></>
    </>
  );
}

DeletionModalConfirmation.propTypes = {
  showDeletionModal: PropTypes.bool.isRequired,
  setShowDeletionModal: PropTypes.func.isRequired,
  handleOnPressDelete: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    price: PropTypes.string,
    active: PropTypes.bool,
    createdAt: PropTypes.string,
    merchant: PropTypes.shape({
      id: PropTypes.number,
      businessName: PropTypes.string,
    }),
  }).isRequired,
};

export default DeletionModalConfirmation;
