import { Grid, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function AnnouncementTableRowLoader() {
  return (
    <TableBody>
      <TableRow>
        <TableCell width="150px">
          <Skeleton width="60%" />
        </TableCell>
        <TableCell>
          <Skeleton width="60%" />
        </TableCell>
        <TableCell width="200px">
          <Skeleton width="60%" />
        </TableCell>

        <TableCell width="100px">
          <Grid container spacing={1}>
            <Grid item>
              <Skeleton height={20} width={20} circle={true} />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
export default AnnouncementTableRowLoader;
