import { Box, Grid, IconButton, styled, Typography, useTheme } from "@mui/material";
import React from "react";
import { useFormikContext } from "formik";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PropTypes from "prop-types";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Spacer from "../../../components/utils/spacer.component";
import DetailCardContainer from "../../../components/utils/detail-card-container.component";
import CenteredRowBox from "../../../components/utils/centered-row-box.component";
import BorderCtaButton from "../../../components/button/border-cta-button.component";
import FormDatePicker from "./form-date-picker.component";

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginTop: "20px",
});

const FlexStartRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

export default function PromoPeriodForm({ isEditing, setIsEditing, forCreateScreen }) {
  const theme = useTheme();
  const { resetForm } = useFormikContext();

  return (
    <Grid item xs={12}>
      <DetailCardContainer>
        <CenteredRowBox>
          <Typography variant="h6">Promo Period</Typography>
          {!forCreateScreen && (
            <>
              <Spacer size="s" position="left" />
              <IconButton
                sx={{ color: theme.palette.colors.text.primary }}
                disabled={isEditing.promoPeriod}
                onClick={() => setIsEditing((i) => ({ ...i, promoPeriod: true }))}
              >
                <ModeEditIcon />
              </IconButton>
            </>
          )}
        </CenteredRowBox>
        <Spacer size="m" position="top" />
        <FlexStartRowBox>
          <Grid item xs={6}>
            <Typography>Start Date</Typography>
            <Spacer size="xs" position="top" />
            <FormDatePicker
              name="startDate"
              width="100%"
              placeholder="Start Date"
              disabled={forCreateScreen ? false : !isEditing.promoPeriod}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={6}>
            <Typography>End Date</Typography>
            <Spacer size="xs" position="top" />
            <FormDatePicker
              name="endDate"
              width="100%"
              placeholder="End Date"
              disabled={forCreateScreen ? false : !isEditing.promoPeriod}
            />
          </Grid>
        </FlexStartRowBox>
        <Spacer size="l" position="top" />
        <FlexStartRowBox>
          <Grid item xs={6}>
            <Typography>Start Time</Typography>
            <Spacer size="xs" position="top" />
            <FormTimePicker
              width="100%"
              name="startTime"
              label=""
              placeholder="Promo Start Time"
              disabled={forCreateScreen ? false : !isEditing.promoPeriod}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={6}>
            <Typography>End Time</Typography>
            <Spacer size="xs" position="top" />
            <FormTimePicker
              width="100%"
              name="endTime"
              label=""
              placeholder="Promo End Time"
              disabled={forCreateScreen ? false : !isEditing.promoPeriod}
            />
          </Grid>
        </FlexStartRowBox>
        {!forCreateScreen && isEditing.promoPeriod && (
          <ButtonContainer>
            <BorderCtaButton
              width="100px"
              onClickButton={() => {
                resetForm();
                setIsEditing((i) => ({ ...i, promoPeriod: false }));
              }}
            >
              <Typography sx={{ color: theme.palette.colors.brand.primary }}>Cancel</Typography>
            </BorderCtaButton>
            <Spacer size="s" position="left" />
            <FormSubmitButton width="100px">
              <Typography>Save</Typography>
            </FormSubmitButton>
          </ButtonContainer>
        )}
      </DetailCardContainer>
    </Grid>
  );
}

PromoPeriodForm.defaultProps = {
  setIsEditing: null,
  isEditing: {
    promoPeriod: false,
  },
};

PromoPeriodForm.propTypes = {
  setIsEditing: PropTypes.func,
  isEditing: PropTypes.shape({
    promoPeriod: PropTypes.bool,
  }),
  forCreateScreen: PropTypes.bool.isRequired,
};
