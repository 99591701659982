import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  IconButton,
  Collapse,
  TableRow,
  Typography,
  Table,
  TableBody,
  styled,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import Spacer from "../../../../components/utils/spacer.component";
import TableHeader from "../../../../components/tables/table-header.component";

const TableTopContainer = styled(Box)(({ theme }) => ({
  padding: "6px 16px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.colors.ui.tableBorder}`,
  alignItems: "flex-end",
}));

function MerchantBusinessRow({ record, onToggleDelete }) {
  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <TableRow
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell>
          <Typography variant="body2">{record.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{record.merchant.companyName}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{record.city}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{record.contactNo}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
            {record.status === "rejected_admin" ? "Rejected" : record.status}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{record.createdAt}</Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Link
            to={{
              pathname: "/merchants/business/edit",
              search: `merchantId=${record.merchant.id}&businessId=${record.id}`,
            }}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>
          <IconButton
            disabled={!record.deletable}
            onClick={() =>
              onToggleDelete({ merchantId: record.merchant.id, businessId: record.id })
            }
          >
            <DeleteIcon />
          </IconButton>
          <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
            {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <TableTopContainer>
              <Typography>Businesses Details</Typography>
            </TableTopContainer>
            <Table size="small">
              <TableHeader
                headerCells={["Images", "Address", "Rejected Reason"]}
                nonSortableColumns={["Images", "Address", "Rejected Reason"]}
              />
              <TableBody>
                <TableRow>
                  <TableCell>
                    <img alt="gym-img" src={record.imagePath} width="200px" />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{record.location}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" noWrap={true}>
                      {record.status === "rejected_admin" && record.rejections.length > 0
                        ? record.rejections[record.rejections.length - 1].reason
                        : "None"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Spacer size="xl" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

MerchantBusinessRow.defaultProps = {
  record: {
    id: null,
    merchantId: null,
    merchantName: "",
    name: "",
    createdAt: "",
    description: "",
    location: "",
    contactNo: "",
    imagePath: "",
    status: "",
    city: "",
    rejections: [],
  },
};

MerchantBusinessRow.propTypes = {
  onToggleDelete: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    merchant: PropTypes.shape({
      id: PropTypes.number,
      companyName: PropTypes.string,
    }),
    deletable: PropTypes.bool.isRequired,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.string,
    city: PropTypes.string,
    contactNo: PropTypes.string,
    imagePath: PropTypes.string,
    status: PropTypes.string,
    rejections: PropTypes.arrayOf(
      PropTypes.shape({
        reason: PropTypes.string,
        rejectedBy: PropTypes.string,
      }),
    ),
  }),
};

export default MerchantBusinessRow;
