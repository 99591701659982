import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  IconButton,
  Collapse,
  TableRow,
  Typography,
  Table,
  TableBody,
  Box,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import LoginIcon from "@mui/icons-material/Login";
import Spacer from "../../../../components/utils/spacer.component";
import TableHeader from "../../../../components/tables/table-header.component";

function Row({ record, shadowLoginToUser }) {
  const [collapse, setCollapse] = useState(false);
  const theme = useTheme();

  const getTrainers = (trainerArr) => {
    const trainers = [];
    trainerArr.map((item) => trainers.push(`${item.firstName} ${item.lastName}`));
    return trainers.join(", ");
  };

  return (
    <>
      <TableRow
        key={`${record.user.id}-${record.user.registeredAt}`}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Typography variant="body2" noWrap={true} sx={{ width: "200px" }}>
            {record.user.email}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography variant="body2" noWrap={true} sx={{ width: "200px" }}>
            {`${record.user.firstName} ${record.user.lastName}`}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography variant="body2" noWrap={true} sx={{ width: "150px" }}>
            {record.user.registeredAt}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <IconButton onClick={() => shadowLoginToUser(record.user.id)}>
            <LoginIcon />
          </IconButton>
          <Link to={`/users/${record.user.id}`}>
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>
          <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
            {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Spacer size="m" />
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography>Bookings - </Typography>
              <Spacer size="xs" position="left" />
              <Link
                to={{ pathname: `/users/booking_details/${record.user.id}` }}
                state={{ id: record.user.id }}
                style={{ textDecoration: "none", textTransform: "none" }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: theme.palette.colors.text.link,
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  show all
                </Typography>
              </Link>
            </Box>
            <Spacer />

            <Table size="small">
              <TableHeader
                headerCells={[
                  "Order Type",
                  "Gym Name",
                  "Booking Date",
                  "Time",
                  "Category",
                  "Title",
                  "Trainer",
                  "Promo Code",
                  "Status",
                ]}
                nonSortableColumns={[
                  "Order Type",
                  "Gym Name",
                  "Booking Date",
                  "Time",
                  "Category",
                  "Title",
                  "Trainer",
                  "Promo Code",
                  "Status",
                ]}
              />
              <TableBody>
                {record.bookings.length > 0 ? (
                  record.bookings.map((booking) => (
                    <TableRow key={booking.id}>
                      <TableCell>
                        <Typography variant="body2" noWrap={true}>
                          {booking.type === "merchant_class_session" && "Class Session"}
                          {booking.type === "merchant_event_attendee" && "Merchant Event"}
                          {(booking.type === "gym_session" ||
                            booking.type === "merchant_hourly_booking") &&
                            "Gym Session"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap={true}>
                          {booking.location.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap={true}>
                          {booking.date}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap={true}>
                          {booking.time}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap={true}>
                          {booking.type.toUpperCase() === "MERCHANT_CLASS_SESSION"
                            ? booking.session?.class.category.label
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap={true}>
                          {booking.type.toUpperCase() === "MERCHANT_CLASS_SESSION" &&
                            booking.session?.class.name}
                          {booking.type.toUpperCase() === "MERCHANT_EVENT_ATTENDEE" &&
                            booking.session?.event.name}
                          {booking.type.toUpperCase() !== "MERCHANT_CLASS_SESSION" &&
                            booking.type.toUpperCase() !== "MERCHANT_EVENT_ATTENDEE" &&
                            "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap={true}>
                          {booking.type.includes("merchant_class_session") &&
                          booking.session?.trainers?.length > 0
                            ? getTrainers(booking.session?.trainers)
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap={true}>
                          {booking.promoCode === null || booking.promoCode === ""
                            ? "-"
                            : booking.promoCode}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap={true}>
                          {booking.status}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow colSpan={10}>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Spacer size="m" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.defaultProps = {
  record: {
    user: {
      id: null,
      email: "",
      firstName: "",
      lastName: "",
      registeredAt: "",
      phoneNumber: "",
      phoneVerified: false,
      emailVerified: false,
      gender: "",
      dateOfBirth: "",
      imagePath: "",
    },
    bookings: [
      {
        id: null,
        type: "",
        date: "",
        status: "",
        time: "",
        location: {
          name: "",
          address: "",
          lat: "",
          long: "",
          imagePath: "",
        },
        session: {
          trainers: [
            {
              id: null,
              firstName: "",
              lastName: "",
              imagePath: "",
            },
          ],
          class: {
            name: "",
            category: {
              label: "",
            },
          },
        },
        promoCode: "",
      },
    ],
  },
};

Row.propTypes = {
  shadowLoginToUser: PropTypes.func.isRequired,
  record: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      registeredAt: PropTypes.string,
      phoneNumber: PropTypes.string,
      phoneVerified: PropTypes.bool,
      emailVerified: PropTypes.bool,
      gender: PropTypes.string,
      dateOfBirth: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    bookings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        date: PropTypes.string,
        status: PropTypes.string,
        time: PropTypes.string,
        location: PropTypes.shape({
          name: PropTypes.string,
          address: PropTypes.string,
          lat: PropTypes.string,
          long: PropTypes.string,
          imagePath: PropTypes.string,
        }),
        session: PropTypes.shape({
          trainers: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              firstName: PropTypes.string,
              lastName: PropTypes.string,
              imagePath: PropTypes.string,
            }),
          ),
          class: PropTypes.shape({
            name: PropTypes.string,
            category: PropTypes.shape({
              label: PropTypes.string,
            }),
          }),
        }),
        promoCode: PropTypes.string,
      }),
    ),
  }),
};

export default Row;
