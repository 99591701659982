import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api.service";

export const getPromoCodeList = createAsyncThunk("employees/promo_code/search", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/promo_codes?q=${payload.q}&startAt=${payload.startAt}&endAt=${payload.endAt}&page=${payload.page}`,
  );
  return response;
});

export const createPromoCode = createAsyncThunk(
  "employees/promo_codes/create123",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/promo_codes`,
      payload,
    );
    return response;
  },
);

export const getPromoCodeDetail = createAsyncThunk(
  "employees/promo_code/detail",
  async (promoCodeId) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/promo_codes/${promoCodeId}`,
    );
    return response;
  },
);

export const updatePromoCode = createAsyncThunk("employees/promo_codes/update", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/promo_codes/${payload.promoCodeId}?params=${payload.params}`,
    payload,
  );
  return response;
});

export const deletePromoCode = createAsyncThunk(
  "employees/promo_codes/delete",
  async (promoCodeId) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/promo_codes/${promoCodeId}`,
    );
    return response;
  },
);

export const getPromoCodeRedemptionList = createAsyncThunk(
  "employees/promo_code/redemptions",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/promo_codes/${payload.promoCodeId}/redemptions?page=${payload.page}`,
    );
    return response;
  },
);

const promoCodeSlice = createSlice({
  name: "promoCode",
  initialState: {
    getPromoCodeListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deletePromoCodeObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createPromoCodeObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPromoCodeDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePromoCodeObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPromoCodeRedemptionListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getPromoCodeList.pending]: (state) => {
      state.getPromoCodeListObj.status = "pending";
    },
    [getPromoCodeList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPromoCodeListObj.status = "succeeded";
      state.getPromoCodeListObj.data = data;
      state.getPromoCodeListObj.successMessage = message;
    },
    [getPromoCodeList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPromoCodeListObj.status = "failed";
      state.getPromoCodeListObj.errorMessage = message;
    },
    [deletePromoCode.pending]: (state) => {
      state.deletePromoCodeObj.status = "pending";
    },
    [deletePromoCode.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deletePromoCodeObj.status = "succeeded";
      state.deletePromoCodeObj.data = data;
      state.deletePromoCodeObj.successMessage = message;
    },
    [deletePromoCode.rejected]: (state, action) => {
      const { message } = action.error;

      state.deletePromoCodeObj.status = "failed";
      state.deletePromoCodeObj.errorMessage = message;
    },
    [createPromoCode.pending]: (state) => {
      state.createPromoCodeObj.status = "pending";
    },
    [createPromoCode.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createPromoCodeObj.status = "succeeded";
      state.createPromoCodeObj.data = data;
      state.createPromoCodeObj.successMessage = message;
    },
    [createPromoCode.rejected]: (state, action) => {
      const { message } = action.error;

      state.createPromoCodeObj.status = "failed";
      state.createPromoCodeObj.errorMessage = message;
    },
    [getPromoCodeDetail.pending]: (state) => {
      state.getPromoCodeDetailObj.status = "pending";
    },
    [getPromoCodeDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPromoCodeDetailObj.status = "succeeded";
      state.getPromoCodeDetailObj.data = data;
      state.getPromoCodeDetailObj.successMessage = message;
    },
    [getPromoCodeDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPromoCodeDetailObj.status = "failed";
      state.getPromoCodeDetailObj.errorMessage = message;
    },
    [updatePromoCode.pending]: (state) => {
      state.updatePromoCodeObj.status = "pending";
    },
    [updatePromoCode.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePromoCodeObj.status = "succeeded";
      state.updatePromoCodeObj.data = data;
      state.updatePromoCodeObj.successMessage = message;

      state.getPromoCodeDetailObj.data = data;
    },
    [updatePromoCode.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePromoCodeObj.status = "failed";
      state.updatePromoCodeObj.errorMessage = message;
    },
    [getPromoCodeRedemptionList.pending]: (state) => {
      state.getPromoCodeRedemptionListObj.status = "pending";
    },
    [getPromoCodeRedemptionList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPromoCodeRedemptionListObj.status = "succeeded";
      state.getPromoCodeRedemptionListObj.data = data;
      state.getPromoCodeRedemptionListObj.successMessage = message;
    },
    [getPromoCodeRedemptionList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPromoCodeRedemptionListObj.status = "failed";
      state.getPromoCodeRedemptionListObj.errorMessage = message;
    },
  },
});

export default promoCodeSlice.reducer;

// state
export const promoCodeSelector = (state) => state.promoCode;
