import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import CtaButton from "../../../../../../components/button/cta-button.component";
import BorderCtaButton from "../../../../../../components/button/border-cta-button.component";
import { merchantPackagePlanSelector } from "../../../../../../services/merchant/package/plan/plan-slice.service";

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[3],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  width: isMobile ? "350px" : "550px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

function PlanDeletionModalConfirmation({
  record,
  showDeletionModal,
  setShowDeletionModal,
  handleOnPressDelete,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { deletePackagePlanObj } = useSelector(merchantPackagePlanSelector);

  const handleCancel = () => {
    setShowDeletionModal(false);
  };

  const handleConfirm = () => {
    handleOnPressDelete(record.id);
  };

  return (
    <>
      <Modal open={showDeletionModal} onClose={handleCancel}>
        <ModalBox isMobile={isMobile}>
          <Grid container spacing={1}>
            <Grid container justifyContent="flex-end">
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" textAlign="center">
                Confirm to proceed removing plan
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>
                  <Grid container justifyContent="center">
                    <img
                      alt="plan-img"
                      src={record.imagePath}
                      width={130}
                      style={{ borderRadius: 8 }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>{record.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>Merchant: {record.merchant.businessName} </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>{record.numberOfSessions} Sessions</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>RM{record.price}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <BorderCtaButton onClickButton={handleCancel}>
                        <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                          Cancel
                        </Typography>
                      </BorderCtaButton>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CtaButton
                        onClickButton={handleConfirm}
                        disabled={deletePackagePlanObj.status === "pending"}
                      >
                        <Typography>Confirm</Typography>
                      </CtaButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ModalBox>
      </Modal>
      <></>
    </>
  );
}

PlanDeletionModalConfirmation.propTypes = {
  showDeletionModal: PropTypes.bool.isRequired,
  setShowDeletionModal: PropTypes.func.isRequired,
  handleOnPressDelete: PropTypes.func.isRequired,
  record: PropTypes.shape({
    deletable: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    imagePath: PropTypes.string.isRequired,
    numberOfPurchasers: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    priceCents: PropTypes.number.isRequired,
    numberOfSessions: PropTypes.number.isRequired,
    status: PropTypes.string,
    title: PropTypes.string.isRequired,
    merchant: PropTypes.shape({
      id: PropTypes.number.isRequired,
      businessName: PropTypes.string.isRequired,
    }),
    planDetails: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
      }),
    ).isRequired,
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ).isRequired,
    category: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
          imagePath: PropTypes.string,
        }),
      ),
    }).isRequired,
  }).isRequired,
};

export default PlanDeletionModalConfirmation;
