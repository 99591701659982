import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Breadcrumbs, Stack, Link, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import PaddedView from "../../../../../components/utils/padded-view.component";
import ImagePlan from "../components/plan/image/image-plan.component";
import PlanDetail from "../components/plan/detail/plan-detail.component";
import {
  getMerchantSubscriptionPlan,
  merchantSubscriptionPlanSelector,
} from "../../../../../services/merchant/subscription/plan/plan-slice.service";

function PlanEditScreen() {
  const dispatch = useDispatch();
  const params = useParams();
  const { getMerchantSubscriptionPlanObj } = useSelector(merchantSubscriptionPlanSelector);

  useEffect(() => {
    dispatch(getMerchantSubscriptionPlan({ planId: params.plan_id }));
  }, []);

  return (
    <>
      <Box>
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Plan Edit</Typography>
            </Grid>
            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link
                    underline="hover"
                    key="2"
                    color="inherit"
                    href="/merchants/subscriptions/plans/list"
                  >
                    <Typography variant="body1">Plan List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    Plan Edit
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
        </PaddedView>

        {getMerchantSubscriptionPlanObj.status === "succeeded" ? (
          <>
            <PaddedView>
              <Grid item>
                <Typography variant="h5">
                  Merchant Business Name:{" "}
                  {getMerchantSubscriptionPlanObj.data.merchant.businessName}
                </Typography>
              </Grid>
            </PaddedView>
            <ImagePlan
              imagePath={getMerchantSubscriptionPlanObj.data.imagePath}
              planId={getMerchantSubscriptionPlanObj.data.id}
            />
            <PlanDetail record={getMerchantSubscriptionPlanObj.data} />
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

export default PlanEditScreen;
