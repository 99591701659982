import React from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

function KatchLogo() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isMobile ? 150 : 200}
      height={isMobile ? 35 : 50}
      viewBox="0 0 335.37 107.799"
    >
      <g id="Katch_Logo_White" data-name="Katch Logo White" transform="translate(0 0)">
        <path
          id="Path_12641"
          data-name="Path 12641"
          d="M386.4,103.5h8.392l-2.665,20.865L412.475,103.5h10.432l-24.436,24.72L419.621,155.6H409.472L390.932,131l-3.118,24.6H379.65Z"
          transform="translate(-277.86 -75.746)"
          fill="#fff"
        />
        <path
          id="Path_12642"
          data-name="Path 12642"
          d="M544.37,149.47h8.164l20.808-36.793,10.486,36.793h8l-16.7-54.35Z"
          transform="translate(-398.416 -69.613)"
          fill="#fff"
        />
        <path
          id="Path_12643"
          data-name="Path 12643"
          d="M734.349,103.53h35.4l-1.051,7.534H755.61l-5.96,44.491h-8.108l5.925-44.521-13.947.043Z"
          transform="translate(-536.852 -75.768)"
          fill="#fff"
        />
        <path
          id="Path_12644"
          data-name="Path 12644"
          d="M922.366,102.879a28.486,28.486,0,0,0-31.294,0,29.778,29.778,0,0,0-12.5,22.76c-.187,2.735-.662,14,7.1,21a19.331,19.331,0,0,0,4.121,2.839,29,29,0,0,0,10.2,3.553,29.717,29.717,0,0,0,16.932-2.617q.808-5.019,1.616-10.036c-.941.756-9.864,7.687-19.862,4.483a18.968,18.968,0,0,1-7.775-4.907,21.834,21.834,0,0,1-4.277-10.574,22.557,22.557,0,0,1,4.277-15.62c.474-.609,7.352-9.132,17.939-8.051a20.011,20.011,0,0,1,12.333,6.266q.6-4.549,1.19-9.1Z"
          transform="translate(-642.955 -71.865)"
          fill="#fff"
        />
        <path
          id="Path_12645"
          data-name="Path 12645"
          d="M1077.159,103.45h8.376l-2.635,19.857h25.131l2.464-19.857h8.335l-6.974,52.044h-8.121l3.231-24.959-25.213.03-3.118,24.929h-8.164Z"
          transform="translate(-783.461 -75.709)"
          fill="#fff"
        />
        <g id="Group_5185" data-name="Group 5185" transform="translate(0 0)">
          <path
            id="Path_12646"
            data-name="Path 12646"
            d="M30,44.265l8.722-8.518a25.458,25.458,0,0,1,9.593-4.756,25.026,25.026,0,0,1,13.886.461A23.954,23.954,0,0,1,77.747,48.885a24.859,24.859,0,0,1-1.941,15.221,23.543,23.543,0,0,1-4.537,6.349,24.038,24.038,0,0,1-8.5,5.671,23.78,23.78,0,0,1-6.807,1.547A27.318,27.318,0,0,1,38.5,71.93Q22.458,56,6.413,40.067A23.438,23.438,0,0,1,1.2,31.45a24.354,24.354,0,0,1,.341-15.988,22.115,22.115,0,0,1,11.226-12.7A24.337,24.337,0,0,1,28.8.474a24.121,24.121,0,0,1,12.325,6.81q8.325,8.228,16.653,16.454l-9.864.794q-6.149-6.1-12.3-12.2A16.568,16.568,0,0,0,23.99,7.3a16.553,16.553,0,0,0-11.679,4.877c-.6.649-6.148,6.893-4.373,15.047A16.542,16.542,0,0,0,13.327,36.1Q27.335,50.157,41.34,64.213a17.675,17.675,0,0,0,10.2,5.673,17.189,17.189,0,0,0,8.164-.681A15.4,15.4,0,0,0,66.4,64.9a15.219,15.219,0,0,0,2.949-4.874,16.774,16.774,0,0,0,.228-11.679,21.121,21.121,0,0,0-3.4-5.215,16.213,16.213,0,0,0-5.1-4.2,15.194,15.194,0,0,0-9.282-1.166,17,17,0,0,0-7.159,2.981l-9.186,8.845L30,44.263Z"
            transform="translate(0.001 0.015)"
            fill="#fff"
          />
          <path
            id="Path_12647"
            data-name="Path 12647"
            d="M21.292,222.375l-5.1-5.215q-4.565,4.508-9.132,9.014A24.533,24.533,0,0,0,.8,238.789a22.588,22.588,0,0,0,.169,9.781,22.134,22.134,0,0,0,2.212,5.612,22.82,22.82,0,0,0,3.743,5.274,23.056,23.056,0,0,0,7.993,5.443,25.362,25.362,0,0,0,5.791,1.566,25.8,25.8,0,0,0,10.113-.547,24.673,24.673,0,0,0,10.853-6.5L58.2,242.786l-10.29-.426L36.457,254a18.229,18.229,0,0,1-4.448,3.247,18.455,18.455,0,0,1-8.5,2.04,16.97,16.97,0,0,1-7.655-2.381,17.361,17.361,0,0,1-7.143-8.673,16.849,16.849,0,0,1-.748-6.759,17.306,17.306,0,0,1,3.982-9.4l9.355-9.7Z"
            transform="translate(-0.25 -158.932)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default KatchLogo;
