import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box, Grid, IconButton, styled, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import BorderCtaButton from "../../../components/button/border-cta-button.component";
import FormFieldNumber from "../../../components/forms/form-field-number.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import OutlinedSelectTextField from "../../../components/forms/outlined-select-text-field.component";
import CenteredRowBox from "../../../components/utils/centered-row-box.component";
import DetailCardContainer from "../../../components/utils/detail-card-container.component";
import Spacer from "../../../components/utils/spacer.component";
import { promoCodeSelector } from "../../../services/promo-code/promo-code-slice.services";
import BusinessListAutoComplete from "./business-list-autocomplete.component";
import ClassListAutoComplete from "./class-list-autocomplete.component";
import MerchantListAutoComplete from "./merchant-list-autocomplete.component";
import PackagePlanListAutoComplete from "./package-plan-list-autocomplete.component";
import PromoFormFieldQuantity from "./promo-form-field-quantity.component";
import SubscriptionPlanListAutoComplete from "./subscription-plan-list-autocomplete.component";
import UserListAutoComplete from "./user-list-autocomplete.component";

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginTop: "20px",
});
const FlexStartRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

const yesNoOption = [
  { type: "No", value: false },
  { type: "Yes", value: true },
];

export default function PromoDetailsForm({ forCreateScreen, isEditing, setIsEditing }) {
  const theme = useTheme();
  const { values, resetForm } = useFormikContext();
  const { getPromoCodeDetailObj } = useSelector(promoCodeSelector);
  return (
    <Grid item xs={12}>
      <DetailCardContainer>
        <CenteredRowBox>
          <Typography variant="h6">Promo Details</Typography>
          {!forCreateScreen && (
            <>
              <Spacer size="s" position="left" />
              <IconButton
                sx={{ color: theme.palette.colors.text.primary }}
                disabled={isEditing.promoRules}
                onClick={() => setIsEditing((i) => ({ ...i, promoRules: true }))}
              >
                <ModeEditIcon />
              </IconButton>
            </>
          )}
        </CenteredRowBox>
        <Spacer size="m" position="top" />
        <FlexStartRowBox>
          <Grid item xs={4}>
            <OutlinedSelectTextField
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="type"
              label="Select type"
              selected=""
              selections={[
                { type: "Percentage", value: "percentage" },
                { type: "Fixed Amount", value: "fixed amount" },
              ]}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={4}>
            <FormFieldText
              name="discount"
              placeholder={values.type === "percentage" ? "Percentage" : "Amount"}
              label={values.type === "percentage" ? "Discount %" : "Discount"}
              disabled={forCreateScreen ? false : !isEditing.promoRules}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={4}>
            <PromoFormFieldQuantity
              name="maxCapAmount"
              placeholder="Max cap amount"
              label="Max cap amount"
              width="100%"
              isPrice
              disabled={
                values.type === "fixed amount" || (forCreateScreen ? false : !isEditing.promoRules)
              }
              selectedType={values.type}
            />
          </Grid>
        </FlexStartRowBox>
        <Spacer size="l" position="top" />
        <FlexStartRowBox>
          <Grid item xs={4}>
            <FormFieldNumber
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="maxNoClaims"
              placeholder="Max no of claims"
              label="Max no of claims"
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={4}>
            <FormFieldNumber
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="maxNoClaimsPerUser"
              placeholder="Max no of claims per user"
              label="Max no of claims per user"
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={4}>
            <FormFieldText
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="minSpentAmount"
              placeholder="Minimum spent amount"
              label="Minimum spent amount"
            />
          </Grid>
        </FlexStartRowBox>
        <Spacer size="l" position="top" />
        <FlexStartRowBox>
          <Grid item xs={4}>
            <OutlinedSelectTextField
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="newUserOnly"
              label="For new user only"
              selections={yesNoOption}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={4}>
            <OutlinedSelectTextField
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="hourlyBookingSpecific"
              label="For hourly booking only"
              selections={yesNoOption}
            />
          </Grid>
          <Grid item xs={4} />
          <Spacer size="xl" position="left" />
        </FlexStartRowBox>
        <Spacer size="l" position="top" />
        <FlexStartRowBox>
          <Grid item xs={4}>
            <OutlinedSelectTextField
              disabled={
                forCreateScreen
                  ? false
                  : getPromoCodeDetailObj.data.owner === "admin" || !isEditing.promoRules
              }
              name="userSpecific"
              label="For specific users"
              selections={yesNoOption}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={12}>
            <UserListAutoComplete
              disabled={
                forCreateScreen
                  ? false
                  : getPromoCodeDetailObj.data.owner === "admin" || !isEditing.promoRules
              }
              name="usersList"
              label="Specific User List"
              placeholder="User's email"
              isSpecific={values.userSpecific}
            />
          </Grid>
        </FlexStartRowBox>
        <Spacer size="l" position="top" />

        <FlexStartRowBox>
          <Grid item xs={4}>
            <OutlinedSelectTextField
              disabled={
                forCreateScreen
                  ? false
                  : getPromoCodeDetailObj.data.owner === "merchant" || !isEditing.promoRules
              }
              name="merchantSpecific"
              label="For specific merchants"
              selections={yesNoOption}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={12}>
            <MerchantListAutoComplete
              disabled={
                forCreateScreen
                  ? false
                  : getPromoCodeDetailObj.data.owner === "merchant" || !isEditing.promoRules
              }
              name="merchantsList"
              label="Specific Merchant List"
              placeholder="Merchant's email"
              isSpecific={values.merchantSpecific}
            />
          </Grid>
        </FlexStartRowBox>
        <Spacer size="l" position="top" />
        <FlexStartRowBox>
          <Grid item xs={4}>
            <OutlinedSelectTextField
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="businessSpecific"
              label="For specific businesses"
              selections={yesNoOption}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={12}>
            <BusinessListAutoComplete
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="businessesList"
              label="Specific Business List"
              placeholder="Business"
              isSpecific={values.businessSpecific}
            />
          </Grid>
        </FlexStartRowBox>
        <Spacer size="l" position="top" />
        <FlexStartRowBox>
          <Grid item xs={4}>
            <OutlinedSelectTextField
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="classSpecific"
              label="For specific classes"
              selections={yesNoOption}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={12}>
            <ClassListAutoComplete
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="classesList"
              label="Specific Class List"
              placeholder="Class"
              isSpecific={values.classSpecific}
            />
          </Grid>
        </FlexStartRowBox>
        <Spacer size="l" position="top" />
        <FlexStartRowBox>
          <Grid item xs={4}>
            <OutlinedSelectTextField
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="packagePlanSpecific"
              label="For specific package plan"
              selections={yesNoOption}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={12}>
            <PackagePlanListAutoComplete
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="packagePlansList"
              label="Specific Package Plan List"
              placeholder="Package Plan"
              isSpecific={values.packagePlanSpecific}
            />
          </Grid>
        </FlexStartRowBox>
        <Spacer size="l" position="top" />
        <FlexStartRowBox>
          <Grid item xs={4}>
            <OutlinedSelectTextField
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="subscriptionPlanSpecific"
              label="For specific subscription plan"
              selections={yesNoOption}
            />
          </Grid>
          <Spacer size="xl" position="left" />
          <Grid item xs={12}>
            <SubscriptionPlanListAutoComplete
              disabled={forCreateScreen ? false : !isEditing.promoRules}
              name="subscriptionPlansList"
              label="Specific Subscription Plan List"
              placeholder="Subscription Plan"
              isSpecific={values.subscriptionPlanSpecific}
            />
          </Grid>
        </FlexStartRowBox>
        <Spacer size="l" position="top" />
        {!forCreateScreen && isEditing.promoRules && (
          <ButtonContainer>
            <BorderCtaButton
              width="100px"
              onClickButton={() => {
                resetForm();
                setIsEditing((i) => ({ ...i, promoRules: false }));
              }}
            >
              <Typography sx={{ color: theme.palette.colors.brand.primary }}>Cancel</Typography>
            </BorderCtaButton>
            <Spacer size="s" position="left" />
            <FormSubmitButton width="100px">
              <Typography>Save</Typography>
            </FormSubmitButton>
          </ButtonContainer>
        )}
      </DetailCardContainer>
    </Grid>
  );
}

PromoDetailsForm.defaultProps = {
  setIsEditing: null,
  isEditing: {
    promoRules: null,
  },
};

PromoDetailsForm.propTypes = {
  setIsEditing: PropTypes.func,
  isEditing: PropTypes.shape({
    promoRules: PropTypes.bool,
  }),
  forCreateScreen: PropTypes.bool.isRequired,
};
