import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableLoader from "../../../components/tables/table-loader.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  getMerchantLeads,
  getMerchantLeadsCSV,
  leadSelector,
} from "../../../services/lead/lead-slice.service";
import FilterOption from "../components/filter-option.component";
import MerchantLeadsListTableIndex from "../components/merchant-leads-list-table-index.component";

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable().label("Search"),
  statuses: Yup.array().min(1).required().label("Statuses"),
});

export default function MerchantLeadsListScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [statuses, setStatuses] = useState(["new", "pending", "done"]);
  const [page, setPage] = useState(0);
  const { getMerchantLeadsObj } = useSelector(leadSelector);

  useEffect(() => {
    dispatch(getMerchantLeads({ q, statuses, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
      dispatch(getMerchantLeadsCSV({ q, statuses }));
    });
  }, []);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getMerchantLeads({ q, statuses, page: newPage })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  };

  const onHandleSubmit = (values) => {
    setQ(values.q);
    setStatuses(values.statuses);
    dispatch(getMerchantLeads({ q: values.q, statuses: values.statuses, page }));
    dispatch(getMerchantLeadsCSV({ q: values.q, statuses: values.statuses }));
  };

  const renderDataRecords = () => {
    const records = getMerchantLeadsObj.data;

    return (
      <>
        {records ? (
          <PaddedView>
            <MerchantLeadsListTableIndex
              handlePageChange={handlePageChange}
              page={page}
              records={records}
            />
          </PaddedView>
        ) : (
          <Typography>No merchant leads.</Typography>
        )}
      </>
    );
  };

  return (
    <Box>
      <Form
        initialValues={{ q: "", statuses: ["new", "pending", "done"] }}
        onSubmit={onHandleSubmit}
        validationSchema={validationSchema}
      >
        <FilterOption />
      </Form>
      <Spacer size="l" />
      {getMerchantLeadsObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </Box>
  );
}
