import React from "react";
import PropTypes from "prop-types";
import { Table, styled, Box, Typography, TableBody } from "@mui/material";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import TableHeader from "../../../components/tables/table-header.component";
import CategoryRow from "./category-row.component";
import CategoryTableLoader from "../loaders/category-table-loader.component";
import TableSort from "../../../components/tables/table-sort.component";

const TableTopContainer = styled(Box)(({ theme }) => ({
  padding: "16px 16px 16px 16px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.colors.ui.tableBorder}`,
  alignItems: "center",
  "& .MuiButton-root": {
    padding: "8px 24px 8px 24px",
    borderTopLeftRadius: theme.shape.borderRadius[3],
    borderTopRightRadius: theme.shape.borderRadius[3],
    borderBottomLeftRadius: theme.shape.borderRadius[3],
    borderBottomRightRadius: theme.shape.borderRadius[3],
    backgroundColor: theme.palette.colors.brand.primary,
    textTransform: "none",
    ":hover": {
      backgroundColor: theme.palette.colors.brand.primary,
    },
  },
}));

function CategoryTableIndex({ onClickDelete, categoryName, categoryListObj }) {
  const columnMapping = {
    ID: "id",
    Label: "label",
    Status: "active",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (categoryListObj.data && categoryListObj.status === "succeeded") {
      records = [...categoryListObj.data];
      const sortedData = records.sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records = sortedData;
    }
    return records;
  };
  const renderTableContent = () => {
    if (categoryListObj.status === "succeeded") {
      const records = getProcessedRecord();
      return (
        <>
          <TableBody>
            {categoryListObj.data &&
              records.map((record) => (
                <CategoryRow
                  key={record.id}
                  record={record}
                  onClickDelete={onClickDelete}
                  categoryName={categoryName}
                />
              ))}
          </TableBody>
        </>
      );
    }
    return <CategoryTableLoader />;
  };

  return (
    <TableWrapper>
      <TableTopContainer>
        <Typography variant="h6">
          {categoryName.charAt(0).toUpperCase() + categoryName.slice(1)} List
        </Typography>
      </TableTopContainer>

      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerTitle=""
          headerCells={["ID", "Label", "Image", "Status", "Action"]}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
          columnMapping={columnMapping}
          nonSortableColumns={["Image"]}
        />
        {renderTableContent()}
      </Table>
    </TableWrapper>
  );
}

CategoryTableIndex.propTypes = {
  onClickDelete: PropTypes.func.isRequired,
  categoryName: PropTypes.string.isRequired,
  categoryListObj: PropTypes.shape({
    status: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        active: PropTypes.bool,
        deletable: PropTypes.bool,
        imagePath: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default CategoryTableIndex;
