import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  Avatar,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import CtaButton from "../../../../components/button/cta-button.component";
import BorderCtaButton from "../../../../components/button/border-cta-button.component";
import { staffSelector } from "../../../../services/staff/staff-slice.service";

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[3],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  width: isMobile ? "350px" : "450px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

function StaffDeletionModalConfirmation({
  record,
  showDeletionModal,
  setShowDeletionModal,
  handleOnPressDelete,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { deleteStaffObj } = useSelector(staffSelector);

  const handleCancel = () => {
    setShowDeletionModal(false);
  };

  const handleConfirm = () => {
    handleOnPressDelete(record.id);
  };

  return (
    <>
      <Modal open={showDeletionModal} onClose={handleCancel}>
        <ModalBox isMobile={isMobile}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <CloseIconButton onClick={handleCancel}>
                  <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
                </CloseIconButton>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" textAlign="center">
                Confirm to proceed removing staff
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Grid container justifyContent="center">
                    <Avatar
                      alt={record.firstName}
                      src={record.imagePath}
                      sx={{ width: 100, height: 100 }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography>Name:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography>
                            {record.firstName} {record.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography>Email:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography>{record.email}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <CtaButton
                        onClickButton={handleConfirm}
                        disabled={deleteStaffObj.status === "pending"}
                      >
                        <Typography>Confirm</Typography>
                      </CtaButton>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <BorderCtaButton onClickButton={handleCancel}>
                        <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                          Cancel
                        </Typography>
                      </BorderCtaButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ModalBox>
      </Modal>
    </>
  );
}

StaffDeletionModalConfirmation.propTypes = {
  showDeletionModal: PropTypes.bool.isRequired,
  setShowDeletionModal: PropTypes.func.isRequired,
  handleOnPressDelete: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    imagePath: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    deletable: PropTypes.bool.isRequired,
  }).isRequired,
};

export default StaffDeletionModalConfirmation;
