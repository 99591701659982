import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Typography, Grid, styled, IconButton } from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import FormImageCropper from "./form-image-cropper.component";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const StyledButtonIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  padding: 0,
  top: 5,
  right: 5,
  backgroundColor: theme.palette.colors.brand.primary,
  width: 25,
  height: 25,
}));

const StyledCloseIcon = styled(CloseIcon)((props) => ({
  width: 17,
  height: 17,
  stroke: props.theme.palette.colors.brand.white,
  strokeWidth: 3,
}));

function FormImageUpload({ name, filePreview, setFilePreview, setUploadedImage, allowEdit }) {
  const theme = useTheme();
  const [image, setImage] = useState("");
  const [showCropper, setShowCropper] = useState(false);
  const { setFieldValue, errors, setFieldError, setFieldTouched, touched } = useFormikContext();
  const showError = allowEdit && touched[name] && typeof errors[name] === "string";

  const handleRemoveImage = () => {
    setFieldValue(name, "");
    setFieldError(name, "");
    setFilePreview("");
    setUploadedImage(false);
  };

  return (
    <>
      <Grid container flexDirection="column">
        {filePreview ? (
          <>
            <Grid item>
              <Box
                sx={{
                  position: "relative",
                  width: 200,
                  "& .MuiIconButton-root": {
                    "&:hover": {
                      backgroundColor: theme.palette.colors.brand.primary,
                    },
                  },
                }}
              >
                <img
                  src={filePreview}
                  alt="package plan"
                  style={{
                    height: 200,
                    width: 200,
                    borderRadius: theme.shape.borderRadius[1],
                    backgroundSize: "100% 100%",
                  }}
                />
                {allowEdit && (
                  <StyledButtonIcon onClick={handleRemoveImage}>
                    <StyledCloseIcon />
                  </StyledButtonIcon>
                )}
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <Button
                onClick={() => {
                  setFieldError(name, "");
                }}
                onBlur={() => setFieldTouched(name)}
                component="label"
                sx={{
                  backgroundColor: "transparent",
                  borderTopLeftRadius: theme.shape.borderRadius[1],
                  borderTopRightRadius: theme.shape.borderRadius[1],
                  borderBottomLeftRadius: theme.shape.borderRadius[1],
                  borderBottomRightRadius: theme.shape.borderRadius[1],
                  border: `2px dashed ${theme.palette.colors.ui.disabled}`,
                  "&:hover": {
                    background: "transparent",
                  },
                }}
              >
                <Box
                  sx={{
                    height: 180,
                    width: 180,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: theme.palette.colors.ui.disabled,
                  }}
                >
                  <AddCircleOutlineRoundedIcon />
                </Box>
                <input
                  type="file"
                  name={name}
                  hidden
                  accept="image/*"
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  onBlur={() => setFieldTouched(name)}
                  onChange={(e) => {
                    const { files } = e.target;
                    if (files) {
                      const myFile = files[0]; // single file upload only
                      const withinFileSizeLimit = myFile && myFile.size <= 5000000; // 1mb = 1^6 bytes
                      const allowedFileType = myFile && SUPPORTED_FORMATS.includes(myFile.type);

                      if (myFile && withinFileSizeLimit && allowedFileType) {
                        setImage(URL.createObjectURL(myFile));
                        setShowCropper(true);
                      } else {
                        if (!withinFileSizeLimit) {
                          setFieldError(name, "Image file cannot be more than 5mb");
                        } else if (!allowedFileType) {
                          setFieldError(name, `Only ${SUPPORTED_FORMATS} are allowed`);
                        }
                        setFilePreview("");
                      }
                    }
                  }}
                />
              </Button>
            </Grid>
          </>
        )}
        <Grid item>
          {showError && errors[name] && (
            <Typography sx={{ color: theme.palette.darkColors.text.error }}>
              {errors[name]}
            </Typography>
          )}
        </Grid>
        <FormImageCropper
          name={name}
          setShowCropper={setShowCropper}
          showCropper={showCropper}
          imageFile={image}
          setFilePreview={setFilePreview}
          setUploadedImage={setUploadedImage}
        />
      </Grid>
    </>
  );
}

FormImageUpload.defaultProps = {
  setUploadedImage: () => {},
};

FormImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  filePreview: PropTypes.string.isRequired,
  setFilePreview: PropTypes.func.isRequired,
  allowEdit: PropTypes.bool.isRequired,
  setUploadedImage: PropTypes.func,
};

export default FormImageUpload;
