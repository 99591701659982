import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import * as Yup from "yup";
import Form from "../../../../../components/forms/form.component";
import FilterOptions from "../components/filter-options.component";
import Spacer from "../../../../../components/utils/spacer.component";
import TableIndex from "../components/table-index.component";
import PaddedView from "../../../../../components/utils/padded-view.component";
import TableLoader from "../../../../../components/tables/table-loader.component";
import { SnackbarContext } from "../../../../../components/notification/snackbar.context";
import {
  fitnessClassSessionSelector,
  getSessionList,
  updateSessionStatus,
} from "../../../../../services/merchant/fitness-class/sessions/session.slice.service";

const validationSchema = Yup.object().shape({
  classesId: Yup.array().nullable().label("Classes"),
  statuses: Yup.array().nullable().label("Statuses"),
  page: Yup.number().required(),
});

function SessionListScreen() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const { getSessionListObj } = useSelector(fitnessClassSessionSelector);
  const [payloadParams, setPayloadParams] = useState(null);
  const [sortCate, setSortCate] = useState({
    className: "idle",
    time: "idle",
    price: "idle",
    status: "idle",
  });
  const createSnackBar = useContext(SnackbarContext);

  useEffect(() => {
    dispatch(getSessionList({ page }));
  }, []);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getSessionList({ ...payloadParams, page: newPage }));
  };

  const handleUpdateSessionStatus = (sessionId, status) => {
    dispatch(updateSessionStatus({ sessionId, status })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      if (meta.requestStatus === "fulfilled") {
        handlePageChange(null, page);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
    });
  };

  const processRecords = () => {
    const sortedData = { ...getSessionListObj.data };

    if (sortCate.className === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.class.name < b.class.name) {
          return 1;
        }
        if (a.class.name > b.class.name) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.className === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.class.name > b.class.name) {
          return 1;
        }
        if (a.class.name < b.class.name) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.time === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.startAt < b.startAt) {
          return 1;
        }
        if (a.startAt > b.startAt) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.time === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.startAt > b.startAt) {
          return 1;
        }
        if (a.startAt < b.startAt) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.price === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.price < b.price) {
          return 1;
        }
        if (a.price > b.price) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.price === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.price > b.price) {
          return 1;
        }
        if (a.price < b.price) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.status === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.status < b.status) {
          return 1;
        }
        if (a.status > b.status) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.status === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.status > b.status) {
          return 1;
        }
        if (a.status < b.status) {
          return -1;
        }
        return 0;
      });
    }

    return sortedData;
  };

  const renderDataRecords = () => (
    <>
      {getSessionListObj.data &&
      getSessionListObj.data.items &&
      getSessionListObj.data.items.length > 0 ? (
        <>
          <PaddedView>
            <TableIndex
              handleUpdateSessionStatus={handleUpdateSessionStatus}
              page={page}
              handlePageChange={handlePageChange}
              records={processRecords()}
              sortCate={sortCate}
              setSortCate={setSortCate}
            />
          </PaddedView>
        </>
      ) : (
        <>
          <Typography>No Sessions</Typography>
        </>
      )}
    </>
  );

  const onHandleSubmit = (values) => {
    const payload = {
      q: values.q,
      classesId: values.classesId.map((item) => item.id).join(","),
      statuses: values.statuses,
      page,
    };
    setPage(0);
    setPayloadParams({
      q: values.q,
      classesId: values.classesId.map((item) => item.id).join(","),
      statuses: values.statuses,
      page,
    });
    dispatch(getSessionList(payload));
  };

  return (
    <>
      <Form
        initialValues={{ classesId: [], statuses: [], page }}
        validationSchema={validationSchema}
        onSubmit={onHandleSubmit}
      >
        <FilterOptions />
      </Form>

      <Spacer size="l" />
      {getSessionListObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </>
  );
}

export default SessionListScreen;
