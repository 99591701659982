import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, InputAdornment, Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import Spacer from "../utils/spacer.component";

function FormFieldTextMultiline({ name, placeholder, type, isCode, rows, label, disabled }) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();

  const showError = touched[name] && typeof errors[name] === "string";

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": {
            borderTopLeftRadius: theme.shape.borderRadius[1],
            borderTopRightRadius: theme.shape.borderRadius[1],
            borderBottomLeftRadius: theme.shape.borderRadius[1],
            borderBottomRightRadius: theme.shape.borderRadius[1],
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderTopLeftRadius: theme.shape.borderRadius[1],
              borderTopRightRadius: theme.shape.borderRadius[1],
              borderBottomLeftRadius: theme.shape.borderRadius[1],
              borderBottomRightRadius: theme.shape.borderRadius[1],
              backgroundColor: theme.palette.colors.bg.primary,
            },
          },
        }}
      >
        <TextField
          label={label}
          disabled={disabled}
          onKeyDown={keyPress}
          rows={rows}
          multiline
          error={showError}
          variant="outlined"
          helperText={showError ? errors[name] : null}
          onBlur={() => setFieldTouched(name)}
          name={name}
          type={type || name}
          placeholder={placeholder}
          onChange={(e) => {
            setFieldValue(
              name,
              isCode ? e.target.value.toUpperCase().replace(" ", "") : e.target.value,
            );
          }}
          InputProps={{
            startAdornment: (
              <>
                {name === "email" && (
                  <InputAdornment position="start">
                    <PersonIcon />
                    <Spacer position="right" />
                  </InputAdornment>
                )}
                {(name === "password" || name === "confirmPassword") && (
                  <InputAdornment position="start">
                    <LockIcon />
                    <Spacer position="right" />
                  </InputAdornment>
                )}
              </>
            ),
          }}
          value={values[name]}
        />
      </Box>
    </>
  );
}

FormFieldTextMultiline.defaultProps = {
  type: null,
  isCode: false,
  rows: 1,
  label: "",
  disabled: false,
};

FormFieldTextMultiline.propTypes = {
  label: PropTypes.string,
  rows: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  isCode: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FormFieldTextMultiline;
