import React, { useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { FormLabel, Grid, IconButton, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import PaddedView from "../../../../components/utils/padded-view.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import OutlinedSelectTextField from "../../../../components/forms/outlined-select-text-field.component";
import PhoneNumberTextField from "../../../../components/forms/phone-number-text-field.component";
import TableWrapper from "../../../../components/tables/table-wrapper.component";
import TableHeader from "../../../../components/tables/table-header.component";
import FormFieldTextMultiline from "../../../../components/forms/form-field-text-multiline.component";
import MerchantBusinessAddressAutoComplete from "./merchant-business-address-autocomplete.component";
import RejectedFormField from "./rejected-form-field.component";
import { updateMerchantBusinessDetail } from "../../../../services/merchant/business/merchant-business-slice.service";
import FormMultiSelect from "../../../../components/forms/form-multi-select.component";

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  description: Yup.string().required().label("About Us"),
  location: Yup.string().required().label("Address"),
  locationLat: Yup.string().required().label("Address"),
  locationLong: Yup.string().required().label("Address"),
  city: Yup.string().label("City").required(),
  state: Yup.string().label("State").required(),
  postcode: Yup.string().label("Postcode").required(),
  contactNo: Yup.string()
    .required()
    .label("Phone number")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{8}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid.",
    ),
  images: Yup.array().of(Yup.object().shape({})).min(1).label("Images"),
  status: Yup.string().required().label("Status"),
  rejectReason: Yup.string()
    .nullable()
    .when("status", {
      is: "rejected_admin",
      then: Yup.string().required(),
    })
    .label("Reject Reason"),
});

function MerchantBusinessDetails({ record, merchantId, businessId, amenity }) {
  const [allowEdit, setAllowEdit] = useState(false);
  const dispatch = useDispatch();
  const onHandleSubmit = (values) => {
    if (values.status !== "rejected_admin") {
      values.rejectReason = "";
    }
    setAllowEdit(false);
    dispatch(updateMerchantBusinessDetail(values));
  };

  return (
    <>
      <PaddedView>
        <Formik
          validationSchema={validationSchema}
          onSubmit={onHandleSubmit}
          initialValues={{
            merchantId,
            businessId,
            name: record.name,
            description: record.description,
            location: record.location,
            locationLat: record.locationLat,
            locationLong: record.locationLong,
            city: record.city,
            state: record.state,
            postcode: record.postcode,
            contactNo: record.contactNo,
            status: record.status,
            amenitiesId: record.amenities.map((option) => option.id),
            rejectReason:
              record.rejections?.length > 0
                ? record.rejections[record.rejections.length - 1].reason
                : "",
          }}
        >
          {({ handleSubmit, dirty }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        alignSelf: "flex-end",
                      }}
                    >
                      <FormLabel>Details</FormLabel>
                    </Grid>
                    <Grid item xs={2}>
                      {allowEdit ? (
                        <>
                          <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setAllowEdit(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAllowEdit(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        name="name"
                        placeholder="Enter Business Name"
                        label="Business Name"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <PhoneNumberTextField name="contactNo" disabled={!allowEdit} />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormFieldTextMultiline
                        rows={3}
                        label="About Us"
                        placeholder="Description About Us"
                        name="description"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <MerchantBusinessAddressAutoComplete name="location" disabled={!allowEdit} />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                      <OutlinedSelectTextField
                        name="status"
                        label="Status"
                        selections={[
                          { type: "Draft", value: "draft" },
                          { type: "Published", value: "publish" },
                          { type: "Rejected", value: "rejected_admin" },
                        ]}
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormMultiSelect
                        name="amenitiesId"
                        label="Amenity"
                        options={amenity.map((option) => ({
                          value: option.id,
                          label: option.amenity,
                        }))}
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <RejectedFormField disabled={!allowEdit} />
                    </Grid>
                    {record.rejections?.length > 0 && (
                      <Grid item xs={12}>
                        <TableWrapper>
                          <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
                            <TableHeader
                              headerTitle="Rejected Reasons"
                              headerCells={["Reason", "Rejected by"]}
                              nonSortableColumns={["Reason", "Rejected by"]}
                            />

                            <TableBody>
                              {record.rejections.map((rejection) => (
                                <TableRow record={record} key={uuidv4()}>
                                  <TableCell>{rejection.reason}</TableCell>
                                  <TableCell>{rejection.rejectedBy}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableWrapper>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </PaddedView>
    </>
  );
}

MerchantBusinessDetails.propTypes = {
  merchantId: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
  amenity: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      amenity: PropTypes.string,
    }),
  ).isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.string,
    locationLat: PropTypes.string,
    locationLong: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postcode: PropTypes.string,
    contactNo: PropTypes.string,
    status: PropTypes.string,
    amenities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        amenity: PropTypes.string,
      }),
    ),
    rejections: PropTypes.arrayOf(
      PropTypes.shape({
        reason: PropTypes.string,
        rejectedBy: PropTypes.string,
      }),
    ),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        imagePath: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default MerchantBusinessDetails;
