import React from "react";
import { Autocomplete, TextField, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

function NotificationSpecificTargetAutoComplete({
  optionList,
  name,
  placeholder,
  type,
  onSearchKeywordChange,
}) {
  const theme = useTheme();
  const { setFieldValue, values } = useFormikContext();

  const getIsDisabled = () => {
    if (values.targetConsumersObj.filter((item) => item.value === type).length > 0) {
      return false;
    }
    return true;
  };

  return (
    <Autocomplete
      disabled={getIsDisabled()}
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width: "100%",
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: theme.palette.colors.bg.primary,
          },
        },
        flex: 1,
      }}
      multiple
      options={optionList}
      getOptionLabel={(option) => option.title}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterSelectedOptions
      value={values[name]}
      onChange={(event, value) => setFieldValue(name, value)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          onChange={(e) => onSearchKeywordChange(values[name], e.target.value)}
        />
      )}
    />
  );
}

NotificationSpecificTargetAutoComplete.defaultProps = {
  onSearchKeywordChange: null,
};

NotificationSpecificTargetAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  optionList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.string.isRequired,
  onSearchKeywordChange: PropTypes.func,
};

export default NotificationSpecificTargetAutoComplete;
