import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import AddDescriptionConfirmationModal from "./add-description-confirmation-modal.component";

export default function PackageDescriptionAddModal({
  planId,
  allowEditDetail,
  setAllowEditDetail,
}) {
  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <>
      <Grid item>
        <Typography variant="body1">Description</Typography>
      </Grid>
      <Grid item xs={2}>
        {allowEditDetail ? (
          <>
            <IconButton size="small" onClick={() => setAllowEditDetail(false)}>
              <CheckIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={() => setShowAddModal(true)}>
              <AddIcon fontSize="small" />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              size="small"
              onClick={() => {
                setAllowEditDetail(true);
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </>
        )}
      </Grid>
      <AddDescriptionConfirmationModal
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        title="Add Detail"
        planId={planId}
      />
    </>
  );
}

PackageDescriptionAddModal.propTypes = {
  planId: PropTypes.number.isRequired,
  allowEditDetail: PropTypes.bool.isRequired,
  setAllowEditDetail: PropTypes.func.isRequired,
};
