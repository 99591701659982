import { Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Form from "../../../../components/forms/form.component";
import TableLoader from "../../../../components/tables/table-loader.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import {
  creditSelector,
  getCreditTransactions,
} from "../../../../services/credit/credit-slice.service";
import CreditTransactionsListTableIndex from "../components/credit-transactions-list-table-index.component";
import FilterOptions from "../components/filter-options.component";

const validationSchema = Yup.object().shape({
  users: Yup.array().label("Users").nullable(),
  types: Yup.array().label("Types").of(Yup.string()).required(),
  startAt: Yup.date().label("Start date").nullable().typeError("Invalid date"),
  endAt: Yup.date()
    .min(Yup.ref("startAt"), "End date can't be before start date")
    .label("End date")
    .nullable()
    .when("startAt", {
      is: (startAt) => startAt,
      then: Yup.date()
        .min(Yup.ref("startAt"), "End date can't be before start date")
        .label("End date")
        .typeError("End date is required")
        .required(),
    }),
});

function CreditTransactionsListScreen() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const { getCreditTransactionsObj } = useSelector(creditSelector);
  const [payloadParams, setPayloadParams] = useState(null);

  useEffect(() => {
    dispatch(getCreditTransactions({ page }));
  }, []);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getCreditTransactions({ ...payloadParams, page: newPage }));
  };

  const renderDataRecords = () => {
    const records = getCreditTransactionsObj.data;

    return (
      <>
        {records ? (
          <PaddedView>
            <CreditTransactionsListTableIndex
              handlePageChange={handlePageChange}
              page={page}
              records={records}
            />
          </PaddedView>
        ) : (
          <Typography>No credit transactions.</Typography>
        )}
      </>
    );
  };

  const onHandleSubmit = (values) => {
    const payload = {
      types: values.types.join(","),
      userIds: values.users.map((item) => item.id).join(","),
      startAt: values.startAt && moment(values.startAt).format("Y-MM-DD 00:00:00"),
      endAt: values.endAt && moment(values.endAt).format("Y-MM-DD 23:59:59"),
      page,
    };
    setPage(0);
    setPayloadParams({
      userIds: values.users.map((item) => item.id).join(","),
      startAt: values.startAt && moment(values.startAt).format("Y-MM-DD 00:00:00"),
      endAt: values.endAt && moment(values.endAt).format("Y-MM-DD 23:59:59"),
    });
    dispatch(getCreditTransactions(payload));
  };

  return (
    <>
      <Form
        initialValues={{
          types: [],
          users: [],
          startAt: null,
          endAt: null,
        }}
        validationSchema={validationSchema}
        onSubmit={onHandleSubmit}
      >
        <FilterOptions />
      </Form>

      <Spacer size="l" />
      {getCreditTransactionsObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </>
  );
}

export default CreditTransactionsListScreen;
