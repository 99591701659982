import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  merchantSubscriptionPlanSelector,
  getMerchantSubscriptionPlans,
  deleteSubscriptionPlan,
} from "../../../../../services/merchant/subscription/plan/plan-slice.service";
import TableIndex from "../components/plan-list/table-index.component";
import TableLoader from "../../../../../components/tables/table-loader.component";
import Form from "../../../../../components/forms/form.component";
import FilterOptions from "../components/plan-list/filter-options.component";
import PaddedView from "../../../../../components/utils/padded-view.component";

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable().label("Search"),
  page: Yup.number().required(),
});

function PlanListScreen() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [payloadParams, setPayloadParams] = useState(null);
  const { getMerchantSubscriptionPlansObj } = useSelector(merchantSubscriptionPlanSelector);
  const [sortCate, setSortCate] = useState({
    title: "idle",
    merchantBusinessName: "idle",
    validity: "idle",
    subscribers: "idle",
    price: "idle",
    status: "idle",
  });

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getMerchantSubscriptionPlans({ ...payloadParams, page: newPage }));
  };

  const handleOnPressDelete = (planId) => {
    dispatch(deleteSubscriptionPlan({ planId })).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        dispatch(getMerchantSubscriptionPlans({ ...payloadParams, page }));
      }
    });
  };

  const processRecords = () => {
    const sortedData = { ...getMerchantSubscriptionPlansObj.data };

    if (sortCate.title === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.title < b.title) {
          return 1;
        }
        if (a.title > b.title) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.title === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.title > b.title) {
          return 1;
        }
        if (a.title < b.title) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.merchantBusinessName === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.merchantBusinessName < b.merchantBusinessName) {
          return 1;
        }
        if (a.merchantBusinessName > b.merchantBusinessName) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.merchantBusinessName === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.merchantBusinessName > b.merchantBusinessName) {
          return 1;
        }
        if (a.merchantBusinessName < b.merchantBusinessName) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.validity === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.numberOfDays < b.numberOfDays) {
          return 1;
        }
        if (a.numberOfDays > b.numberOfDays) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.validity === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.numberOfDays > b.numberOfDays) {
          return 1;
        }
        if (a.numberOfDays < b.numberOfDays) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.subscribers === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.numberOfSubscribers < b.numberOfSubscribers) {
          return 1;
        }
        if (a.numberOfSubscribers > b.numberOfSubscribers) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.subscribers === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.numberOfSubscribers > b.numberOfSubscribers) {
          return 1;
        }
        if (a.numberOfSubscribers < b.numberOfSubscribers) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.price === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.price < b.price) {
          return 1;
        }
        if (a.price > b.price) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.price === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.price > b.price) {
          return 1;
        }
        if (a.price < b.price) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.status === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.status < b.status) {
          return 1;
        }
        if (a.status > b.status) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.status === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.status > b.status) {
          return 1;
        }
        if (a.status < b.status) {
          return -1;
        }
        return 0;
      });
    }
    return sortedData;
  };

  useEffect(() => {
    dispatch(getMerchantSubscriptionPlans({ page }));
  }, []);

  useEffect(() => {
    processRecords();
  }, [
    sortCate.title,
    sortCate.merchantBusinessName,
    sortCate.validity,
    sortCate.subscribers,
    sortCate.price,
    sortCate.status,
  ]);

  const renderDataRecords = () => (
    <>
      {getMerchantSubscriptionPlansObj.data &&
      getMerchantSubscriptionPlansObj.data.items.length > 0 ? (
        <>
          <TableIndex
            page={page}
            handlePageChange={handlePageChange}
            records={processRecords()}
            sortCate={sortCate}
            setSortCate={setSortCate}
            handleOnPressDelete={handleOnPressDelete}
          />
        </>
      ) : (
        <PaddedView>
          <Typography>No plans created yet.</Typography>
        </PaddedView>
      )}
    </>
  );

  const onHandleSubmit = (values) => {
    const payload = {
      q: values.q,
      merchantsId: values.merchantsId.map((item) => item.id).join(","),
      page: 0,
    };
    setPage(0);
    setPayloadParams({
      q: values.q,
      merchantsId: values.merchantsId.map((item) => item.id).join(","),
    });
    dispatch(getMerchantSubscriptionPlans(payload));
  };

  return (
    <>
      <Form
        initialValues={{ merchantsId: [], q: "", page }}
        validationSchema={validationSchema}
        onSubmit={onHandleSubmit}
      >
        <FilterOptions />
      </Form>

      {getMerchantSubscriptionPlansObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </>
  );
}

export default PlanListScreen;
