import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, TableCell, TableRow, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import DeletionModalConfirmation from "./deletion-modal-confirmation.component";

function HighlightsRow({ record, onToggleDelete }) {
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  return (
    <>
      <DeletionModalConfirmation
        showDeletionModal={showDeletionModal}
        setShowDeletionModal={setShowDeletionModal}
        record={record}
        handleOnPressDelete={onToggleDelete}
      />
      <TableRow
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell sx={{ width: "50px" }}>
          <Typography variant="body2">{record.id}</Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography variant="body2">{record.session.event.title}</Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography variant="body2">{record.session.title}</Typography>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Typography variant="body2">{`RM ${record.session.price}`}</Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography variant="body2">{record.session.date}</Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography variant="body2">{record.session.time}</Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <IconButton
            onClick={() => {
              setShowDeletionModal(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

HighlightsRow.propTypes = {
  onToggleDelete: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    session: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      price: PropTypes.string,
      date: PropTypes.string,
      time: PropTypes.string,
      event: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        location: PropTypes.shape({
          name: PropTypes.string,
        }),
        images: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            imagePath: PropTypes.string,
          }).isRequired,
        ),
      }),
    }),
  }).isRequired,
};

export default HighlightsRow;
