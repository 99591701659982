import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import Spacer from "../../../components/utils/spacer.component";

export default function MerchantBusinessEditLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} />
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Skeleton width="100%" height="130px" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton width="100%" height="130px" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton width="100%" height="130px" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton width="100%" height="130px" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={isMobile ? 12 : 5}>
        <Skeleton width="100%" height="52px" />
      </Grid>
      <Grid item xs={isMobile ? 12 : 5}>
        <Skeleton width="100%" height="52px" />
      </Grid>
      <Grid item xs={isMobile ? 12 : 5}>
        <Skeleton width="100%" height="52px" />
      </Grid>
      <Grid item xs={isMobile ? 12 : 5}>
        <Skeleton width="100%" height="52px" />
      </Grid>
      <Grid item xs={isMobile ? 12 : 5}>
        <Skeleton width="100%" height="52px" />
      </Grid>

      <Grid item xs={12}>
        <Spacer size="xxl" position="top" />
      </Grid>
    </Grid>
  );
}
