import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";
import { DebounceInput } from "react-debounce-input";
import BorderCtaButton from "../../../../components/button/border-cta-button.component";
import CtaButton from "../../../../components/button/cta-button.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";

function SearchEntries({ searchKeyword, setSearchKeyword, placeholder }) {
  const theme = useTheme();

  const handleClearSearchUser = () => {
    setSearchKeyword("");
  };

  return (
    <PaddedView>
      <Grid
        container
        sx={{
          backgroundColor: theme.palette.colors.bg.secondary,
          borderRadius: `${theme.shape.borderRadius[1]}px`,
        }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <PaddedView>
                <Box
                  sx={{
                    "& .MuiTextField-root": {
                      borderTopLeftRadius: theme.shape.borderRadius[1],
                      borderTopRightRadius: theme.shape.borderRadius[1],
                      borderBottomLeftRadius: theme.shape.borderRadius[1],
                      borderBottomRightRadius: theme.shape.borderRadius[1],
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderTopLeftRadius: theme.shape.borderRadius[1],
                        borderTopRightRadius: theme.shape.borderRadius[1],
                        borderBottomLeftRadius: theme.shape.borderRadius[1],
                        borderBottomRightRadius: theme.shape.borderRadius[1],
                        backgroundColor: theme.palette.colors.bg.primary,
                      },
                    },
                  }}
                >
                  <DebounceInput
                    minLength={1}
                    debounceTimeout={500}
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                    element={TextField}
                    value={searchKeyword}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                          <Spacer position="right" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <>
                          {searchKeyword && (
                            <InputAdornment position="end">
                              <IconButton onClick={handleClearSearchUser}>
                                <HighlightOffIcon />
                              </IconButton>
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                    placeholder={placeholder}
                  />
                </Box>
              </PaddedView>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
            <Grid item xs={12} sm={3}>
              <PaddedView>
                <CtaButton onClickButton={() => setSearchKeyword(searchKeyword)}>
                  <Typography>Search</Typography>
                </CtaButton>
              </PaddedView>
            </Grid>
            <Grid item xs={12} sm={3}>
              <PaddedView>
                <BorderCtaButton onClickButton={handleClearSearchUser}>
                  <Typography sx={{ color: theme.palette.colors.brand.primary }}>Clear</Typography>
                </BorderCtaButton>
              </PaddedView>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PaddedView>
  );
}

SearchEntries.defaultProps = {
  placeholder: "Search by first name, last name, time or status",
};

SearchEntries.propTypes = {
  searchKeyword: PropTypes.string.isRequired,
  setSearchKeyword: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default SearchEntries;
