import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { useFormikContext } from "formik";
import { FormGroup, FormControlLabel, Checkbox, Box, useTheme } from "@mui/material";

function FormCheckBoxes({ options, name, disabled }) {
  const { setFieldValue, values } = useFormikContext();
  const theme = useTheme();

  const handleChange = (value) => {
    let returnData = [];
    const data = values[name];
    if (data.includes(value)) {
      returnData = data.filter((datum) => datum !== value);
    } else {
      returnData = data.concat(value);
    }
    setFieldValue(name, returnData);
  };

  return (
    <FormGroup>
      {options.map((option) => (
        <Box key={uuidv4()}>
          <FormControlLabel
            checked={values[name].includes(option.id)}
            disabled={disabled}
            control={
              <Checkbox
                onChange={() => handleChange(option.id)}
                sx={{
                  "&.Mui-checked": {
                    color: theme.palette.colors.brand.primary,
                  },
                }}
              />
            }
            label={option.name}
          />
        </Box>
      ))}
    </FormGroup>
  );
}

FormCheckBoxes.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default FormCheckBoxes;
