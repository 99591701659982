import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, patchReq, postReq } from "../api.service";

export const getStatementList = createAsyncThunk("employees/statements/list", async (payload) => {
  const { merchantsId, statementId, status, page, paidStartDate, paidEndDate } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/statements?${
      page ? "page=".concat(page) : "page=1"
    }${merchantsId.length ? "&merchantsId=".concat(merchantsId) : ""}${
      statementId ? "&statementId=".concat(statementId) : ""
    }${status ? "&status=".concat(status) : ""}${
      paidStartDate ? "&paidStartDate=".concat(paidStartDate) : ""
    }${paidEndDate ? "&paidEndDate=".concat(paidEndDate) : ""}`,
  );
  return response;
});

export const getStatementDetail = createAsyncThunk(
  "employees/statements/detail",
  async (statementId) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/statements/${statementId}`,
    );
    return response;
  },
);

export const closeStatement = createAsyncThunk("employees/statements/close", async (payload) => {
  const response = await patchReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/statements/${payload.statementId}/close`,
    payload,
  );
  return response;
});

export const pendingStatement = createAsyncThunk(
  "employees/statements/pending",
  async (payload) => {
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/statements/${payload.statementId}/pending`,
      payload,
    );
    return response;
  },
);

export const disputeStatement = createAsyncThunk(
  "employees/statements/dispute",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/statements/${payload.statementId}/dispute`,
      payload,
    );
    return response;
  },
);

export const getStatementTransactionList = createAsyncThunk(
  "employees/statements/transactions",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/statements/${payload.statementId}/transactions?page=${payload.page}`,
    );
    return response;
  },
);

const statementSlice = createSlice({
  name: "statement",
  initialState: {
    reduxStoreStatementDetails: {},
    getStatementListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getStatementDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    closeStatementObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    pendingStatementObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    disputeStatementObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getStatementTransactionListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    setReduxStoreStatementDetails: (state, action) => {
      const { payload } = action;
      state.reduxStoreStatementDetails = payload;
    },
  },
  extraReducers: {
    [getStatementList.pending]: (state) => {
      state.getStatementListObj.status = "pending";
    },
    [getStatementList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStatementListObj.status = "succeeded";
      state.getStatementListObj.data = data;
      state.getStatementListObj.successMessage = message;
    },
    [getStatementList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStatementListObj.status = "failed";
      state.getStatementListObj.errorMessage = message;
    },
    [getStatementDetail.pending]: (state) => {
      state.getStatementDetailObj.status = "pending";
    },
    [getStatementDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStatementDetailObj.status = "succeeded";
      state.getStatementDetailObj.data = data;
      state.getStatementDetailObj.successMessage = message;
    },
    [getStatementDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStatementDetailObj.status = "failed";
      state.getStatementDetailObj.errorMessage = message;
    },
    [closeStatement.pending]: (state) => {
      state.closeStatementObj.status = "pending";
    },
    [closeStatement.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.closeStatementObj.status = "succeeded";
      state.closeStatementObj.data = data;
      state.closeStatementObj.successMessage = message;

      state.getStatementDetailObj.data = data;
    },
    [closeStatement.rejected]: (state, action) => {
      const { message } = action.error;

      state.closeStatementObj.status = "failed";
      state.closeStatementObj.errorMessage = message;
    },
    [pendingStatement.pending]: (state) => {
      state.pendingStatementObj.status = "pending";
    },
    [pendingStatement.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.pendingStatementObj.status = "succeeded";
      state.pendingStatementObj.data = data;
      state.pendingStatementObj.successMessage = message;

      state.getStatementDetailObj.data = data;
    },
    [pendingStatement.rejected]: (state, action) => {
      const { message } = action.error;

      state.pendingStatementObj.status = "failed";
      state.pendingStatementObj.errorMessage = message;
    },
    [disputeStatement.pending]: (state) => {
      state.disputeStatementObj.status = "pending";
    },
    [disputeStatement.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.disputeStatementObj.status = "succeeded";
      state.disputeStatementObj.data = data;
      state.disputeStatementObj.successMessage = message;

      state.getStatementDetailObj.data = data;
    },
    [disputeStatement.rejected]: (state, action) => {
      const { message } = action.error;

      state.disputeStatementObj.status = "failed";
      state.disputeStatementObj.errorMessage = message;
    },
    [getStatementTransactionList.pending]: (state) => {
      state.getStatementTransactionListObj.status = "pending";
    },
    [getStatementTransactionList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStatementTransactionListObj.status = "succeeded";
      state.getStatementTransactionListObj.data = data;
      state.getStatementTransactionListObj.successMessage = message;
    },
    [getStatementTransactionList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStatementTransactionListObj.status = "failed";
      state.getStatementTransactionListObj.errorMessage = message;
    },
  },
});

export default statementSlice.reducer;

// state
export const statementSelector = (state) => state.statement;

export const { setReduxStoreStatementDetails } = statementSlice.actions;
