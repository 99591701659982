import React from "react";
import { Typography, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import ButtonComponent from "./button.component";

function PasswordUpdated() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = () => {
    navigate("/login");
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.darkColors.bg.tertiary,
          borderRadius: `${theme.shape.borderRadius[2]}px`,
          flex: 1,
          maxWidth: 500,
        }}
      >
        <PaddedView multiples={isMobile ? 3 : 4}>
          <Typography variant="h5">Password Updated !</Typography>
          <Spacer size="xs" />
          <Typography variant="body2">Sign in with your new password now.</Typography>

          <Spacer size="l" />

          <Spacer size="l" />
          <ButtonComponent title="Confirm" submitHandle={handleSubmit} />
        </PaddedView>
      </Box>
    </Box>
  );
}

export default PasswordUpdated;
