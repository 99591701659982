import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TableCell, TableRow, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

function MerchantLeadsRow({ record }) {
  return (
    <TableRow
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "unset",
        },
      }}
    >
      <TableCell>
        <Typography variant="body2">{record.company}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
          {record.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.phone}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.email}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
          {record.status}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <Link
          to={{ pathname: "/leads/merchants/edit", search: `merchantId=${record.id}` }}
          state={{ ...record }}
        >
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  );
}

MerchantLeadsRow.defaultProps = {
  record: {
    id: null,
    name: "",
    email: "",
    company: "",
    phone: "",
    status: "",
  },
};

MerchantLeadsRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
    phone: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default MerchantLeadsRow;
