import React from "react";
import PropTypes from "prop-types";
import { TextField, InputAdornment, Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DebounceInput } from "react-debounce-input";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Spacer from "../../../components/utils/spacer.component";

function SearchAnnouncement({
  searchKeyword,
  setSearchKeyword,
  placeholder,
  onChangeSearchKeyword,
}) {
  const theme = useTheme();

  const handleClearSearch = () => {
    setSearchKeyword("");
  };

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[1],
          borderTopRightRadius: theme.shape.borderRadius[1],
          borderBottomLeftRadius: theme.shape.borderRadius[1],
          borderBottomRightRadius: theme.shape.borderRadius[1],
          width: "100%",
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[1],
            borderTopRightRadius: theme.shape.borderRadius[1],
            borderBottomLeftRadius: theme.shape.borderRadius[1],
            borderBottomRightRadius: theme.shape.borderRadius[1],
            backgroundColor: theme.palette.colors.bg.primary,
          },
        },
      }}
    >
      <DebounceInput
        minLength={1}
        debounceTimeout={500}
        onChange={(e) => onChangeSearchKeyword(e.target.value)}
        element={TextField}
        value={searchKeyword}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
              <Spacer position="right" />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {searchKeyword && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <HighlightOffIcon />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
        }}
        placeholder={placeholder}
      />
    </Box>
  );
}

SearchAnnouncement.defaultProps = {
  placeholder: "Search by first name, last name, time or status",
};

SearchAnnouncement.propTypes = {
  searchKeyword: PropTypes.string.isRequired,
  setSearchKeyword: PropTypes.func.isRequired,
  onChangeSearchKeyword: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default SearchAnnouncement;
