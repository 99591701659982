import React from "react";
import { TextField, InputAdornment, Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DebounceInput } from "react-debounce-input";
import SearchIcon from "@mui/icons-material/Search";
import { useFormikContext } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PropTypes from "prop-types";
import Spacer from "../../../components/utils/spacer.component";

function SearchBar({ name, width }) {
  const theme = useTheme();
  const { setFieldValue, values, handleSubmit } = useFormikContext();

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width,
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
          },
        },
      }}
    >
      <DebounceInput
        debounceTimeout={500}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          setTimeout(() => {
            handleSubmit();
          }, 50);
        }}
        element={TextField}
        value={values[name]}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
              <Spacer position="right" />
            </InputAdornment>
          ),
          endAdornment: values[name] && (
            <InputAdornment position="end">
              <IconButton sx={{ padding: "0px" }} onClick={() => setFieldValue(name, "")}>
                <HighlightOffIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        placeholder="Code / Title"
      />
    </Box>
  );
}

SearchBar.defaultProps = {
  width: "300px",
};

SearchBar.propTypes = {
  width: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default SearchBar;
