import React from "react";
import PropTypes from "prop-types";
import { TextField, Typography, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { v4 as uuidv4 } from "uuid";
import { useTheme } from "@mui/material/styles";
import PaddedView from "../../../components/utils/padded-view.component";

function VerificationBlocks({ verificationCodeSubmitHandle, setShowAlert, showAlert }) {
  const theme = useTheme();
  const CODE_LENGTH = new Array(6).fill(0);
  const codeInput = [];

  const handleChange = (e) => {
    if (showAlert) {
      setShowAlert(false);
    }

    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    const fieldIntIndex = parseInt(fieldIndex, 10);

    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(`input[name=${fieldName}-${fieldIntIndex + 1}]`);

        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
    codeInput[parseInt(e.target.id, 10)] = value;

    verificationCodeSubmitHandle(codeInput);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <PaddedView>
        <Grid>
          <Grid
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {showAlert && (
              <Typography variant="body2" sx={{ color: theme.palette.colors.brand.primary }}>
                *** Invalid Verification Code ***
              </Typography>
            )}
            {CODE_LENGTH.map((item, index) => (
              <TextField
                color={showAlert ? "error" : "primary"}
                key={uuidv4()}
                sx={{ width: 50, height: 100, textAlignLast: "center" }}
                id={index.toString()}
                type="text"
                name={`field-${index}`}
                inputProps={{ maxLength: 1 }}
                onChange={handleChange}
                autoFocus={index === 0}
              />
            ))}
          </Grid>
        </Grid>
      </PaddedView>
    </Box>
  );
}

VerificationBlocks.propTypes = {
  verificationCodeSubmitHandle: PropTypes.func.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
};

export default VerificationBlocks;
