import React from "react";
import { Button, styled } from "@mui/material";
import PropTypes from "prop-types";

const ButtonContainer = styled(Button)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[3],
  borderTopRightRadius: theme.shape.borderRadius[3],
  borderBottomLeftRadius: theme.shape.borderRadius[3],
  borderBottomRightRadius: theme.shape.borderRadius[3],
  backgroundColor: "transparent",
  textTransform: "none",
  border: `1px solid ${theme.palette.colors.brand.primary}`,
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

function BorderButton({ children, onClickButton, disabled, width, height }) {
  return (
    <ButtonContainer
      variant="contained"
      disabled={disabled}
      onClick={onClickButton}
      sx={{ width, height }}
    >
      {children}
    </ButtonContainer>
  );
}

BorderButton.defaultProps = {
  width: "100%",
  disabled: false,
  onClickButton: null,
  height: "100%",
};

BorderButton.propTypes = {
  onClickButton: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default BorderButton;
