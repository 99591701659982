import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Spacer from "../../../components/utils/spacer.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "100%",
  "& .MuiOutlinedInput-root": {
    height: "200px",
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.text.inputField,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}));

export default function RejectReasonModal({ showModal, setShowModal, reason }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          padding: isMobile ? "30px" : "40px",
          width: isMobile ? "350px" : "500px",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Box sx={{ width: "24px" }} />
              <Typography sx={{ textAlign: "center", fontSize: "20px" }}>
                Notification to Merchant
              </Typography>
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="xl" position="top" />
            <Typography>Reject Reason:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="m" position="top" />
            <StyledTextField value={reason} disabled />
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

RejectReasonModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  reason: PropTypes.string.isRequired,
};
