import { Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Form from "../../../../components/forms/form.component";
import TableLoader from "../../../../components/tables/table-loader.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import {
  getMerchantHourlyBookings,
  merchantHourlyBookingSelector,
} from "../../../../services/merchant/hourly-booking/hourly-booking-slice.service";
import FilterOptions from "../components/filter-options.component";
import TableIndex from "../components/table-index.component";

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable(),
  businesses: Yup.array().label("Businesses").nullable(),
  statuses: Yup.array().label("Status").of(Yup.string()).required(),
  startAt: Yup.date().label("Start date").nullable().typeError("Invalid date"),
  endAt: Yup.date()
    .min(Yup.ref("startAt"), "End date can't be before start date")
    .label("End date")
    .nullable()
    .when("startAt", {
      is: (startAt) => startAt,
      then: Yup.date()
        .min(Yup.ref("startAt"), "End date can't be before start date")
        .label("End date")
        .typeError("End date is required")
        .required(),
    }),
});

function HourlyBookingListScreen() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const { getMerchantHourlyBookingsObj } = useSelector(merchantHourlyBookingSelector);
  const [payloadParams, setPayloadParams] = useState(null);
  const [sortCate, setSortCate] = useState({
    name: "idle",
    merchant: "idle",
    business: "idle",
    price: "idle",
  });

  useEffect(() => {
    dispatch(getMerchantHourlyBookings({ page }));
  }, []);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getMerchantHourlyBookings({ ...payloadParams, page: newPage }));
  };

  const processRecords = () => {
    const sortedData = { ...getMerchantHourlyBookingsObj.data };

    if (sortCate.name === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.user.name < b.user.name) {
          return 1;
        }
        if (a.user.name > b.user.name) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.name === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.user.name > b.user.name) {
          return 1;
        }
        if (a.user.name < b.user.name) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.merchant === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.merchant.businessName < b.merchant.businessName) {
          return 1;
        }
        if (a.merchant.businessName > b.merchant.businessName) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.merchant === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.merchant.businessName > b.merchant.businessName) {
          return 1;
        }
        if (a.merchant.businessName < b.merchant.businessName) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.business === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.business.name < b.business.name) {
          return 1;
        }
        if (a.business.name > b.business.name) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.business === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.business.name > b.business.name) {
          return 1;
        }
        if (a.business.name < b.business.name) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.price === "dsc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.booking.price < b.booking.price) {
          return 1;
        }
        if (a.booking.price > b.booking.price) {
          return -1;
        }
        return 0;
      });
    } else if (sortCate.price === "asc") {
      sortedData.items = [...sortedData.items].sort((a, b) => {
        if (a.booking.price > b.booking.price) {
          return 1;
        }
        if (a.booking.price < b.booking.price) {
          return -1;
        }
        return 0;
      });
    }

    return sortedData;
  };

  const renderDataRecords = () => (
    <>
      {getMerchantHourlyBookingsObj.data &&
      getMerchantHourlyBookingsObj.data.items &&
      getMerchantHourlyBookingsObj.data.items.length > 0 ? (
        <>
          <PaddedView>
            <TableIndex
              page={page}
              handlePageChange={handlePageChange}
              records={processRecords()}
              sortCate={sortCate}
              setSortCate={setSortCate}
            />
          </PaddedView>
        </>
      ) : (
        <>
          <Typography>No Bookings</Typography>
        </>
      )}
    </>
  );

  const onHandleSubmit = (values) => {
    const payload = {
      q: values.q,
      statuses: values.statuses.join(","),
      businessesId: values.businesses.map((item) => item.id).join(","),
      startAt: values.startAt && moment(values.startAt).format("Y-MM-DD 00:00:00"),
      endAt: values.endAt && moment(values.endAt).format("Y-MM-DD 23:59:59"),
      page,
    };
    setPage(0);
    setPayloadParams({
      q: values.q,
      businessesId: values.businesses.map((item) => item.id).join(","),
      startAt: values.startAt && moment(values.startAt).format("Y-MM-DD 00:00:00"),
      endAt: values.endAt && moment(values.endAt).format("Y-MM-DD 23:59:59"),
    });
    dispatch(getMerchantHourlyBookings(payload));
  };

  return (
    <>
      <Form
        initialValues={{
          statuses: [],
          q: "",
          businesses: [],
          startAt: null,
          endAt: null,
        }}
        validationSchema={validationSchema}
        onSubmit={onHandleSubmit}
      >
        <FilterOptions />
      </Form>

      <Spacer size="l" />
      {getMerchantHourlyBookingsObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </>
  );
}

export default HourlyBookingListScreen;
