import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import FilterOptions from "../components/filter-options.component";
import Form from "../../../../components/forms/form.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import TableLoader from "../../../../components/tables/table-loader.component";
import {
  deleteAdditionalFees,
  merchantAdditionalFeesSelector,
  searchMerchantAdditionalFees,
} from "../../../../services/merchant/additional-fees/additional-fees-slice.service";
import AdditionalFeesListTableIndex from "../components/additional-fees-list-table-index.component";

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable().label("Search"),
  page: Yup.number().required(),
});

function MerchantAdditionalFeesListScreen() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [payloadParams, setPayloadParams] = useState(null);
  const { searchMerchantAdditionalFeesObj } = useSelector(merchantAdditionalFeesSelector);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(searchMerchantAdditionalFees({ ...payloadParams, page: newPage }));
  };

  const handleOnPressDelete = (feeId) => {
    dispatch(deleteAdditionalFees({ feeId })).then(({ meta }) => {
      if (meta.requestStatus === "fulfilled") {
        dispatch(searchMerchantAdditionalFees({ ...payloadParams, page }));
      }
    });
  };

  const onHandleSubmit = (values) => {
    const payload = {
      q: values.q,
      merchantsId: values.merchantsId.map((item) => item.id).join(","),
      page: 0,
    };
    setPage(0);
    setPayloadParams({
      q: values.q,
      merchantsId: values.merchantsId.map((item) => item.id).join(","),
    });
    dispatch(searchMerchantAdditionalFees(payload));
  };

  const renderDataRecords = () => {
    const records = searchMerchantAdditionalFeesObj.data;
    return (
      <>
        {searchMerchantAdditionalFeesObj.data.items.length > 0 ? (
          <PaddedView>
            <AdditionalFeesListTableIndex
              handlePageChange={handlePageChange}
              page={page}
              onToggleDelete={handleOnPressDelete}
              records={records}
            />
          </PaddedView>
        ) : (
          <PaddedView>
            <Typography>No additional fees created yet.</Typography>
          </PaddedView>
        )}
      </>
    );
  };

  useEffect(() => {
    dispatch(searchMerchantAdditionalFees({ page }));
  }, []);

  return (
    <>
      <Form
        initialValues={{ merchantsId: [], q: "", page }}
        validationSchema={validationSchema}
        onSubmit={onHandleSubmit}
      >
        <FilterOptions />
      </Form>

      {searchMerchantAdditionalFeesObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </>
  );
}

export default MerchantAdditionalFeesListScreen;
