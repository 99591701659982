import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, useMediaQuery, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as Yup from "yup";

import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import Form from "../../../components/forms/form.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

function EmailForm({ setUserEmail, handleSubmit }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const submitHandle = (values) => {
    setUserEmail(values.email);
    handleSubmit(values);
  };

  return (
    <Form initialValues={{ email: "" }} validationSchema={validationSchema} onSubmit={submitHandle}>
      <PaddedView multiples={isMobile ? 3 : 4}>
        <Typography variant="h5">Forgot Password</Typography>
        <Spacer size="xs" />
        <Typography variant="body2">Please enter your email address</Typography>

        <Spacer size="l" />
        <FormFieldText name="email" placeholder="Email" label="Email" />

        <Spacer size="l" />
        <FormSubmitButton>
          <Typography>Confirm</Typography>
        </FormSubmitButton>
        <Spacer size="l" />
        <Grid>
          <Grid container justifyContent="flex-start">
            <Grid>
              <Link
                sx={{ paddingLeft: 1 }}
                underline="none"
                key="1"
                variant="body2"
                href="/login"
                color={theme.palette.text.primary}
              >
                {"< BACK"}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
    </Form>
  );
}

EmailForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setUserEmail: PropTypes.func.isRequired,
};

export default EmailForm;
