import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  IconButton,
  TableRow,
  Box,
  Collapse,
  Table,
  TableBody,
  useTheme,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Spacer from "../../../components/utils/spacer.component";
import TableHeader from "../../../components/tables/table-header.component";

function PromoCodeRow({ record }) {
  const [collapse, setCollapse] = useState(false);
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const theme = useTheme();

  return (
    <>
      <TableRow
        key={record.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "0px",
          },
        }}
      >
        <TableCell width="25px">
          <Typography variant="body2">{record.id}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{record.title}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{record.code}</Typography>
        </TableCell>
        <TableCell width="100px">
          <Typography variant="body2">
            {record.type === "percentage" ? `${record.discount}%` : `RM ${record.discount}`}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" sx={{ minWidth: "140px" }}>
            {record.startAt}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" sx={{ minWidth: "140px" }}>
            {record.endAt}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{record.redeemedCount}</Typography>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
              {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Spacer size="m" />
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography>Promo Code Details</Typography>
            </Box>
            <Spacer />
            <Table size="small">
              <TableHeader
                headerCells={[
                  "Type",
                  "Max cap amount",
                  "Max no. claims",
                  "Max no. claims per user",
                  "New user only",
                  "",
                ]}
                nonSortableColumns={[
                  "Type",
                  "Max cap amount",
                  "Max no. claims",
                  "Max no. claims per user",
                  "New user only",
                  "",
                ]}
              />
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" noWrap={true}>
                      {capitalize(record.type.replace("_", " "))}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" noWrap={true}>
                      {`RM ${record.maxCapAmount}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" noWrap={true}>
                      {record.maxNoClaims}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" noWrap={true}>
                      {record.maxNoClaimsPerUser}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" noWrap={true}>
                      {record.newUserOnly ? "Yes" : "No"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {record.editable && (
                      <Link
                        style={{ textDecorationColor: theme.palette.colors.brand.primary }}
                        to={{
                          pathname: "/promo_codes/details/edit",
                          search: `promoCodeId=${record.id}`,
                        }}
                      >
                        <Typography variant="body2" noWrap={true} type="BrandColor">
                          View Detail
                        </Typography>
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Spacer size="m" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

PromoCodeRow.defaultProps = {
  record: {
    id: null,
    title: "",
    code: "",
    discount: "",
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    type: "",
    maxCapAmount: "",
    maxNoClaims: "",
    maxNoClaimsPerUser: "",
    newUserOnly: "",
    userSpecific: "",
    redeemedCount: "",
    editable: false,
  },
};

PromoCodeRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    editable: PropTypes.bool,
    code: PropTypes.string,
    discount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    type: PropTypes.string,
    maxCapAmount: PropTypes.string,
    maxNoClaims: PropTypes.number,
    maxNoClaimsPerUser: PropTypes.number,
    newUserOnly: PropTypes.bool,
    userSpecific: PropTypes.bool,
    redeemedCount: PropTypes.number,
  }),
};

export default PromoCodeRow;
