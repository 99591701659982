import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableHead, TableRow, IconButton, Typography, Box } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { v4 as uuidv4 } from "uuid";
import Spacer from "../../../../components/utils/spacer.component";

function TableHeader({ headerCells, headerTitle, setSortCate, sortCate }) {
  const renderTableCell = (headCell) => {
    switch (headCell) {
      case "Name":
        return (
          <>
            <Box sx={{ display: "flex", paddingTop: 1 }}>
              <Typography>{headCell}</Typography>
              <Spacer position="right" />
              <IconButton
                size="small"
                sx={{ marginTop: "-5px", marginLeft: "-10px" }}
                onClick={() =>
                  setSortCate({
                    name: sortCate.name === "asc" ? "dsc" : "asc",
                    merchant: "idle",
                    business: "idle",
                    price: "idle",
                  })
                }
              >
                {sortCate.name === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Box>
          </>
        );
      case "Merchant":
        return (
          <>
            <Box sx={{ display: "flex", paddingTop: 1 }}>
              <Typography>{headCell}</Typography>
              <Spacer position="right" />
              <IconButton
                size="small"
                sx={{ marginTop: "-5px", marginLeft: "-10px" }}
                onClick={() =>
                  setSortCate({
                    name: "idle",
                    merchant: sortCate.merchant === "asc" ? "dsc" : "asc",
                    business: "idle",
                    price: "idle",
                  })
                }
              >
                {sortCate.merchant === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Box>
          </>
        );
      case "Business":
        return (
          <>
            <Box sx={{ display: "flex", paddingTop: 1 }}>
              <Typography>{headCell}</Typography>
              <Spacer position="right" />
              <IconButton
                size="small"
                sx={{ marginTop: "-5px", marginLeft: "-10px" }}
                onClick={() =>
                  setSortCate({
                    name: "idle",
                    merchant: "idle",
                    business: sortCate.business === "asc" ? "dsc" : "asc",
                    price: "idle",
                  })
                }
              >
                {sortCate.business === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Box>
          </>
        );
      case "Price":
        return (
          <>
            <Box sx={{ display: "flex", paddingTop: 1 }}>
              <Typography>{headCell}</Typography>
              <Spacer position="right" />
              <IconButton
                size="small"
                sx={{ marginTop: "-5px", marginLeft: "-10px" }}
                onClick={() =>
                  setSortCate({
                    name: "idle",
                    merchant: "idle",
                    business: "idle",
                    price: sortCate.price === "asc" ? "dsc" : "asc",
                  })
                }
              >
                {sortCate.price === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Box>
          </>
        );

      default:
        return <Typography>{headCell}</Typography>;
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headerCells.map((headCell, index) => (
          <TableCell key={uuidv4()}>
            <>{index === 0 && <Typography variant="h6">{headerTitle}</Typography>}</>
          </TableCell>
        ))}
      </TableRow>
      <TableRow
        sx={{
          "& .MuiTableCell-root": {
            borderTop: "1px solid #e0e0e0",
          },
        }}
      >
        {headerCells.map((headCell) => (
          <TableCell key={headCell}>{renderTableCell(headCell)}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TableHeader.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  headerCells: PropTypes.arrayOf(
    PropTypes.oneOf(["Name", "Merchant", "Business", "Price", "Start At", "End At", "Status"]),
  ).isRequired,
  sortCate: PropTypes.shape({
    name: PropTypes.oneOf(["idle", "asc", "dsc"]),
    merchant: PropTypes.oneOf(["idle", "asc", "dsc"]),
    business: PropTypes.oneOf(["idle", "asc", "dsc"]),
    price: PropTypes.oneOf(["idle", "asc", "dsc"]),
  }).isRequired,
  setSortCate: PropTypes.func.isRequired,
};

export default TableHeader;
