import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import React from "react";
import BorderCtaButton from "../../../../components/button/border-cta-button.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormMultiSelect from "../../../../components/forms/form-multi-select.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import FormDatePicker from "../../../earning/components/form-date-picker.component";
import BusinessListAutoComplete from "./business-list-autocomplete.component";

function FilterOptions() {
  const theme = useTheme();
  const { resetForm, handleSubmit } = useFormikContext();

  const handleClearForm = () => {
    resetForm();
    handleSubmit();
  };

  return (
    <>
      <PaddedView>
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.colors.bg.secondary,
            borderRadius: `${theme.shape.borderRadius[1]}px`,
          }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <PaddedView>
                      <BusinessListAutoComplete
                        name="businesses"
                        placeholder="Merchant Businesses"
                        multiple={true}
                      />
                    </PaddedView>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PaddedView>
                      <FormMultiSelect
                        name="statuses"
                        label="Statuses"
                        options={[
                          { label: "Active", value: "active" },
                          { label: "Expired", value: "expired" },
                          { label: "Pending Payment", value: "pending_payment" },
                          { label: "Payment Cancelled", value: "payment_cancelled" },
                          { label: "Refunded", value: "refunded" },
                        ]}
                      />
                    </PaddedView>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <PaddedView>
                      <FormDatePicker
                        name="startAt"
                        disablePast={false}
                        width="100%"
                        placeholder="Start At"
                      />
                    </PaddedView>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PaddedView>
                      <FormDatePicker
                        name="endAt"
                        disablePast={false}
                        width="100%"
                        placeholder="End At"
                      />
                    </PaddedView>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <PaddedView>
                  <FormFieldText
                    name="q"
                    placeholder="Search for bookings by user name"
                    label="Search"
                  />
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <FormSubmitButton type="submit">
                    <Typography>Search</Typography>
                  </FormSubmitButton>
                </PaddedView>
              </Grid>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <BorderCtaButton onClickButton={handleClearForm}>
                    <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                      Clear
                    </Typography>
                  </BorderCtaButton>
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
    </>
  );
}

export default FilterOptions;
