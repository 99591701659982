import { Box, Breadcrumbs, Grid, Link, Stack, styled, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormFieldTextMultiline from "../../../components/forms/form-field-text-multiline.component";
import FormFieldTextWithCounter from "../../../components/forms/form-field-text-with-counter.component";

import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import SimpleBackdrop from "../../../components/utils/backdrop-loading.component";
import DetailCardContainer from "../../../components/utils/detail-card-container.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { createPromoCode } from "../../../services/promo-code/promo-code-slice.services";
import PromoDetailsForm from "../components/promo-details-form.component";
import PromoPeriodForm from "../components/promo-period-form.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .nullable()
    .label("Code")
    .length(6, "Code must have exactly 6 alphanumeric character"),
  title: Yup.string().required().max(50).label("Title"),
  description: Yup.string().required().label("Description"),
  startDate: Yup.date().required().label("Start Date").typeError("Invalid date"),
  startTime: Yup.date().required().label("Start Time"),
  endDate: Yup.date()
    .label("End Date")
    .min(Yup.ref("startDate"), "End date can't earlier than start date")
    .required(),
  endTime: Yup.date()
    .label("End Time")
    .required()
    .when(["startDate", "endDate"], (startDate, endDate) => {
      try {
        if (format(endDate, "yyyy-MM-dd") === format(startDate, "yyyy-MM-dd")) {
          return Yup.date()
            .label("End Time")
            .min(Yup.ref("startTime"), "End time can't earlier than start time on the same day")
            .required();
        }

        return Yup.date().label("End Time").required();
      } catch (err) {
        return Yup.date().label("End Time").required();
      }
    }),
  type: Yup.string().label("Type").required(),
  discount: Yup.number()
    .required()
    .when("type", {
      is: "percentage",
      then: Yup.number().integer().min(0).max(100).required(),
    })
    .when("type", {
      is: "fixed amount",
      then: Yup.number().min(1).required(),
    })
    .label("Discount"),
  maxCapAmount: Yup.number().when("type", {
    is: "percentage",
    then: Yup.number().min(0).required(),
  }),
  maxNoClaims: Yup.number().min(0).required(),
  maxNoClaimsPerUser: Yup.number().min(0).required(),
  newUserOnly: Yup.bool().label("New User Only").required(),
  minSpentAmount: Yup.number().min(0).required(),
  userSpecific: Yup.bool().label("Users Specific").required(),
  usersList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("userSpecific", {
      is: true,
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific User List"),
  merchantSpecific: Yup.bool().label("Merchants Specific").required(),
  merchantsList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("merchantSpecific", {
      is: true,
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific Merchant List"),
  businessSpecific: Yup.bool().label("Business Specific").required(),
  businessesList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("businessSpecific", {
      is: true,
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific Business List"),
  classSpecific: Yup.bool().label("Class Specific").required(),
  classesList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("classSpecific", {
      is: true,
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific Class List"),
  packagePlanSpecific: Yup.bool().label("Package Plan Specific").required(),
  packagePlansList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("packagePlanSpecific", {
      is: true,
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific Package Plan List"),
  subscriptionPlanSpecific: Yup.bool().label("Subscription Plan Specific").required(),
  subscriptionPlansList: Yup.array()
    .of(Yup.object().shape({ id: Yup.number() }))
    .when("subscriptionPlanSpecific", {
      is: true,
      then: Yup.array()
        .of(Yup.object().shape({ id: Yup.number() }))
        .required()
        .min(1),
    })
    .label("Specific Subscription Plan List"),
  hourlyBookingSpecific: Yup.bool().label("Hourly Booking Specific").required(),
});

export default function PromoCodeCreateScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);

  const onCreatePromoCode = (values) => {
    setIsLoading(true);
    const payload = { ...values };

    if (!values.userSpecific) {
      payload.usersList = [];
    } else {
      payload.usersList = payload.usersList.map((item) => item.id);
    }
    if (!values.merchantSpecific) {
      payload.merchantsList = [];
    } else {
      payload.merchantsList = payload.merchantsList.map((item) => item.id);
    }
    if (!values.businessSpecific) {
      payload.businessesList = [];
    } else {
      payload.businessesList = payload.businessesList.map((item) => item.id);
    }
    if (!values.classSpecific) {
      payload.classesList = [];
    } else {
      payload.classesList = payload.classesList.map((item) => item.id);
    }
    if (!values.subscriptionPlanSpecific) {
      payload.subscriptionPlansList = [];
    } else {
      payload.subscriptionPlansList = payload.subscriptionPlansList.map((item) => item.id);
    }
    if (!values.packagePlanSpecific) {
      payload.packagePlansList = [];
    } else {
      payload.packagePlansList = payload.packagePlansList.map((item) => item.id);
    }

    if (values.type === "fixed amount") payload.maxCapAmount = payload.discount;
    const startAt = `${format(new Date(values.startDate), "yyyy-MM-dd")} ${format(
      new Date(values.startTime),
      "HH:mm:00",
    )}`;
    const endAt = `${format(new Date(values.endDate), "yyyy-MM-dd")} ${format(
      new Date(values.endTime),
      "HH:mm:00",
    )}`;

    dispatch(
      createPromoCode({
        ...payload,
        startAt,
        endAt,
      }),
    ).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        navigate("/promo_codes/list");
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <PaddedView multiples={3}>
      <SimpleBackdrop isLoading={isLoading} />
      <Grid item display="flex" justifyContent="end">
        <Stack spacing={2} sx={{ paddingRight: 1 }}>
          <Breadcrumbs separator=">" aria-label="breadcrumb">
            <Link underline="hover" key="1" color="inherit" href="/">
              <Typography variant="body1">Dashboard</Typography>
            </Link>
            <Link underline="hover" key="2" color="inherit" href="/promo_codes/list">
              <Typography variant="body1">Promo Code List</Typography>
            </Link>
            <Typography variant="body1" sx={{ textDecoration: "underline" }}>
              New Promo Code
            </Typography>
          </Breadcrumbs>
        </Stack>
      </Grid>
      <Spacer size="xl" position="top" />
      <Form
        validationSchema={validationSchema}
        onSubmit={onCreatePromoCode}
        initialValues={{
          code: "",
          title: "",
          description: "",
          startDate: new Date(),
          endDate: new Date(),
          startTime: new Date(),
          endTime: new Date(),
          type: "",
          discount: "",
          maxCapAmount: "",
          maxNoClaims: 0,
          maxNoClaimsPerUser: 1,
          newUserOnly: false,
          minSpentAmount: 0,
          userSpecific: false,
          usersList: [],
          merchantSpecific: false,
          merchantsList: [],
          businessSpecific: false,
          businessesList: [],
          hourlyBookingSpecific: false,
          subscriptionPlanSpecific: false,
          subscriptionPlansList: [],
          packagePlanSpecific: false,
          packagePlansList: [],
          classSpecific: false,
          classesList: [],
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Typography variant="h4">Create Promo Code</Typography>
            </SpaceBetweenBox>
          </Grid>
          <Grid item xs={12}>
            <DetailCardContainer>
              <Grid item xs={12}>
                <Typography>Code (Optional)</Typography>
                <Spacer size="xs" position="top" />
                <FormFieldTextWithCounter
                  maxLength={6}
                  name="code"
                  placeholder="Autogenerated if left empty"
                  width="100%"
                  toUpper={true}
                />
              </Grid>
            </DetailCardContainer>
          </Grid>
          <Grid item xs={12}>
            <DetailCardContainer>
              <Grid item xs={12}>
                <Typography>Title</Typography>
                <Spacer size="xs" position="top" />
                <FormFieldTextWithCounter
                  maxLength={50}
                  name="title"
                  placeholder="Enter Promo Code Title"
                  width="100%"
                />
              </Grid>
            </DetailCardContainer>
          </Grid>
          <Grid item xs={12}>
            <DetailCardContainer>
              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                <Typography>Description</Typography>
                <Spacer size="xs" position="top" />
                <FormFieldTextMultiline
                  name="description"
                  placeholder="Enter promo code description here..."
                  multiline
                  rows={5}
                />
              </Box>
            </DetailCardContainer>
          </Grid>
          <PromoPeriodForm forCreateScreen={true} />
          <PromoDetailsForm forCreateScreen={true} />
          <Grid item xs={12} />
          <Grid container justifyContent="flex-end">
            <SpaceBetweenBox>
              <FormSubmitButton width="125px" isLoading={false}>
                <Typography>Confirm</Typography>
              </FormSubmitButton>
            </SpaceBetweenBox>
          </Grid>
          <Grid item xs={12} />
        </Grid>
      </Form>
    </PaddedView>
  );
}
