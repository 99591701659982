import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Typography, Grid, IconButton, styled } from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import ReactPlayer from "react-player";
import Spacer from "../../../components/utils/spacer.component";

const SUPPORTED_FORMATS = ["video/mp4"];

const StyledButtonIcon = styled(IconButton)((props) => ({
  position: "absolute",
  padding: 0,
  top: 10,
  right: 10,
  backgroundColor: props.theme.palette.colors.brand.primary,
  width: 25,
  height: 25,
}));

const StyledCloseIcon = styled(CloseIcon)((props) => ({
  width: 17,
  height: 17,
  stroke: props.theme.palette.colors.brand.white,
  strokeWidth: 3,
}));

function PopUpVideoUpload({ name, video, onVideoUpload, onAttachmentDelete }) {
  const theme = useTheme();
  const { errors, setFieldError } = useFormikContext();

  const renderUploadButton = () => (
    <Button
      onClick={() => setFieldError(name, "")}
      component="label"
      sx={{
        backgroundColor: "transparent",
        borderTopLeftRadius: theme.shape.borderRadius[1],
        borderTopRightRadius: theme.shape.borderRadius[1],
        borderBottomLeftRadius: theme.shape.borderRadius[1],
        borderBottomRightRadius: theme.shape.borderRadius[1],
        border: `2px dashed ${theme.palette.colors.ui.disabled}`,
        "&:hover": {
          background: "transparent",
        },
      }}
    >
      <Box
        sx={{
          minHeight: "150px",
          height: "100%",
          width: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme.palette.colors.ui.disabled,
        }}
      >
        <AddCircleOutlineRoundedIcon />
      </Box>
      <input
        type="file"
        name={name}
        hidden
        accept="video/*"
        onChange={(e) => {
          const { files } = e.target;
          if (files) {
            const selectedFIles = [];
            const targetFilesObject = [...files];
            targetFilesObject.map((file) => selectedFIles.push(file));
            const withinFileSizeLimit = selectedFIles.some((item) => item.size <= 5000000); // 1mb = 1^6 bytes
            const allowedFileType = selectedFIles.some((item) =>
              SUPPORTED_FORMATS.includes(item.type),
            );

            if (withinFileSizeLimit && allowedFileType) {
              for (let i = 0; i < selectedFIles.length; i += 1) {
                const fileReader = new FileReader();
                fileReader.onload = () => {
                  if (fileReader.readyState === 2) {
                    onVideoUpload(fileReader.result);
                  }
                };
                fileReader.readAsDataURL(selectedFIles[i]);
              }
            } else if (!withinFileSizeLimit) {
              setFieldError(name, "Image file cannot be more than 5mb");
            } else if (!allowedFileType) {
              setFieldError(name, `Only ${SUPPORTED_FORMATS} are allowed`);
            }
          }
        }}
      />
    </Button>
  );

  const renderContent = () => {
    if (video.length > 0) {
      return (
        <Grid container sx={{ flexDirection: "row" }} spacing={2}>
          {video.map((i) => (
            <Grid item key={uuidv4()}>
              <Box
                sx={{
                  position: "relative",
                  "& .MuiIconButton-root": {
                    "&:hover": {
                      backgroundColor: theme.palette.colors.brand.primary,
                    },
                  },
                }}
              >
                <ReactPlayer url={i.path} controls width="320px" height="180px" />
                <StyledButtonIcon onClick={() => onAttachmentDelete(i.id)}>
                  <StyledCloseIcon />
                </StyledButtonIcon>
              </Box>
            </Grid>
          ))}
        </Grid>
      );
    }
    return renderUploadButton();
  };

  return (
    <Grid container flexDirection="column">
      <Grid item xs={12}>
        <Typography>Upload video (Maximum 1 video)</Typography>
        <Spacer size="m" position="bottom" />
      </Grid>
      <Grid item xs={12}>
        {renderContent()}
      </Grid>
      <Grid item xs={12}>
        {errors[name] && (
          <Typography sx={{ color: theme.palette.darkColors.text.error }}>
            {errors[name]}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

PopUpVideoUpload.defaultProps = {
  video: [],
};

PopUpVideoUpload.propTypes = {
  name: PropTypes.string.isRequired,
  video: PropTypes.arrayOf(PropTypes.shape({ path: PropTypes.string })),
  onVideoUpload: PropTypes.func.isRequired,
  onAttachmentDelete: PropTypes.func.isRequired,
};

export default PopUpVideoUpload;
