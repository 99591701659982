import React from "react";
import { Grid } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function PopUpDetailLoader() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Skeleton width="350px" height="40px" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton width="350px" height="40px" />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Skeleton width="200px" height="150px" />
          </Grid>
          <Grid item>
            <Skeleton width="200px" height="150px" />
          </Grid>
          <Grid item>
            <Skeleton width="200px" height="150px" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PopUpDetailLoader;
