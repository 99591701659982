import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api.service";

export const getDeletedAccList = createAsyncThunk("accDeletion/acc_list", async (payload) => {
  const response = getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/account_deletions?accountableType=${
      payload.type
    }&page=${payload.page + 1}`,
  );
  return response;
});

export const deleteUser = createAsyncThunk("accDeletion/delete_user", async (payload) => {
  const response = postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/account_deletions/${payload}/complete`,
  );
  return response;
});

const accDeletionSlice = createSlice({
  name: "category",
  initialState: {
    getDeletedAccListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteUserObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [getDeletedAccList.pending]: (state) => {
      state.getDeletedAccListObj.status = "pending";
    },
    [getDeletedAccList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getDeletedAccListObj.status = "succeeded";
      state.getDeletedAccListObj.data = data;
      state.getDeletedAccListObj.successMessage = message;
    },
    [getDeletedAccList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getDeletedAccListObj.status = "failed";
      state.getDeletedAccListObj.errorMessage = message;
    },
    [deleteUser.pending]: (state) => {
      state.deleteUserObj.status = "pending";
    },
    [deleteUser.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteUserObj.status = "succeeded";
      state.deleteUserObj.data = data;
      state.deleteUserObj.successMessage = message;
    },
    [deleteUser.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteUserObj.status = "failed";
      state.deleteUserObj.errorMessage = message;
    },
  },
});

export default accDeletionSlice.reducer;

// state
export const accDeletionSelector = (state) => state.accDeletion;
