import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { putReq } from "../../api.service";

export const updateStaffImage = createAsyncThunk("staffs/{staff_id}/image", async (payload) => {
  const { image, staffId } = payload;
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/staffs/${staffId}/images`,
    { image },
  );
  return response;
});

const staffImageSlice = createSlice({
  name: "staffImage",
  initialState: {
    staffHasImage: false,
    updateStaffImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    setStaffHasImage: (state, action) => {
      const { payload } = action;
      state.staffHasImage = payload;
    },
  },
  extraReducers: {
    [updateStaffImage.pending]: (state) => {
      state.updateStaffImageObj.status = "pending";
    },
    [updateStaffImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.updateStaffImageObj.status = "succeeded";
      state.updateStaffImageObj.data = data;
      state.updateStaffImageObj.successMessage = message;
    },
    [updateStaffImage.rejected]: (state, action) => {
      const { message } = action.error;
      state.updateStaffImageObj.status = "failed";
      state.updateStaffImageObj.errorMessage = message;
    },
  },
});

export default staffImageSlice.reducer;

// state
export const staffImageSelector = (state) => state.staffImage;

// action
export const { setStaffHasImage } = staffImageSlice.actions;
