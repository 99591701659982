import { Box, Grid, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import CustomizedSnackbars from "../../../components/notification/snackbar.component";
import SimpleBackdrop from "../../../components/utils/backdrop-loading.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { getAnnouncementList } from "../../../services/announcement/announcement-slice.service";
import {
  publishNotification,
  searchConsumer,
} from "../../../services/notification/notification-slice.service";
import { getPopUpList } from "../../../services/pop-up/pop-up-slice.service";
import AnnouncementTableIndex from "../components/announcement-table-index.component";
import NotificationConsumerTypeSelection from "../components/notification-consumer-type-selection.component";
import NotificationSpecificTargetAutoComplete from "../components/notification-specific-target-auto-complete.component";
import NotificationTypeSelect from "../components/notification-type-select.component";
import PopUpTableIndex from "../../pop-up/components/pop-up-table-index.component";
// import PopUpTableIndex from "../components/pop-up-table-index.component";

const validationSchema = Yup.object().shape({
  group: Yup.string().required(),
  targetConsumersObj: Yup.array().of(Yup.object().shape({})).min(1).label("Targeted Consumer"),
  userList: Yup.array().of(Yup.object().shape({})),
  trainerList: Yup.array().of(Yup.object().shape({})),
  announcementsId: Yup.array()
    .of(Yup.string())
    .nullable()
    .when("group", {
      is: "announcement",
      then: Yup.array().of(Yup.string()).min(1).required(),
    })
    .label("Announcement"),
  popUpsId: Yup.array()
    .of(Yup.string())
    .nullable()
    .when("group", {
      is: "pop-up",
      then: Yup.array().of(Yup.string()).min(1).required(),
    })
    .label("Pop Up"),
});

const FlexStartRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
});

const LabelContainer = styled(Box)({
  display: "flex",
  width: "220px",
  height: "56px",
  alignItems: "center",
});

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

function NotificationScreen() {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [trainerList, setTrainerList] = useState([]);
  const [page, setPage] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [notificationType, setNotificationType] = useState("announcement");

  const showSuccessSnackBar = (message) => {
    setIsShowSnackBar(true);
    setSnackBarType("success");
    setSnackBarMsg(message);
  };

  const showErrorSnackBar = (message) => {
    setIsShowSnackBar(true);
    setSnackBarType("error");
    setSnackBarMsg(message);
  };

  const onPublishNotifications = (values) => {
    setIsLoading(true);
    const usersId = [];
    const trainersId = [];
    const targetConsumers = [];

    values.targetConsumersObj.map((item) => targetConsumers.push(item.value));
    values.userList.map((item) => usersId.push(item.id));
    values.trainerList.map((item) => trainersId.push(item.id));

    dispatch(publishNotification({ targetConsumers, usersId, trainersId, ...values })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          showSuccessSnackBar(payload.message);
        }
        if (meta.requestStatus === "rejected") {
          showErrorSnackBar(error.message);
        }
      },
    );
  };

  const refreshAnnouncementList = () => {
    dispatch(getAnnouncementList({ q: searchKeyword, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const handleAnnouncementPageChange = (newPage) => {
    setPage(newPage);
    dispatch(getAnnouncementList({ q: searchKeyword, page: newPage })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onChangeAnnouncementSearchKeyword = (keyword) => {
    setSearchKeyword(keyword);
    dispatch(getAnnouncementList({ q: keyword, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const refreshPopUpList = () => {
    dispatch(getPopUpList({ q: searchKeyword, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const handlePopUpPageChange = (newPage) => {
    setPage(newPage);
    dispatch(getPopUpList({ q: searchKeyword, page: newPage })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onChangePopUpSearchKeyword = (keyword) => {
    setSearchKeyword(keyword);
    dispatch(getPopUpList({ q: keyword, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const getUserList = () => {
    const tempItemList = [];
    const searchBody = { type: "user", prevQ: [], newQ: "" };
    dispatch(searchConsumer(searchBody)).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        payload.data.map((item) =>
          tempItemList.push({
            title: `${item.firstName} ${item.lastName} (${item.email})`,
            id: item.id,
          }),
        );
        setUserList(tempItemList);
      }
    });
  };

  const onUserSearchChange = (selectedList, words) => {
    const tempItemList = [];
    const searchBody = { type: "user", prevQ: selectedList, newQ: words };
    dispatch(searchConsumer(searchBody)).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        payload.data.map((item) =>
          tempItemList.push({
            title: item.title ? item.title : `${item.firstName} ${item.lastName} (${item.email})`,
            id: item.id,
          }),
        );
        setUserList(tempItemList);
      }
    });
  };

  const getTrainerList = () => {
    const tempItemList = [];
    const searchBody = { type: "trainer", prevQ: [], newQ: "" };
    dispatch(searchConsumer(searchBody)).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        payload.data.map((item) =>
          tempItemList.push({
            title: item.title ? item.title : `${item.firstName} ${item.lastName} (${item.email})`,
            id: item.id,
          }),
        );
        setTrainerList(tempItemList);
      }
    });
  };

  const onTrainerSearchChange = (selectedList, words) => {
    const tempItemList = [];
    const searchBody = { type: "trainer", prevQ: selectedList, newQ: words };
    dispatch(searchConsumer(searchBody)).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        payload.data.map((item) =>
          tempItemList.push({
            title: `${item.firstName} ${item.lastName} (${item.email})`,
            id: item.id,
          }),
        );
        setTrainerList(tempItemList);
      }
    });
  };

  useEffect(() => {
    refreshPopUpList();
    refreshAnnouncementList();
    getUserList();
    getTrainerList();
  }, []);

  const onChangeNotificationType = (type) => {
    setPage(0);
    setSearchKeyword("");
    setNotificationType(type);
  };

  const renderContent = () => (
    <Form
      initialValues={{
        group: "announcement",
        targetConsumersObj: [
          { label: "User", value: "user" },
          { label: "Personal Trainer", value: "trainer" },
        ],
        userList: [],
        trainerList: [],
        announcementsId: [],
        popUpsId: [],
      }}
      onSubmit={onPublishNotifications}
      validationSchema={validationSchema}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SpaceBetweenBox>
            <FlexStartRowBox>
              <LabelContainer sx={{ height: "40px" }}>
                <Typography>Type</Typography>
              </LabelContainer>
              <NotificationTypeSelect
                name="group"
                onChangeNotificationType={onChangeNotificationType}
              />
            </FlexStartRowBox>
            <FormSubmitButton width="125px">
              <Typography>Publish</Typography>
            </FormSubmitButton>
          </SpaceBetweenBox>
        </Grid>
        <Grid item xs={12}>
          <FlexStartRowBox>
            <LabelContainer sx={{ height: "42px" }}>
              <Typography>Targeted Consumer</Typography>
            </LabelContainer>

            <NotificationConsumerTypeSelection name="targetConsumersObj" />
          </FlexStartRowBox>
        </Grid>
        <Grid item xs={12}>
          <FlexStartRowBox>
            <LabelContainer>
              <Typography>Specify To User</Typography>
            </LabelContainer>

            <NotificationSpecificTargetAutoComplete
              optionList={userList}
              name="userList"
              placeholder="Enter user name"
              type="user"
              onSearchKeywordChange={onUserSearchChange}
            />
          </FlexStartRowBox>
        </Grid>
        <Grid item xs={12}>
          <FlexStartRowBox>
            <LabelContainer>
              <Typography>Specify To Personal Trainer</Typography>
            </LabelContainer>
            <NotificationSpecificTargetAutoComplete
              optionList={trainerList}
              name="trainerList"
              placeholder="Enter trainer name"
              type="trainer"
              onSearchKeywordChange={onTrainerSearchChange}
            />
          </FlexStartRowBox>
        </Grid>

        <Grid item xs={12}>
          {notificationType === "announcement" && (
            <AnnouncementTableIndex
              page={page}
              handlePageChange={handleAnnouncementPageChange}
              onChangeSearchKeyword={onChangeAnnouncementSearchKeyword}
              searchKeyword={searchKeyword}
            />
          )}
          {notificationType === "pop-up" && (
            <PopUpTableIndex
              page={page}
              handlePageChange={handlePopUpPageChange}
              onChangeSearchKeyword={onChangePopUpSearchKeyword}
              searchKeyword={searchKeyword}
            />
          )}
        </Grid>
      </Grid>
    </Form>
  );

  return (
    <PaddedView>
      <SimpleBackdrop isLoading={isLoading} />
      <CustomizedSnackbars
        isShow={isShowSnackBar}
        setShowSnackbar={setIsShowSnackBar}
        message={snackBarMsg}
        type={snackBarType}
      />
      <Spacer size="l" position="top" />
      {renderContent()}
    </PaddedView>
  );
}

export default NotificationScreen;
