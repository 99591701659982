import React from "react";
import PropTypes from "prop-types";
import { Box, Checkbox, styled, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

function AnnouncementRow({ record, onClickCheckbox }) {
  const theme = useTheme();
  const { values } = useFormikContext();

  return (
    <TableRow key={record.id}>
      <TableCell width="150px">
        <Typography variant="body2" noWrap={true}>
          {record.id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true}>
          {record.header}
        </Typography>
      </TableCell>
      <TableCell width="200px">
        <Typography variant="body2" noWrap={true}>
          {record.createdAt}
        </Typography>
      </TableCell>

      <TableCell width="100px">
        <RowBox>
          <Checkbox
            disableRipple
            sx={{
              "&.Mui-checked": {
                color: theme.palette.colors.brand.primary,
              },
              ":hover": { backgroundColor: "transparent" },
            }}
            onChange={() => onClickCheckbox(record.id)}
            checked={values.announcementsId.some(
              (checkedCheckbox) => checkedCheckbox === record.id,
            )}
          />
        </RowBox>
      </TableCell>
    </TableRow>
  );
}

AnnouncementRow.propTypes = {
  onClickCheckbox: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    header: PropTypes.string,
    subheader: PropTypes.string,
    content: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
};

export default AnnouncementRow;
