import { Box, Breadcrumbs, FormLabel, Grid, Link, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormPrice from "../../../../components/forms/form-price.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import OutlinedSelectTextField from "../../../../components/forms/outlined-select-text-field.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import SimpleBackdrop from "../../../../components/utils/backdrop-loading.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import MerchantListAutoComplete from "../components/merchant-list-autocomplete.component";
import { createAdditionalFees } from "../../../../services/merchant/additional-fees/additional-fees-slice.service";

const validationSchema = Yup.object().shape({
  merchantId: Yup.number().required().label("Merchant Id"),
  description: Yup.string().required().min(2).label("Description"),
  price: Yup.number().required().label("Price"),
  active: Yup.boolean().required().label("Status"),
});

function MerchantAdditionalFeesNewScreen() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const createSnackBar = useContext(SnackbarContext);

  const onHandleSubmit = (values) => {
    setIsLoading(true);
    const payloadValues = {
      ...values,
      amountCents: parseFloat(values.price * 100),
    };
    dispatch(createAdditionalFees(payloadValues)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({ type: "success", message: payload.message });
        navigate("/merchants/additional_fees/list");
      }
    });
  };

  return (
    <>
      <SimpleBackdrop isLoading={isLoading} />
      <Box>
        <PaddedView>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">New Additional Fees</Typography>
            </Grid>

            <Grid item sx={{ alignSelf: "center" }}>
              <Stack spacing={2} sx={{ paddingRight: 1 }}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link underline="hover" key="1" color="inherit" href="/">
                    <Typography variant="body1">Dashboard</Typography>
                  </Link>
                  <Link
                    underline="hover"
                    key="2"
                    color="inherit"
                    href="/merchants/additional_fees/list"
                  >
                    <Typography variant="body1">Additional Fees List</Typography>
                  </Link>
                  <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                    New Additional Fees
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>

            <Formik
              validationSchema={validationSchema}
              initialValues={{
                merchantId: null,
                description: "",
                price: "",
                active: false,
              }}
              onSubmit={onHandleSubmit}
            >
              {({ isValid }) => (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Grid item container sx={{ width: 200 }}>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            alignSelf: "center",
                          }}
                        >
                          <FormLabel>Details</FormLabel>
                        </Grid>
                      </Grid>

                      <Grid item container spacing={2}>
                        <Grid item xs={12}>
                          <Spacer size="m" />
                          <FormFieldText
                            name="description"
                            placeholder="Enter Description"
                            label="Description"
                            disabled={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MerchantListAutoComplete
                            name="selectedMerchant"
                            placeholder="Select a merchant"
                            disabled={false}
                            multiple={false}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormPrice
                            name="price"
                            placeholder="Enter Price"
                            label="Price"
                            disabled={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <OutlinedSelectTextField
                            name="active"
                            label="Status"
                            disabled={false}
                            selections={[
                              { type: "Active", value: true },
                              { type: "Inactive", value: false },
                            ]}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Grid container justifyContent="flex-end">
                            <Grid item sm={2} xs={12}>
                              <FormSubmitButton type="submit" disabled={!isValid}>
                                <Typography>Create</Typography>
                              </FormSubmitButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
        </PaddedView>
      </Box>
    </>
  );
}

export default MerchantAdditionalFeesNewScreen;
