import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { FormLabel, Grid, IconButton } from "@mui/material";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import PaddedView from "../../../../components/utils/padded-view.component";

import FormPrice from "../../../../components/forms/form-price.component";
import OutlinedSelectTextField from "../../../../components/forms/outlined-select-text-field.component";
import Spacer from "../../../../components/utils/spacer.component";

import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import { updateAdditionalFees } from "../../../../services/merchant/additional-fees/additional-fees-slice.service";

const validationSchema = Yup.object().shape({
  feeId: Yup.number().required(),
  description: Yup.string().required().label("Description"),
  amountCents: Yup.number().required().label("Price"),
  active: Yup.boolean().label("Status"),
});

function AdditionalFeesDetail({ record }) {
  const [allowEdit, setAllowEdit] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);

  const onHandleSubmit = (values) => {
    setAllowEdit(false);
    const payloadValues = {
      ...values,
      amountCents: parseFloat(values.amountCents * 100),
    };

    dispatch(updateAdditionalFees(payloadValues)).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({ type: "success", message: payload.message });
      }
    });
  };

  return (
    <>
      <PaddedView>
        <Formik
          validationSchema={validationSchema}
          onSubmit={onHandleSubmit}
          initialValues={{
            feeId: record.id,
            description: record.description,
            amountCents: record.price.replace(",", ""),
            active: record.active,
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Grid item container sx={{ width: 200 }}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        alignSelf: "center",
                      }}
                    >
                      <FormLabel>Details</FormLabel>
                    </Grid>
                    <Grid item container xs={6} sx={{ justifyContent: "flex-end" }}>
                      {allowEdit ? (
                        <>
                          <IconButton size="small" onClick={handleSubmit}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setAllowEdit(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAllowEdit(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <Spacer size="m" />
                      <FormFieldText
                        name="description"
                        placeholder="Enter Description"
                        label="Description"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Spacer size="m" />
                      <FormPrice
                        name="amountCents"
                        placeholder="Enter Price"
                        label="Price"
                        disabled={!allowEdit}
                      />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                      <OutlinedSelectTextField
                        name="active"
                        label="Status"
                        disabled={!allowEdit}
                        selections={[
                          { type: "Active", value: true },
                          { type: "Inactive", value: false },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </PaddedView>
    </>
  );
}

AdditionalFeesDetail.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    merchant: PropTypes.shape({
      id: PropTypes.number.isRequired,
      businessName: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default AdditionalFeesDetail;
