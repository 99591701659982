import React from "react";
import { Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";

import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import BorderCtaButton from "../../../../components/button/border-cta-button.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import FormMultiSelect from "../../../../components/forms/form-multi-select.component";
import MerchantListAutoComplete from "./merchant-list-autocomplete.component";
import PlanSpecificListAutoComplete from "./plan-specific-list-autocomplete.component";

function FilterOptions() {
  const theme = useTheme();
  const { resetForm, handleSubmit } = useFormikContext();

  const handleClearForm = () => {
    resetForm();
    handleSubmit();
  };

  return (
    <>
      <PaddedView>
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.colors.bg.secondary,
            borderRadius: `${theme.shape.borderRadius[1]}px`,
          }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <PaddedView>
                      <MerchantListAutoComplete
                        name="merchantsId"
                        placeholder="Merchant"
                        multiple={true}
                      />
                    </PaddedView>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PaddedView>
                      <PlanSpecificListAutoComplete
                        name="plansId"
                        placeholder="Subscription Plans"
                      />
                    </PaddedView>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <PaddedView>
                      <FormFieldText
                        name="q"
                        placeholder="Search for user by name or phone number"
                        label="Search"
                      />
                    </PaddedView>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <PaddedView>
                      <FormMultiSelect
                        name="statuses"
                        label="Statuses"
                        options={[
                          { label: "Active", value: "active" },
                          { label: "Pending Activation", value: "pending_activation" },
                          { label: "Expired", value: "expired" },
                        ]}
                      />
                    </PaddedView>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <FormSubmitButton type="submit">
                    <Typography>Search</Typography>
                  </FormSubmitButton>
                </PaddedView>
              </Grid>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <BorderCtaButton onClickButton={handleClearForm}>
                    <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                      Clear
                    </Typography>
                  </BorderCtaButton>
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
    </>
  );
}

export default FilterOptions;
