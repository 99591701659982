import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, putReq, delReq, postReq } from "../api.service";

export const createStaff = createAsyncThunk("staffs/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/staffs`,
    payload,
  );
  return response;
});

export const getStaffs = createAsyncThunk("staffs", async (payload) => {
  const { page, q, roles, gymsIdAccess } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/staffs?page=${page + 1}${
      q ? "&q=".concat(q) : ""
    }${roles ? "&roles=".concat(roles) : ""}${
      gymsIdAccess ? "&gymsIdAccess=".concat(gymsIdAccess) : ""
    }`,
  );
  return response;
});

export const getStaff = createAsyncThunk("staffs/{staff_id}", async (payload) => {
  const { staffId } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/staffs/${staffId}`,
  );
  return response;
});

export const updateStaff = createAsyncThunk("staffs/{staff_id}/edit", async (payload) => {
  const { firstName, lastName, email, staffId } = payload;
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/staffs/${staffId}`,
    {
      firstName,
      lastName,
      email,
    },
  );
  return response;
});

export const deleteStaff = createAsyncThunk("staffs/{staff_id}/delete", async (payload) => {
  const { staffId } = payload;
  const response = await delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/staffs/${staffId}`,
  );
  return response;
});

const staffSlice = createSlice({
  name: "staff",
  initialState: {
    staffHasImage: false,
    createStaffObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getStaffsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getStaffObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateStaffObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteStaffObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [createStaff.pending]: (state) => {
      state.createStaffObj.status = "pending";
    },
    [createStaff.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.createStaffObj.status = "succeeded";
      state.createStaffObj.data = data;
      state.createStaffObj.successMessage = message;
    },
    [createStaff.rejected]: (state, action) => {
      const { message } = action.error;
      state.createStaffObj.status = "failed";
      state.createStaffObj.errorMessage = message;
    },
    [getStaffs.pending]: (state) => {
      state.getStaffsObj.status = "pending";
    },
    [getStaffs.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.getStaffsObj.status = "succeeded";
      state.getStaffsObj.data = data;
      state.getStaffsObj.successMessage = message;
    },
    [getStaffs.rejected]: (state, action) => {
      const { message } = action.error;
      state.getStaffsObj.status = "failed";
      state.getStaffsObj.errorMessage = message;
    },
    [getStaff.pending]: (state) => {
      state.getStaffObj.status = "pending";
    },
    [getStaff.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.getStaffObj.status = "succeeded";
      state.getStaffObj.data = data;
      state.getStaffObj.successMessage = message;
    },
    [getStaff.rejected]: (state, action) => {
      const { message } = action.error;
      state.getStaffObj.status = "failed";
      state.getStaffObj.errorMessage = message;
    },
    [updateStaff.pending]: (state) => {
      state.updateStaffObj.status = "pending";
    },
    [updateStaff.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.updateStaffObj.status = "succeeded";
      state.updateStaffObj.data = data;
      state.updateStaffObj.successMessage = message;
    },
    [updateStaff.rejected]: (state, action) => {
      const { message } = action.error;
      state.updateStaffObj.status = "failed";
      state.updateStaffObj.errorMessage = message;
    },
    [deleteStaff.pending]: (state) => {
      state.deleteStaffObj.status = "pending";
    },
    [deleteStaff.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      state.deleteStaffObj.status = "succeeded";
      state.deleteStaffObj.data = data;
      state.deleteStaffObj.successMessage = message;
    },
    [deleteStaff.rejected]: (state, action) => {
      const { message } = action.error;
      state.deleteStaffObj.status = "failed";
      state.deleteStaffObj.errorMessage = message;
    },
  },
});

export default staffSlice.reducer;

// state
export const staffSelector = (state) => state.staff;

// action
// export const { } = staffSlice.actions;
