import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import MerchantDeleteConfirmationModal from "../../../components/notification/confirmation-modal.component";
import MerchantBusinessListTableIndex from "../components/merchant-business-list/merchant-business-list-table-index.component";
import Form from "../../../components/forms/form.component";
import FilterOption from "../components/merchant-business-list/filter-option.component";
import TableLoader from "../../../components/tables/table-loader.component";
import {
  deleteMerchantBusiness,
  merchantBusinessSelector,
  searchMerchantBusiness,
} from "../../../services/merchant/business/merchant-business-slice.service";

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable().label("Search"),
  startDate: Yup.date().label("Start date").nullable().typeError("Invalid date"),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "End date can't be before start date")
    .label("End date")
    .nullable()
    .when("startDate", {
      is: (startDate) => startDate,
      then: Yup.date()
        .min(Yup.ref("startDate"), "End date can't be before start date")
        .label("End date")
        .typeError("End date is required")
        .required(),
    }),
  statuses: Yup.array().nullable().label("Statuses"),
});

export default function MerchantBusinessListScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [page, setPage] = useState(0);
  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [objToDelete, setObjToDelete] = useState(null);
  const { deleteMerchantBusinessObj, searchMerchantBusinessObj } =
    useSelector(merchantBusinessSelector);

  useEffect(() => {
    dispatch(searchMerchantBusiness({ q, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  }, []);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(
      searchMerchantBusiness({
        q,
        page: newPage,
        createdStartAt: startAt,
        createdEndAt: endAt,
        statuses,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  };

  const onToggleDelete = (obj) => {
    setObjToDelete(obj);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    dispatch(deleteMerchantBusiness(objToDelete)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setQ("");
        setPage(0);
        setShowDeleteModal(false);
        createSnackBar({ type: "success", message: payload.message });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: error.message });
      }
    });
  };

  const onHandleSubmit = (values) => {
    let createdStartAt = "";
    let createdEndAt = "";
    if (values.startDate !== null) {
      createdStartAt = format(new Date(values.startDate), "yyyy-MM-dd 00:00:00");
    }
    if (values.endDate !== null) {
      createdEndAt = format(new Date(values.endDate), "yyyy-MM-dd 23:59:59");
    }
    setQ(values.q);
    setPage(0);
    setStartAt(createdStartAt);
    setEndAt(createdEndAt);
    setStatuses(values.statuses);
    dispatch(
      searchMerchantBusiness({
        q: values.q,
        page: 0,
        createdStartAt,
        createdEndAt,
        statuses: values.statuses,
      }),
    );
  };

  const renderDataRecords = () => {
    const records = searchMerchantBusinessObj.data;

    return (
      <>
        {records ? (
          <PaddedView>
            <MerchantBusinessListTableIndex
              handlePageChange={handlePageChange}
              page={page}
              onToggleDelete={onToggleDelete}
              records={records}
            />
          </PaddedView>
        ) : (
          <Typography>No businesses.</Typography>
        )}
      </>
    );
  };

  return (
    <Box>
      <MerchantDeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title="Remove Business"
        message="Are you sure to remove this business?"
        onSubmit={onConfirmDelete}
        isDisabled={deleteMerchantBusinessObj.status === "pending"}
      />
      <Form
        initialValues={{
          q: "",
          startDate: null,
          endDate: null,
          statuses: [],
        }}
        onSubmit={onHandleSubmit}
        validationSchema={validationSchema}
      >
        <FilterOption />
      </Form>
      <Spacer size="l" />
      {searchMerchantBusinessObj.status === "succeeded" ? (
        <>{renderDataRecords()}</>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </Box>
  );
}
