import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
// import Text from "../../../components/text.component";

export default function SingleSelect({ itemList, name, placeholder, disabled }) {
  const { values, setFieldValue, touched, errors, setFieldTouched } = useFormikContext();
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  return (
    <FormControl
      fullWidth
      sx={{
        "& .MuiOutlinedInput-root": {
          borderTopLeftRadius: theme.shape.borderRadius[1],
          borderTopRightRadius: theme.shape.borderRadius[1],
          borderBottomLeftRadius: theme.shape.borderRadius[1],
          borderBottomRightRadius: theme.shape.borderRadius[1],
          backgroundColor: theme.palette.colors.bg.primary,
        },
      }}
    >
      <Select
        onBlur={() => setFieldTouched(name)}
        value={
          values[name] === null || values[name] === undefined || itemList.length === 0
            ? ""
            : values[name]
        }
        disabled={disabled}
        onChange={handleChange}
        inputProps={{ "aria-label": "Without label" }}
        error={showError}
        displayEmpty
      >
        <MenuItem value="" disabled>
          <Typography sx={{ color: theme.palette.colors.text.disabled }}>{placeholder}</Typography>
        </MenuItem>
        {itemList.map((item) => (
          <MenuItem key={item.id} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText error={showError}>{errors[name]}</FormHelperText>}
    </FormControl>
  );
}

SingleSelect.defaultProps = {
  disabled: false,
};

SingleSelect.propTypes = {
  itemList: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.number, id: PropTypes.number }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
