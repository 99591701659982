import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../../components/notification/dialog-boxes.component";
import CustomizedSnackbars from "../../../components/notification/snackbar.component";
import SimpleBackdrop from "../../../components/utils/backdrop-loading.component";
import {
  announcementSelector,
  deleteAnnouncement,
  getAnnouncementList,
} from "../../../services/announcement/announcement-slice.service";
import AnnouncementTableIndex from "../components/announcement-table-index.component";
import SearchAnnouncement from "../components/search-announcement-title.component";
import AnnouncementTableLoader from "../loader/announcement-table-loader.component";

function AnnouncementListScreen() {
  const [page, setPage] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { getAnnouncementListObj, deleteAnnouncementObj } = useSelector(announcementSelector);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [announcementId, setAnnouncementId] = useState("");

  const showErrorSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("error");
    setIsShowSnackBar(true);
  };

  const showSuccessSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("success");
    setIsShowSnackBar(true);
  };

  const refreshCurrentPage = () => {
    dispatch(getAnnouncementList({ q: searchKeyword, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setIsLoading(false);
        setIsShowDeleteModal(false);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  useEffect(() => {
    dispatch(getAnnouncementList({ q: searchKeyword, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  }, []);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getAnnouncementList({ q: searchKeyword, page: newPage })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onChangeSearchKeyword = (keyword) => {
    setSearchKeyword(keyword);
    dispatch(getAnnouncementList({ q: keyword, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const onClickDelete = (id) => {
    setAnnouncementId(id);
    setIsShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteAnnouncement(announcementId)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        showSuccessSnackBar(payload.message);
        refreshCurrentPage();
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  const renderDataRecords = () => {
    if (getAnnouncementListObj.status === "succeeded") {
      return (
        <AnnouncementTableIndex
          onClickDelete={onClickDelete}
          records={getAnnouncementListObj.data}
          handlePageChange={handlePageChange}
          page={page}
        />
      );
    }
    return <AnnouncementTableLoader />;
  };

  return (
    <>
      <CustomizedSnackbars
        isShow={isShowSnackBar}
        setShowSnackbar={setIsShowSnackBar}
        message={snackBarMsg}
        type={snackBarType}
      />
      <SimpleBackdrop isLoading={isLoading} />
      <AlertDialog
        isShow={isShowDeleteModal}
        setIsShowAlert={setIsShowDeleteModal}
        isConfirmHandle={onConfirmDelete}
        title="Confirmation"
        message="Are you sure to delete ?"
        buttonText="Confirm"
        disabled={
          deleteAnnouncementObj.status === "pending" || getAnnouncementListObj.status === "pending"
        }
      />
      <SearchAnnouncement
        placeholder="Search list by header"
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
      {renderDataRecords()}
    </>
  );
}

export default AnnouncementListScreen;
