import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

function BackdropImage({ children, bgImg }) {
  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient( rgba(35, 35, 35, 0.5), rgba(35, 35, 35, 0.8)), url(${bgImg})`,
        backgroundSize: "cover",
        height: "100vh",
        opacity: 1,
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
}

BackdropImage.propTypes = {
  bgImg: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default BackdropImage;
