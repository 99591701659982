import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import TableWrapper from "../../../../components/tables/table-wrapper.component";
import TableHeader from "./table-header.component";
import Row from "./row.component";

function TableIndex({ page, handlePageChange, setSortCate, sortCate, records }) {
  return (
    <TableWrapper>
      <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
        <TableHeader
          headerTitle="Subscribers List"
          setSortCate={setSortCate}
          sortCate={sortCate}
          headerCells={["Image", "Name", "Plan", "Price", "Days", "Tenure", "Status", "Action"]}
        />

        <TableBody>
          {records.items.map((record) => (
            <Row record={record} key={uuidv4()} />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={records.pagination.perPage}
              count={records.pagination.totalItems}
              rowsPerPageOptions={[]}
              showFirstButton={true}
              showLastButton={true}
              onPageChange={handlePageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableWrapper>
  );
}

TableIndex.propTypes = {
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  sortCate: PropTypes.shape({
    name: PropTypes.oneOf(["idle", "asc", "dsc"]),
    plan: PropTypes.oneOf(["idle", "asc", "dsc"]),
    price: PropTypes.oneOf(["idle", "asc", "dsc"]),
    validity: PropTypes.oneOf(["idle", "asc", "dsc"]),
    tenure: PropTypes.oneOf(["idle", "asc", "dsc"]),
    status: PropTypes.oneOf(["idle", "asc", "dsc"]),
  }).isRequired,
  setSortCate: PropTypes.func.isRequired,
  records: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        start_at: PropTypes.string,
        end_at: PropTypes.string,
        status: PropTypes.oneOf(["active", "expired", "pending_activation"]),
        user: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          phone: PropTypes.string,
          imagePath: PropTypes.string,
        }),
        merchant: PropTypes.shape({
          id: PropTypes.number.isRequired,
          businessName: PropTypes.string.isRequired,
        }),
        plan: PropTypes.shape({
          title: PropTypes.string.isRequired,
          description: PropTypes.string,
          shortDescription: PropTypes.string.isRequired,
          numberOfDays: PropTypes.number.isRequired,
        }).isRequired,
        price: PropTypes.string.isRequired,
        businesses: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }).isRequired,
    ).isRequired,
    pagination: PropTypes.shape({
      perPage: PropTypes.number.isRequired,
      totalItems: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default TableIndex;
