import React from "react";
import PropTypes from "prop-types";
import { Box, FormHelperText, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format, isValid } from "date-fns";

function FormPickerDate({ name, label, disabled }) {
  const { setFieldValue, values, touched, errors } = useFormikContext();
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (date) => {
    setFieldValue(name, isValid(date) ? format(date, "yyyy-MM-dd") : date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <DatePicker
          disabled={disabled}
          inputFormat="dd/MM/yyyy"
          value={values[name]}
          onChange={(date) => {
            handleChange(date);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={showError}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderTopLeftRadius: theme.shape.borderRadius[1],
                  borderTopRightRadius: theme.shape.borderRadius[1],
                  borderBottomLeftRadius: theme.shape.borderRadius[1],
                  borderBottomRightRadius: theme.shape.borderRadius[1],
                  backgroundColor: theme.palette.colors.bg.primary,
                },
              }}
            />
          )}
        />
        {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      </Box>
    </LocalizationProvider>
  );
}

FormPickerDate.defaultProps = {
  disabled: false,
};

FormPickerDate.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default FormPickerDate;
