import React from "react";
import PropTypes from "prop-types";
import { Button, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function CtaButton({ children, width, disabled, onClickButton }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        "& .MuiButton-root": {
          borderTopLeftRadius: theme.shape.borderRadius[3],
          borderTopRightRadius: theme.shape.borderRadius[3],
          borderBottomLeftRadius: theme.shape.borderRadius[3],
          borderBottomRightRadius: theme.shape.borderRadius[3],
          textTransform: "none",
          padding: 1,
          "&:hover": {
            backgroundColor: theme.palette.colors.brand.primary,
          },
        },
      }}
    >
      <Button
        variant="contained"
        disabled={disabled}
        onClick={() => onClickButton !== null && onClickButton()}
        sx={{ backgroundColor: theme.palette.colors.brand.primary, width }}
      >
        {children}
      </Button>
    </Box>
  );
}

CtaButton.defaultProps = {
  width: "100%",
  disabled: false,
  onClickButton: null,
};

CtaButton.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  onClickButton: PropTypes.func,
};

export default CtaButton;
