import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Link, TableCell, TableRow, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import React, { useState } from "react";
import DeletionModalConfirmation from "./deletion-modal-confirmation.component";

function AdditionalFeesRow({ record, onToggleDelete }) {
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  return (
    <>
      <DeletionModalConfirmation
        showDeletionModal={showDeletionModal}
        setShowDeletionModal={setShowDeletionModal}
        record={record}
        handleOnPressDelete={onToggleDelete}
      />
      <TableRow
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell sx={{ width: "50px" }}>
          <Typography variant="body2">{record.id}</Typography>
        </TableCell>
        <TableCell sx={{ width: "300px" }}>
          <Typography variant="body2">{record.description}</Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography variant="body2">{`RM ${record.price}`}</Typography>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Typography variant="body2">{record.active ? "Active" : "Inactive"}</Typography>
        </TableCell>
        <TableCell sx={{ width: "300px" }}>
          <Typography variant="body2">{record.createdAt}</Typography>
        </TableCell>
        <TableCell sx={{ width: "150px" }}>
          <Link color="inherit" href={`/merchants/edit?merchantId=${record.merchant.id}`}>
            <Typography variant="body2" noWrap={true}>
              {record.merchant.businessName}
            </Typography>
          </Link>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <RouterLink
            to={{ pathname: "/merchants/additional_fees/edit", search: `feeId=${record.id}` }}
            state={{ ...record }}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </RouterLink>
          <IconButton
            onClick={() => {
              setShowDeletionModal(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

AdditionalFeesRow.defaultProps = {
  record: {
    id: null,
    description: "",
    price: "",
    active: "",
    createdAt: "",
    merchant: {
      id: null,
      businessName: "",
    },
  },
};

AdditionalFeesRow.propTypes = {
  onToggleDelete: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    price: PropTypes.string,
    active: PropTypes.bool,
    createdAt: PropTypes.string,
    merchant: PropTypes.shape({
      id: PropTypes.number,
      businessName: PropTypes.string,
    }),
  }),
};

export default AdditionalFeesRow;
